import { get } from "../../../shared/utilities/Utils";

// TODO: this class is additional abstraction layer
export class AceSnapshot<T = any> {
    private _data: T;

    constructor(data: T = null) {
        this.update(data);
    }

    public update<V extends T>(data: V) {
        this._data = data;
    }

    public get(path: string, defaultValue = null) {
        return get(this._data, path, defaultValue);
    }
}
