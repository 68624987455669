import moment from "moment";
import { DiscountHelper } from "../../../shared/utilities/DiscountHelper";
import { BookingOrderVoucher, VoucherBase } from "../discount-voucher";
import { DiscountBase, DiscountNus, DiscountPromotion } from "../discount.model";
import { IAceConfig } from "../interfaces/IAceConfig";
import { TicketDeliveryCode } from "../entities/ticket";
import { AceBookingOrder } from "./ace-booking-order";
import { AceBookingPayment } from "./ace-booking-payment";
import { AcePassenger } from "./payloads/ace-passenger";

export class AceBooking {
    public bookingID: string;
    public status: DataModel.BookingStatus;
    public isEmpty: boolean;
    public bookingTotal: DataModel.MoneyType;
    public bookingOrders: AceBookingOrder[] = [];
    public passengers: AcePassenger[] = [];
    public expires: moment.Moment;
    public numberOfAdultPassengers: number = 0;
    public numberOfChildPassengers: number = 0;
    public paymentRequirements: DataModel.PaymentRequirements;
    public financials: DataModel.Financials;
    public payments: AceBookingPayment[];
    public customInformation: DataModel.CustomInformationPair[];
    public vouchers: VoucherBase[] = [];
    public defaultCurrency = "GBP";
    public defaultCurrencySymbol = "£";
    public defaultTickingOptions: any;
    public paymentInformation: DataModel.PaymentInformation;
    private bookingOrderAmount: number;
    public isBookingForJourneyAmends: boolean;

    constructor(data: DataModel.BookingRecord | null = null, config: IAceConfig | null = null, isBookingAmendable: boolean = false) {
        if (config) {
            this.defaultCurrency = config.defaultCurrency;
            this.defaultCurrencySymbol = config.defaultCurrencySymbol;
            this.defaultTickingOptions = config.ticketingOptions;
        }
        this.isBookingForJourneyAmends = isBookingAmendable;

        if (data) {
            this.init(data);
        } else {
            this.initEmpty();
        }
    }

    public get hasITSOFulfilment(): boolean {
        return this.bookingOrders.some(
            (order: AceBookingOrder) => order.fulfillmentInformation && order.fulfillmentInformation.selectedTicketingOption.code === TicketDeliveryCode.SCT
        );
    }

    public get isNewSmartcardRequested(): boolean {
        return (
            this.hasITSOFulfilment && this.passengers.some((passenger: AcePassenger) => passenger.smartCardNumber && passenger.smartCardNumber.indexOf("111111111111111111") > -1)
        );
    }

    public hasMultipleOrders(): boolean {
        return this.bookingOrderAmount > 1;
    }

    public hasNoValidBookings(): boolean {
        return this.bookingOrderAmount === 0;
    }

    public getOrder(id: string): AceBookingOrder {
        return this.bookingOrders.find(order => order.orderID === id);
    }

    public isOpenReturn(orderId: string): boolean {
        const type = this.customInformation.find(it => it.key === `sourceSearchType-${orderId}`);
        return type ? type.value === "OPEN" : false;
    }

    public isReturn(orderId: string): boolean {
        const type = this.customInformation.find(it => it.key === `sourceSearchType-${orderId}`);
        return type ? type.value === "RETURN" : false;
    }

    public getSingleBookingDelivery() {
        return this.bookingOrders[0].fulfillmentInformation;
    }

    public hasSeasonTicket(): boolean {
        return this.bookingOrders.length ? this.bookingOrders[0].isSeasons : false;
    }

    public getPaymentSummary() {
        return this.payments[0] || null;
    }

    public getVoucher(orderId: string) {
        return this.vouchers.find(voucher => voucher.orderId === orderId);
    }

    public getDiscountType(discount: DiscountBase): string {
        if (discount instanceof DiscountPromotion) {
            return DiscountHelper.DISCOUNT_TYPE_PROMOTION;
        }

        if (discount instanceof DiscountNus) {
            return DiscountHelper.DISCOUNT_TYPE_NUS;
        }
    }

    private init(booking: DataModel.BookingRecord) {
        this.isEmpty = false;
        this.bookingID = booking.recordLocator;
        this.bookingTotal = booking.revenueTotal;
        this.status = booking.status;
        this.bookingOrderAmount = booking.orders.length;
        this.bookingOrders = booking.orders.map(order => new AceBookingOrder(order, this.defaultTickingOptions)).filter(order => order.status !== "RELEASED");
        this.isEmpty = this.bookingOrders.length === 0 || this.status === "CLOSED";
        this.paymentInformation = booking.paymentInformation;

        if (this.bookingOrders.length > 0) {
            this.expires = this.bookingOrders.map(order => order.expires).reduce((a, b) => (a?.unix() > b?.unix() ? a : b));
            this.parsePassengers(booking);
            this.parseFinancials(booking);
            this.paymentRequirements = booking.paymentRequirements;
            this.customInformation = booking.customInformation;
            this.vouchers = booking.orders.map(order => new BookingOrderVoucher(booking.discountInformation, order.orderID)).filter(voucher => Object.keys(voucher).length !== 0);
        }
    }

    private initEmpty() {
        this.isEmpty = true;
        this.bookingTotal = {
            value: 0,
            currency: this.defaultCurrency,
            currencySymbol: this.defaultCurrencySymbol
        };
    }

    private parsePassengers(booking: DataModel.BookingRecord): void {
        // go through each passenger
        this.passengers = booking.passengers.map(passenger => {
            const p = new AcePassenger();
            p.setNameFirst(passenger.nameFirst);
            p.setNameLast(passenger.nameLast);
            p.setAgeAtTimeOfTravel(passenger.ageAtTimeOfTravel);
            p.setPassengerID(passenger.passengerID);
            p.smartCardNumber = passenger.smartCardNumber;
            p.cardReference = (<any>passenger).cardReference;
            p.setPassengerTitle(passenger.title);
            return p;
        });

        this.numberOfAdultPassengers = this.passengers.filter(passenger => passenger.isAdult).length;
        this.numberOfChildPassengers = this.passengers.filter(passenger => passenger.isChild).length;
    }

    private parseFinancials(booking: DataModel.BookingRecord) {
        this.financials = booking.financials;
        this.payments = booking.financials.payments.filter(p => p.type === "PAYMENT").map(payment => new AceBookingPayment(payment));
        this.payments.sort((a, b) => (a.postingDate < b.postingDate ? 1 : -1));
    }
}
