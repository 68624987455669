import { Direction } from "../../enums/Direction";
import { AceBookingOrder } from "../../models/ace/ace-booking-order";
import { AceSelection } from "../../models/ace/ace-selection.model";
import { FareFilterEvent } from "../../models/entities/fare";

type FastestLegsDetailsPayload = { fastestLegs: { out: string; rtn: string } };

export class FilterFares {
    static readonly type = "[Journey] Filter fares";
    constructor(public payload: FareFilterEvent) {}
}

export class UpdateJourneyDetails {
    static readonly type = "[Journey] Update details";
    constructor(public payload: FastestLegsDetailsPayload) {}
}

export class ActiveJourneyAmend {
    static readonly type = "[Journey] Amend Active";
    constructor(public payload: boolean) {}
}

export class UpdateJourneyAmend {
    static readonly type = "[Journey] Amend update";
    constructor(public payload: boolean) {}
}
export class UpdateQTTDialog {
    static readonly type = "[Journey] Amend QTT Dialog update";
    constructor(public payload: boolean) {}
}

export class UpdateJourneyAmendOrder {
    static readonly type = "[Journey] AmendOrder update";
    constructor(public payload: AceBookingOrder) {}
}
export class UpdateJourneyAmendBooking {
    static readonly type = "[Journey] AmendBooking update";
    constructor(public payload: object) {}
}

export class UpdateJourneyAmendDirection {
    static readonly type = "[Journey] AmendDirection update";
    constructor(public payload: Direction) {}
}
export class UpdateJourneyExchangeSummary {
    static readonly type = "[Journey] AmendExchangeSummary update";
    constructor(public payload: object) {}
}
export class UpdateJourneyExchangeSelection {
    static readonly type = "[Journey] AmendExchangeSelection update";
    constructor(public payload: object) {}
}
export class ClearFareFilter {
    static readonly type = "[Journey] Clear filters";
}
export class ClearJourneyState {
    static readonly type = "[Journey] Clear State";
}
