import { ISelectOption } from "../../../shared/components/select/select.component";

export class SeatOptions {
    public direction: ISelectOption[] = [{ label: "Don't mind", value: "none", selected: true }];
    public position: ISelectOption[] = [{ label: "Don't mind", value: "none", selected: true }];
    public coach: ISelectOption[] = [{ label: "Don't mind", value: "none", selected: true }];

    public table: boolean = false;
    public power: boolean = false;
    public luggage: boolean = false;
    public lavatory: boolean = false;
    public nearby: boolean = false;

    constructor(seatPreferences: DataModel.AvailableSeatPreferenceType[]) {
        seatPreferences.map(type => {
            if (["BACKWARD", "FORWARD", "AIRLINE"].indexOf(type.value) > -1) {
                this.direction.push({ label: this.formatLabel(type.value), value: type.value, selected: false });
            }

            if (["AISLE", "WINDOW", "MIDDLE"].indexOf(type.value) > -1) {
                this.position.push({ label: this.formatLabel(type.value), value: type.value, selected: false });
            }

            if (type.value === "QUIET") {
                this.coach.push({ label: this.formatLabel(type.value), value: type.value, selected: false });
            }

            if (type.value === "LAVATORY") {
                this.lavatory = true;
            }

            if (type.value === "LUGGAGE") {
                this.luggage = true;
            }

            if (type.value === "NEARBY") {
                this.nearby = true;
            }

            if (type.value === "POWER") {
                this.power = true;
            }

            if (type.value === "TABLE") {
                this.table = true;
            }
        });
    }

    private formatLabel(label: string): string {
        return label.toLowerCase().replace(/\b[a-z]/g, word => word.toUpperCase());
    }
}
