import moment from "moment";
import { ITravelPointType } from "../interfaces/ISearchResult";
import { ITravelPoint } from "../interfaces/ITravelPoint";
import { AceLegSolution } from "./ace-leg-solution.model";

export class AceSearchResultDirection {
    public originTravelPoint: ITravelPoint;
    public destinationTravelPoint: ITravelPoint;
    public legSolutions: AceLegSolution[];
    public representativeDateTime: moment.Moment;
    public representativeTimestamp: number;

    constructor(data: DataModel.Leg) {
        this.init(data);
    }

    private init(data: DataModel.Leg): void {
        const parseTravelPointType = (type: string) => (type === "STATION" ? ITravelPointType.STATION : ITravelPointType.UNKNOWN);

        this.originTravelPoint = {
            facet: data.originTravelPoint["facet"],
            code: data.originTravelPoint.code,
            type: parseTravelPointType(data.originTravelPoint.type)
        };

        this.destinationTravelPoint = {
            facet: data.destinationTravelPoint["facet"],
            code: data.destinationTravelPoint.code,
            type: parseTravelPointType(data.destinationTravelPoint.type)
        };

        this.legSolutions = data.legSolutions.map(leg => new AceLegSolution(leg)).sort((a, b) => (a.departureDateTime < b.departureDateTime ? -1 : 1));

        const firstLegSolution = this.legSolutions[0];
        this.representativeDateTime = firstLegSolution.departureDateMoment;
        this.representativeTimestamp = firstLegSolution.departureTimestamp;
    }
}
