import { AbstractControl, ValidatorFn, Validators } from "@angular/forms";
import { PHONE_NUMBER_ALLOWED_CHARS } from "./../utilities/Utils";
export class PhoneValidators {
    public static readonly PHONE_NUMBER_MIN_CHARS = 5;
    public static readonly PHONE_NUMBER_MAX_CHARS = 64;

    public static checkNumber(): ValidatorFn {
        return (control: AbstractControl): { [key: string]: any } => {
            if (control.value) {
                let value = control.value;
                PHONE_NUMBER_ALLOWED_CHARS.forEach(char => (value = value.split(char).join("")));
                const pattern = new RegExp(/^\+?[\d\s]+$|(^$)/);
                return pattern.test(String(value)) ? null : { validnumber: true };
            }
        };
    }

    public static maxLength() {
        return Validators.maxLength(this.PHONE_NUMBER_MAX_CHARS);
    }

    public static minLength() {
        return Validators.minLength(this.PHONE_NUMBER_MIN_CHARS);
    }
}
