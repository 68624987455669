import { Injectable } from "@angular/core";
import { Store } from "@ngxs/store";
import { combineLatest, Observable, throwError } from "rxjs";
import { catchError, map, mergeMap, take } from "rxjs/operators";
import { environment } from "../../environments/environment";
import { POINT_TO_POINT_REQUEST } from "../shared/constants/api-calls";
import { IAceConfig } from "../shared/models/interfaces/IAceConfig";
import { IExtendedMetadata } from "../shared/state/metadata/metadata-state.model";
import { UpdateMetadata } from "../shared/state/metadata/metadata.actions";
import { MetadataState } from "../shared/state/metadata/metadata.state";
import { ConfigService } from "./config.service";
import { CredentialsSigner } from "./credentials-signer.service";

@Injectable({
    providedIn: "root"
})
export class AceCoreApiService {
    public isMobile$: Observable<boolean>;
    public metadata$: Observable<IExtendedMetadata>;
    public context: string;

    constructor(private http: CredentialsSigner, private config: ConfigService, private store: Store) {
        this.metadata$ = this.store.select(state => MetadataState.metadata(state.metadata));
        this.isMobile$ = this.store.select(state => MetadataState.isMobile(state.metadata));
    }

    public call<T = {}, K = DataModel.StandardResponse>(name?: string, payloadData: T | {} = {}, stubFile: string = "", context: any = null, url?: string): Observable<K> {
        let payload: DataModel.StandardRequest = {
            name,
            data: stubFile ? { stubS3: stubFile } : payloadData
        };

        return combineLatest([this.metadata$.pipe(take(1)), this.config.config$, this.isMobile$.pipe(take(1))]).pipe(
            map(([metadata, config, isMobile]) => {
                payload = this.setContext(payload, isMobile, config, context);
                payload = this.setMetadata(metadata, payload);

                if (environment.tocId) {
                    payload.tocId = environment.tocId as DataModel.TocID;
                }

                return {
                    endpointUrl: url || config.aceCoreApi.authDefault,
                    payload
                };
            }),
            mergeMap(request => this.http.post<K>(request.endpointUrl, request.payload)),
            map(res => {
                if (!res) {
                    return throwError(() => "empty response observable error");
                }

                // Models mismatch purposes
                const fallbackRes = res as any;

                if (fallbackRes["errorMessage"]) {
                    const err = new Error(fallbackRes["errorMessage"]);
                    err.name = `Error ${fallbackRes["errorCode"]}`;
                    throw err;
                }

                if (fallbackRes.metadata) {
                    if (fallbackRes.metadata.sessionId) {
                        this.store.dispatch(new UpdateMetadata({ sessionId: fallbackRes.metadata.sessionId }));
                    }

                    if (fallbackRes.metadata.sessionState) {
                        this.store.dispatch(new UpdateMetadata({ sessionState: fallbackRes.metadata.sessionState }));
                    }
                }

                if (fallbackRes.id && fallbackRes.id !== "") {
                    this.store.dispatch(new UpdateMetadata({ lastRequestId: fallbackRes.id }));
                }

                return fallbackRes;
            }),
            catchError(error => throwError(() => (error.error ? error.error : error.message)))
        );
    }

    private setContext(payload: DataModel.StandardRequest, isMobile: boolean, config: IAceConfig, context: string) {
        if (!this.context) {
            this.context = isMobile ? config.srContext.webMobile : config.srContext.webDesktop;
        }

        // DataRequests don't have a context because they don't go to SilverRail
        if (this.context && payload.name !== "DataRequest") {
            payload.context = this.context;
        }

        // override context if passed in directly into call()
        if (context !== null) {
            payload.context = context;
        }

        return payload;
    }

    private setMetadata(metadata: DataModel.Metadata, payload: DataModel.StandardRequest): DataModel.StandardRequest {
        const hasMetadata = metadata && metadata.client && metadata.clientVersion;

        if (hasMetadata) {
            payload.metadata = {
                client: metadata.client,
                clientVersion: metadata.clientVersion
            };
        }

        if (hasMetadata && metadata.sessionId) {
            payload.metadata.sessionId = metadata.sessionId;
        }

        if (hasMetadata && metadata.sessionState) {
            payload.metadata.sessionState = metadata.sessionState;
        }

        return payload;
    }
}
