import { AbstractControl, ValidatorFn, ValidationErrors } from "@angular/forms";

export class BasicValidator {
    public static checkChars(): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            const value = control.value;
            const pattern = new RegExp(/([.,;:<>?()[\]@\/\\])/);
            return pattern.test(value) ? { restrictedChars: true } : null;
        };
    }
    public static checkInjection(): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            const value = control.value;
            const pattern = new RegExp(/(https|http|www|create|select|from|insert|delete)/, "i");
            return pattern.test(value) ? { restrictedChars: true } : null;
        };
    }
}
