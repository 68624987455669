import { Injectable } from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";
import { Store } from "@ngxs/store";
import { filter } from "rxjs/operators";
import { FlowStep } from "../shared/models/entities/flow-step";
import { UpdateNavigation } from "../shared/state/navigation/navigation.actions";

@Injectable({
    providedIn: "root"
})
export class FlowService {
    constructor(private _router: Router, private _store: Store) {}

    public createFlowStep() {
        this._router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe((event: NavigationEnd) => {
            const url = event.url.toUpperCase();
            this.setFlowStep(url);
        });
    }

    public setFlowStep(url: string): void {
        const [location, skipMainContent] = url.split("#");
        url = skipMainContent ? location.toUpperCase() : url;

        if (url.includes("/SEARCH/M/OUTBOUND")) {
            url = "MOBILE_SEARCH_OUTBOUND";
        } else if (url.includes("/SEARCH/M/RETURN")) {
            url = "MOBILE_SEARCH_RETURN";
        } else if (url.includes("/SEARCH/M/FARES")) {
            url = "MOBILE_SEARCH_FARES";
        } else if (url.includes("?")) {
            url = url.split("?")[0];
        }

        const step: string = url.includes("/ACCOUNT/DETAILS/BOOKINGS/B-") ? "BOOKING_DETAILS" : url.split("/").slice(-1)[0].replace(/-/g, "_");
        this._store.dispatch(new UpdateNavigation({ flowStep: step === "" ? FlowStep[FlowStep.QTT] : step }));
    }
}
