export interface IToastEvent {
    type: ToastEventType;
    value?: any;
}

export enum ToastEventType {
    ADD,
    CLEAR,
    CLEAR_ALL
}
