import { AceSearchResult } from "../../shared/models/ace/ace-search-result.model";
import { SearchPayloadCriteria } from "../../shared/models/criterias/search-payload-criteria";
import { IAceProjectionRow } from "../../shared/models/interfaces/IAceProjectionRow";
import { environment } from "./../../../environments/environment";
import { isSameDay } from "date-fns";
import { NextResults, PromotedLegSolutionsType } from "../../shared/models/entities/promoted-service";

export const filterOutBussinessZoneFares = (rows: IAceProjectionRow[]) =>
    rows.filter(row => !row.fareClass.includes("BUSINESS_ZONE")).filter(row => row.prices.some(price => !price.fareClasses.includes("BUSINESS_ZONE")));

export const findPromotedService = (
    searchParams: SearchPayloadCriteria,
    currentResults: AceSearchResult,
    nextResults: NextResults
): Record<"promotedOutLeg" | "promotedRtnLeg", PromotedLegSolutionsType> => {
    const { tocMarketingCarrierName } = environment;
    const { outboundTime, inboundTime, outboundTimeType } = searchParams?.params;
    const currentOutLeg = currentResults?.outgoingResults?.legSolutions;
    const currentRtnLeg = currentResults?.returnResults?.legSolutions;

    const nextOutLeg = nextResults?.outgoingResults?.filter(leg => isSameDay(new Date(leg.departureDateTime), new Date(outboundTime)));
    const nextRtnLeg = nextResults?.returnResults?.filter(leg => isSameDay(new Date(leg.departureDateTime), new Date(inboundTime)));

    if (outboundTimeType === "ARRIVAL") {
        nextOutLeg?.sort((a, b) => new Date(b.arrivalDateTime).getTime() - new Date(a.arrivalDateTime).getTime());
        nextRtnLeg?.sort((a, b) => new Date(b.arrivalDateTime).getTime() - new Date(a.arrivalDateTime).getTime());
    }

    const getPromoService = (legSolutions: PromotedLegSolutionsType[]) =>
        legSolutions?.filter(leg => !leg.isCancelled)?.find(leg => leg.travelSegments.find(seg => seg.marketingCarrier === tocMarketingCarrierName));

    const isPromotedOutLegExist = getPromoService(currentOutLeg) ?? undefined;
    const isPromotedRtnLegExist = getPromoService(currentRtnLeg) ?? undefined;
    const promotedOutLeg = !isPromotedOutLegExist ? getPromoService(nextOutLeg) : undefined;
    const promotedRtnLeg = !isPromotedRtnLegExist ? getPromoService(nextRtnLeg) : undefined;

    return { promotedOutLeg, promotedRtnLeg };
};
