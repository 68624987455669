import { isBefore, isSameMinute, subMinutes, isAfter } from "date-fns";
import { TravelDepartOptions } from "../classes/TravelDepartOptions";
import { IQttValidationError } from "../models/interfaces/IQttValidationError";
import { RailcardQuery, SilverRailRailcardCode, SilverRailRailcard } from "../models/api/railcard";

export class QttValidator {
    public static isOutboundDateTimeValid(outboundDateTime: Date, outboundDepartOption: string): IQttValidationError {
        const today = new Date();
        const subtractedToday = subMinutes(today, 30);
        const invalidDeparture =
            (isBefore(outboundDateTime, subtractedToday) || outboundDateTime.getTime() === subtractedToday.getTime()) && outboundDepartOption === TravelDepartOptions.DEPARTURE;
        const invalidArrival = (isBefore(outboundDateTime, today) || outboundDateTime.getTime() === today.getTime()) && outboundDepartOption === TravelDepartOptions.ARRIVAL;

        if (invalidDeparture || invalidArrival) {
            return {
                message: "The outbound time is in the past",
                theme: "error",
                icon: "error"
            };
        }
    }

    public static isDepartureTimeValid(isReturn: boolean, isOpenReturn: boolean, outboundDateTime: Date, returnDateTime: Date): IQttValidationError {
        if (outboundDateTime && returnDateTime && isReturn && !isOpenReturn && (isAfter(outboundDateTime, returnDateTime) || isSameMinute(outboundDateTime, returnDateTime))) {
            return {
                message: "Your departure time is the same or after your return time",
                theme: "error",
                icon: "error"
            };
        }
    }

    public static areStationsEqual(origin: string, destination: string): IQttValidationError {
        if (origin && destination && origin === destination) {
            return {
                message: "Neither station must be the same or empty",
                theme: "error",
                icon: "error"
            };
        }
    }

    public static haveStations(origin: string, destination: string): IQttValidationError {
        if (!destination) {
            return {
                message: "You must include a destination station",
                theme: "error",
                icon: "error"
            };
        } else if (!origin) {
            return {
                message: "You must include a departure station",
                theme: "error",
                icon: "error"
            };
        }
    }

    public static isViaAvoidSameAsStation(origin: string, destination: string, via: string, avoid: string): IQttValidationError {
        if ((origin && destination && via && [destination, origin].includes(via)) || (origin && destination && avoid && [destination, origin].includes(avoid))) {
            return {
                message: "Via/Avoid station must be different",
                theme: "error",
                icon: "error"
            };
        }
    }

    public static arePassengerValid(adults: number, children: number): IQttValidationError {
        if (adults === 0 && children === 0) {
            return {
                message: "You must include a minimum of one child or adult passenger",
                theme: "warning",
                icon: "info"
            };
        }
    }

    public static railcards(railcardsSet: SilverRailRailcard[], railcardQuery: RailcardQuery<string>[], adults: number): IQttValidationError {
        const validWithAdultOnly = [SilverRailRailcardCode.RAILCARD_1617, SilverRailRailcardCode.RAILCARD_1625];
        const selectedRailcard = railcardQuery.find(railcard => validWithAdultOnly.find(code => railcard.Code === code)) ?? null;
        const railcard = selectedRailcard != null && railcardsSet.find(railcard => selectedRailcard.Code === railcard.code);

        if (railcard && adults === 0) {
            const { code } = railcard;
            const messages: Record<SilverRailRailcardCode, string> = {
                [SilverRailRailcardCode.RAILCARD_1617]: "16-17 railcard",
                [SilverRailRailcardCode.RAILCARD_1625]: "16-25 railcard"
            };

            if (code && messages[code]) {
                return {
                    message: `${messages[code]} can only be applied to adult fares`,
                    theme: "error",
                    icon: "error"
                };
            }
        }
    }
}
