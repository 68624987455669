import moment from "moment";
import { IQueryParams } from "../interfaces/IQueryParams";

export class JourneyCriteria {
    public originTravelPoint: string;
    public destinationTravelPoint: string;
    public travelPoint: string;
    public travelPointType: string;
    public travelDate: moment.Moment;
    public travelDateTimeType: string;

    private OPEN_RETURN_DAYS_DIFF = 5;
    private endDate: moment.Moment = moment();
    private daysToEnd: number;

    constructor(maxDaysToStartFromToday: number, search: IQueryParams, isReturn: boolean = false, isOpenReturn: boolean = false) {
        this.originTravelPoint = isReturn ? search.destination : search.origin;
        this.destinationTravelPoint = isReturn ? search.origin : search.destination;

        this.travelDateTimeType = isReturn && !isOpenReturn ? search.inboundTimeType : search.outboundTimeType;
        this.travelDate = moment(isReturn && !isOpenReturn ? search.inboundTime : search.outboundTime);

        this.travelPoint = search.avoid || search.via || "";
        this.travelPointType = (search.via ? "via" : "") || (search.avoid ? "avoid" : "");

        if (isOpenReturn) {
            this.daysToEnd = this.endDate.add(maxDaysToStartFromToday, "day").diff(moment(search.outboundTime).subtract(6, "hours"), "days");

            if (this.daysToEnd < this.OPEN_RETURN_DAYS_DIFF) {
                this.travelDate.add(this.daysToEnd, "day").subtract(6, "hours");
            } else {
                this.travelDate.add(this.OPEN_RETURN_DAYS_DIFF, "day");
            }
        }
    }

    public updateTime(time) {
        this.travelDate.hour(time.hour()).minute(time.minute()).second(time.second());
    }

    public updateDate(date) {
        this.travelDate.year(date.year()).month(date.month()).date(date.date());
    }

    public toJSON(): object {
        return {
            originTravelPoint: {
                code: this.originTravelPoint
            },
            destinationTravelPoint: {
                code: this.destinationTravelPoint
            },
            [this.getTravelType()]: {
                date: this.travelDate.format("YYYY-MM-DD"),
                time: this.travelDate.format("HH:mm:ss")
            },
            routingRestrictions: this.getRoutingRestrictions()
        };
    }

    private getRoutingRestrictions() {
        // if restriction location provided
        if (this.travelPoint) {
            return [
                {
                    type: this.travelPointType === "via" ? "PREFERRED" : "RESTRICTED",
                    routingTravelPoint: {
                        code: this.travelPoint
                    }
                }
            ];
        }

        return null;
    }

    private getTravelType() {
        return this.travelDateTimeType === "DEPARTURE" ? "departureDateTimeWindow" : "arrivalDateTimeWindow";
    }
}
