import { IPassengerInformationRequired, PhotocardFieldType } from "../api/photocard";
import { flatten, get, isEmpty, uniq } from "../../../shared/utilities/Utils";
import { IQueryParams } from "../interfaces/IQueryParams";
import { ISearchResultType } from "../interfaces/ISearchResult";
import { Promotion } from "../promotion.model";
import { AceDesktopSearchProjection } from "./ace-desktop-search-projection.model";
import { AceMobileSearchProjection } from "./ace-mobile-search-projection.model";
import { AcePassPrice } from "./ace-pass-price.model";
import { AcePointToPointPrice } from "./ace-point-to-point-price.model";
import { AceSearchResultDirection } from "./ace-search-result-direction.model";
import { AceSeasonSearchProjectionModel } from "./ace-season-search-projection.model";
import { AceSnapshot } from "./ace-snapshot";
import { AceContactInformation } from "./payloads/ace-contact-information";
import { AcePassenger } from "./payloads/ace-passenger";
import { AceTravelDocumentInformation } from "./payloads/ace-travel-documents-payload";

export class AceSearchResult {
    public travelPass: boolean;
    public requiredInformation: IPassengerInformationRequired = {
        isNameRequired: false,
        isPhotocardRequired: false,
        isTitleRequired: false
    };
    public pointToPoint: boolean;
    public isEmpty: boolean;
    public isOpenReturn: boolean = false;
    public hasOutgoingResults: boolean;
    public hasReturnResults: boolean;
    public resultType: ISearchResultType;
    public outgoingResults: AceSearchResultDirection;
    public returnResults: AceSearchResultDirection;
    public fareClasses: string[];
    public promotions: Promotion[];
    public passengers: AcePassenger[];
    public serviceClasses: DataModel.ServiceClass[];
    public prices: AcePointToPointPrice[];
    public seasonPrices: AcePassPrice[];
    public originTravelPoint: string;
    public destinationTravelPoint: string;
    public searchParams: IQueryParams | null;
    public searchResultId: string;
    public cacheKey: string;
    private _desktopProjection: AceDesktopSearchProjection;
    private _desktopSeasonsProjection: AceSeasonSearchProjectionModel;
    private _mobileProjection: AceMobileSearchProjection;
    private _configSnapshot: AceSnapshot;
    private amendingJourneyDirection: string;

    constructor(
        searchResultId: string,
        data: DataModel.PointToPointShoppingResponse | DataModel.TravelPassShoppingResponse | DataModel.ExchangeSearchResponse,
        cacheKey: string,
        configSnapshot: AceSnapshot,
        searchParams: IQueryParams = null,
        direction?: string
    ) {
        this.searchResultId = searchResultId;
        this.searchParams = searchParams;
        this.cacheKey = cacheKey;
        this._configSnapshot = configSnapshot;
        this.amendingJourneyDirection = direction;
        this._init(data);
    }

    public getDesktopProjection(): AceDesktopSearchProjection {
        if (!this._desktopProjection) {
            this._desktopProjection = new AceDesktopSearchProjection(this, this._configSnapshot);
        }
        return this._desktopProjection;
    }

    public getSeasonsProjection(): AceSeasonSearchProjectionModel {
        if (!this._desktopSeasonsProjection) {
            this._desktopSeasonsProjection = new AceSeasonSearchProjectionModel(this, this._configSnapshot);
        }
        return this._desktopSeasonsProjection;
    }

    public getMobileProjection(): AceMobileSearchProjection {
        if (!this._mobileProjection) {
            this._mobileProjection = new AceMobileSearchProjection(this, this._configSnapshot);
        }
        return this._mobileProjection;
    }

    private _init(data: DataModel.PointToPointShoppingResponse | DataModel.TravelPassShoppingResponse | DataModel.ExchangeSearchResponse): void {
        const isResponseAmended = (<DataModel.ExchangeSearchResponse>data).results?.exchangeSets;
        let legs = (<DataModel.PointToPointShoppingResponse>data).results?.legs ?? [];
        const promotions = get<any, DataModel.Promotion[]>(data, "promotions", []);
        const passengerInformationRequired = get<any, DataModel.PassengerInformation[]>(data, "results.passengerInformationRequired", null);

        if (isResponseAmended && isResponseAmended[0]) {
            const lastTravelSegment = isResponseAmended[0].legSolutions[0].travelSegments[isResponseAmended[0].legSolutions[0].travelSegments?.length - 1];
            legs = [
                {
                    originTravelPoint: isResponseAmended[0].legSolutions[0].travelSegments[0].originTravelPoint,
                    destinationTravelPoint: lastTravelSegment.destinationTravelPoint,
                    legSolutions: isResponseAmended[0].legSolutions,
                    legID: "L_1"
                }
            ];
        }

        try {
            // check if season ticket search
            if (Array.isArray(passengerInformationRequired)) {
                this.travelPass = true;
                this._parsePhotocardRequiredFields(passengerInformationRequired);

                this.seasonPrices = get<any, DataModel.PassPrice[]>(data, "results.fareInformation.prices", []).map((price: DataModel.PassPrice) => new AcePassPrice(price));

                if (isEmpty(this.seasonPrices)) {
                    this.isEmpty = true;
                    this.resultType = ISearchResultType.EMPTY;
                }
            } else {
                if (!isResponseAmended) {
                    this.pointToPoint = true;

                    // Invalid response
                    if (!Array.isArray(legs)) {
                        this.isEmpty = true;
                        this.resultType = ISearchResultType.INVALID;
                        return;
                    }

                    // NO results
                    if (legs.length === 0) {
                        this.isEmpty = true;
                        this.resultType = ISearchResultType.EMPTY;
                        return;
                    }

                    // More than two? Invalid
                    if (legs.length > 2) {
                        this.isEmpty = true;
                        this.resultType = ISearchResultType.INVALID;
                        return;
                    }
                }

                // Single
                this.isEmpty = false;
                this.resultType = ISearchResultType.SINGLE;
                this.hasReturnResults = false;
                this.hasOutgoingResults = true;
                this.outgoingResults = new AceSearchResultDirection(legs[0]);
                this.originTravelPoint = this.outgoingResults.originTravelPoint.code;
                this.destinationTravelPoint = this.outgoingResults.destinationTravelPoint.code;

                // Return
                if (legs.length === 2) {
                    this.resultType = ISearchResultType.RETURN;
                    this.hasReturnResults = true;
                    this.returnResults = new AceSearchResultDirection(legs[1]);
                }

                if (isResponseAmended) {
                    this.prices = get<any, DataModel.PointToPointPrice[]>(data, "results.exchangeSets[0].prices", []).map(
                        (item: DataModel.PointToPointPrice) => new AcePointToPointPrice(item)
                    );
                } else {
                    this.prices = get<any, DataModel.PointToPointPrice[]>(data, "results.fareInformation.prices", []).map(
                        (item: DataModel.PointToPointPrice) => new AcePointToPointPrice(item)
                    );
                }

                this.checkIfOpenReturn();

                this.fareClasses = uniq(flatten(this.prices.map(price => price.fareClasses)));
                this.serviceClasses = uniq(this.prices.map(price => price.serviceClass));
            }
        } catch (e) {
            console.error("AceSearchResultsModel initialisation error", e);
        }

        if (isResponseAmended) {
            this._parsePassengers(data.results.passengers, true);
        } else {
            this._parsePassengers(data.results.passengers);
        }

        this._parsePromotions(promotions);
    }

    private checkIfOpenReturn(): void {
        const isSearchOpenReturn = this.searchParams.openReturn && this.searchParams.openReturn === "true";

        if (isSearchOpenReturn) {
            const filteredPrices = this.prices.filter(price => price.ticketableFares.all.some(it => it.fareCode.openReturn === "YES"));

            if (filteredPrices.length) {
                this.prices = filteredPrices;
                this.isOpenReturn = true;
            }
        }
    }

    private _parsePhotocardRequiredFields(passengerInfo: DataModel.PassengerInformation[]): void {
        const photocardFields = [...passengerInfo];
        photocardFields.forEach(field => {
            if (field) {
                const requiredFields = this.requiredInformation;

                switch (field.type) {
                    case PhotocardFieldType.PASSENGER_NAME:
                        this.requiredInformation = { ...requiredFields, isNameRequired: true };
                        break;
                    case PhotocardFieldType.PASSENGER_TITLE:
                        this.requiredInformation = { ...requiredFields, isTitleRequired: true };
                        break;
                    case PhotocardFieldType.TRAVEL_DOCUMENTS:
                        const isPhotocardRequired = field.acceptableFormOfTravelDocument && field.acceptableFormOfTravelDocument.includes("PHOTOCARD");
                        this.requiredInformation = { ...requiredFields, isPhotocardRequired };
                        break;
                    default:
                        break;
                }
            }
        });
    }

    private _parsePromotions(promotions: DataModel.Promotion[]): void {
        if (promotions) {
            this.promotions = promotions.map((promotion: DataModel.Promotion) => {
                return new Promotion(promotion);
            });
        }
    }

    private _parsePassengers(passengers: DataModel.PassengerSpec[], isAmended = false) {
        this.passengers = passengers.map((passenger, index) => {
            let passengerId;
            if (isAmended) {
                passengerId = passenger.passengerSpecID;
            } else {
                passengerId = "PAX_SPEC_" + index;
            }
            const p = new AcePassenger()
                .setPassengerID(passengerId)
                .setPassengerTitle("Mr")
                .setNameFirst("EXAMPLE_FIRST_NAME")
                .setNameLast("EXAMPLE_LAST_NAME")
                .setDateOfBirth("1977-08-10")
                .setContactInformation([
                    new AceContactInformation().setContactType("HOME").setContactMedium("PHONE").setContactInfo("000"),
                    new AceContactInformation().setContactType("HOME").setContactMedium("EMAIL").setContactInfo("demo@example.com")
                ])
                .setAgeAtTimeOfTravel(passenger.age);
            if (this.travelPass) {
                p.setContactDocumentInformation([
                    new AceTravelDocumentInformation()
                        .setDocumentType("PHOTOCARD")
                        .setDocumentnumber("12345")
                        .setExpirationDate("2018-05-31")
                        .setIssueAuthority({
                            countryCode: "UK",
                            stateProv: ""
                        })
                        .setDocumentAffiliation("")
                ]);
            }
            return p;
        });
    }
}
