<div class="toast" [ngClass]="[toast.theme]">
    <div *ngIf="toast.icon" aria-hidden="true" class="toast-icon">
        <svg viewBox="0 0 24 24" *ngIf="toast.icon !== 'error'" class="toast-icon">
            <use [attr.xlink:href]="'#' + toast.icon" />
        </svg>

        <ace-error-sign *ngIf="toast.icon === 'error'" class="toast-icon"></ace-error-sign>
    </div>

    <div *ngIf="toast.msg" class="toast-text">
        <span *ngIf="toast.title" class="toast-title" [innerHTML]="toast.title"></span>
        <br *ngIf="toast.title && toast.msg" />
        <span *ngIf="toast.msg" class="toast-msg" [innerHTML]="toast.msg"></span>
        <ng-container *ngIf="toast.msg"><span *aceAnnounce="toast.msg" class="sr-only"></span></ng-container>
    </div>

    <button *ngIf="toast.icon" class="toast-icon toast-close" title="Close" (click)="close($event)">
        <svg viewBox="0 0 24 24" aria-hidden="true">
            <title>close</title>
            <use xlink:href="#close" />
        </svg>
    </button>
</div>
