import { ComponentRef, ViewContainerRef } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { Collection } from "../../shared/classes/Collection";
import { DisplayStackItem } from "./DisplayStackItem";

export class ModalDisplayStack {
    private viewContainer: ViewContainerRef;
    private items: Collection<DisplayStackItem>;
    public stack$: BehaviorSubject<boolean>;

    constructor(viewContainer: ViewContainerRef) {
        this.viewContainer = viewContainer;
        this.items = new Collection<DisplayStackItem>();
        this.stack$ = new BehaviorSubject(false);
    }

    public add(componentRef: ComponentRef<any>): BehaviorSubject<ComponentRef<any>> {
        const stackItem = new DisplayStackItem(componentRef);
        const body = document.querySelector("body");
        componentRef.instance["destroy"] = () => {
            if (this.items.count() === 1) {
                body.classList.remove("disable-scroll");
            }
            this.remove(stackItem);
            componentRef.destroy();
        };
        this.items.add(stackItem);
        if (!body.classList.contains("disable-scroll")) {
            body.classList.add("disable-scroll");
        }
        this.evaluateStack();
        return stackItem.component$;
    }

    public getTopItem(): DisplayStackItem {
        return this.items.getItemByIndex(this.items.count() - 1);
    }

    public remove(item: DisplayStackItem): boolean {
        const operation = this.items.remove(item);
        this.evaluateStack();
        return operation;
    }

    private evaluateStack(): void {
        const count = this.items.count();
        let item;
        if (count) {
            item = count ? this.getTopItem().component$.getValue() : null;
        }
        this.display(item);
        this.stack$.next(count ? true : false);
    }

    private display(component: ComponentRef<any>): void {
        this.viewContainer.detach();
        if (component) {
            this.viewContainer.insert(component.hostView);
        }
    }
}
