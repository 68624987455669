import { Injectable } from "@angular/core";
import { Action, Selector, State, StateContext } from "@ngxs/store";
import { Tooltip, TooltipStateModel } from "./tooltip-state.model";
import { CloseTooltip, ToggleTooltips } from "./tooltip.actions";

const TOOLTIPS = [
    {
        id: "tooltip-timetable",
        enabled: false
    },
    {
        id: "tooltip-ticket-choice",
        enabled: false
    },
    {
        id: "tooltip-continue",
        enabled: false
    }
];

export const initialState: TooltipStateModel = {
    tooltips: TOOLTIPS
};

@State<TooltipStateModel>({
    name: "tooltip",
    defaults: initialState
})
@Injectable()
export class TooltipState {
    @Selector([TooltipState])
    static tooltips(state: TooltipStateModel): Tooltip[] {
        return state && state.tooltips ? state.tooltips : null;
    }

    @Selector([TooltipState.tooltips])
    static tooltipsDisabled(tooltips: Tooltip[]): boolean {
        return !!(tooltips.length && tooltips.every(tooltip => tooltip.enabled === false));
    }

    @Action(ToggleTooltips)
    toggleTooltips({ getState, patchState }: StateContext<TooltipStateModel>, action: ToggleTooltips) {
        const { tooltips } = getState();
        patchState({ tooltips: tooltips.map(tooltip => ({ id: tooltip.id, enabled: action.payload })) });
    }

    @Action(CloseTooltip)
    closeTooltip({ getState, patchState }: StateContext<TooltipStateModel>, action: CloseTooltip) {
        const state = getState();
        patchState({ tooltips: [...state.tooltips.filter(tooltip => tooltip.id !== action.payload.id), action.payload] });
    }
}
