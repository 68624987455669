export class NRERedirecting {
    static readonly type = "[NRE] Redirecting";
}

export class NRERedirected {
    static readonly type = "[NRE] Redirected";
}

export class SetHandoffID {
    static readonly type = "[NRE] Set handoff ID";

    constructor(public handoffId: string) {}
}
