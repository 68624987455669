import { Component, Input, OnInit } from "@angular/core";
import { ReplaySubject } from "rxjs";
import { environment } from "../../../../environments/environment";
import { AccountService } from "../../../services/account.service";
import { ConfigService } from "../../../services/config.service";
import { AceUser } from "../../models/ace/ace-user.model";
import { IAceError } from "../../models/interfaces/IAceError";
import { RxjsComponent } from "../../RxjsComponent";

@Component({
    selector: "ace-notification-box",
    templateUrl: "./notification-box.component.html",
    styleUrls: ["./notification-box.component.scss"]
})
export class NotificationBoxComponent extends RxjsComponent implements OnInit {
    @Input() public error: IAceError | string;
    @Input() public icon: string;
    @Input() public type: string;

    public user$: ReplaySubject<AceUser> = new ReplaySubject(null);
    public user: AceUser;
    public errorCode: string;

    constructor(private accountService: AccountService, public configService: ConfigService) {
        super();
    }

    public ngOnInit() {
        this.addSubscription(
            this.accountService.currentUser$?.subscribe(user => {
                this.user$.next(user);
                this.user = user;
            })
        );

        if (this.error) {
            if (typeof this.error === "string") {
                this.errorCode = this.error;
            } else {
                this.errorCode = this.error.code;
            }
        }
    }

    public get tocId(): string {
        return environment.tocId;
    }
}
