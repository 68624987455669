import { Component, Input } from "@angular/core";

@Component({
    selector: "ace-spinner",
    templateUrl: "spinner.component.html",
    styleUrls: ["spinner.component.scss"]
})
export class SpinnerComponent {
    @Input() public confirmLoadingLabel: string;
    @Input() public icon: "solid" | "dotted" = "solid";

    public get svgName(): string {
        return this.icon === "dotted" ? "#loading-dotted" : "#loading-solid";
    }
}
