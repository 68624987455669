<div aceViewportTracker class="app-inner" [class.show-elids]="showElids">
    <div class="loading" [class.active]="loading"></div>
    <ace-header></ace-header>
    <ace-notify-toast></ace-notify-toast>
    <ace-message-box *ngIf="isGenericBannerOn && !loading" title="" iconTitle="warning-triangle" body="" theme="warning" spacing="true" wide="true"></ace-message-box>
    <main>
        <div class="app-content">
            <router-outlet></router-outlet>
        </div>
    </main>
    <ace-debug-ui *ngIf="showDebugUi" (showElids)="showElids = $event"></ace-debug-ui>
    <ace-footer></ace-footer>
    <ace-ui-modal></ace-ui-modal>
</div>
