import { Component, EventEmitter, Input, Output } from "@angular/core";

@Component({
    selector: "ace-switch",
    templateUrl: "switch.component.html",
    styleUrls: ["switch.component.scss"]
})
export class SwitchComponent {
    @Input() public ariaLabel;
    @Input() public activeLabel = "Yes";
    @Input() public disabledLabel = "No";
    @Input() public value: boolean = false;
    @Output() public valueChange: EventEmitter<boolean> = new EventEmitter();

    public onClick() {
        this.value = !this.value;
        this.valueChange.emit(this.value);
    }
}
