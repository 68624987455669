import { isPlatformBrowser } from "@angular/common";
import { ClassProvider, FactoryProvider, Injectable, InjectionToken, PLATFORM_ID } from "@angular/core";

export const WINDOW = new InjectionToken("WindowToken");

export abstract class WindowRef {
    public get nativeWindow(): Window | Object {
        throw new Error("Not implemented.");
    }
}

@Injectable()
export class BrowserWindowRef extends WindowRef {
    constructor() {
        super();
    }

    public get nativeWindow(): Window | Object {
        return window;
    }
}

// eslint-disable-next-line prefer-arrow/prefer-arrow-functions
export function windowFactory(browserWindowRef: BrowserWindowRef, platformId: Object): Window | Object {
    if (isPlatformBrowser(platformId)) {
        return browserWindowRef.nativeWindow;
    }
    return new Object();
}

const browserWindowProvider: ClassProvider = {
    provide: WindowRef,
    useClass: BrowserWindowRef
};

const windowProvider: FactoryProvider = {
    provide: WINDOW,
    useFactory: windowFactory,
    deps: [WindowRef, PLATFORM_ID]
};

export const WINDOW_PROVIDERS = [browserWindowProvider, windowProvider];
