import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild } from "@angular/core";
import { Select, Store } from "@ngxs/store";
import { Observable } from "rxjs";
import { StationPickerMobileModalComponent } from "../../../modal/components/modals/station-picker-mobile-modal.component/station-picker-mobile-modal.component";
import { ModalService } from "../../../modal/services/modal.service";
import { DESTINATION_STATION_PICKER, ORIGIN_STATION_PICKER, VIAAVOID_STATION_PICKER } from "../../constants/station-pickers";
import { StationPickerType, ViaAvoidType } from "../../models/entities/station-picker";
import { RxjsComponent } from "../../RxjsComponent";
import { MetadataState } from "../../state/metadata/metadata.state";
import { QttState } from "../../state/qtt/qtt.state";
import { ButtonComponent } from "../button/button.component";
import { ISelectOption, SelectComponent } from "../select/select.component";
import { StationPickerComponent } from "../station-picker/station-picker.component";

@Component({
    selector: "ace-qtt-station-pickers",
    templateUrl: "./qtt-station-pickers.component.html",
    styleUrls: ["./qtt-station-pickers.component.scss"]
})
export class QttStationPickersComponent extends RxjsComponent implements OnChanges {
    @Select(QttState.stationPicker) public isStationPickerDisabled$: Observable<boolean>;
    @Input() public baseId: string = "";
    @Input() public originTravelPoint: string = "";
    @Input() public destinationTravelPoint: string = "";
    @Input() public viaAvoidTravelPoint: string = "";
    @Input() public viaAvoidSelectedOption: ViaAvoidType = "via";
    @Input() public qttOpen: boolean = false;
    @Input() public hasViaAvoid: boolean = false;
    @Input() public isJourneyAmendable: boolean = false;
    @Output() public hasViaAvoidChange: EventEmitter<boolean> = new EventEmitter();
    @Output() public stationPickerFocus: EventEmitter<boolean> = new EventEmitter();
    @Output() public originTravelPointChange: EventEmitter<string> = new EventEmitter();
    @Output() public destinationTravelPointChange: EventEmitter<string> = new EventEmitter();
    @Output() public viaAvoidTravelPointChange: EventEmitter<string> = new EventEmitter();
    @Output() public viaAvoidSelectedOptionChange: EventEmitter<ViaAvoidType> = new EventEmitter();
    @ViewChild("fromStationPicker") public fromStationPicker: StationPickerComponent;
    @ViewChild("toStationPicker") public toStationPicker: StationPickerComponent;
    @ViewChild("viaAvoidStationPicker") public viaAvoidStationPicker: StationPickerComponent;
    @ViewChild("viaAvoidSelect") public viaAvoidSelect: SelectComponent;
    @ViewChild("viaAvoidOpenButton") public viaAvoidOpenButton: ButtonComponent;
    public viaAvoidOptionSelectedIndex: number = 0;
    public viaAvoidOptions: ISelectOption[] = [
        { label: "Via", value: "via" },
        { label: "Avoid", value: "avoid" }
    ];

    constructor(private store: Store, private modalService: ModalService) {
        super();
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes.viaAvoidSelectedOption && changes.viaAvoidSelectedOption.previousValue !== changes.viaAvoidSelectedOption.currentValue) {
            changes.viaAvoidSelectedOption.currentValue === "via" ? (this.viaAvoidOptionSelectedIndex = 0) : (this.viaAvoidOptionSelectedIndex = 1);
        }
    }

    public onPickerFocus(stationPickerType: "origin" | "destination"): void {
        this.stationPickerFocus.emit(true);
        this.createMobileStationPicker(stationPickerType);
    }

    public openViaAvoid(): void {
        this.hasViaAvoid = true;
        this.hasViaAvoidChange.emit(true);

        setTimeout(() => {
            if (this.viaAvoidSelect != null) {
                this.viaAvoidSelect?.selectElement?.nativeElement?.focus();
            }
        }, 150);
    }

    public onViaAvoidStationPickerClick(stationPickerType: "viaavoid"): void {
        this.createMobileStationPicker(stationPickerType);
    }

    public onStationSwitch(): void {
        this.addSubscription(
            this.isStationPickerDisabled$?.subscribe(isStationPickerDisabled => {
                if (!isStationPickerDisabled && this.originTravelPoint && this.destinationTravelPoint) {
                    const origin = this.originTravelPoint;
                    const destination = this.destinationTravelPoint;
                    this.originTravelPointChange.emit(destination);
                    this.destinationTravelPointChange.emit(origin);
                }
            })
        );
    }

    public closeVisAvoid(): void {
        this.hasViaAvoid = false;
        this.hasViaAvoidChange.emit(false);

        setTimeout(() => {
            if (this.viaAvoidOpenButton != null) {
                this.viaAvoidOpenButton?.focus();
            }
        }, 0);
    }

    public onViaAvoidClick(): void {
        this.hasViaAvoid = !this.hasViaAvoid;
        this.hasViaAvoidChange.emit(this.hasViaAvoid);
    }

    public onViaAvoidSelectedOptionChange(option: ISelectOption): void {
        this.viaAvoidSelectedOption = option.value;
        this.viaAvoidSelectedOptionChange.emit(this.viaAvoidSelectedOption);
    }

    public onOriginChange(station: string): void {
        this.originTravelPointChange.emit(station);
    }

    public onDestinationChange(station: string): void {
        this.destinationTravelPointChange.emit(station);
    }

    public onViaAvoidChange(station: string): void {
        this.viaAvoidTravelPoint = station;
        this.viaAvoidTravelPointChange.emit(this.viaAvoidTravelPoint);
    }

    private createMobileStationPicker(travelPointPickerType: StationPickerType): void {
        const stationPickerMetadata = [ORIGIN_STATION_PICKER, DESTINATION_STATION_PICKER, VIAAVOID_STATION_PICKER].find(picker => picker.id === travelPointPickerType);
        const travelPoints = {
            origin: this.originTravelPoint,
            destination: this.destinationTravelPoint,
            viaavoid: this.viaAvoidTravelPoint
        };

        if (this.store.selectSnapshot(state => MetadataState.isMobile(state.metadata))) {
            this.modalService.create(StationPickerMobileModalComponent, {
                cta: "Continue",
                stationPickerMetadata,
                stationPickerType: travelPointPickerType,
                stationTravelPoint: travelPoints[travelPointPickerType],
                handleCTAClick: (instance: { stationTravelPoint: string; stationPickerType: StationPickerType; destroy: () => {} }) => {
                    const { stationTravelPoint, stationPickerType } = instance;

                    const onTravelPointsChange = {
                        origin: () => this.onOriginChange(stationTravelPoint),
                        destination: () => this.onDestinationChange(stationTravelPoint),
                        viaavoid: () => this.onViaAvoidChange(stationTravelPoint)
                    };

                    onTravelPointsChange[stationPickerType]();

                    instance.destroy();
                    this.closeStationPickers();
                },
                handleCloseClick: instance => {
                    instance.destroy();
                    this.closeStationPickers();
                }
            });
        }
    }

    private closeStationPickers(): void {
        this.fromStationPicker?.onBlur();
        this.toStationPicker?.onBlur();
        this.viaAvoidStationPicker?.onBlur();
    }
}
