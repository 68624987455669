class NuscardComponent {
    private _isValid: boolean = true;

    public get isValid(): boolean {
        return this._isValid;
    }
}

export interface INusCardPayload {
    card: string;
    valid: boolean;
}

export enum NusStatusEnum {
    PRE_VALIDATION,
    VALID,
    INVALID,
    EXPIRED,
    EXCEPTION
}

export { NuscardComponent };
