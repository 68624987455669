import { AceUserType } from "./ace-user-type";
import { AceUser } from "./ace-user.model";

export class AceAnonymousUser extends AceUser {
    /**
     *
     * @type {boolean}
     */
    public isLoggedIn = false;
    public type: AceUserType = AceUserType.ANONYMOUS;
    public userType: string = "ANONYMOUS";

    constructor() {
        super();
    }
}
