import { IOrderStateModel } from "./order-state.model";

export class SelectSmartcardOption {
    static readonly type = "[Order] Select smartcard option";
}

export class UpdateOrder {
    static readonly type = "[Order] Update order";
    constructor(public payload: IOrderStateModel) {}
}

export class AmendOrder {
    static readonly type = "[Order] Amend order";
}

export class RemoveOrder {
    static readonly type = "[Order] Remove order";
}

export class ClearOrders {
    static readonly type = "[Order] Clear all orders";
}
