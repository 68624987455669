export abstract class AceModel {
    private _data: any;

    constructor(data: any) {
        this._data = data;

        if (data) {
            this._init();
        } else {
            this._initEmpty();
        }
    }

    protected _clearData(): void {
        this._data = null;
    }

    protected abstract _init();
    protected abstract _initEmpty();

    public get data(): any {
        return this._data;
    }
}
