import { ISmartcardPassengers } from "../interfaces/ISmartcardPassenegers";
import { AceModel } from "./abstract/ace-model";
import { AceFee } from "./ace-fee.model";
import { AceTicketingOptionDetails } from "./ace-ticketing-option-details.model";

export class AceTicketingOption extends AceModel implements DataModel.TicketingOption {
    public title: string;
    public description: string;
    public details: AceTicketingOptionDetails;
    public fee: AceFee;
    public code: DataModel.TicketingOptionCode;
    public type: string;
    public departureStation: boolean;
    public destination: string;
    public redeliveryAllowed: boolean;
    public isSelected: boolean = false;
    public passengerDeliveryOptions: ISmartcardPassengers = null;

    constructor(data: DataModel.TicketingOption) {
        super(data);
    }

    protected _init() {
        this.title = this.data.description;
        this.description = "";
        this.fee = new AceFee(this.data.fee);
        this.code = this.data.code;
        this.type = this.data.type;
        this.departureStation = this.data.departureStation;
        this.destination = this.data.destination;
        this.redeliveryAllowed = this.data.redeliveryAllowed;
    }

    protected _initEmpty() {
        return;
    }
}
