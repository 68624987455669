export enum PhotocardFieldType {
    PASSENGER_NAME = "PASSENGER_NAME",
    PASSENGER_TITLE = "PASSENGER_TITLE",
    TRAVEL_DOCUMENTS = "TRAVEL_DOCUMENTS"
}

export interface IPassengerInformationRequired {
    isNameRequired: boolean;
    isTitleRequired: boolean;
    isPhotocardRequired: boolean;
}
