import { CurrencyPipe } from "@angular/common";
import { Pipe, PipeTransform } from "@angular/core";
import { AcePointToPointPrice } from "../models/ace/ace-point-to-point-price.model";

@Pipe({
    name: "priceLabel"
})
export class PriceLabelPipe implements PipeTransform {
    constructor(private currencyPipe: CurrencyPipe) {}

    transform(price: AcePointToPointPrice, isReturnColumn: boolean): string {
        if (price == null) {
            return null;
        }

        let finalPrice = 0;

        if (isReturnColumn && !price.isSingleFare && !price.isReturnSingleFare) {
            finalPrice = 0;
        } else if (!isReturnColumn || price.isSingleFare) {
            finalPrice = price.totalPrice.promotion?.promotionPrice ?? price.totalPrice.value;
        }

        return `${this.currencyPipe.transform(finalPrice, "GBP")} ${price.fareDescription}`;
    }
}
