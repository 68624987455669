export const POINT_TO_POINT_REQUEST = "PointToPointShoppingRequest";
export const TRAVEL_PASS_REQUEST = "TravelPassShoppingRequest";
export const CONFIRM_BOOKING_REQUEST = "ConfirmBookingRecordRequest";
export const BRAINTREE_PAYMENT_REQUEST = "BrainTreePaymentRequest";
export const BRAINTREE_TOKEN_REQUEST = "BrainTreeClientTokenRequest";
export const CALLING_POINTS_REQUEST = "GetIntermediateTravelPointsRequest";
export const VALIDATE_SMARTCARD_REQUEST = "ValidateSmartCardDetailsRequest";
export const SMART_CARD_JOURNEYS_REQUEST = "GetSmartCardJourneysRequest";
export const CREATE_SMARTCARD_REQUEST = "CreateSmartCardRequest";
export const STORE_SMART_CARDS_REQUEST = "StoreSmartCardsRequest";
export const USER_SMART_CARDS_REQUEST = "GetUserSmartCardsRequest";
export const GET_SMART_CARD_PRODUCTS_REQUEST = "GetSmartCardProductsRequest";
export const HOTLIST_AND_REPLACE_SMARTCARD_REQUEST = "ReplaceSmartCardRequest";
export const REMOVE_SMARTCARD_REQUEST = "RemoveSmartCardRequest";
export const USER_LOGIN_REQUEST = "UserLoginRequest";
export const USER_AUTH_REQUEST = "WhoAmIRequest";
export const EMAIL_REGISTER_REQUEST = "EmailRegisterRequest";
export const USER_VERIFY_REQUEST = "UserVerificationRequest";
export const EMAIL_VERIFY_REQUEST = "EmailVerificationRequest";
export const USER_INIT_RESET_REQUEST = "InitResetPasswordRequest";
export const USER_RESET_REQUEST = "ResetPasswordRequest";
export const CLAIM_ACCOUNT_REQUEST = "ClaimGuestAccountRequest";
export const RESEND_EMAIL_REQUEST = "ResendVerificationEmailRequest";
export const USER_BOOKING_REQUEST = "UserBooking";
export const USER_REGISTER_REQUEST = "UserRegisterRequest";
export const USER_UPDATE_REQUEST = "UserUpdateRequest";
export const CLAIM_GUEST_ACCOUNT_REQUEST = "ClaimGuestAccountRequest";
export const PRINT_AT_HOME_PDF_REQUEST = "GetValueDocumentPDFRequest";
export const ETICKET_PDF_REQUEST = "ETicketsDownloadRequest";
export const APPLE_WALLET_TICKET_PASS_REQUEST = "PkpassDownloadRequest";
export const GOOGLE_WALLET_PASSES_REQUEST = "GooglePayLinkDownloadRequest";
export const SAVE_USER_RECENT_STATION_REQUEST = "SaveUserRecentStationRequest";
export const EXCHANGE_SEARCH_REQUEST = "ExchangeSearchRequest";
export const DELETE_USER_ACCOUNT_REQUEST = "DeleteUserAccountRequest";
export const PROCESS_EXCHANGE_REQUEST = "ProcessExchangeRequest";
