/* eslint-disable prefer-arrow/prefer-arrow-functions */
import { ActivatedRouteSnapshot } from "@angular/router";

export class Utils {
    private static _currencySymbolMap: Array<{
        currency: string;
        symbol: string;
    }> = [
        { currency: "GBP", symbol: "£" },
        { currency: "USD", symbol: "$" },
        { currency: "EUR", symbol: "€" }
    ];

    private static _htmlNode = document.createElement("div");

    /**
     * Converts string of type "P0Y0M0DT0H28M0S" and returns label and value ["28 mins", 1680]
     *
     * @param durationString
     * @returns {{label: string, value: number, labelShort: string}}
     */
    public static parseSilverRailDuration(durationString: string) {
        return silverRailDurationParser(durationString);
    }

    public static extractTextNodeFromHTML(htmlString: string): string {
        Utils._htmlNode.innerHTML = htmlString;
        return Utils._htmlNode.innerText;
    }

    public static aceFeeDecorator(fee, attribute: string): string {
        let result: string;
        if (!fee || !fee.value) {
            result = "Free";
        } else {
            if (attribute === "symbol") {
                const curr = this._currencySymbolMap.find(symbol => symbol.currency === fee.currency);
                result = curr.symbol + String(fee.value.toFixed(2));
            } else {
                result = String(fee.value) + " " + String(fee.currency);
            }
        }
        return result;
    }

    public static getPathFromSnapshot(snapshot: ActivatedRouteSnapshot): string[] {
        const pathSegments: string[] = [];

        if (snapshot.url.length !== 0 && snapshot.url[0].path !== "") {
            pathSegments.push(snapshot.url[0].path);
        }

        if (snapshot.children.length !== 0) {
            pathSegments.push(...Utils.getPathFromSnapshot(snapshot.children[0]));
        }

        return pathSegments;
    }
}

/**
 *
 * @param durationString
 * @param currentValue
 * @param timeOffsets
 * @param timeLabels
 * @param timeLabelsShort
 * @returns {any}
 */
function silverRailDurationParser(
    durationString: string,
    currentValue?: { label: string; value: number; labelShort: string },
    timeOffsets?: number[],
    timeLabels?: string[],
    timeLabelsShort?: string[]
): { label: string; value: number; labelShort: string } {
    if (durationString.charAt(0) === "P") {
        return silverRailDurationParser(
            durationString.substr(1),
            { label: "", value: 0, labelShort: "" },
            [31536000, 2592000, 86400, 3600, 60, 1],
            ["y", "mo", "d", "h", "m", "s"],
            ["y", "mo", "d", "h", "m", "s"]
        );
    }

    if (durationString.charAt(0) === "T") {
        return silverRailDurationParser(durationString.substr(1), currentValue, timeOffsets, timeLabels, timeLabelsShort);
    }

    const parsedValue = parseInt(durationString, 10);
    const timeLabel = timeLabels.shift();
    const timeLabelShort = timeLabelsShort.shift();
    const timeOffset = timeOffsets.shift();
    let label = "";
    let labelShort = "";
    let value = 0;
    const newDurationString = durationString.substr(String(parsedValue).length + 1);
    if (parsedValue > 0) {
        labelShort = parsedValue + timeLabelShort;
        label = parsedValue + timeLabel;
        value = parsedValue * timeOffset;
    }

    // Only 1 character left? Means this is the last value "S" and we are done.
    if (newDurationString.length <= 1) {
        return currentValue;
    }

    const nextVal = silverRailDurationParser(newDurationString, currentValue, timeOffsets, timeLabels, timeLabelsShort);
    return {
        labelShort: currentValue.labelShort + labelShort + (labelShort.length > 0 && nextVal.labelShort.length > 0 ? " " : "") + nextVal.labelShort,
        label: currentValue.label + label + (label.length > 0 && nextVal.label.length > 0 ? " " : "") + nextVal.label,
        value: currentValue.value + value + nextVal.value
    };
}
