<div class="accordion">
    <button
        class="accordion-head"
        #headElement
        [attr.id]="'accordion-control-' + id"
        [class.accordion-head-toggled]="isOpen"
        [attr.tabindex]="tabindex"
        [attr.aria-expanded]="isOpen"
        [attr.aria-controls]="'accordion-content-' + id"
        (click)="onClickHandler()"
    >
        <ng-content select="accordion-head"></ng-content>
    </button>
    <div
        role="region"
        class="accordion-content"
        [class.accordion-content-visible]="isOpen"
        [attr.id]="'accordion-content-' + id"
        [attr.aria-labelledby]="'accordion-control-' + id"
    >
        <ng-content select="accordion-content"></ng-content>
    </div>
</div>
