import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from "@angular/core";

type CheckboxThemes = "theme-light";

@Component({
    selector: "ace-checkbox",
    templateUrl: "checkbox.component.html",
    styleUrls: ["checkbox.component.scss"]
})
export class CheckboxComponent {
    @Input() public checkboxId: string;
    @Input() public theme: CheckboxThemes;
    @Input() public label: string;
    @Input() public checkmarkHidden: boolean = false;
    @Input() public disabled: boolean = false;
    @Input() public checked: boolean = false;
    @Input() public smallFontSize: boolean = true;
    @Input() public withMargin: boolean = true;
    @Output() public check = new EventEmitter<boolean>();
    @ViewChild("checkboxElement") private checkboxElement: ElementRef<HTMLDivElement>;

    public focus(): void {
        setTimeout(() => {
            this.checkboxElement?.nativeElement?.focus();
        }, 0);
    }

    public onSubmit(): void {
        if (this.disabled) {
            return;
        }

        this.checked = !this.checked;
        this.check.emit(this.checked);
    }
}
