import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, Router } from "@angular/router";
import { Select } from "@ngxs/store";
import { combineLatest, Observable } from "rxjs";
import { map, take } from "rxjs/operators";
import { SmartCardType } from "schema-enums/SmartCardType";
import { AceSmartcard } from "../models/ace/ace-smartcard.model";
import { IAceConfig } from "../models/interfaces/IAceConfig";
import { AccountState } from "../state/account/account.state";
import { ConfigState } from "../state/config/config.state";

@Injectable()
export class RegisteredSmartcardGuard implements CanActivate {
    @Select(AccountState.smartcards) public smartcards$: Observable<AceSmartcard[]>;
    @Select(ConfigState.stnr) public stnrConfig$: Observable<IAceConfig["stnr"]>;

    constructor(private router: Router) {}

    public canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
        return combineLatest([this.smartcards$, this.stnrConfig$]).pipe(
            map(([cards, stnrConfig]) => ({ cards, stnrConfig })),
            map(result => {
                const card = result.cards.find(card => card.smartCardId === parseInt(route.params["id"], 10));
                if (card) {
                    return card.businessId === result.stnrConfig.businessId && card.type === SmartCardType.Registered;
                } else {
                    this.router.navigate(["/account/details/smartcards"]);
                }
            }),
            take(1)
        );
    }
}
