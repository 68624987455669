import { Injectable, Renderer2, RendererFactory2 } from "@angular/core";
import { BehaviorSubject } from "rxjs";

@Injectable({
    providedIn: "root"
})
export class TicketPreviewService {
    private isExpandedSubject = new BehaviorSubject(false);
    public isExpanded$ = this.isExpandedSubject.asObservable();
    private renderer: Renderer2;

    constructor(private rendererFactory: RendererFactory2) {
        this.renderer = this.rendererFactory.createRenderer(null, null);
    }

    public toggle(): void {
        this.isExpandedSubject?.getValue() === false ? this.expand() : this.collapse();
    }

    public collapse(): void {
        this.isExpandedSubject.next(false);
        this.renderer.removeClass(document.body, "page-scroll-disabled");
    }

    private expand(): void {
        this.isExpandedSubject.next(true);
        this.renderer.addClass(document.body, "page-scroll-disabled");
    }
}
