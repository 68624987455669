import { UntypedFormGroup, ValidationErrors, ValidatorFn, Validators } from "@angular/forms";
import { BasicValidator } from "./BasicValidator";
import { UtilValidator } from "./UtilValidator";

export class AddressValidators {
    public static readonly FIELD_MAX_LENGTH = 250;
    public static readonly ANY_POSTCODE_REGEX = /^[a-zA-Z0-9\s\-]{3,}$/;
    public static readonly UK_POSTCODE_REGEX =
        /^(([A-Z]{1,2}\d[A-Z\d]?|ASCN|STHL|TDCU|BBND|[BFS]IQQ|PCRN|TKCA) ?\d[A-Z]{2}|BFPO ?\d{1,4}|(KY\d|MSR|VG|AI)[ -]?\d{4}|[A-Z]{2} ?\d{2}|GE ?CX|GIR ?0A{2}|SAN ?TA1)$/;

    public static postcodeRegexpMatch(postcode: string, country: string, optional = false): boolean {
        if (optional && (postcode === "" || postcode == null)) {
            return true;
        }

        const upperCasePostcode = postcode.toUpperCase();

        return country !== "GB" ? AddressValidators.ANY_POSTCODE_REGEX.test(upperCasePostcode) : AddressValidators.UK_POSTCODE_REGEX.test(upperCasePostcode);
    }

    public static postcode(postcodeKey: string, countryKey: string = null, optional = false): ValidatorFn {
        return (group: UntypedFormGroup): ValidationErrors | null => {
            const country = countryKey ? String(group.get(countryKey)?.value ?? "") : "";
            const postcode = String(group.get(postcodeKey)?.value ?? "");
            const result = AddressValidators.postcodeRegexpMatch(postcode, country, optional);

            return result ? null : { postcode: true };
        };
    }

    public static fieldMaxLength(length = this.FIELD_MAX_LENGTH) {
        return Validators.maxLength(length);
    }

    public static baseField(length?: number) {
        return Validators.compose([BasicValidator.checkChars(), BasicValidator.checkInjection(), this.fieldMaxLength(length)]);
    }

    public static requiredBaseField(length?: number) {
        return Validators.compose([UtilValidator.hasAnyNonWhitespaceCharacter, this.baseField(length)]);
    }
}
