<div class="railcard">
    <ng-container *ngFor="let railcard of railcardComponent?.railcards; let i = index">
        <div class="railcard-row">
            <div class="col col-9" data-elid="railcard-picker">
                <label class="sr-only" [attr.for]="'railcard-picker-select-' + index">Railcard</label>
                <ace-select
                    #railcardSelects
                    [selectId]="'railcard-picker-select-' + index"
                    [options]="railcard?.options"
                    [placeholder]="'Please select'"
                    [preSelected]="populateSelect(railcard)"
                    (optionSelect)="onRailcardSelected(railcard, $event)"
                    data-elid="qtt-railcard-select"
                ></ace-select>
            </div>

            <div class="col col-3">
                <ace-button class="railcard-remove" (click)="removeRailcard(i, $event)" data-elid="qtt-railcard-remove">Remove<span class="sr-only"> railcard</span></ace-button>
            </div>
        </div>

        <div class="railcard-row" *ngIf="i === 0 && (config.hasFeature('saveRailcards') | async)">
            <ace-checkbox checkboxId="railcard-picker-save-railcard-checkbox" [theme]="'theme-light'">Save railcard</ace-checkbox>
        </div>
    </ng-container>

    <div class="railcard-row-add" data-elid="qtt-railcard-add" *ngIf="showRailcard">
        <ace-button #addButton (click)="addRailcard($event)">
            Add
            <ng-container *ngIf="railcardComponent?.railcards?.length">another <span class="sr-only"> railcard</span></ng-container>
            <ng-container *ngIf="!railcardComponent?.railcards?.length">railcard</ng-container>

            <svg viewBox="0 0 24 24" class="railcard-icon" aria-hidden="true">
                <title>plus</title>
                <use xlink:href="#plus" />
            </svg>
        </ace-button>
    </div>
</div>
