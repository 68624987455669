export class AceBookingPassenger {
    public title: string = "";
    public firstName: string = "";
    public lastName: string = "";
    public age: number;
    public isAdult: boolean = false;
    public isChild: boolean = false;
    public contactInformation: any = {};
    public smartCardNumber: string = "";

    constructor(passenger: DataModel.Passenger) {
        this.title = passenger.title;
        this.firstName = passenger.nameFirst;
        this.lastName = passenger.nameLast;
        this.age = passenger.ageAtTimeOfTravel;
        this.isAdult = this.age >= 16;
        this.isChild = this.age < 16;
        this.contactInformation = passenger.contactInformation;
        this.smartCardNumber = passenger.smartCardNumber;
    }

    public get isDefined(): boolean {
        return this.firstName !== "EXAMPLE_FIRST_NAME" && this.lastName !== "EXAMPLE_LAST_NAME";
    }
}
