import { Injectable } from "@angular/core";
import { CookieService } from "ngx-cookie-service";
import { levels, logs as config } from "../../environments/loggerConfig";

interface ILogger {
    isLoggerEnabled: boolean;
    loggerContext: string;
    log(msg: any, prefix?: string): void;
    error(msg: any, prefix?: string): void;
    info(msg: any, prefix?: string): void;
    warn(msg: any): void;
    trace(): void;
}

@Injectable({
    providedIn: "root"
})
export class LoggerService implements ILogger {
    public isLoggerEnabled: boolean;
    private context: string;
    private levels = levels;

    constructor(private _cookieService: CookieService) {}

    public log(msg: any, prefix?: string): void {
        if (!this.validateLogLevel("log")) {
            return;
        }

        if (!this.isLoggerEnabled) {
            return;
        }

        console.group("%c[LOG]: " + this.context, "color: green");

        prefix ? console.log(`[LOG]: ${this.context}, ${prefix}`, msg) : console.log(`[LOG]: ${this.context}`, msg);

        console.groupEnd();
    }

    public error(msg: any, prefix?: string): void {
        if (!this.validateLogLevel("error")) {
            return;
        }

        if (!this.isLoggerEnabled) {
            return;
        }

        console.error(`ERROR: [${this.context} ${prefix}]`, msg);
    }

    public info(msg: any, prefix?: string): void {
        if (!this.validateLogLevel("info")) {
            return;
        }

        if (!this.isLoggerEnabled) {
            return;
        }

        console.group("%c[INFO]: " + this.context, "color: blue");

        if (prefix) {
            // eslint-disable-next-line no-console
            console.info(`[INFO]: ${this.context}, ${prefix}`, msg);
        } else {
            // eslint-disable-next-line no-console
            console.info(`[INFO]: ${this.context}`, msg);
        }

        console.groupEnd();
    }

    public warn(msg: any): void {
        if (!this.validateLogLevel("warn")) {
            return;
        }

        if (!this.isLoggerEnabled) {
            return;
        }

        console.warn(`WARN: [${this.context}]`, msg);
    }

    public trace(): void {
        if (!this.validateLogLevel("trace")) {
            return;
        }

        if (!this.isLoggerEnabled) {
            return;
        }

        // eslint-disable-next-line no-console
        console.trace();
    }

    public set loggerContext(context: string) {
        const stateFromCookie = this._cookieService.get("isLoggerEnabled");
        if (stateFromCookie !== "") {
            this.isLoggerEnabled = stateFromCookie === "1";
        } else {
            this.isLoggerEnabled = config.isLoggerEnabled && this.validateLogContext(context);
            this.context = context;
        }
    }

    private validateLogLevel(logLevel: logLevel): boolean {
        return this.levels.includes(logLevel);
    }

    private validateLogContext(context: string): boolean {
        if (!config.hasOwnProperty(context)) {
            console.error(`Context ${context} is missing in logger config`);
            return;
        }

        return config[context];
    }
}

type logLevel = "log" | "error" | "info" | "warn" | "trace" | "debug";
