import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { of, ReplaySubject } from "rxjs";
import { map, mergeMap } from "rxjs/operators";

@Injectable({
    providedIn: "root"
})
export class TocFaqService {
    private _faq$: ReplaySubject<IDrupalFaqItem[]> = new ReplaySubject(1);

    /**
     * faq content path from cms
     * @type {string}
     * @private
     */
    // todo: swap lines below to remove static content JSON loading
    // private _faqUrl: string = environment.cmsUrl + this._faqPath;
    private _faqUrl: string = "//" + location.host + "/assets/json/faq.json";

    constructor(private _http: HttpClient) {
        const contentRequest$ = this._http.get<DataModel.StandardResponse>(this._faqUrl).pipe(map(res => res.data as IDrupalFaqItem[]));

        contentRequest$?.subscribe(data => {
            const faq = [];
            data.forEach(item => faq.push(item));
            this._faq$.next(faq);
        });
    }

    public reduceGroup(groupName: string) {
        return this._faq$.pipe(mergeMap(items => of(items.filter(item => item.attributes.name === groupName))));
    }
}

export interface IDrupalFaqItem {
    attributes: IDrupalFaqAttribute;
}

export interface IDrupalFaqAttribute {
    answer: IDrupalFaqContent;
    name: string;
    priority: number;
    question: string;
}

export interface IDrupalFaqContent {
    format: string;
    value: string;
}
