import { environment } from "./../../../environments/environment";
import { AceBooking } from "./ace/ace-booking";
import { AceBookingOrder } from "./ace/ace-booking-order";

export class CompleteTransactionAnalyticsModel {
    public sor: string = null;
    public from: string = null;
    public to: string = null;
    public railcards: number = 0;

    constructor(booking: AceBooking, order: AceBookingOrder) {
        this.sor = booking.customInformation[0].value;

        this.from = order.originTravelPoint.code;
        this.to = order.destinationTravelPoint.code;

        this.railcards = order.railcardAmount;
    }

    public getCompletionData(): string {
        const keyOrder = this._getOrder();
        let stringParams = "";

        for (const key of Object.keys(keyOrder)) {
            const objKey = keyOrder[key];
            let value = this[objKey] !== undefined ? this[objKey] : null;

            if (typeof value === "number") {
                if (value === 0) {
                    value = null;
                } else {
                    value = objKey + ":" + this[objKey];
                }
            }

            stringParams += (Number(key) === 0 ? "" : "|") + value;
        }

        return stringParams;
    }

    public getStationCodes(): { origin: string; destination: string } {
        if (this.to && this.from) {
            return {
                origin: this.from,
                destination: this.to
            };
        }
    }

    public getOperatingCarrier(order: AceBookingOrder): string[] {
        if (order != null) {
            const operators = [];

            [order.outgoingLeg != null ? order.outgoingLeg : null, order.returnLeg != null ? order.returnLeg : null]
                .filter(it => it != null)
                .forEach(leg =>
                    leg.travelSegments.forEach(segment => {
                        if (segment && segment.operatingCarrier) {
                            operators.push(segment.operatingCarrier.toUpperCase());
                        }
                    })
                );

            return operators;
        }
    }

    private _getOrder(): string[] {
        return ["sor", "from", "to", "railcards"];
    }
}
