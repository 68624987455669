import { isEmpty, uniq, uniqBy } from "../../../shared/utilities/Utils";
import { IAmenity } from "../interfaces/ISearchResult";
import { Utils } from "./ace-utils";

export class AceTicketableFare {
    public totalPrice: DataModel.TotalPrice;
    public cabinClass: string;
    public fareDisplayName: string;
    public isSingle: boolean;
    public isAdvanced: boolean;
    public fareOrigin: DataModel.TravelPoint;
    public fareDestination: DataModel.TravelPoint;
    public fareOriginTerminals: string[];
    public fareDestinationTerminals: string[];
    public hasReservation: boolean = false;
    public hasRailcard = false;
    public hasGroupsave = false;
    public prices: DataModel.Price[];
    public fareTextRules: any[] = [];
    public fareTicketRules: any[] = [];
    public ticketingOptions: DataModel.TicketingOptionsAvailable;
    public ticketableFareID: string;
    public outwardFareExpirationDate: string;
    public returnFareExpirationDate?: string;
    public expirationAdditionalTime?: string;
    public reservationType: DataModel.ReservableType[];
    public farePassengers: DataModel.PassengerReference[];
    public amenities: IAmenity[] = [];
    public optionalPrices: any[] = [];
    public fareQualifiers: any;
    public compoundKey: string;
    public seatAssignment: ISegmentSeat[];
    public fareCode: any;
    public fareClass: string;
    public fareStartDateTime: string;
    public fareExpirationDateTime: string;
    public ticketRulesHTML: any[];
    public ticketRulesText: string[];
    public routeRule: any;
    public fareCodes: DataModel.FareCode[];
    public rules: DataModel.Rule[] = [];
    private readonly GROUPSAVE_CODE: string = "UK_GROUPSAVE";

    constructor(data: DataModel.TicketableFare) {
        if (isEmpty(data)) {
            throw new Error("TicketableFare constructor argument is required");
        }
        this._init(data);
    }

    private _init(data: DataModel.TicketableFare): void {
        this.fareCodes = data.passengerReferences[0].fareCodes;
        this.totalPrice = data.totalPrice;
        this.prices = data.prices;
        this.ticketingOptions = data.ticketingOptionsAvailable;
        this.hasReservation = this.prices.some(price => price.type === "RESERVATION");
        this.fareOrigin = data.fareOrigin;
        this.fareDestination = data.fareDestination;
        this.fareOriginTerminals = data.fareOriginTerminals.map(a => a.code);
        this.fareDestinationTerminals = data.fareDestinationTerminals.map(a => a.code);
        this.ticketableFareID = data.ticketableFareID;
        this.fareQualifiers = data.fareQualifiers;

        this.fareDisplayName = data.passengerReferences[0].fareCodes[0].fareDisplayName != null ? data.passengerReferences[0].fareCodes[0].fareDisplayName : "";
        this.isSingle = this.fareDisplayName.toLocaleLowerCase().indexOf("single") > -1;
        this.isAdvanced = this.fareDisplayName.toLocaleLowerCase().indexOf("advance") > -1;
        this.ticketableFareID = data.ticketableFareID;
        this.farePassengers = data.passengerReferences;
        this.optionalPrices = data.optionalPrices;
        this.ticketableFareID = data.ticketableFareID;
        this.outwardFareExpirationDate = data.outwardFareExpirationDate;
        this.returnFareExpirationDate = data.returnFareExpirationDate;
        this.expirationAdditionalTime = data.expirationAdditionalTime;
        this.farePassengers = data.passengerReferences;

        this.fareCode = data.passengerReferences[0].fareCodes[0];
        this.fareDisplayName = this.fareCode.fareDisplayName;
        this.fareStartDateTime = this.fareCode.fareStartDateTime;
        this.fareExpirationDateTime = this.fareCode.fareExpirationDateTime;
        this.cabinClass = this.fareCode.cabinClass;
        this.fareClass = this.fareCode.fareClass;
        this.rules = data.rules;

        data.rules.forEach(rule => {
            if (rule.type === "TEXT" && rule.description !== ".") {
                return this.fareTextRules.push(rule);
            }

            if (rule.priceType === "TICKET") {
                return this.fareTicketRules.push(rule);
            }
        });

        if (data.fareQualifiers.length > 0) {
            this.hasGroupsave = data.fareQualifiers.some(fare => fare.program === this.GROUPSAVE_CODE);
            this.hasRailcard = data.fareQualifiers.some(fare => fare.program !== this.GROUPSAVE_CODE);
        }

        const fareCodes: DataModel.FareCode[] = [].concat(...data.passengerReferences.map(pax => pax.fareCodes));

        // Amenities
        // Within the fare codes of each passengerReference, there is an "amenitiesAll" array which
        // needs to be connected to the referenced TravelSegment.
        uniqBy(fareCodes, "travelSegmentIDRef").forEach(fareCode => {
            this.amenities = (fareCode.amenities as IAmenity[]).map(a => {
                a["serviceClass"] = fareCode.serviceClass;
                a["cabinClass"] = fareCode.cabinClass;
                a["travelSegmentIDRef"] = fareCode.travelSegmentIDRef;
                return a;
            });
        });

        // Compound to determine the unique grouping for this ticketable fare
        this.compoundKey = uniq(
            fareCodes.map(f => {
                // Remove the last number since it signifies direction. This will allow us to keep the singles grouped together
                // in both direction while preserving fareCode uniqueness
                const parts = f.code.split("-");
                parts.pop();
                return parts.join("-");
            })
        ).join("_");

        this.reservationType = [].concat(...data.passengerReferences.map(passenger => passenger.fareCodes)).map(fare => fare.reservable);
        const reservations = [].concat(...data.passengerReferences.map(pref => pref.fareCodes).filter(it => it != null)).map(fare => {
            return {
                seats: fare.seatAssignment,
                travelSeg: fare.travelSegmentIDRef
            };
        });

        this.ticketRulesHTML = this.fareTextRules.map(line => {
            return `<p>${line.description.replace(/(?:(http(s)?:\/\/[^\s]+))/m, '<a href="$1" target="_blank">$1</a>')}</p>`;
        });

        this.ticketRulesText = this.ticketRulesHTML.map(a => Utils.extractTextNodeFromHTML(a));

        this.routeRule = this.ticketRulesHTML.filter(rule => rule.indexOf("via") > -1 || rule.indexOf("valid") > -1 || rule.indexOf("Valid") > -1)[0];

        if (this.routeRule) {
            this.routeRule = Utils.extractTextNodeFromHTML(this.routeRule);
        }

        this.seatAssignment = reservations.filter(res => res.seats !== null);
    }
}

interface ISegmentSeat {
    travelSeg: string;
    seats: any;
}
