import { AcePassenger } from "./ace-passenger";

export class AceCreateSeasonsBookingPayload {
    private _travelPassShoppingSharedId: string = "";
    private _passengers: AcePassenger[] = [];
    private _travelPassQuery: any = {};

    public toJSON(): object {
        return {
            travelPassShoppingSharedId: this._travelPassShoppingSharedId,
            passengers: this._passengers,
            travelPassQuery: this._travelPassQuery
        };
    }

    public setTravelPassQuery(searchParams: any, priceId: string) {
        this._travelPassQuery = {
            travelPointPair: {
                originTravelPoint: {
                    type: "STATION",
                    code: searchParams.origin
                },
                destinationTravelPoint: {
                    type: "STATION",
                    code: searchParams.destination
                },
                startDate: searchParams.startDate
            },
            travelPassPricesIds: [priceId],
            passIssueType: "NEW"
        };

        if (searchParams.endDate) {
            this._travelPassQuery.travelPointPair.endDate = searchParams.endDate;
        }

        return this;
    }

    public setPointToPointRequestId(value: string): AceCreateSeasonsBookingPayload {
        this._travelPassShoppingSharedId = value;
        return this;
    }

    public setPassengers(value: AcePassenger[]): AceCreateSeasonsBookingPayload {
        this._passengers = value;
        return this;
    }
}
