import { AbstractControl, ValidatorFn } from "@angular/forms";

export class EmailValidator {
    public static email(): ValidatorFn {
        return (control: AbstractControl): { [key: string]: any } => {
            const value = control.value;
            const pattern = new RegExp(
                /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            );
            return pattern.test(value) ? null : { email: true };
        };
    }

    public static checkEmailsMatch(control: AbstractControl) {
        const email = control.get("email");
        const confirmEmail = control.get("confirmEmail");
        if (!(email && confirmEmail)) {
            return null;
        }
        return email.value === confirmEmail.value ? null : { emailNoMatch: true };
    }
}
