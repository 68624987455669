<div
    role="dialog"
    class="modal-generic t-row {{ theme }}"
    data-elid="generic-modal"
    aria-modal="true"
    aria-labelledby="generic-modal-title"
    [attr.aria-description]="body"
    cdkTrapFocus
>
    <div class="t-col t-col-12">
        <div class="t-row">
            <span id="generic-modal-title" class="copy-title-2" data-elid="generic-title-modal">{{ title }}</span>
            <p data-elid="generic-body-modal">{{ body }}</p>
        </div>
        <div class="t-row">
            <div class="t-col t-col-12">
                <ace-button #ctaBtn appearance="editorial" size="primary" (action)="onButtonClicked()" id="generic-action-modal" data-elid="generic-action-modal">
                    {{ cta }}
                </ace-button>
            </div>
        </div>
    </div>
</div>
