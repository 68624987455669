import { Injectable } from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";
import { BehaviorSubject, EMPTY, from as fromPromise, Observable, Observer, of } from "rxjs";
import { mergeMap, share } from "rxjs/operators";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from "../../environments/environment";
import { TTL_STORAGE_KEY } from "../shared/constants/cache-keys";
import { CredentialsSignerConfig } from "../shared/models/entities/cognito";
import { get } from "../shared/utilities/Utils";
import { AwsSdkService } from "./aws-sdk.service";
import { ConfigService } from "./config.service";
import { LoggerService } from "./logger.service";
import { SessionManagementService } from "./session-management.service";
import { VersionCheck } from "./versioncheck.service";

@Injectable({
    providedIn: "root"
})
export class CredentialsSigner {
    public authenticatedSessionExpired: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    public defaultContentType: string = "application/json";
    public getCredsObservable: Observable<any>;
    public config$ = new BehaviorSubject<CredentialsSignerConfig>(null);
    private FETimestamp: number = null;
    // session duration is expressed in [ms]
    private FESessionDuration: number = 50 * 60 * 1000;
    private readonly sessionCheckExtendUrls: string[] = ["/verifyUser"];
    private AWS: any;

    constructor(
        private http: HttpClient,
        private configService: ConfigService,
        private router: Router,
        private versionCheck: VersionCheck,
        private awsSdkService: AwsSdkService,
        private sessionManagementService: SessionManagementService,
        private logger: LoggerService
    ) {
        this.logger.loggerContext = "CredentialsSigner";

        this.AWS = this.awsSdkService.getAWS();
        this.getCredsObservable = new Observable((observer: Observer<any>) =>
            this.getCreds().subscribe(val => {
                observer.next(val);
                observer.complete();
            })
        ).pipe(share());

        this.router?.events?.subscribe(e => {
            if (e instanceof NavigationEnd) {
                this.logger.info("NavigationEnd for url: " + e.url);
                this.getCredsObservable.subscribe();
            }
        });

        this.authenticatedSessionExpired.asObservable();

        if (environment && environment.sessionDuration) {
            this.FESessionDuration = environment.sessionDuration * 60 * 1000;
        }
    }

    public setCreds(identityId: string, token: string): Observable<any> {
        return this.configService.get("aceCoreApi").pipe(
            mergeMap((aceCoreApi: any) => {
                const config: CredentialsSignerConfig = {
                    region: aceCoreApi.region,
                    IdentityPoolId: aceCoreApi.identityPoolId
                };

                if (token && identityId) {
                    config.Logins = {
                        "cognito-identity.amazonaws.com": token
                    };
                    config.IdentityId = identityId;
                    this.authenticatedSessionExpired.next(false);
                    localStorage.setItem("cognitoConfig", JSON.stringify(config));
                }

                this.setFETimestamp(new Date().getTime() + this.FESessionDuration);

                this.AWS.config.credentials = null;
                return this.updateAwsConfig(config);
            })
        );
    }

    public isAuthenticatedObservable() {
        return this.getCreds().pipe(
            mergeMap(
                () =>
                    new Observable((observer: Observer<any>) => {
                        this.logger.warn(`isAuthenticatedObservable is ${this.isAuthenticated()}`);
                        observer.next(this.isAuthenticated());
                        observer.complete();
                    })
            )
        );
    }

    public clearCognito() {
        let status: boolean = false;
        if (this.AWS.config.credentials && this.AWS.config.credentials.clearCachedId) {
            this.AWS.config.credentials.clearCachedId();
            this.AWS.config.credentials = new this.AWS.Credentials();
            status = true;
        }

        // clear saved cognito session
        this.config$.next(null);
        localStorage.removeItem("cognitoConfig");
        return status;
    }

    public post<K>(url: string, body?: DataModel.StandardRequest, headers: any = {}): Observable<K> {
        return this.getCredsObservable.pipe(
            mergeMap(credentials => {
                if (!credentials) {
                    this.logger.error("Cannot sign POST request - no AWS credentials available");
                    return EMPTY;
                }

                // we need to check app version on each api call
                this.versionCheck.check();

                const requestURL = url;
                headers["host"] = this.AWS.util.urlParse(requestURL).host;

                if (!headers["Content-Type"] || !headers["content-type"]) {
                    headers["Content-Type"] = this.defaultContentType;
                }

                const httpRequest = new this.AWS.HttpRequest(requestURL, this.AWS.config.region);
                httpRequest.method = "POST";
                httpRequest.headers = headers;
                httpRequest.body = body ? JSON.stringify(body) : undefined;

                const v4signer = new this.AWS.Signers.V4(httpRequest, "execute-api", true);

                v4signer.addAuthorization(credentials, this.AWS.util.date.getDate());

                let signedHeaders = new HttpHeaders();
                for (const key of Object.keys(httpRequest.headers)) {
                    signedHeaders = signedHeaders.set(key, httpRequest.headers[key]);
                }
                signedHeaders = signedHeaders.delete("host");
                return this.http.post<K>(httpRequest.endpoint.href, httpRequest.body, {
                    params: httpRequest.endpoint.search,
                    headers: signedHeaders
                });
            })
        );
    }

    private getCreds(): Observable<any> {
        this.logger.log("getCreds");
        const now = new Date().getTime();
        if (this.getFETimestamp() > now) {
            this.logger.log("FET valid - extending FE & BE sessions");
            // extend both sessions if the FE session is valid
            this.setFETimestamp(new Date().getTime() + this.FESessionDuration);

            this.authenticatedSessionExpired.next(false);

            return this.refreshBETimestamp(now);
        } else {
            // identity is expired
            if (this.isAuthenticated() && this.isSessionCheck()) {
                this.logger.error("FET invalid - authenticated identity expired");

                if (this.clearCognito()) {
                    this.authenticatedSessionExpired.next(true);
                }

                if (this.isNreRedirect()) {
                    return of(null);
                }

                this.sessionManagementService.handleExpiredSession();

                return of(null);
            } else {
                this.logger.error("FET invalid - unauthenticated identity");
                this.setFETimestamp(new Date().getTime() + this.FESessionDuration);
                return this.refreshBETimestamp(now);
            }
        }
    }

    private isNreRedirect(): boolean {
        if (window.location.pathname.indexOf("/booking/seats-and-extras") === 0 && window.location.search.indexOf("?cacheId=") === 0) {
            return true;
        }

        if (window.location.pathname.indexOf("/nre") === 0) {
            return true;
        }
        return false;
    }

    private isAuthenticated(): boolean {
        const storedConfig = localStorage.getItem("cognitoConfig");
        if (storedConfig) {
            return true;
        }

        const logins: any = get(this.AWS.config.credentials, "params.Logins");
        return logins ? logins["cognito-identity.amazonaws.com"] !== null : false;
    }

    private refreshBETimestamp(now: number): Observable<any> {
        if (this.getBETimestamp() <= now) {
            return this.refreshCognito();
        } else {
            this.logger.warn("BET valid - returning credentials without change");
            return of(this.AWS.config.credentials);
        }
    }

    private isSessionCheck(): boolean {
        return this.sessionCheckExtendUrls.indexOf(window.location.pathname) < 0;
    }

    private refreshCognito(): Observable<any> {
        this.logger.info("Starting Cognito refresh");

        const loginCognitoConfig = localStorage.getItem("cognitoConfig");
        this.config$.next(loginCognitoConfig != null ? JSON.parse(loginCognitoConfig) : null);

        if (this.AWS.config.credentials && this.AWS.config.credentials.identityId) {
            // refresh credentials
            this.logger.log("Getting credentials with new config", "red");
            return this.getCognitoCredentials();
        } else {
            return this.configService.get("aceCoreApi").pipe(
                mergeMap((aceCoreApi: any) => {
                    const config = loginCognitoConfig
                        ? JSON.parse(loginCognitoConfig)
                        : {
                              region: aceCoreApi.region,
                              IdentityPoolId: aceCoreApi.identityPoolId
                          };

                    this.logger.log("Refreshing credentials with new config", "red");
                    return this.updateAwsConfig(config);
                })
            );
        }
    }

    private getFETimestamp(): number {
        if (!this.FETimestamp) {
            this.FETimestamp = Number(localStorage.getItem(TTL_STORAGE_KEY));
        }
        return this.FETimestamp;
    }

    private setFETimestamp(timestamp: number): void {
        localStorage.setItem(TTL_STORAGE_KEY, String(timestamp));
        this.FETimestamp = timestamp;
    }

    private getBETimestamp(): number {
        return this.AWS.config.credentials && this.AWS.config.credentials.expireTime ? new Date(this.AWS.config.credentials.expireTime).getTime() : 0;
    }

    private getCognitoCredentials(): Observable<any> {
        return fromPromise(
            this.AWS.config.credentials.getPromise().then(() => {
                return this.AWS.config.credentials;
            })
        );
    }

    private updateAwsConfig(config: CredentialsSignerConfig): Observable<any> {
        this.config$.next(config);

        if (!this.AWS.config.credentials || !this.AWS.config.credentials.identityId) {
            this.clearAWSCognitoCache(config.IdentityPoolId);
            const credentials = new this.AWS.CognitoIdentityCredentials(config);

            this.AWS.config.update({ region: config.region, credentials });
        }

        return this.getCognitoCredentials();
    }

    private clearAWSCognitoCache(identityPoolId: string): void {
        const identityCredentials = new this.AWS.CognitoIdentityCredentials({ IdentityPoolId: identityPoolId });
        identityCredentials.clearCachedId();
    }
}
