import { DeliveryPreferencesHelper } from "../../../shared/utilities/DeliveryPreferencesHelper";
import { isEmpty } from "../../../shared/utilities/Utils";
import { ISearchResultType } from "../interfaces/ISearchResult";
import { Promotion } from "../promotion.model";
import { ReservationType } from "../entities/reservation-type";
import { ISeatsPreferences } from "./../interfaces/ISeatsPreferences";
import { AceBookingOrder } from "./ace-booking-order";
import { AceLegSolution } from "./ace-leg-solution.model";
import { AcePassPrice } from "./ace-pass-price.model";
import { AcePointToPointPrice } from "./ace-point-to-point-price.model";
import { AceSearchResult } from "./ace-search-result.model";
import { AceSelectionState } from "./ace-selection-state";
import { AceTicketingOption } from "./ace-ticketing-option.model";
import { AceUserType } from "./ace-user-type";
import { AceUser } from "./ace-user.model";
import { AceCreateTicketingOptionPayload } from "./payloads/ace-create-ticketing-option-payload";
import { AcePassenger } from "./payloads/ace-passenger";

export class AceSelection {
    public state: AceSelectionState;
    public searchResults: AceSearchResult;
    public outgoingPrice: AcePointToPointPrice;
    public outgoingLegSolution: AceLegSolution;
    public returnPrice: AcePointToPointPrice;
    public returnLegSolution: AceLegSolution;
    public legSolutions: AceLegSolution[] = [];
    public prices: AcePointToPointPrice[] = [];
    public isOpenReturn: boolean;
    public isReturn: boolean;
    public isSingle: boolean;
    public isNre: boolean = false;
    public handoffId: string;
    public travelPass: boolean = false;
    public ticketingOption: AceCreateTicketingOptionPayload | null;
    public orderTicketingOption: AceCreateTicketingOptionPayload | null;
    public order: AceBookingOrder | null;
    public seasonPrice: AcePassPrice;
    public error: Error;
    public defaultFulfilment: DataModel.TicketingOptionCode;
    public validatedSelection: {
        complete: boolean;
        prices: AcePointToPointPrice[];
        legSolutions: AceLegSolution[];
    };

    private validatedSeasonSelection: {
        complete: boolean;
        prices: AcePassPrice;
    };

    constructor(
        searchResults?: AceSearchResult,
        priceIds: string[] | string = [],
        legSolutionsIds: string[] | string = [],
        ticketingOption: AceCreateTicketingOptionPayload | null = null,
        order: AceBookingOrder | null = null,
        nreResult?: DataModel.NreValidateBookingRecordInformationResponse,
        defaultFulfilment?: DataModel.TicketingOptionCode
    ) {
        this.defaultFulfilment = defaultFulfilment || null;
        this.state = AceSelectionState.NONE;
        if (nreResult) {
            this.initNre(nreResult);
            if (ticketingOption) {
                this.ticketingOption = ticketingOption;
            }
        }

        if (searchResults && searchResults.travelPass) {
            this.searchResults = searchResults;
            this.initSeasons(searchResults, priceIds);
            this.travelPass = true;
        } else {
            this.init(searchResults, priceIds, legSolutionsIds);
        }

        if (this.state !== AceSelectionState.NONE) {
            this.ticketingOption = ticketingOption;
        }

        if (order) {
            this.order = order;
            this.ticketingOption = ticketingOption;
            this.state = AceSelectionState.AMEND;
            this.orderTicketingOption = new AceCreateTicketingOptionPayload(order.selectedTicketingOption);
        }
    }

    public applyDefaultDelivery(user: AceUser, ticketingOptions: AceTicketingOption[], passengers: AcePassenger[]) {
        if (user && user.type === AceUserType.MEMBER && user.hasDeliveryPreference()) {
            const selectedOption: AceTicketingOption = this.ticketingOption
                ? DeliveryPreferencesHelper.getTicketingOptionByCode(ticketingOptions, this.ticketingOption.fulfillmentInformation.ticketOption.code)
                : DeliveryPreferencesHelper.getDeliveryOptionByUserPreference(user.getDeliveryPreference(), ticketingOptions);

            if (!selectedOption) {
                this.fallbackDeliveryDefaults(ticketingOptions);
            }

            this.updatePrimaryPassenger(user);

            // we have user default preference in ticketing options
            if (selectedOption) {
                selectedOption.isSelected = true;

                if (DeliveryPreferencesHelper.isPrintAtHome(selectedOption) && passengers.length === 1) {
                    passengers.map((p: AcePassenger) => {
                        p.setNameFirst(user.firstName);
                        p.setNameLast(user.lastName);
                    });

                    this.ticketingOption = new AceCreateTicketingOptionPayload(selectedOption, user.getDefaultAddress());
                } else {
                    this.ticketingOption = new AceCreateTicketingOptionPayload(selectedOption, user.getDefaultAddress());
                }
            } else {
                this.fallbackDeliveryDefaults(ticketingOptions);
            }
        } else {
            this.fallbackDeliveryDefaults(ticketingOptions);
        }
    }

    public isEmpty(): boolean {
        return this.state === AceSelectionState.NONE;
    }

    public isComplete(): boolean {
        return this.state === AceSelectionState.COMPLETE;
    }

    public isPartial(): boolean {
        return this.state === AceSelectionState.PARTIAL;
    }

    public isInvalid(): boolean {
        return this.state === AceSelectionState.INVALID;
    }

    public getAllPriceIds(): string[] {
        if (this.state !== AceSelectionState.COMPLETE) {
            return [];
        }
        return this.validatedSelection.prices.map(p => p.priceID);
    }

    /**
     * Iterates through the prices of validatedSelection, filters ones with promotion and creates object literal that
     * encapsulates order, price and promotion
     * @param orderLocator
     * @returns {any}
     */
    public getAllPromoPrices(orderLocator): Array<{ key: string; value: string }> {
        if (this.state !== AceSelectionState.COMPLETE) {
            return [];
        }

        return this.validatedSelection.prices
            .filter((p: AcePointToPointPrice) => !!p.totalPrice.promotion)
            .map((p: AcePointToPointPrice) => {
                const legNumber: number = p.legSolutions[0].isReturn ? 2 : 1;
                const currentPromotion: Promotion = this.searchResults.promotions.find(promo => promo.id === p.totalPrice.promotion.promotionId);
                return {
                    key: `PromotionType#${orderLocator}#${p.priceID}#${p.totalPrice.promotion.promotionId}#${p.totalPrice.promotion.promotionPrice}#${p.totalPrice.promotion.percentValue}#${p.promoHash}#${legNumber}#${currentPromotion.type}`,
                    value: String(p.totalPrice.promotion.value)
                };
            });
    }

    public getTicketingOptionId(): string {
        if (this.state !== AceSelectionState.COMPLETE) {
            return "";
        }
        return this.ticketingOption ? this.ticketingOption.fulfillmentInformation.ticketOption.code : "";
    }

    public getAllLegSolutionIds(): string[] {
        if (this.state !== AceSelectionState.COMPLETE) {
            return [];
        }
        return this.validatedSelection.legSolutions.map(l => l.legSolutionID);
    }

    public getPassengerSelection(user: AceUser | null = null): AcePassenger[] {
        if (this.state !== AceSelectionState.COMPLETE) {
            return [];
        }

        // TODO:  temporary workaround for users who have null first and last names set in their profile e.g.
        // because of claiming a guest account which  does not require them upon registration
        // see https://ormlondon.atlassian.net/browse/AMS-2967 for a discussion
        if (user && user.firstName && user.lastName) {
            this.updatePrimaryPassenger(user);
        }

        return this.searchResults.passengers;
    }

    public getSelectedPassengerIds(user: AceUser | null = null): string[] {
        if (this.state !== AceSelectionState.COMPLETE) {
            return [];
        }

        // TODO:  temporary workaround for users who have null first and last names set in their profile e.g.
        // because of claiming a guest account which  does not require them upon registration
        // see https://ormlondon.atlassian.net/browse/AMS-2967 for a discussion
        if (user && user.firstName && user.lastName) {
            this.updatePrimaryPassenger(user);
        }

        const passengerIds = [];

        this.searchResults.passengers.forEach(passenger => {
            passengerIds.push(passenger.passengerID);
        });

        return passengerIds;
    }

    public updatePrimaryPassenger(user: AceUser): void {
        const passenger = this.searchResults.passengers[0];

        if (!passenger.isDefined) {
            passenger.setNameFirst(user.firstName);
            passenger.setNameLast(user.lastName);
        }

        const primaryEmail = user.emails.find(email => email.primary);

        if (primaryEmail) {
            this.searchResults.passengers.forEach(it => it.setEmail(primaryEmail.email));
        }
    }

    public getSeatsPreferences(): ISeatsPreferences | null {
        if (this.state !== AceSelectionState.COMPLETE) {
            return null;
        }

        const isFirstClass = this.prices.some(price => price.serviceClass === "FIRST");
        let isAvailable = true;

        if (this.outgoingPrice.reservationType.outgoing === ReservationType.NOT_POSSIBLE) {
            isAvailable = !!(this.returnPrice && this.returnPrice.reservationType.return !== ReservationType.NOT_POSSIBLE);
        }

        return {
            isReturn: this.isReturn,
            isOpenReturn: this.isOpenReturn,
            outType: this.outgoingPrice.reservationType.outgoing,
            returnType: this.returnPrice ? this.returnPrice.reservationType.return : this.outgoingPrice.reservationType.return,
            selectionsAvailable: isAvailable,
            options: this.outgoingLegSolution.seatOptions[isFirstClass ? "FIRST" : "THIRD"]
        };
    }

    public getNreSeatsPreferences(data: DataModel.NreValidateBookingRecordInformationResponse): ISeatsPreferences | null {
        const order = data.bookingRecordInformation.orderInformationSet[0];
        const isFirstClass = order.prices.some((p: any) => p.serviceClass === "FIRST");
        const legSolutions = order.legSolutions.map(it => new AceLegSolution(it));
        const outgoingPrice = this.parseOutgoingPrice(this.prices);
        const returnPrice = this.parseReturnPrice(this.prices);
        let isAvailable = true;

        if (outgoingPrice.reservationType.outgoing === ReservationType.NOT_POSSIBLE) {
            isAvailable = !!(returnPrice && returnPrice.reservationType.return !== ReservationType.NOT_POSSIBLE);
        }

        return {
            isReturn: order.legSolutions.length === 2,
            isOpenReturn: false,
            outType: outgoingPrice.reservationType.outgoing,
            returnType: returnPrice ? returnPrice.reservationType.return : outgoingPrice.reservationType.return,
            selectionsAvailable: isAvailable,
            options: legSolutions[0].seatOptions[isFirstClass ? "FIRST" : "THIRD"]
        };
    }

    public getSeasonPrice(): AcePassPrice {
        if (this.state !== AceSelectionState.COMPLETE) {
            return null;
        }
        return this.seasonPrice;
    }

    public getSeasonPriceId(): string {
        if (this.state !== AceSelectionState.COMPLETE) {
            return null;
        }
        return this.seasonPrice.priceID;
    }

    public getPrimaryPassengerSelection(): AcePassenger {
        if (this.state !== AceSelectionState.COMPLETE) {
            return null;
        }
        return this.searchResults.passengers[0];
    }

    public getPriceId(): string[] {
        if (this.state !== AceSelectionState.COMPLETE) {
            return null;
        }
        return [this.seasonPrice.priceID];
    }

    public getSearchTypeData(orderId: string): { customInformation: DataModel.CustomInformationPair[] } {
        return {
            customInformation: [
                {
                    key: `sourceSearchType-${orderId}`,
                    value: this.travelPass ? "SEASONS" : this.isOpenReturn ? "OPEN" : this.isReturn ? "RETURN" : "SINGLE"
                }
            ]
        };
    }

    public getPromotionsData(orderLocator: string) {
        return {
            customInformation: this.getAllPromoPrices(orderLocator)
        };
    }

    private fallbackDeliveryDefaults(ticketingOptions: AceTicketingOption[]): void {
        let selectedOption: AceTicketingOption;
        if (!this.ticketingOption) {
            selectedOption = DeliveryPreferencesHelper.getTicketingOptionByCode(ticketingOptions, this.defaultFulfilment);

            if (selectedOption) {
                selectedOption.isSelected = true;
                this.ticketingOption = new AceCreateTicketingOptionPayload(selectedOption ? selectedOption : ticketingOptions[0]);
            }
        } else {
            selectedOption = ticketingOptions.find(
                opt => opt.type === this.ticketingOption.fulfillmentInformation.ticketOption.type && opt.code === this.ticketingOption.fulfillmentInformation.ticketOption.code
            );

            if (selectedOption) {
                selectedOption.isSelected = true;
            }
        }
    }

    private init(searchResults, priceIds, legSolutionsIds): void {
        if (searchResults && !isEmpty(priceIds) && !isEmpty(legSolutionsIds)) {
            this.searchResults = searchResults;
        } else {
            return;
        }

        if (this.searchResults.resultType === ISearchResultType.INVALID || this.searchResults.resultType === ISearchResultType.EMPTY) {
            return;
        }

        try {
            this.validatedSelection = this.searchResults.getDesktopProjection().validateCombination(priceIds, legSolutionsIds);
        } catch (e) {
            this.error = e;
            this.state = AceSelectionState.INVALID;
            return;
        }

        // If we are here, then the selection is valid, but maybe not complete
        if (this.validatedSelection.complete) {
            this.state = AceSelectionState.COMPLETE;
        } else {
            this.state = AceSelectionState.PARTIAL;
        }

        this.initParams();
    }

    private initNre(response: DataModel.NreValidateBookingRecordInformationResponse): void {
        const orderInformation = response.bookingRecordInformation.orderInformationSet[0];
        const validatedSelection = {
            complete: true,
            prices: orderInformation.prices.map(price => new AcePointToPointPrice(price)),
            legSolutions: orderInformation.legSolutions.map(legSolution => new AceLegSolution(legSolution))
        };

        this.isNre = true;
        this.handoffId = response.handoffRequestId;
        this.validatedSelection = validatedSelection;

        this.initParams();
    }

    private initSeasons(searchResults, seasonPrice): void {
        const priceId = Array.isArray(seasonPrice) ? seasonPrice[0] : seasonPrice;

        if (!priceId) {
            return;
        }

        try {
            this.validatedSeasonSelection = this.searchResults.getSeasonsProjection().isSelectedValid(priceId);
        } catch (e) {
            console.error(e);
            this.error = e;
            this.state = AceSelectionState.INVALID;
            return;
        }

        // If we are here, then the selection is valid, but maybe not complete
        if (this.validatedSeasonSelection.complete) {
            this.state = AceSelectionState.COMPLETE;
        } else {
            this.state = AceSelectionState.PARTIAL;
        }

        this.seasonPrice = this.validatedSeasonSelection.prices;
    }

    private initParams(): void {
        this.prices = this.validatedSelection.prices;
        this.legSolutions = this.validatedSelection.legSolutions;
        this.outgoingLegSolution = this.legSolutions.find(legSolution => legSolution.isOutgoing === true);
        this.returnLegSolution = this.legSolutions.find(legSolution => legSolution.isOutgoing === false);

        this.outgoingPrice = this.parseOutgoingPrice(this.prices);
        this.returnPrice = this.parseReturnPrice(this.prices);

        this.isSingle = this.outgoingPrice.isSingleFare;
        if (this.searchResults) {
            this.isReturn = this.searchResults.searchParams.inbound === "true";
            this.isOpenReturn = this.searchResults.isOpenReturn;
        }
    }

    private parseOutgoingPrice(prices: AcePointToPointPrice[]): AcePointToPointPrice {
        let outgoingPrice = prices.find(
            price => price.isOutgoingSingleFare || (this.outgoingLegSolution && price.legReferences.indexOf(this.outgoingLegSolution.legSolutionID) > -1)
        );

        if (!outgoingPrice) {
            outgoingPrice = prices[0];
        }

        return outgoingPrice;
    }

    private parseReturnPrice(prices: AcePointToPointPrice[]): AcePointToPointPrice {
        return prices.find(price => price.isReturnSingleFare || (this.returnLegSolution && price.legReferences.indexOf(this.returnLegSolution.legSolutionID) > -1));
    }
}
