import { Pipe, PipeTransform } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { TocContentService } from "../../services/toc-content.service";

@Pipe({
    name: "contentByKey"
})
export class ContentByKeyPipe implements PipeTransform {
    constructor(private tocContentService: TocContentService) {}

    public transform(key: string): Observable<string> {
        return this.tocContentService.content$.pipe(
            map(contentMap => {
                if (contentMap) {
                    return contentMap[key] !== undefined ? contentMap[key] : key;
                } else {
                    return key;
                }
            })
        );
    }
}
