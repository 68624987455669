import { DeliveryPreferencesHelper } from "../../../../shared/utilities/DeliveryPreferencesHelper";
import { AceTicketingOption } from "../ace-ticketing-option.model";
import { AcePassenger } from "./ace-passenger";

export class AceCreateTicketingOptionPayload {
    public fulfillmentInformation: DataModel.FulfillmentInformation;

    constructor(option: AceTicketingOption | any, optionInfo: any = null, restore: boolean = false) {
        this.fulfillmentInformation = {
            ticketOption: option
        };

        if (DeliveryPreferencesHelper.isPrintAtHome(option) && optionInfo) {
            this._parsePrintAtHomeId(optionInfo);
        }

        if (DeliveryPreferencesHelper.isMailType(option) && optionInfo) {
            this._parseUserAddress(optionInfo, restore);
        }
    }

    public isValid(passengers: AcePassenger[] = []): boolean {
        const { ticketOption, shippingAddress, shippingName } = this.fulfillmentInformation;

        if (DeliveryPreferencesHelper.isMailType(ticketOption)) {
            return Boolean(shippingAddress && shippingName);
        }

        if (DeliveryPreferencesHelper.isPrintAtHome(ticketOption)) {
            return passengers.every(passenger => passenger.isDefined);
        }

        if (DeliveryPreferencesHelper.isSmartTicket(ticketOption)) {
            const { passengerDeliveryOptions: smartcardPassengers } = <AceTicketingOption>ticketOption;
            return smartcardPassengers
                ? Object.keys(smartcardPassengers).every(option => {
                      const orderDetails = smartcardPassengers[option] || {};
                      return Boolean(orderDetails.isValid || (orderDetails.dataStore && orderDetails.dataStore.isValid));
                  })
                : false;
        }

        return true;
    }

    public hasShippingChanged(orderDeliveryOptions: DataModel.OrderFulfillmentInformation): boolean {
        if (!DeliveryPreferencesHelper.hasShipping(orderDeliveryOptions.selectedTicketingOption)) {
            return false;
        }

        const address: DataModel.Address = {
            address1: orderDeliveryOptions.shippingAddress.address1,
            address2: orderDeliveryOptions.shippingAddress.address2,
            city: orderDeliveryOptions.shippingAddress.city,
            country: orderDeliveryOptions.shippingAddress.country,
            addressType: orderDeliveryOptions.shippingAddress.addressType,
            zipCode: orderDeliveryOptions.shippingAddress.zipCode,
            stateProv: orderDeliveryOptions.shippingAddress.stateProv
        };

        address.address2 = address.address2 === "," ? ", " : address.address2;

        const selectedTickOpt = Array.from(Object.keys(address), key => address[key]);
        const orderTickOpt = Array.from(Object.keys(this.fulfillmentInformation.shippingAddress), key => {
            return this.fulfillmentInformation.shippingAddress[key];
        });

        if (this.fulfillmentInformation.shippingName !== orderDeliveryOptions.shippingName) {
            return true;
        }

        if (!(selectedTickOpt.length === orderTickOpt.length && selectedTickOpt.every((value, key) => value === orderTickOpt[key]))) {
            return true;
        }
    }

    public hasDeliveryMethodChanged(orderDeliveryOptions: DataModel.OrderFulfillmentInformation): boolean {
        if ([orderDeliveryOptions.selectedTicketingOption.code, orderDeliveryOptions.selectedTicketingOption.code].every(val => val === "SCT")) {
            return true;
        }
        return this.fulfillmentInformation.ticketOption.code !== orderDeliveryOptions.selectedTicketingOption.code;
    }

    private _parseUserAddress(data, restore) {
        if (restore) {
            this.fulfillmentInformation.shippingAddress = data.shippingAddress;
            this.fulfillmentInformation.shippingName = data.shippingName;
        } else {
            this.fulfillmentInformation.shippingName = data.name;

            const [address1, address2] = this._addressDelimeterConv(data);

            this.fulfillmentInformation.shippingAddress = {
                address1,
                address2,
                city: data.address.city,
                country: data.address.country,
                addressType: "BUSINESS",
                zipCode: data.address.postcode
            };

            if (data.address.county) {
                this.fulfillmentInformation.shippingAddress["stateProv"] = data.address.county;
            }
        }
    }

    private _addressDelimeterConv(data): string[] {
        let address1 = ", ";
        let address2 = ", ";

        if (data && data.address && data.address.line1) {
            address1 = data.address.line1 + ", ";
        }

        if (data && data.address && data.address.line2) {
            address1 += data.address.line2;
        }

        if (data && data.address && data.address.line3 && data.address.line3 !== ",") {
            address2 = data.address.line3 + ", ";
        }

        if (data && data.address && data.address.line4 && data.address.line3 !== ",") {
            address2 += data.address.line4;
        }

        return [address1, address2];
    }

    private _parsePrintAtHomeId(data) {
        this.fulfillmentInformation.shippingName = data.name;
    }
}
