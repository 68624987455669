import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, Router } from "@angular/router";
import { BehaviorSubject, combineLatest, Observable } from "rxjs";
import { filter, take } from "rxjs/operators";
import { PromptComponent } from "../../modal/components/modals/prompt/prompt.component";
import { ModalService } from "../../modal/services/modal.service";
import { BasketService } from "../../services/basket.service";
import { AceBooking } from "../models/ace/ace-booking";

@Injectable()
export class SingleBookingOnlyGuard implements CanActivate {
    public isSeasonRoute: boolean;
    public canContinue$ = new BehaviorSubject<boolean>(null);

    constructor(private router: Router, private basketService: BasketService, private modal: ModalService) {}

    public canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
        this.isSeasonRoute = route.url.some(tRoute => tRoute.path === "season");

        this.canContinue$.next(null);
        combineLatest([this.basketService.isBasketRefreshing$.pipe(filter(ref => ref === false)), this.basketService.booking$])
            .pipe(take(1))
            .subscribe(([_, booking]) => {
                if (booking.hasITSOFulfilment) {
                    this.showBookingRemovalPrompt(booking, "Sorry, you can only have one order for a booking if Smartcard was the fulfilment option selected");
                } else {
                    if (
                        (this.multipleBookingsPresent(booking) && !booking.hasSeasonTicket() && this.isSeasonRoute) ||
                        (this.multipleBookingsPresent(booking) && booking.hasSeasonTicket() && !this.isSeasonRoute) ||
                        (this.multipleBookingsPresent(booking) && booking.hasSeasonTicket() && this.isSeasonRoute)
                    ) {
                        this.showBookingRemovalPrompt(
                            booking,
                            "There's another booking in your basket already.\nIf you select 'continue with new booking', the previous booking will be removed."
                        );
                    } else {
                        this.canContinue$.next(true);
                    }
                }
            });

        return this.canContinue$.pipe(
            filter(can => can !== null),
            take(1)
        );
    }

    public multipleBookingsPresent(booking: AceBooking): boolean {
        return booking && !booking.isEmpty;
    }

    private showBookingRemovalPrompt(booking: AceBooking, description: string) {
        this.modal.create(PromptComponent, {
            title: "Another booking in the basket",
            description,
            confirmCTAText: "Continue with new booking",
            cancelCTAText: "Complete previous booking",
            confirm: instance => {
                if (booking.hasMultipleOrders() && !booking.hasSeasonTicket()) {
                    this.basketService.removeAllMultipleBookings().subscribe(() => {
                        instance.destroy();
                        this.canContinue$.next(true);
                    });
                } else {
                    this.basketService.clearBasket();
                    instance.destroy();
                }
            },
            cancel: instance => {
                instance.destroy();
                this.router.navigate(["booking/summary"]);
                this.canContinue$.next(false);
            }
        });
    }
}
