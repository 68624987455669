import { flatten, isEmpty, uniq } from "../../../shared/utilities/Utils";
import { IAmenity } from "../interfaces/ISearchResult";
import { AceTicketableFare } from "./ace-ticketable-fare.model";

export class AceTicketableFareCollection {
    public fareDescription: string;
    public amenities: IAmenity[];
    public compoundKey: string;
    public ticketingOptions = {
        tod: false,
        etk: false,
        pah: false,
        prn: false,
        eml: false,
        sct: false,
        departurestationtod: false,
        sms: false,
        xvd: false
    };
    public all: AceTicketableFare[];

    constructor(data: DataModel.TicketableFare[]) {
        if (isEmpty(data)) {
            throw new Error("TicketableFareCollection constructor argument is required");
        }
        this._init(data);
    }

    private _init(data: DataModel.TicketableFare[]): void {
        this.all = data.map(it => new AceTicketableFare(it));
        this.fareDescription = this.all.map(ticketableFare => ticketableFare.fareDisplayName).join(", ");
        this.amenities = flatten(this.all.map(ticketableFare => ticketableFare.amenities));

        Object.keys(this.ticketingOptions).forEach(key => {
            this.ticketingOptions[key] = this.all.some(ticketableFare => ticketableFare.ticketingOptions[key]);
        });

        // Compound key calculation. This will determine the mixing deck row that the parent price will be shown in
        this.compoundKey = uniq(this.all.map(ticketableFare => ticketableFare.compoundKey)).join("_");
    }
}
