<div class="nuscard">
    <div class="nuscard-row nuscard-row-alt">
        <svg class="nus-icon" viewBox="-1.5 0 20 5" role="img">
            <title>NUS card</title>
            <use xlink:href="#nus" />
        </svg>
        <span data-elid="nuscard-add-caption" class="info">Get 25% off Grand Central Advance tickets today with TOTUM.</span>
    </div>

    <div class="nuscard-row" [formGroup]="form">
        <input data-elid="nus-id" type="text" class="input nuscard-input" placeholder="TOTUM card ID" formControlName="nusIdFormControl" />
        <div class="nuscard-check-status">
            <svg data-elid="nus-valid" class="nuscard-valid-indicator" *ngIf="cardStatus == nusStatusEnum.VALID" viewBox="0 0 24 24" role="img">
                <title>checkmark</title>
                <use xlink:href="#checkmark" />
            </svg>
            <ace-loading-indicator class="nuscard-validating-indicator" [default]="true" *ngIf="validating"></ace-loading-indicator>
        </div>
    </div>

    <div *ngIf="form.get('nusIdFormControl').hasError('wellFormedNus') && form.get('nusIdFormControl').dirty" class="input-error-text">
        TOTUM card number should be 18 digits long
    </div>

    <ace-notification-box
        data-elid="nus-invalid"
        class="nuscard-invalid-indicator nuscard-notification"
        *ngIf="cardStatus == nusStatusEnum.INVALID"
        [error]="cardError"
        type="error"
    ></ace-notification-box>
    <ace-notification-box
        data-elid="nus-expired"
        class="nuscard-expired-indicator nuscard-notification"
        *ngIf="cardStatus == nusStatusEnum.EXPIRED"
        [error]="cardError"
        type="error"
    ></ace-notification-box>
    <ace-notification-box
        data-elid="nus-expired"
        class="nuscard-expired-indicator nuscard-notification"
        *ngIf="cardStatus == nusStatusEnum.EXCEPTION"
        error="NUSEXCEPTION"
        type="error"
    ></ace-notification-box>
    <ace-notification-box
        class="nus-save-warning nuscard-notification"
        *ngIf="cardStatus == nusStatusEnum.VALID && !user?.isLoggedIn"
        error="NUSGUESTSAVE"
        type="warning"
        icon="warning"
    ></ace-notification-box>

    <ace-button appearance="link" class="nuscard-remove" (action)="removeNus($event)" *ngIf="form.get('nusIdFormControl').value">Remove</ace-button>

    <div class="nuscard-row" *ngIf="user?.isLoggedIn && !user.getNusCardId()">
        <ace-checkbox checkboxId="nuscard-picker-save-nuscard-checkbox" class="nuscard-save" [checked]="saveNusCard" theme="theme-light" (check)="saveNusCard = !saveNusCard"
            >Save TOTUM card</ace-checkbox
        >
    </div>
</div>
