import { Injectable } from "@angular/core";
import { Action, Selector, State, StateContext } from "@ngxs/store";
import { SilverRailRailcard } from "../../models/api/railcard";
import { IRailcardsStateModel } from "./railcards-state.model";
import { SetRailcards } from "./railcards.actions";

@State<IRailcardsStateModel>({
    name: "railcards",
    defaults: []
})
@Injectable()
export class RailcardsState {
    @Selector([RailcardsState])
    static railcards(state: IRailcardsStateModel): SilverRailRailcard[] {
        return state ? state : [];
    }

    @Action(SetRailcards)
    setRailcards({ setState }: StateContext<IRailcardsStateModel>, action: SetRailcards) {
        setState(action.payload.filter(it => it.isInput));
    }
}
