<svg *ngIf="default" [attr.width]="size + 'px'" [attr.height]="size + 'px'" [attr.viewBox]="'0 0 100 100'" class="loading-ring">
    <circle cx="50" cy="50" r="40" stroke-dasharray="163.36281798666926 87.9645943005142" stroke="currentColor" fill="none" stroke-width="10"></circle>
</svg>

<div class="moving-train-loader" data-elid="app-loader" *ngIf="tocId === 'GC' && !default">
    <span class="sr-only" role="alert" aria-busy="true">Loading, please wait</span>
    <div class="CR-loader">
        <div class="moving-lines-wrapper">
            <svg class="moving-lines line-1" [attr.viewBox]="'0 0 916 67'">
                <rect class="line" width="616" height="67" rx="33.5" ry="33.5"></rect>
            </svg>
            <svg class="moving-lines line-2" [attr.viewBox]="'0 0 916 67'">
                <rect class="line" width="616" height="67" rx="33.5" ry="33.5"></rect>
            </svg>
            <svg class="moving-lines-ltr line-3" [attr.viewBox]="'0 0 916 67'">
                <rect class="line" width="616" height="67" rx="33.5" ry="33.5"></rect>
            </svg>
            <svg class="moving-lines-ltr line-4" [attr.viewBox]="'0 0 916 67'">
                <rect class="line" width="616" height="67" rx="33.5" ry="33.5"></rect>
            </svg>
        </div>

        <svg class="train-shadow" [attr.viewBox]="'0 0 256.53 132'">
            <path class="drop-shading" d="M255.53,59.47l1-59.47H1L0,76H1.41c7.46,33.78,61.52,56,127.12,56,70.69,0,128-26.44,128-64A37,37,0,0,0,255.53,59.47Z"></path>
        </svg>

        <svg class="tracks" [attr.viewBox]="'0 0 277 168.76'">
            <path
                class="rail"
                d="M273.62,129.86,147.14,3.37a11.52,11.52,0,0,0-16.28,0,11.11,11.11,0,0,0-1.22,1.46,11.24,11.24,0,0,0-1.46,1.22L3.37,130.86a11.52,11.52,0,0,0,0,16.28h0a11.52,11.52,0,0,0,16.29,0L139,27.8,257.34,146.14a11.5,11.5,0,0,0,16.28,0h0A11.5,11.5,0,0,0,273.62,129.86Z"
            ></path>
            <g id="rails">
                <rect class="rail-4" x="8" y="138.75" width="255" height="8.5"></rect>
                <rect class="rail-3" x="8" y="138.75" width="255" height="8.5"></rect>
                <rect class="rail-2" x="8" y="138.75" width="255" height="8.5"></rect>
                <rect class="rail-1" x="8" y="138.75" width="255" height="8.5"></rect>
            </g>
        </svg>

        <svg class="train" [attr.viewBox]="'0 0 342.44 383.35'">
            <path
                class="gradient"
                fill="none"
                d="M331.39,181.34c.48-15.93.24-31.89.26-47.83C331.74,60.75,278,3.7,208.79,3.3q-19.74-.12-39.47-.13V35.4c22.15.89,44.63,2.37,66.2,7.29,44.34,10.11,60.17,40.64,48.85,90.84a83.13,83.13,0,0,1-77.12,64.84c-12.62.57-25.29.09-37.93.09V382.17c6.26-.07,12.53-.18,18.8-.15,66.18.28,124.78-48.19,139.6-115.7,2.71-12.35,3.57-34.1,3.57-38.12C331.69,220.48,331.29,184.71,331.39,181.34Z"
            ></path>
            <path
                class="gradient"
                fill="none"
                d="M11.05,181.34c-.48-15.93-.23-31.89-.25-47.83C10.71,60.75,64.48,3.7,133.66,3.3q19.72-.12,39.47-.13V35.4c-22.16.89-44.64,2.37-66.21,7.29C62.58,52.8,46.75,83.33,58.07,133.53a83.13,83.13,0,0,0,77.13,64.84c12.62.57,25.28.09,37.93.09V382.17c-6.27-.07-12.54-.18-18.8-.15-66.19.28-124.79-48.19-139.6-115.7C12,254,11.15,232.22,11.15,228.2,10.75,220.48,11.15,184.71,11.05,181.34Z"
            ></path>
            <g>
                <rect class="wheels darkest-color" x="42.33" y="316.03" width="21.12" height="65.58" rx="5" ry="5"></rect>
                <rect class="wheels darkest-color" x="277.25" y="316.03" width="21.12" height="65.58" rx="5" ry="5"></rect>
                <g class="train-body">
                    <ellipse class="window light-color" cx="168.02" cy="113.88" rx="127.46" ry="98.51"></ellipse>
                    <path
                        class="bottom-shading"
                        d="M266.32,314.58a122.54,122.54,0,0,0-44-22.72c-9.46-2.62-19.11-4.47-28.77-6.15-7.31-1.27-15.24-3.55-22.91-3.77v0h-.34v0c-7.67.22-15.6,2.5-22.91,3.77-9.66,1.68-19.31,3.53-28.77,6.15a122.66,122.66,0,0,0-44,22.72A181.21,181.21,0,0,0,50.32,338c26.31,27.53,62.61,44.24,101.54,44.07,6.15,0,12.3.08,18.46.15h.34c6.16-.07,12.31-.17,18.46-.15,38.93.17,75.23-16.54,101.54-44.07A180.69,180.69,0,0,0,266.32,314.58Z"
                    ></path>
                    <path
                        class="right-border main-color"
                        d="M333.13,180.21c.48-16.11.24-32.25.26-48.37C333.48,58.24,279.08.54,209.11.13Q189.15,0,169.18,0V32.6c22.42.9,45.15,2.4,67,7.38C281,50.2,297,81.09,285.56,131.86a84.08,84.08,0,0,1-78,65.58c-12.77.58-25.58.09-38.37.09V383.35c6.34-.07,12.68-.18,19-.16,66.94.29,126.22-48.74,141.21-117,2.74-12.49,3.62-34.49,3.62-38.54C333.43,219.81,333,183.62,333.13,180.21Z"
                    ></path>
                    <path
                        class="right-shades main-color"
                        d="M239.5,39c9.71-2.5,37.52,15.41,37.32,60.13.65,47.8-30.73,88.7-60.53,101,44.6-1.56,74.4-43.85,74.8-109.95.11-17-.62-21.51-2.62-32.15C283,30.12,263.59,34.5,254.92,37.9Z"
                    ></path>
                    <path
                        class="right-bg bg-color"
                        d="M331.39,181.34c.48-15.93.24-31.89.26-47.83C331.74,60.75,278,3.7,208.79,3.3q-19.74-.12-39.47-.13V35.4c22.15.89,44.63,2.37,66.2,7.29,44.34,10.11,60.17,40.64,48.85,90.84a83.13,83.13,0,0,1-77.12,64.84c-12.62.57-25.29.09-37.93.09V382.17c6.26-.07,12.53-.18,18.8-.15,66.18.28,124.78-48.19,139.6-115.7,2.71-12.35,3.57-34.1,3.57-38.12C331.69,220.48,331.29,184.71,331.39,181.34Z"
                    ></path>
                    <path
                        class="light-color-shading-40"
                        d="M317.13,121.8s-30.67,39.74-42,69.87-13.81,68.13-26.24,75.46-51.7,18.67-55.73,29.33-17.55,18.15-25.46,18.15l1.76-96.41s22.07,16.43,39.83,16.43C252.25,234.63,279.79,150.63,317.13,121.8Z"
                    ></path>
                    <g class="right-lines-side">
                        <path
                            class="side-lines-light"
                            d="M331.3,232c0.5-8.3,0.2-16.8,0.1-25.1c-36.4,13.6-63.5,47.6-72.4,109.3c6.9-38,35-68.6,72-78.8c0.1-1.5,0.2-3,0.3-4.6
                                    C331.3,232.6,331.3,232.3,331.3,232z"
                        ></path>
                        <path
                            class="dark-color"
                            d="M331.6,170.8c0-0.3,0.1-0.7,0.2-1c-0.1-3.5-0.1-6.9-0.2-10.4c-31.8,14.7-58.2,52.6-73.5,121.8
                                    c11.4-45,39.9-77.7,73.5-86.8C330.7,186.9,330.7,178.3,331.6,170.8z"
                        ></path>
                    </g>
                    <path
                        class="side-lines-light shading-20"
                        d="M231.72,331c.19-1.74.38-3.49.59-5.23.39-3.32,1-10.18,1-10.18-25.55,5.46-46.33,25.53-56.65,66a65.1,65.1,0,0,1,54.19-46.19C231.16,334,231.45,332.5,231.72,331Z"
                    ></path>
                    <path
                        class="front-top-line"
                        d="M252.19,304.45c.27-2.37.53-4.75.81-7.12.53-4.52,1.35-13.85,1.35-13.85-34.77,7.43-63,34.74-77.09,89.85A88.58,88.58,0,0,1,251,310.48C251.44,308.48,251.83,306.47,252.19,304.45Z"
                    ></path>
                    <circle class="circle-color" cx="191.84" cy="260.14" r="14.78"></circle>
                    <path
                        class="left-shades main-color"
                        d="M171,274.19c-1.1-24.92-8.38-52.17-.74-76.65-11.8.07-23.61.43-35.39-.1a84.09,84.09,0,0,1-78-65.58C45.43,81.09,61.44,50.2,106.3,40c20.9-4.77,42.64-6.35,64.14-7.26-1-10.91-1.25-21.9.39-32.71q-18.75,0-37.49.12C63.36.54,9,58.24,9.06,131.84c0,16.12-.23,32.26.26,48.37.1,3.41-.31,39.6.1,47.41,0,4,.87,26,3.61,38.54,15,68.29,74.27,117.32,141.21,117,5.45,0,10.9.06,16.35.13a5.58,5.58,0,0,1,.5-4.73,5.24,5.24,0,0,1-.31-1.79c0-17.34-.17-34.69.09-52C171.13,307.91,171.77,291,171,274.19Z"
                    ></path>
                    <path
                        class="left-shades main-color"
                        d="M87.53,37.9C78.85,34.5,59.45,30.12,54,58c-2,10.64-2.73,15.16-2.62,32.15.41,66.1,30.2,108.39,74.8,110C96.35,187.85,65,147,65.62,99.15,65.43,54.43,93.23,36.52,102.94,39Z"
                    ></path>
                    <path
                        class="left-bg bg-color"
                        d="M11.05,181.34c-.48-15.93-.23-31.89-.25-47.83C10.71,60.75,64.48,3.7,133.66,3.3q19.72-.12,39.47-.13V35.4c-22.16.89-44.64,2.37-66.21,7.29C62.58,52.8,46.75,83.33,58.07,133.53a83.13,83.13,0,0,0,77.13,64.84c12.62.57,25.28.09,37.93.09V382.17c-6.27-.07-12.54-.18-18.8-.15-66.19.28-124.79-48.19-139.6-115.7C12,254,11.15,232.22,11.15,228.2,10.75,220.48,11.15,184.71,11.05,181.34Z"
                    ></path>
                    <g class="left-lines-side">
                        <path
                            d="M10.9,206.8c0.6,2.7,0.4,5.8,0.3,9c-0.3,7.2,0.3,14.4,0.6,21.6c36.9,10.3,64.8,40.8,71.7,78.8
                                    C74.5,254.4,47.4,220.4,10.9,206.8z"
                            class="side-lines-light"
                        ></path>
                        <path
                            d="M10.9,159.5c0,6.1,0.2,12.1,0.2,18.2c0,5.5-0.7,11.3-0.1,16.7c33.5,9.2,62,41.8,73.3,86.8
                                    C69.1,212.2,42.7,174.2,10.9,159.5z"
                            class="dark-color"
                        ></path>
                    </g>
                    <path
                        class="side-lines-light shading-20"
                        d="M110.73,331c-.2-1.74-.39-3.49-.6-5.23-.39-3.32-1-10.18-1-10.18,25.55,5.46,46.33,25.53,56.65,66a65.09,65.09,0,0,0-54.19-46.19C111.28,334,111,332.5,110.73,331Z"
                    ></path>
                    <path
                        class="front-top-line"
                        d="M90.25,304.45c-.27-2.37-.53-4.75-.81-7.12-.53-4.52-1.35-13.85-1.35-13.85,34.77,7.43,63,34.74,77.09,89.85a88.57,88.57,0,0,0-73.74-62.85C91,308.48,90.62,306.47,90.25,304.45Z"
                    ></path>
                    <path
                        class="light-color-shading-40"
                        d="M24.7,121.8s30.67,39.74,42,69.87,13.81,68.13,26.24,75.46,51.7,18.67,55.73,29.33,17.55,18.15,25.46,18.15l-1.76-96.41s-22.07,16.43-39.84,16.43C89.58,234.63,62,150.63,24.7,121.8Z"
                    ></path>
                    <circle class="circle-color" cx="150.6" cy="260.14" r="14.78"></circle>
                    <path class="light-color-shading-50" d="M38.08,319.94s18.05-13.17,43.44,42.83C81.52,362.77,35.63,325.76,38.08,319.94Z"></path>
                    <path class="light-color-shading-50" d="M303.43,319.94S285.37,306.77,260,362.77C260,362.77,305.88,325.76,303.43,319.94Z"></path>
                    <path
                        class="shine-color"
                        d="M175.86,56.45c-.31-1.2-.62-2.41-.94-3.61-.63-2.29-1.58-7.06-1.58-7.06,42.38-.9,76.73,9.48,93.58,36.35C254.69,65.63,219.34,57,177.28,59.44,176.76,58.45,176.3,57.45,175.86,56.45Z"
                    ></path>
                    <path
                        class="shine-color"
                        d="M168.58,56.45c.32-1.2.62-2.41.95-3.61.62-2.29,1.57-7.06,1.57-7.06-42.38-.9-76.73,9.48-93.58,36.35C89.75,65.63,125.11,57,167.16,59.44,167.68,58.45,168.14,57.45,168.58,56.45Z"
                    ></path>
                    <path
                        class="shine-color"
                        d="M169.21,181.41c.26,1.2.51,2.41.78,3.61.52,2.29,1.31,7.06,1.31,7.06-35,.9-63.44-9.48-77.36-36.35,10.11,16.5,39.34,25.12,74.1,22.69C168.47,179.41,168.85,180.41,169.21,181.41Z"
                    ></path>
                    <path
                        class="shine-color"
                        d="M175.23,181.41c-.26,1.2-.51,2.41-.78,3.61-.52,2.29-1.3,7.06-1.3,7.06,35,.9,63.43-9.48,77.35-36.35-10.1,16.5-39.33,25.12-74.09,22.69C176,179.41,175.59,180.41,175.23,181.41Z"
                    ></path>
                </g>
            </g>
        </svg>
    </div>
</div>

<div class="loader-spinning-trains" data-elid="app-loader" *ngIf="tocId === 'CH' && !default">
    <span class="sr-only" role="alert" aria-busy="true">Loading, please wait</span>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 125.72 124.93">
        <g class="cls-1">
            <g id="Layer_2" data-name="Layer 2">
                <g id="Layer_1-2" data-name="Layer 1">
                    <path class="cls-2" d="M110.43,99.75a3.23,3.23,0,0,1,4.4-.49,3.16,3.16,0,0,1,.67,4.54,3.1,3.1,0,0,1-4.52.36A3.26,3.26,0,0,1,110.43,99.75Z" />
                    <path class="cls-2" d="M118,86.72a3.24,3.24,0,0,1,4.13-1.84,3.39,3.39,0,0,1,1.75,4.49,3.07,3.07,0,0,1-4.28,1.7A3.48,3.48,0,0,1,118,86.72Z" />
                    <path class="cls-2" d="M7.54,86.25A3.49,3.49,0,0,1,5.9,90.6a3.07,3.07,0,0,1-4.27-1.75,3.4,3.4,0,0,1,1.79-4.48A3.24,3.24,0,0,1,7.54,86.25Z" />
                    <path class="cls-2" d="M15,99.38a3.26,3.26,0,0,1-.59,4.41,3.09,3.09,0,0,1-4.52-.4,3.17,3.17,0,0,1,.71-4.54A3.23,3.23,0,0,1,15,99.38Z" />
                    <path
                        class="cls-2"
                        d="M109.31,85.71A46.7,46.7,0,0,1,106.39,91a.75.75,0,0,1-1.06.09l-1.93-1.32a.78.78,0,0,1-.35-1,43.09,43.09,0,0,0,2.7-4.86.74.74,0,0,1,1-.21l2.09,1A.8.8,0,0,1,109.31,85.71Z"
                    />
                    <path class="cls-3" d="M118.72,77.36q-.47,2-1.06,3.89,2.65-.1,5.33-.38a69.19,69.19,0,0,0,2.23-13.25l-1.59-.15q-.05.71-.12,1.41c-.45,5.09-4.8,8.54-4.79,8.47" />
                    <path class="cls-2" d="M97.08,79.44" />
                    <path
                        class="cls-4"
                        d="M25.51,78.85c12.92,31.32,61.57,31.38,74.63.18a42.07,42.07,0,0,0,3-12.11A1.83,1.83,0,0,1,105,65.48a130.91,130.91,0,0,0,16.23-2.17c2.72.06,2.57,2.34,2.56,2.33q0,.92-.11,1.83-.05.71-.12,1.41c-.45,5.09-4.8,8.54-4.79,8.47q-.47,2-1.06,3.89a52.26,52.26,0,0,1-13,21.7l.81.79,2,2,.81.79c-24,24-67.27,23.91-91.12-.23l3.63-3.53A52.62,52.62,0,0,1,7.4,79C5.8,79.45,23.59,74.21,25.51,78.85Zm83.35,5.85a.8.8,0,0,1,.46,1A46.72,46.72,0,0,1,106.39,91a.75.75,0,0,1-1.06.09l-1.93-1.32a.78.78,0,0,1-.35-1,43.11,43.11,0,0,0,2.7-4.86.74.74,0,0,1,1-.21Z"
                    />
                    <path
                        class="cls-5"
                        d="M101.28,91.28a42.33,42.33,0,0,1-3.47,4.05.76.76,0,0,0,.18,1L99.66,98a.76.76,0,0,0,1.06.13,45.87,45.87,0,0,0,3.76-4.39.77.77,0,0,0-.28-1l-1.88-1.4A.76.76,0,0,0,101.28,91.28Z"
                    />
                    <path
                        class="cls-5"
                        d="M57,109.07q-2.6-.29-5.16-.83c-.32-.07-.67.23-.78.68l-.56,2.24a.73.73,0,0,0,.43.94q2.77.59,5.59.9c.35,0,.68-.3.73-.75l.26-2.29C57.54,109.5,57.31,109.11,57,109.07Z"
                    />
                    <path
                        class="cls-5"
                        d="M105.75,83.88a43.09,43.09,0,0,1-2.7,4.86.78.78,0,0,0,.35,1l1.93,1.32a.75.75,0,0,0,1.06-.09,46.7,46.7,0,0,0,2.92-5.26.8.8,0,0,0-.46-1l-2.09-1A.74.74,0,0,0,105.75,83.88Z"
                    />
                    <path
                        class="cls-5"
                        d="M65.5,109.33q-2.62.13-5.23,0c-.33,0-.62.34-.65.79l-.15,2.29a.74.74,0,0,0,.59.86q2.83.14,5.67,0a.74.74,0,0,0,.59-.86l-.16-2.29C66.11,109.67,65.82,109.31,65.5,109.33Z"
                    />
                    <path
                        class="cls-5"
                        d="M73.83,108.25q-2.56.54-5.16.83c-.32,0-.55.43-.5.88l.25,2.29c0,.45.38.79.73.75q2.82-.31,5.59-.9a.73.73,0,0,0,.43-.94l-.56-2.24C74.5,108.49,74.15,108.18,73.83,108.25Z"
                    />
                    <path
                        class="cls-5"
                        d="M82,105.81A49.86,49.86,0,0,1,77,107.47c-.31.09-.46.51-.34,1l.66,2.21a.77.77,0,0,0,.86.63,54,54,0,0,0,5.35-1.8.72.72,0,0,0,.25-1l-1-2.11C82.65,105.93,82.25,105.69,82,105.81Z"
                    />
                    <path class="cls-2" d="M87.22,97.95l9.85,14.59" />
                    <path
                        class="cls-3"
                        d="M34.06,95.79a1,1,0,0,1,1.38,0,41,41,0,0,0,6.34,4,1,1,0,0,1,.57,1.21L35,115.11c-.21.41-1,.45-1.73.07a57.66,57.66,0,0,1-8.91-5.64c-.66-.51-.94-1.23-.64-1.58Z"
                    />
                    <path
                        class="cls-5"
                        d="M36.76,105.66h0c-.7-.39-1.07-1-.84-1.38L38,101c.23-.36.92-.36,1.54,0h0c.62.34,1,.91.76,1.29l-1.92,3.44C38.2,106,37.45,106,36.76,105.66Z"
                    />
                    <path
                        class="cls-5"
                        d="M32,102.64h0c-.65-.47-.95-1.14-.67-1.47l2.48-3.09c.27-.33,1-.25,1.53.16h0c.58.41.85,1,.61,1.37l-2.3,3.22C33.37,103.18,32.62,103.1,32,102.64Z"
                    />
                    <path class="cls-2" d="M38.53,98l-9.84,14.6" />
                    <path class="cls-2" d="M15.12,99.53a3.23,3.23,0,0,0-4.4-.52,3.17,3.17,0,0,0-.69,4.54,3.1,3.1,0,0,0,4.52.39A3.26,3.26,0,0,0,15.12,99.53Z" />
                    <path class="cls-2" d="M7.62,86.44a3.24,3.24,0,0,0-4.12-1.86,3.4,3.4,0,0,0-1.77,4.48A3.07,3.07,0,0,0,6,90.8,3.48,3.48,0,0,0,7.62,86.44Z" />
                    <path
                        class="cls-2"
                        d="M16.3,85.48a46.76,46.76,0,0,0,2.9,5.29.75.75,0,0,0,1.06.09l1.93-1.32a.78.78,0,0,0,.36-1,43.18,43.18,0,0,1-2.68-4.88.74.74,0,0,0-1-.22l-2.09,1A.8.8,0,0,0,16.3,85.48Z"
                    />
                    <path
                        class="cls-2"
                        d="M18.85,83.45a.74.74,0,0,1,1,.22,43.17,43.17,0,0,0,2.68,4.88.78.78,0,0,1-.36,1l-1.93,1.32a.75.75,0,0,1-1.06-.09,46.81,46.81,0,0,1-2.9-5.29.8.8,0,0,1,.46-1Z"
                    />
                    <path
                        class="cls-5"
                        d="M24.31,91.1a42.34,42.34,0,0,0,3.45,4.08.76.76,0,0,1-.18,1L25.9,97.83a.76.76,0,0,1-1.06.12,45.84,45.84,0,0,1-3.74-4.41.77.77,0,0,1,.28-1l1.88-1.39A.76.76,0,0,1,24.31,91.1Z"
                    />
                    <path
                        class="cls-5"
                        d="M19.87,83.67a43.17,43.17,0,0,0,2.68,4.88.78.78,0,0,1-.36,1l-1.93,1.32a.75.75,0,0,1-1.06-.09,46.81,46.81,0,0,1-2.9-5.29.8.8,0,0,1,.46-1l2.09-1A.74.74,0,0,1,19.87,83.67Z"
                    />
                    <path
                        class="cls-6"
                        d="M83.4,101A1,1,0,0,1,84,99.81a41,41,0,0,0,6.34-4,1,1,0,0,1,1.38,0L102,107.92c.3.35,0,1.07-.64,1.58a57.63,57.63,0,0,1-8.9,5.65c-.74.38-1.52.34-1.73-.07Z"
                    />
                    <path class="cls-6" d="M116.45,84.4,103.67,79c-.42-.18-.67-.58-.56-.89A42.84,42.84,0,0,0,104.36,74a.73.73,0,0,1,.95-.39l13.3,3.69" />
                    <path
                        class="cls-5"
                        d="M47.57,111.27a54,54,0,0,1-5.35-1.8.72.72,0,0,1-.25-1l.95-2.12c.19-.42.59-.66.89-.54a49.89,49.89,0,0,0,4.94,1.66c.31.09.47.51.34,1l-.66,2.21A.77.77,0,0,1,47.57,111.27Z"
                    />
                    <path
                        class="cls-5"
                        d="M110.86,78.58h0c-.27.87-.83,1.44-1.23,1.29l-3.68-1.42c-.4-.16-.51-.9-.28-1.67h0c.24-.77.74-1.32,1.15-1.2l3.75,1.17C111,76.88,111.12,77.71,110.86,78.58Z"
                    />
                    <path
                        class="cls-5"
                        d="M93.79,102.6h0c-.65.47-1.39.54-1.64.19l-2.3-3.22c-.25-.35,0-1,.6-1.37h0c.57-.41,1.26-.5,1.53-.16l2.49,3.09C94.73,101.46,94.44,102.14,93.79,102.6Z"
                    />
                    <path
                        class="cls-5"
                        d="M89,105.64h0c-.7.39-1.45.38-1.65,0l-1.93-3.44c-.21-.37.14-.94.75-1.29h0c.62-.34,1.3-.34,1.54,0l2.12,3.33C90.07,104.62,89.7,105.25,89,105.64Z"
                    />
                    <path class="cls-2" d="M15.29,25.18a3.23,3.23,0,0,1-4.4.49,3.16,3.16,0,0,1-.67-4.54,3.1,3.1,0,0,1,4.52-.36A3.26,3.26,0,0,1,15.29,25.18Z" />
                    <path class="cls-2" d="M7.73,38.21A3.24,3.24,0,0,1,3.6,40a3.39,3.39,0,0,1-1.75-4.49,3.07,3.07,0,0,1,4.28-1.7A3.48,3.48,0,0,1,7.73,38.21Z" />
                    <path class="cls-2" d="M118.18,38.68a3.49,3.49,0,0,1,1.64-4.35,3.07,3.07,0,0,1,4.27,1.75,3.4,3.4,0,0,1-1.79,4.48A3.24,3.24,0,0,1,118.18,38.68Z" />
                    <path class="cls-2" d="M110.72,25.55a3.26,3.26,0,0,1,.59-4.41,3.09,3.09,0,0,1,4.52.4,3.17,3.17,0,0,1-.71,4.54A3.23,3.23,0,0,1,110.72,25.55Z" />
                    <path
                        class="cls-2"
                        d="M16.4,39.22A46.69,46.69,0,0,1,19.33,34a.75.75,0,0,1,1.06-.09l1.93,1.32a.78.78,0,0,1,.35,1A43.12,43.12,0,0,0,20,41.05a.74.74,0,0,1-1,.21l-2.09-1A.8.8,0,0,1,16.4,39.22Z"
                    />
                    <path class="cls-3" d="M7,47.58q.47-2,1.06-3.89-2.65.1-5.33.38A69.18,69.18,0,0,0,.5,57.31l1.59.15q.05-.71.12-1.41C2.65,51,7,47.5,7,47.58" />
                    <path class="cls-2" d="M28.64,45.49" />
                    <path
                        class="cls-4"
                        d="M100.21,46.08C87.29,14.76,38.64,14.7,25.58,45.89a42.07,42.07,0,0,0-3,12.11,1.83,1.83,0,0,1-1.83,1.45A130.92,130.92,0,0,0,4.53,61.61C1.81,61.56,2,59.28,2,59.29q0-.92.11-1.83.05-.71.12-1.41C2.65,51,7,47.5,7,47.58q.47-2,1.06-3.89A52.26,52.26,0,0,1,21,22l-.81-.79-2-2-.81-.79c24-24,67.27-23.91,91.12.23l-3.63,3.53A52.62,52.62,0,0,1,118.32,46C119.92,45.48,102.12,50.72,100.21,46.08ZM16.86,40.23a.8.8,0,0,1-.46-1A46.69,46.69,0,0,1,19.33,34a.75.75,0,0,1,1.06-.09l1.93,1.32a.78.78,0,0,1,.35,1A43.12,43.12,0,0,0,20,41.05a.74.74,0,0,1-1,.21Z"
                    />
                    <path
                        class="cls-5"
                        d="M24.44,33.65a42.33,42.33,0,0,1,3.47-4.05.76.76,0,0,0-.18-1l-1.67-1.64A.76.76,0,0,0,25,26.81a45.86,45.86,0,0,0-3.76,4.39.77.77,0,0,0,.28,1l1.88,1.4A.76.76,0,0,0,24.44,33.65Z"
                    />
                    <path
                        class="cls-5"
                        d="M68.73,15.86q2.6.29,5.16.83c.32.07.67-.23.78-.68l.56-2.24a.73.73,0,0,0-.43-.94q-2.77-.59-5.59-.9c-.35,0-.68.3-.73.75L68.23,15C68.18,15.43,68.4,15.82,68.73,15.86Z"
                    />
                    <path
                        class="cls-5"
                        d="M20,41.05a43.12,43.12,0,0,1,2.7-4.86.78.78,0,0,0-.35-1l-1.93-1.32a.75.75,0,0,0-1.06.09,46.71,46.71,0,0,0-2.92,5.26.8.8,0,0,0,.46,1l2.09,1A.74.74,0,0,0,20,41.05Z"
                    />
                    <path
                        class="cls-5"
                        d="M60.22,15.6c1.74-.09,3.49-.09,5.23,0,.33,0,.62-.34.65-.79l.15-2.29a.74.74,0,0,0-.59-.86c-1.89-.09-3.78-.09-5.67,0a.74.74,0,0,0-.59.86l.16,2.29C59.6,15.26,59.89,15.62,60.22,15.6Z"
                    />
                    <path
                        class="cls-5"
                        d="M51.88,16.68q2.56-.54,5.16-.83c.32,0,.55-.43.5-.88l-.25-2.29c0-.45-.38-.79-.73-.75q-2.82.31-5.59.9a.73.73,0,0,0-.43.94L51.1,16C51.21,16.45,51.56,16.75,51.88,16.68Z"
                    />
                    <path
                        class="cls-5"
                        d="M43.76,19.12a49.87,49.87,0,0,1,4.94-1.66c.31-.09.46-.51.34-1l-.66-2.21a.77.77,0,0,0-.86-.63,54,54,0,0,0-5.35,1.8.72.72,0,0,0-.25,1l1,2.11C43.06,19,43.46,19.24,43.76,19.12Z"
                    />
                    <path class="cls-2" d="M38.49,27,28.64,12.4" />
                    <path
                        class="cls-3"
                        d="M91.65,29.14a1,1,0,0,1-1.38,0,41,41,0,0,0-6.34-4,1,1,0,0,1-.57-1.21L90.71,9.82c.21-.41,1-.45,1.73-.07a57.66,57.66,0,0,1,8.91,5.64c.66.51.94,1.23.64,1.58Z"
                    />
                    <path
                        class="cls-5"
                        d="M89,19.27h0c.7.39,1.07,1,.84,1.38L87.68,24c-.23.36-.92.36-1.54,0h0c-.62-.34-1-.91-.76-1.29l1.92-3.44C87.51,18.89,88.26,18.88,89,19.27Z"
                    />
                    <path
                        class="cls-5"
                        d="M93.74,22.29h0c.65.47.94,1.14.67,1.47l-2.48,3.09c-.27.33-1,.25-1.53-.16h0c-.57-.41-.85-1-.61-1.37l2.3-3.22C92.35,21.75,93.09,21.83,93.74,22.29Z"
                    />
                    <path class="cls-2" d="M87.18,27,97,12.36" />
                    <path class="cls-2" d="M110.6,25.4a3.23,3.23,0,0,0,4.4.52,3.17,3.17,0,0,0,.69-4.54,3.1,3.1,0,0,0-4.52-.39A3.26,3.26,0,0,0,110.6,25.4Z" />
                    <path class="cls-2" d="M118.1,38.49a3.24,3.24,0,0,0,4.12,1.86A3.4,3.4,0,0,0,124,35.87a3.07,3.07,0,0,0-4.27-1.73A3.48,3.48,0,0,0,118.1,38.49Z" />
                    <path
                        class="cls-2"
                        d="M109.42,39.45a46.75,46.75,0,0,0-2.9-5.29.75.75,0,0,0-1.06-.09l-1.93,1.32a.78.78,0,0,0-.36,1,43.2,43.2,0,0,1,2.68,4.88.74.74,0,0,0,1,.22l2.09-1A.8.8,0,0,0,109.42,39.45Z"
                    />
                    <path
                        class="cls-2"
                        d="M106.87,41.48a.74.74,0,0,1-1-.22,43.17,43.17,0,0,0-2.68-4.88.78.78,0,0,1,.36-1l1.93-1.32a.75.75,0,0,1,1.06.09,46.75,46.75,0,0,1,2.9,5.29.8.8,0,0,1-.46,1Z"
                    />
                    <path
                        class="cls-5"
                        d="M101.41,33.83A42.36,42.36,0,0,0,98,29.75a.76.76,0,0,1,.18-1l1.68-1.63a.76.76,0,0,1,1.06-.12,45.86,45.86,0,0,1,3.74,4.41.77.77,0,0,1-.28,1l-1.88,1.39A.76.76,0,0,1,101.41,33.83Z"
                    />
                    <path
                        class="cls-5"
                        d="M105.84,41.26a43.17,43.17,0,0,0-2.68-4.88.78.78,0,0,1,.36-1l1.93-1.32a.75.75,0,0,1,1.06.09,46.75,46.75,0,0,1,2.9,5.29.8.8,0,0,1-.46,1l-2.09,1A.74.74,0,0,1,105.84,41.26Z"
                    />
                    <path
                        class="cls-6"
                        d="M42.32,23.9a1,1,0,0,1-.57,1.22,41,41,0,0,0-6.34,4,1,1,0,0,1-1.38,0L23.68,17c-.3-.35,0-1.07.64-1.58a57.63,57.63,0,0,1,8.9-5.65c.74-.38,1.52-.34,1.73.07Z"
                    />
                    <path
                        class="cls-5"
                        d="M78.15,13.66a54,54,0,0,1,5.35,1.8.72.72,0,0,1,.25,1l-.95,2.12c-.19.42-.59.66-.89.54A49.9,49.9,0,0,0,77,17.45c-.31-.09-.46-.51-.34-1l.66-2.21A.77.77,0,0,1,78.15,13.66Z"
                    />
                    <path class="cls-6" d="M9.26,40.53,22,45.91c.42.18.67.58.56.89a42.84,42.84,0,0,0-1.25,4.12.73.73,0,0,1-.95.39L7.11,47.61" />
                    <path
                        class="cls-5"
                        d="M14.86,46.35h0c.27-.87.83-1.44,1.23-1.29l3.68,1.42c.4.16.51.9.28,1.67h0c-.24.77-.74,1.32-1.15,1.2l-3.75-1.17C14.73,48.05,14.59,47.22,14.86,46.35Z"
                    />
                    <path
                        class="cls-5"
                        d="M36.71,19.29h0c.7-.39,1.45-.38,1.65,0l1.93,3.44c.21.37-.14.94-.75,1.29h0c-.62.34-1.3.35-1.54,0l-2.12-3.33C35.64,20.31,36,19.68,36.71,19.29Z"
                    />
                    <path
                        class="cls-5"
                        d="M31.93,22.33h0c.65-.47,1.39-.54,1.64-.19l2.3,3.22c.25.35,0,1-.6,1.37h0c-.57.41-1.26.5-1.53.16L31.26,23.8C31,23.47,31.28,22.79,31.93,22.33Z"
                    />
                    <path class="cls-5" d="M103.13,66.93,120,68.63l.37-5.11L113,64.67l-8,.82A2.45,2.45,0,0,0,103.13,66.93Z" />
                    <path class="cls-5" d="M22.5,58,5.68,56.33l-.37,5.11,7.33-1.15,8-.82A2.45,2.45,0,0,0,22.5,58Z" />
                </g>
            </g>
        </g>
    </svg>
</div>
