import { ISelectOption } from "../../shared/components/select/select.component";

export class Railcard {
    public options: ISelectOption[] = [];
    private _isSelected: boolean;
    private _type: string;
    private _value: string;

    constructor(private _railcardOptions: ISelectOption[], isSelected: boolean = false, type: string = "DISCOUNT_TYPE", value?: string) {
        this.options = this._railcardOptions.map(item => {
            return {
                label: item.label,
                type: item.type,
                value: item.value,
                selected: item.selected
            };
        });
        this._isSelected = isSelected;
        this._type = type;
        this._value = value;
    }

    public clone(): Railcard {
        return new Railcard(this.options, this._isSelected, this._type, this._value);
    }

    public onSelectedOption(selection: ISelectOption) {
        this._type = selection.type;
        this._value = selection.value;
        this._isSelected = true;
    }

    public get isSelected(): boolean {
        return this._isSelected;
    }

    public get type(): string {
        return this._type;
    }

    public get value(): string {
        return this._value;
    }
}

export class RailcardComponent {
    private _railcards: Railcard[] = [];
    private _isValid: boolean = true;
    private _options: ISelectOption[];

    constructor(options: ISelectOption[]) {
        this._options = options;
    }

    public add() {
        this._railcards.push(new Railcard(this._options));
        this._validate();
    }

    public clone() {
        return new RailcardComponent(this._options).set(this._cloneRailcards(this._railcards));
    }

    public remove(index: number) {
        this._railcards.splice(index, 1);
        this._validate();
    }

    public set(railcards: Railcard[]): RailcardComponent {
        this._railcards = this._cloneRailcards(railcards);
        this._validate();
        return this;
    }

    public onSelectedOption(railcard: Railcard, option: ISelectOption): void {
        railcard.onSelectedOption(option);
        this._validate();
    }

    public get isValid(): boolean {
        return this._isValid;
    }

    public get railcards(): Railcard[] {
        return this._railcards;
    }

    private _validate() {
        let result = true;
        for (const railcard of this._railcards) {
            if (!railcard.isSelected) {
                result = false;
                break;
            }
        }
        this._isValid = result;
    }

    private _cloneRailcards(railcards: Railcard[]): Railcard[] {
        return railcards.slice().map(item => item.clone());
    }
}
