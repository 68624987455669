<div class="container" cdkTrapFocus>
    <div class="help">
        <div class="help-content">
            <div class="help-content-title">
                <span *ngIf="(data | async) !== null">{{ (data | async)[0].attributes.name }}</span>
            </div>

            <div class="help-content-questions">
                <ng-container *ngFor="let item of data | async; let i = index">
                    <ace-accordion #accordionItem class="help-content-accordion" [id]="'help-modal-' + index">
                        <accordion-head>
                            <span class="help-item-question">{{ i + 1 }}. {{ item.attributes.question }}</span>
                            <span class="help-item-icon" aria-hidden="true">
                                <svg viewBox="0 0 24 24"><use xlink:href="#arrow-down" /></svg>
                            </span>
                        </accordion-head>
                        <accordion-content [innerHTML]="item.attributes.answer.value"></accordion-content>
                    </ace-accordion>
                </ng-container>
            </div>

            <div class="help-close">
                <ace-button appearance="editorial" size="primary" (action)="onButtonClicked()" id="help-modal-close">{{ cta }}</ace-button>
            </div>
        </div>
    </div>
</div>
