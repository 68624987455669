import { DiscountHelper } from "../../../shared/utilities/DiscountHelper";
import { ISearchResultType } from "../interfaces/ISearchResult";
import { AceLegSolution } from "./ace-leg-solution.model";
import { AcePointToPointPrice } from "./ace-point-to-point-price.model";
import { Price } from "./ace-price.model";
import { AceSearchProjection } from "./ace-search-projection.model";
import { AceSearchResult } from "./ace-search-result.model";
import { AceSnapshot } from "./ace-snapshot";

export class AceMobileSearchProjection extends AceSearchProjection {
    public legSolutionPriceMap: IMobileRowGroup[];

    private cachedPriceMap = {};

    constructor(searchResult: AceSearchResult, configSnapshot: AceSnapshot) {
        super(searchResult, configSnapshot);

        this.initMobile();
    }

    public getOutgoingMobileRowGroups(): IMobileRowGroup[] {
        return this.legSolutionPriceMap;
    }

    public getReturnMobileRowGroups(legSolution: AceLegSolution | string) {
        if (typeof legSolution === "string") {
            legSolution = AceLegSolution.getById(legSolution);
        }

        return this.legSolutionPriceMap.find(leg => Boolean(leg && leg.legSolution)).returnRowGroups;
    }

    public getRowGroupByLegSolutionId(rowGroups: IMobileRowGroup[], legSolutionId: string): IMobileRowGroup {
        const rowGroup = rowGroups.find(rowGroup => rowGroup.legSolution.legSolutionID === legSolutionId);
        return rowGroup ? rowGroup : rowGroups[0];
    }

    public getPricesMobileRowGroups(outLegSolution: AceLegSolution, returnLegSolution: AceLegSolution) {
        if (typeof outLegSolution === "string") {
            outLegSolution = AceLegSolution.getById(outLegSolution);
        }
        if (typeof returnLegSolution === "string") {
            returnLegSolution = AceLegSolution.getById(returnLegSolution);
        }

        let prices: AcePointToPointPrice[];
        const cacheKey = returnLegSolution ? outLegSolution.legSolutionID + returnLegSolution.legSolutionID : outLegSolution.legSolutionID;

        // If we've done this before, just serve up the cached version
        if (cacheKey in this.cachedPriceMap) {
            return this.cachedPriceMap[cacheKey];
        }

        const rowGroup = this.getRowGroupByLegSolutionId(this.legSolutionPriceMap, outLegSolution.legSolutionID);

        if (returnLegSolution) {
            const returnRowGroup = this.getRowGroupByLegSolutionId(rowGroup.returnRowGroups, returnLegSolution.legSolutionID);
            prices = returnRowGroup.prices;
        } else {
            prices = rowGroup.prices;
        }

        const priceMap: { [key: string]: IMobileRow } = {};
        prices.forEach(p => {
            p.compoundRowKeys.forEach((key: string) => {
                if (!priceMap[key]) {
                    priceMap[key] = {
                        compoundKey: key,
                        isSingle: p.isSingleFare,
                        rowDescription: [],
                        seatsAvailable: p.seatsAvailable,
                        fareClass: AcePointToPointPrice.compoundToFareClass(key),
                        serviceClass: AcePointToPointPrice.compoundToServiceClass(key),
                        rank: AceSearchProjection.FARE_CLASS_RANK[AcePointToPointPrice.compoundToFareClass(key)]
                            ? AceSearchProjection.FARE_CLASS_RANK[AcePointToPointPrice.compoundToFareClass(key)]
                            : AceSearchProjection.DEFAULT_FARE_CLASS_RANK,
                        prices: []
                    };
                }

                priceMap[key].prices.push(p);
            });
        });

        // sort by price and limit to a single item
        let priceRowGroups: IMobileRow[] = Object.values(priceMap);

        // Determine if multiple start/end locations are in this search
        const multipleStartLocations = Array.from(new Set(this.outgoingLegSolutions.map(l => l.originTravelPoint.code))).length > 1;
        const multipleEndLocations = Array.from(new Set(this.outgoingLegSolutions.map(l => l.destinationTravelPoint.code))).length > 1;

        // Loop through the rows and make some calculations....
        priceRowGroups = priceRowGroups.map(row => {
            // Generate row description
            const price: AcePointToPointPrice = row.prices[0];
            if (price && price.routeRule) {
                row.rowDescription.push(price.routeRule);
            }
            if (price.fareOriginTerminals.length === 0 && multipleStartLocations) {
                row.rowDescription.push(`Journey begins at ${outLegSolution.originTravelPoint.code}`);
            }
            if (price.fareDestinationTerminals.length === 0 && multipleEndLocations) {
                row.rowDescription.push(`Journey terminates at ${outLegSolution.destinationTravelPoint.code}`);
            }
            if (price.fareOriginTerminals.length > 0) {
                row.rowDescription.push(`Fare allows journey start from ${price.fareOriginTerminals.join(", ")}`);
            }
            if (price.fareDestinationTerminals.length > 0) {
                row.rowDescription.push(`Fare allows journey termination at ${price.fareDestinationTerminals.join(", ")}`);
            }

            if (!row.isSingle) {
                // Returns: Just find the cheapest price for this row
                row.prices = [row.prices.reduce((a, b) => (this._getValueOfThePriceWithPromotion(a) < this._getValueOfThePriceWithPromotion(b) ? a : b))];
            } else {
                // Find the cheapest out and return single prices
                let outPrice = row.prices
                    .filter(price => price.isOutgoingSingleFare === true)
                    .sort((a, b) => this._getValueOfThePriceWithPromotion(a) - this._getValueOfThePriceWithPromotion(b));

                let returnPrice = row.prices
                    .filter(price => price.isReturnSingleFare === true)
                    .sort((a, b) => this._getValueOfThePriceWithPromotion(a) - this._getValueOfThePriceWithPromotion(b));

                // Get the cheapest single price from collection
                if (outPrice.length > 1) {
                    outPrice = [outPrice[0]];
                }

                if (returnPrice.length > 1) {
                    returnPrice = [returnPrice[0]];
                }

                // Default is to show all the outgoing singles
                row.prices = outPrice;

                // If a return journey search, then we only show the combined out and return singles for the same compound row key
                if (this.searchResult.resultType === ISearchResultType.RETURN) {
                    if (outPrice.length > 0 && returnPrice.length > 0) {
                        row.prices = [].concat(outPrice, returnPrice);
                    } else {
                        // If no match made, then set to no prices so it gets filtered out later
                        row.prices = [];
                    }
                }
            }

            row.priceFrom = new Price();
            row.prices.forEach(price => {
                row.priceFrom.currency = price.totalPrice.currency;
                row.priceFrom.originalValue += price.totalPrice.value;
                row.priceFrom.promotionValue += this._getValueOfThePriceWithPromotion(price);
            });

            return row;
        });

        // Filter out rows which have no prices (this can happen if there is no matching set of out & return tickets of the same compound key row
        priceRowGroups = priceRowGroups.filter(row => row.prices.length > 0);

        // Sort rows
        priceRowGroups = Array.from(
            priceRowGroups
                .sort((a, b) => {
                    if (a.rank > b.rank) {
                        return -1;
                    }
                    if (a.rank < b.rank) {
                        return 1;
                    }

                    const aPrice = this._getValueOfThePriceWithPromotion(a.prices[0]);
                    const bPrice = this._getValueOfThePriceWithPromotion(b.prices[0]);
                    if (aPrice > bPrice) {
                        return 1;
                    }
                    if (aPrice < bPrice) {
                        return -1;
                    }

                    if (a.fareClass > b.fareClass) {
                        return 1;
                    }
                    if (a.fareClass < b.fareClass) {
                        return -1;
                    }

                    return 0;
                })
                .reduce((map, item) => {
                    if (!map.has(item.priceFrom.promotionValue)) {
                        map.set(item.priceFrom.promotionValue, item);
                    }

                    return map;
                }, new Map<number, IMobileRow>())
                .values()
        ).sort((a, b) => {
            if (a.priceFrom.promotionValue > b.priceFrom.promotionValue) {
                return 1;
            }
            if (a.priceFrom.promotionValue < b.priceFrom.promotionValue) {
                return -1;
            }

            return 0;
        });

        this.cachedPriceMap[cacheKey] = priceRowGroups;

        return priceRowGroups;
    }

    private initMobile() {
        this.legSolutionPriceMap = this.outgoingLegSolutions.map(legSolution => this.buildOutgoingLegSolutionMap(legSolution)).filter(map => map.prices.length > 0);
    }

    private buildOutgoingLegSolutionMap(outgoingLegSolution: AceLegSolution) {
        const outPrices = this.findPricesForAnyLegSolutions(outgoingLegSolution.legSolutionID, this._prices);
        const lowestPrice = this.determinePriceFrom(outPrices);

        const ret = {
            enabled: outPrices.length > 0,
            hasRailcard: outPrices.some(price => price.hasRailcard === true),
            hasGroupsave: outPrices.some(price => price.hasGroupsave === true),
            hasNus: this.isNusPresent(outPrices),
            hasStnr: outPrices[0] != null ? outPrices[0].hasStnr : false,
            hasMTicket: this.isMTicketPresent(outPrices),
            legSolution: outgoingLegSolution,
            prices: outPrices,
            priceFrom: lowestPrice.priceFrom,
            lowestPrice: lowestPrice.lowestPrice,
            returnRowGroups: this.returnLegSolutions.map(returnLegSolution => this.buildReturnLegSolutionMap(outgoingLegSolution, returnLegSolution, outPrices))
        };

        return ret;
    }

    private buildReturnLegSolutionMap(outgoingLegSolution: AceLegSolution, returnLegSolution: AceLegSolution, prices: AcePointToPointPrice[]) {
        const returnPrices = this.findPricesForAllLegSolutions([outgoingLegSolution.legSolutionID, returnLegSolution.legSolutionID], prices);
        const singleOutPrices = prices.filter(price => price.isOutgoingSingleFare === true);
        const singleRetPrices = this.findPricesForAnyLegSolutions(returnLegSolution.legSolutionID, this._prices).filter(price => price.isReturnSingleFare === true);
        const allPrices = [].concat(singleOutPrices, singleRetPrices, returnPrices);

        const lowestPrice = this.determinePriceFrom(allPrices);

        const ret = {
            enabled: allPrices.length > 0,
            hasRailcard: allPrices.some(price => price.hasRailcard === true),
            hasGroupsave: allPrices.some(price => price.hasGroupsave === true),
            hasNus: this.isNusPresent(allPrices),
            hasStnr: allPrices[0] != null ? allPrices[0].hasStnr : false,
            hasMTicket: this.isMTicketPresent(allPrices),
            legSolution: returnLegSolution,
            prices: allPrices,
            priceFrom: lowestPrice.priceFrom,
            lowestPrice: lowestPrice.lowestPrice
        };

        return ret;
    }

    private determinePriceFrom(prices: AcePointToPointPrice[]): { priceFrom: Price; lowestPrice: AcePointToPointPrice } {
        let priceFrom: Price;
        let lowestPrice: AcePointToPointPrice;
        // Determine cheapest price
        if (this.searchResult.resultType === ISearchResultType.SINGLE) {
            const cheapestSingle = prices.reduce((a, b) => (this._getValueOfThePriceWithPromotion(a) < this._getValueOfThePriceWithPromotion(b) ? a : b));
            priceFrom = new Price(cheapestSingle.totalPrice.value, cheapestSingle.totalPrice.currency, this._getValueOfThePriceWithPromotion(cheapestSingle));
            lowestPrice = cheapestSingle;
        }

        if (this.searchResult.resultType === ISearchResultType.RETURN) {
            // Determine cheapest singles combination
            const singlePrices = prices.filter(price => price.isSingleFare === true) || [];
            const cheapestOutSingle = singlePrices
                .filter(price => price.isOutgoingSingleFare === true)
                .reduce((a, b) => (this._getValueOfThePriceWithPromotion(a) < this._getValueOfThePriceWithPromotion(b) ? a : b), null);

            const cheapestReturnSingle = this._prices
                .filter(price => price.isReturnSingleFare === true)
                .reduce((a, b) => (this._getValueOfThePriceWithPromotion(a) < this._getValueOfThePriceWithPromotion(b) ? a : b), null);

            if (cheapestOutSingle && cheapestReturnSingle) {
                if (!priceFrom) {
                    priceFrom = new Price();
                    lowestPrice = null;
                }
                priceFrom.originalValue = cheapestOutSingle.totalPrice.value + cheapestReturnSingle.totalPrice.value;
                priceFrom.promotionValue = this._getValueOfThePriceWithPromotion(cheapestOutSingle) + this._getValueOfThePriceWithPromotion(cheapestReturnSingle);
                lowestPrice = cheapestOutSingle;
            }

            // Determine cheapest return
            const cheapestReturn = prices
                .filter(price => price.isSingleFare === false)
                .reduce((a, b) => (this._getValueOfThePriceWithPromotion(a) < this._getValueOfThePriceWithPromotion(b) ? a : b), null);

            if (cheapestReturn) {
                if (!priceFrom) {
                    priceFrom = new Price(cheapestReturn.totalPrice.value, cheapestReturn.totalPrice.currency, this._getValueOfThePriceWithPromotion(cheapestReturn));
                    lowestPrice = cheapestReturn;
                } else {
                    if (this._getValueOfThePriceWithPromotion(cheapestReturn) < priceFrom.originalValue) {
                        priceFrom.originalValue = cheapestReturn.totalPrice.value;
                        priceFrom.currency = cheapestReturn.totalPrice.currency;
                        priceFrom.promotionValue = this._getValueOfThePriceWithPromotion(cheapestReturn);
                        lowestPrice = cheapestReturn;
                    }
                }
            }
        }

        if (!priceFrom) {
            priceFrom = new Price();
            lowestPrice = null;
        }

        return { priceFrom, lowestPrice };
    }

    private isNusPresent(prices: AcePointToPointPrice[]) {
        for (const price of prices) {
            if (DiscountHelper.isPromotionOfNUSType(this.searchResult.promotions, price.totalPrice.promotion)) {
                return true;
            }
        }
        return false;
    }

    private isMTicketPresent(prices: AcePointToPointPrice[]): boolean {
        return prices.length ? prices[0].hasMTicket : false;
    }
}

interface IMobileRowGroup {
    enabled: boolean;
    hasRailcard: boolean;
    hasGroupsave: boolean;
    hasNus: boolean;
    hasStnr: boolean;
    hasMTicket: boolean;
    legSolution: AceLegSolution;
    prices: AcePointToPointPrice[];
    priceFrom: Price;
    lowestPrice: AcePointToPointPrice;
    returnRowGroups?: IMobileRowGroup[];
}

interface IMobileRow {
    compoundKey: string;
    isSingle: boolean;
    rowDescription: string[];
    seatsAvailable: number;
    fareClass: string;
    serviceClass: string;
    rank: number;
    prices: AcePointToPointPrice[];
    priceFrom?: Price;
}
