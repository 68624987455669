import { Component, ElementRef, EventEmitter, HostListener, Input, OnChanges, OnInit, Output, ViewChild } from "@angular/core";
import { Select, Store } from "@ngxs/store";
import { BehaviorSubject, combineLatest, Observable, ReplaySubject } from "rxjs";
import { distinctUntilChanged, filter, map, take, takeLast } from "rxjs/operators";
import { AccountService } from "../../../services/account.service";
import { ConfigService } from "../../../services/config.service";
import { RailcardService } from "../../../services/railcard.service";
import { AceUser } from "../../models/ace/ace-user.model";
import { INusCardPayload, NuscardComponent, NusStatusEnum } from "../../models/nuscard";
import { ViewportType } from "../../models/entities/viewport";
import { RxjsComponent } from "../../RxjsComponent";
import { MetadataState } from "../../state/metadata/metadata.state";
import { QttState } from "../../state/qtt/qtt.state";
import { DiscountHelper } from "../../utilities/DiscountHelper";
import { NumericalStepperComponent } from "../numerical-stepper/numerical-stepper.component";
import { RailcardPickerComponent } from "../railcard-picker/railcard-picker.component";
import { QttValidator } from "../../validator";
import { NotifyToastService } from "../../../services/notify-toast.service";

@Component({
    selector: "ace-passenger-picker",
    templateUrl: "passenger-picker.component.html",
    styleUrls: ["passenger-picker.component.scss"]
})
export class PassengerPickerComponent extends RxjsComponent implements OnInit, OnChanges {
    @Input() public amend: boolean;
    @Input() public adults: number;
    @Input() public children: number;
    @Input() public max: number;
    @Input() public showPassengerPicker: boolean;
    @Input() public disabled: boolean;
    @Output() public passengersChange = new EventEmitter<string>(null);
    @Output() public init = new EventEmitter<boolean>();
    @ViewChild("inputElement") public inputElement: { nativeElement: any };
    @ViewChild(RailcardPickerComponent) public RailcardPickerComponent: RailcardPickerComponent;
    @ViewChild("adultsStepper") public adultsStepper: NumericalStepperComponent;
    @ViewChild("pickerElement") public pickerElement: ElementRef<HTMLDivElement>;
    @Select(QttState.nusCardNumber) public nusCard$: Observable<string>;

    public localNuscardComponent: NuscardComponent;
    public nusStatus = NusStatusEnum;
    public adultsCount = 1;
    public childrenCount = 0;
    public maxChildren = 0;
    public maxAdults = 0;
    public isOpen$ = new BehaviorSubject(false);
    public nusCardStatus$ = new BehaviorSubject<NusStatusEnum>(NusStatusEnum.PRE_VALIDATION);
    public nusCardErrorCode$ = new BehaviorSubject<string>(null);
    public nusCardValid$ = new BehaviorSubject(true);
    public currentInput$ = new BehaviorSubject<"passenger" | "promotion">(null);
    public nusCardNumber$ = new BehaviorSubject<string>(null);
    public railcardAmount$ = new BehaviorSubject(0);
    public nus$: Observable<boolean>;
    public user$ = new ReplaySubject<AceUser>(null);
    public pickerValid$: Observable<boolean>;
    private changeAdult = 0;
    private changeChild = 0;

    constructor(
        public railcardService: RailcardService,
        public store: Store,
        private ref: ElementRef,
        private config: ConfigService,
        private accountService: AccountService,
        private toastService: NotifyToastService
    ) {
        super();
    }

    @HostListener("document:click", ["$event"])
    @HostListener("document:touchstart", ["$event"])
    public PassengerPickerListener($event): void {
        if (!this.ref.nativeElement.contains($event.target) && this.isOpen$.value) {
            this.onCancel();
        }
    }

    @HostListener("window:keyup", ["$event"])
    public onWindowKeyUp(event: KeyboardEvent): void {
        if (event.key === "Escape" && this.isOpen$.value === true) {
            this.onCancel();
        }
    }

    public ngOnInit(): void {
        this.addSubscription(this.accountService.currentUser$.pipe(filter(user => user != null)).subscribe(user => this.user$.next(user)));
        this.addSubscription(
            this.isOpen$
                .pipe(
                    distinctUntilChanged(),
                    filter(isOpen => isOpen === true)
                )
                .subscribe(() => this.focusSlider())
        );
        this.addSubscription(
            this.railcardService.getRailcardsSelection().subscribe(selectedRailcards => this.railcardAmount$.next(selectedRailcards != null ? selectedRailcards.length : 0))
        );

        this.addSubscription(
            this.railcardService.nusStatus$?.subscribe(status => {
                this.nusCardErrorCode$.next(DiscountHelper.DISCOUNT_TYPE_NUS + NusStatusEnum[status]);
                this.nusCardStatus$.next(status);
            })
        );

        this.addSubscription(
            combineLatest([this.user$, this.nusCard$])
                .pipe(
                    map(([user, nusCardNumber]) => {
                        if (typeof nusCardNumber !== "undefined" && nusCardNumber !== "") {
                            return nusCardNumber;
                        } else {
                            return user ? user.getNusCardId() : "";
                        }
                    })
                )
                .subscribe(nusCardNumber => {
                    this.nusCardNumber$.next(nusCardNumber);

                    if (nusCardNumber) {
                        this.validateNusCard(nusCardNumber);
                    }
                })
        );

        this.changeAdult = this.adults;
        this.changeChild = this.children;
        this.nus$ = this.config.hasFeature("nus");
        this.localNuscardComponent = new NuscardComponent();

        this.pickerValid$ = combineLatest([this.nus$, this.isRailcardValid, this.nusCardStatus$, this.nusCardValid$]).pipe(
            map(([nusEnabled, railcardValid, nusCardStatus, nusCardValid]) =>
                nusEnabled ? railcardValid && nusCardValid && (nusCardStatus === NusStatusEnum.PRE_VALIDATION || nusCardStatus === NusStatusEnum.VALID) : railcardValid
            )
        );
    }

    public ngOnChanges(): void {
        this.setPassengers(this.adults, this.children);
    }

    public onAdultValueChanged(adults: number): void {
        this.adults = adults;
        this.notifyPassengersChange();
    }

    public onChildrenValueChanged(children: number): void {
        this.children = children;
        this.notifyPassengersChange();
    }

    public onCancel(): void {
        this.closePicker();

        this.adults = this.changeAdult;
        this.children = this.changeChild;
        this.setPassengers(this.changeAdult, this.changeChild);

        if (this.showPassengerPicker) {
            this.RailcardPickerComponent.updateRailcardComponent();
        }

        const nusCardNumber = this.nusCardNumber$.getValue();

        this.nusCardNumber$.next(nusCardNumber);

        if (nusCardNumber) {
            this.validateNusCard(nusCardNumber);
        }

        this.focusPickerElement();
    }

    public onDone(): void {
        const passengerError = QttValidator.arePassengerValid(this.adults, this.children);

        if (passengerError) {
            this.toastService.create({
                msg: passengerError.message,
                theme: passengerError.theme,
                icon: passengerError.icon,
                timeout: 5000
            });

            this.focusSlider();

            return;
        }

        this.closePicker();

        this.changeAdult = this.adults;
        this.changeChild = this.children;
        this.setPassengers(this.changeAdult, this.changeChild);

        if (this.showPassengerPicker) {
            this.RailcardPickerComponent.removeNonSelectedRailcards();
            this.RailcardPickerComponent.copyRailcardComponent();
        }

        if (this.railcardService.shouldSaveNusCard) {
            this.saveNusCard();
        }

        this.addSubscription(this.railcardService.currentValidNusCard$.pipe(takeLast(1)).subscribe(nus => this.nusCardNumber$.next(nus ? nus.cardNumber : "")));

        this.focusPickerElement();
    }

    public onNusCardValidChange(valid: boolean): void {
        this.nusCardValid$.next(valid);
    }

    public onNusCardInput(payload: INusCardPayload): void {
        if (payload.valid && payload.card) {
            this.railcardService.clearNusCardValidationStatus();
            this.validateNusCard(payload.card);
        }
    }

    public get isRailcardValid(): Observable<boolean> {
        return this.railcardService.isRailcardValid$.asObservable();
    }

    public get displayPassengerPicker$(): Observable<boolean> {
        return this.currentInput$.pipe(map(currentInput => (this.isMobile ? currentInput === "passenger" : true)));
    }

    public get displayPromotionPicker$(): Observable<boolean> {
        return combineLatest([this.currentInput$, this.nus$]).pipe(map(([currentInput, nus]) => (this.isMobile ? nus && currentInput === "promotion" : Boolean(nus))));
    }

    public get isMobile(): boolean {
        const metadata = this.store.selectSnapshot(state => MetadataState.metadata(state.metadata));
        return [ViewportType.MOBILE, ViewportType.MOBILE_SMALL].includes(metadata.device);
    }

    public openPicker(input: "passenger" | "promotion"): void {
        if (!this.amend) {
            this.isOpen$.next(true);
            this.currentInput$.next(input);
        }
    }

    public closePicker(): void {
        if (!this.amend) {
            this.isOpen$.next(false);
            this.currentInput$.next(null);
        }
    }

    private focusPickerElement(): void {
        setTimeout(() => {
            this.pickerElement?.nativeElement?.focus();
        }, 150);
    }

    private focusSlider(): void {
        setTimeout(() => {
            this.adultsStepper?.sliderElement.nativeElement?.focus();
        }, 0);
    }

    private validateNusCard(nusCardId: string): void {
        this.addSubscription(
            this.railcardService
                .validateNusCard(nusCardId)
                .pipe(take(1))
                .subscribe(status => this.nusCardStatus$.next(status))
        );
    }

    private setPassengers(adults, children): void {
        this.adultsCount = adults;
        this.childrenCount = children;
        this.calculateLimits();
    }

    private notifyPassengersChange(): void {
        this.passengersChange.emit(this.adults + "-" + this.children);
    }

    private calculateLimits(): void {
        this.maxAdults = this.max - this.children;
        this.maxChildren = this.max - this.adults;
    }

    private saveNusCard(): void {
        const nusCard = this.railcardService.currentValidNusCard$.getValue();
        const user = this.accountService.getCurrentUser();
        if (nusCard && user.isLoggedIn) {
            const data: any = user.createUpdateNUSCardPayload(user.userId, nusCard.cardNumber);
            this.addSubscription(this.accountService.update(user.userId, [data]).subscribe());
        } else if (nusCard === null) {
            this.railcardService.shouldSaveNusCard = false;
        }
    }
}
