import { Component, Input, ViewEncapsulation } from "@angular/core";

@Component({
    selector: "ace-error-sign",
    templateUrl: "./error-sign.component.html",
    styleUrls: [],
    encapsulation: ViewEncapsulation.None
})
export class ErrorSignComponent {
    @Input() id: string;
}
