import { Pipe, PipeTransform } from "@angular/core";
import { EMPTY, from, Observable, of } from "rxjs";
import { bufferCount, map, mergeMap, take } from "rxjs/operators";
import { StationService } from "../../services/station.service";

@Pipe({
    name: "station"
})
export class StationPipe implements PipeTransform {
    constructor(private stationService: StationService) {}

    public transform(stationCode: string, attribute: string): Observable<any> {
        if (!stationCode) {
            return EMPTY;
        }

        if (stationCode.length > 5) {
            const stationsFound: string[] = stationCode.match(/(GB|IE)([@^\-&*%#!][A-Z0-9]{2}|[A-Z0-9]{3})/g);

            if (stationsFound) {
                return from(stationsFound).pipe(
                    mergeMap(station => this.stationService.fetchStation(station)),
                    bufferCount(stationsFound.length),
                    take(1),
                    map(res =>
                        res.reduce((ret: string, station) => {
                            if (!station) {
                                return res;
                            }
                            return ret.replace(station.code, station.name);
                        }, stationCode)
                    )
                );
            } else {
                return of(stationCode);
            }
            // We have been given a string and we need to find the station in it
        } else {
            return this.stationService.fetchStationAttribute(stationCode, attribute);
        }
    }
}
