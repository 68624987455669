import moment from "moment";
import { AceLegSolution } from "./ace/ace-leg-solution.model";
import { AcePointToPointPrice } from "./ace/ace-point-to-point-price.model";
import { AceSearchResult } from "./ace/ace-search-result.model";
import { TicketSearchPayloadCriteria } from "./criterias/ticket-search-payload-criteria";
import { IQueryParams } from "./interfaces/IQueryParams";

export class NationRailParams {
    public results: AceSearchResult;
    public criteria: TicketSearchPayloadCriteria;
    public params: IQueryParams;
    public selectedOutgoingLeg: AceLegSolution | null;
    public selectedReturnLeg: AceLegSolution | null;
    public outgoingFare: AcePointToPointPrice | null;
    public returnFare: AcePointToPointPrice | null;
    public isReturn: boolean = false;
    public isOpenReturn: boolean = false;
    public defaultDeliveryOption: DataModel.TicketingOptionCode = null;
    public handoffID: string = "";

    public setParams(params: IQueryParams) {
        this.params = this.updateParams(params);
        this.isReturn = this.params.inbound === "true";
        this.isOpenReturn = this.isReturn && this.params.openReturn === "true";
    }

    public getSelection(results: AceSearchResult) {
        this.results = results;

        this.selectedOutgoingLeg = this.results.outgoingResults.legSolutions.find(it => it.departureDateTime + ".000Z" === this.params.ojd) || null;
        this.outgoingFare = this.getLegFare(this.selectedOutgoingLeg, this.params.ojprice) || null;

        if (this.isReturn && this.results.hasReturnResults) {
            this.selectedReturnLeg = this.results.returnResults.legSolutions.find(it => it.departureDateTime + ".000Z" === this.params.rjd) || null;
            this.returnFare = this.isReturn ? this.getLegFare(this.selectedOutgoingLeg, this.params.rjprice) : null;
        }
    }

    public isValid(): boolean {
        if (this.selectedOutgoingLeg == null || this.outgoingFare == null) {
            return false;
        }

        if (this.isReturn && (this.selectedReturnLeg == null || this.returnFare == null)) {
            return false;
        }

        return true;
    }

    public updateParams(params: IQueryParams) {
        const clone = { ...params };

        if (params.outboundTimeType === "DEPARTURE") {
            if (params.ojd) {
                clone.outboundTime = this.formatTime(moment(params.ojd).utc().subtract(2, "minutes"));
            }
        } else {
            if (params.oja) {
                clone.outboundTime = this.formatTime(moment(params.oja).utc().add(2, "minutes"));
            }
        }

        if (params.ojprice) {
            clone.ojprice = this.toDecimal(params.ojprice);
        }

        if (params.rjprice) {
            clone.rjprice = this.toDecimal(params.rjprice);
        }

        if (params.inboundTimeType === "DEPARTURE") {
            if (params.rjd) {
                clone.inboundTime = this.formatTime(moment(params.rjd).utc().subtract(2, "minutes"));
            }
        } else {
            if (params.rja) {
                clone.inboundTime = this.formatTime(moment(params.rja).utc().add(2, "minutes"));
            }
        }

        if (moment(params.ojd).isDST()) {
            clone.ojd = this.formatTime(params.ojd);
            clone.oja = this.formatTime(params.oja);
        }

        if (params.rjd) {
            if (moment(params.rjd).isDST()) {
                clone.rjd = this.formatTime(params.rjd);
                clone.rja = this.formatTime(params.rja);
            }
        }

        if (params.ls) {
            delete clone["ls"];
        }

        if (params.p) {
            delete clone["p"];
        }

        if (params.requestID) {
            this.handoffID = params.requestID;
        }

        return clone;
    }

    public formatTime(time): string {
        return moment(time).format("YYYY-MM-DDTHH:mm:ss").toLocaleString();
    }

    private getLegFare(leg: AceLegSolution, price: number): AcePointToPointPrice {
        if (leg) {
            return this.matchFarePrice(
                this.results.prices.filter(price => price.legReferences.includes(leg.legSolutionID)),
                price
            );
        } else {
            return null;
        }
    }

    private matchFarePrice(prices: AcePointToPointPrice[], farePrice: number): AcePointToPointPrice {
        return prices.filter(price => price.totalPrice.value === farePrice)[0];
    }

    private toDecimal(val: string): number {
        return Number((Number(val) / 100).toFixed(2));
    }
}
