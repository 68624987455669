<div class="row">
    <div class="col-12">
        <div class="qtt-station">
            <div class="qtt-station-picker">
                <ace-station-picker
                    #fromStationPicker
                    inputLabel="Origin station"
                    defaultLabel="From"
                    defaultAccessibleLabel="Pick origin station"
                    stationPickerName="origin"
                    isRequired
                    [baseId]="baseId"
                    [disabled]="isJourneyAmendable"
                    [value]="originTravelPoint"
                    (valueChange)="onOriginChange($event)"
                    (focused)="onPickerFocus('origin')"
                    data-elid="qtt-from-station"
                ></ace-station-picker>
            </div>
            <div class="qtt-station-picker">
                <ace-station-picker
                    #toStationPicker
                    inputLabel="Destination station"
                    defaultLabel="To"
                    defaultAccessibleLabel="Pick destination station"
                    stationPickerName="destination"
                    isRequired
                    [baseId]="baseId"
                    [disabled]="isJourneyAmendable"
                    [value]="destinationTravelPoint"
                    (valueChange)="onDestinationChange($event)"
                    (focused)="onPickerFocus('destination')"
                    data-elid="qtt-to-station"
                ></ace-station-picker>
            </div>
            <button
                class="qtt-station-swap"
                [class.qtt-station-swap-disabled]="(isStationPickerDisabled$ | async) || isJourneyAmendable"
                [disabled]="(isStationPickerDisabled$ | async) || isJourneyAmendable"
                (click)="onStationSwitch()"
                aria-label="Swap origin station with destination station"
                data-elid="qtt-station-swap"
            >
                <svg viewBox="0 0 24 24" aria-hidden="true">
                    <title>swap</title>
                    <use xlink:href="#swap" />
                </svg>
            </button>
        </div>
    </div>
</div>

<div *ngIf="qttOpen">
    <div class="row">
        <div class="col col-12">
            <div class="qtt-via-avoid">
                <span *ngIf="(isStationPickerDisabled$ | async) === false && !hasViaAvoid" class="qtt-via-avoid-toggle">
                    <ace-button
                        #viaAvoidOpenButton
                        appearance="link-standard-light"
                        data-elid="via-avoid-toggle"
                        ariaLabel="via avoid"
                        [attr.id]="baseId + '-via-avoid-toggle'"
                        (click)="openViaAvoid()"
                    >
                        Via / Avoid
                        <svg viewBox="0 0 24 24" aria-hidden="true">
                            <title>plus</title>
                            <use xlink:href="#plus" />
                        </svg>
                    </ace-button>
                </span>
                <div class="qtt-via-avoid-body" *ngIf="hasViaAvoid">
                    <label class="sr-only" [attr.for]="baseId + '-via-avoid-select'">Via / avoid option</label>
                    <ace-select
                        #viaAvoidSelect
                        [selectId]="baseId + '-via-avoid-select'"
                        [preSelected]="viaAvoidOptionSelectedIndex"
                        [options]="viaAvoidOptions"
                        data-elid="via-avoid-select"
                        (optionSelect)="onViaAvoidSelectedOptionChange($event)"
                    ></ace-select>
                    <ace-station-picker
                        #viaAvoidStationPicker
                        [attr.id]="baseId + '-via-avoid-station-picker'"
                        inputLabel="Via/avoid station"
                        defaultLabel="Enter station"
                        defaultAccessibleLabel="Pick via/avoid station"
                        stationPickerName="viaavoid"
                        data-elid="via-avoid-station-picker"
                        [value]="viaAvoidTravelPoint"
                        (click)="onViaAvoidStationPickerClick('viaavoid')"
                        (valueChange)="onViaAvoidChange($event)"
                    ></ace-station-picker>
                    <ace-button
                        [attr.id]="baseId + '-via-avoid-collapse'"
                        appearance="link-standard-light"
                        data-elid="via-avoid-collapse"
                        ariaLabel="Remove selected via/avoid station"
                        (click)="closeVisAvoid()"
                    >
                        <svg viewBox="0 0 24 24" aria-hidden="true">
                            <title>close</title>
                            <use xlink:href="#close" />
                        </svg>
                    </ace-button>
                </div>
            </div>
        </div>
    </div>
</div>
