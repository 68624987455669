<div class="select {{ theme.length ? 'theme-' + theme : '' }}" [class.select-disabled]="disabled" [class.select-with-icon]="icon">
    <svg viewBox="0 0 24 24" *ngIf="icon">
        <use [attr.xlink:href]="'#' + icon" />
    </svg>
    <select
        #selectElement
        [attr.id]="selectId"
        (change)="onSelect($event.target.value)"
        class="select-input"
        [disabled]="disabled"
        [class.select-input-error]="error"
        [required]="required"
        [attr.aria-describedby]="ariaDescribedby"
    >
        <option class="select-option select-option-placeholder" *ngIf="placeholder && isPlaceholderSet" value="" disabled selected>
            {{ placeholder }}
        </option>
        <option class="select-option" *ngFor="let option of options; let i = index" [value]="i" [selected]="option.selected">{{ option.label }}</option>
    </select>
</div>
