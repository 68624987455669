import { ComponentRef } from "@angular/core";
import { BehaviorSubject } from "rxjs";

export class DisplayStackItem {
    private _componentRef: ComponentRef<any>;
    private _component$: BehaviorSubject<ComponentRef<any>>;

    constructor(componentRef: ComponentRef<any>) {
        this._componentRef = componentRef;
        this._component$ = new BehaviorSubject<ComponentRef<any>>(this._componentRef);
        this._component$.complete();
    }

    public get component$(): BehaviorSubject<ComponentRef<any>> {
        return this._component$;
    }
}
