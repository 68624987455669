<label [attr.for]="checkboxId" class="checkbox" [ngClass]="theme" [class.small-text]="smallFontSize" [class.checkmark-hidden]="checkmarkHidden" [class.with-margin]="withMargin">
    <input
        #checkboxElement
        type="checkbox"
        class="control sr-only"
        [attr.id]="checkboxId"
        [attr.name]="checkboxId"
        [checked]="checked"
        [disabled]="disabled"
        (change)="onSubmit()"
    />
    <div class="square"></div>
    <svg role="img" focusable="false" aria-labelledby="checkmark-icon" viewBox="0 0 24 24" aria-hidden="true">
        <title id="checkmark-icon">checkmark</title>
        <use xlink:href="#checkmark" />
    </svg>
    <span class="content">
        <ng-content></ng-content>
    </span>
</label>
