import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { Select, Store } from "@ngxs/store";
import { addDays, addHours, format, getHours, getMinutes, isAfter, parse, set, setSeconds } from "date-fns";
import { combineLatest, Observable } from "rxjs";
import { filter, map, take } from "rxjs/operators";
import moment from "moment";
import { BasketService } from "../../../services/basket.service";
import { NotifyToastService } from "../../../services/notify-toast.service";
import { RailcardService } from "../../../services/railcard.service";
import { SearchService } from "../../../services/search.service";
import { TravelDepartOptions } from "../../classes/TravelDepartOptions";
import { DATE_TIME_STRING_FORMAT } from "../../constants/date";
import { IQueryParams } from "../../models/interfaces/IQueryParams";
import { NusStatusEnum } from "../../models/nuscard";
import { ConfigState } from "../../state/config/config.state";
import { ClearFareFilter, UpdateJourneyAmend } from "../../state/journey/journey.actions";
import { MetadataState } from "../../state/metadata/metadata.state";
import { UpdateSearchCriteria } from "../../state/qtt/qtt.actions";
import { initialState, QttState } from "../../state/qtt/qtt.state";
import { TimeHelper } from "../../utilities/TimeHelper";
import { ButtonComponent } from "../button/button.component";
import { CalendarCloseOrigin } from "../calendar/calendar.component";
import { NavigationState } from "../../state/navigation/navigation.state";
import { JourneyState } from "../../state/journey/journey.state";
import { AceBookingOrder } from "../../models/ace/ace-booking-order";
import { Direction } from "../../enums/Direction";
import { QttBase } from "./../../classes/QttBase";

@Component({
    selector: "ace-qtt",
    templateUrl: "./qtt.component.html",
    styleUrls: ["./qtt.component.scss"]
})
export class QTTComponent extends QttBase implements OnInit, OnDestroy {
    @Select(MetadataState.isMobile) public isMobile$: Observable<boolean>;
    @Select(ConfigState.maxPassenger) public maxPassenger$: Observable<number>;
    @Select(NavigationState.flowStep) public flowStep$: Observable<string>;
    @Select(JourneyState.currentAmendOrder) public currentAmendingOrder$: Observable<AceBookingOrder>;
    @Select(JourneyState.journeyAmendDirection) public journeyAmendDirection$: Observable<Direction>;
    @Select(JourneyState.currentAmendBooking) public currentAmendingBooking$: Observable<object>;
    @ViewChild("startDateCalendar") public startDateCalendar: ElementRef<HTMLDivElement>;
    @ViewChild("endDateCalendar") public endDateCalendar: ElementRef<HTMLDivElement>;
    @ViewChild("submitButton") public submitButton: ButtonComponent;
    public isOpenReturn: boolean = false;
    public outboundDepartOption: string = TravelDepartOptions.DEPARTURE;
    public returnDepartOption: string = TravelDepartOptions.DEPARTURE;
    public today: Date = new Date();
    public minStartDate: Date;
    public maxDate: Date;
    private isDirect: boolean = false;
    private flowStepName: string;
    private currentAmendingOrder: AceBookingOrder;
    amendingJourneyDirection: string;
    currentAmendingBooking: any;
    originalJourneyDepartureTime: string;
    originalJourneyArrivalTime: string;
    qttParamsCleared: boolean;

    constructor(
        private searchService: SearchService,
        private basketService: BasketService,
        public store: Store,
        protected _railcardService: RailcardService,
        public toastService: NotifyToastService
    ) {
        super(store, toastService, _railcardService);

        this.addSubscription(
            combineLatest([
                this._store.select(state => ConfigState.bookingWindow(state.config)).pipe(filter(bookingWindow => bookingWindow != null)),
                this._store.select(state => QttState.params(state.qtt))
            ]).subscribe(([maxDaysToStartFromToday, params]) => this.setDefaultDateTimes(maxDaysToStartFromToday, params))
        );
    }

    public ngOnInit(): void {
        this.addSubscription(this.currentAmendingOrder$.subscribe(order => (this.currentAmendingOrder = order)));
        this.addSubscription(this.currentAmendingBooking$.subscribe(booking => (this.currentAmendingBooking = booking)));
        this.addSubscription(
            this.journeyAmendDirection$.subscribe(direction => {
                if (direction === Direction.OUT) {
                    this.amendingJourneyDirection = "OUT";
                } else {
                    this.amendingJourneyDirection = "RTN";
                }
            })
        );
        if (this.isJourneyAmendable && this.amendingJourneyDirection === "OUT") {
            this.originalJourneyDepartureTime = this.currentAmendingOrder?.outgoingLeg?.departureDateTime?.toString();
            this.originalJourneyArrivalTime = this.currentAmendingOrder?.outgoingLeg?.arrivalDateTime?.toString();
        } else if (this.isJourneyAmendable && this.amendingJourneyDirection === "RTN") {
            this.originalJourneyDepartureTime = this.currentAmendingOrder?.returnLeg?.departureDateTime?.toString();
            this.originalJourneyArrivalTime = this.currentAmendingOrder?.returnLeg?.arrivalDateTime?.toString();
        }
        this.addSubscription(
            this.isMobile$?.subscribe(mobile => {
                this.isMobile = mobile;
                this.totalCards = mobile || this.isVertical ? 1 : 2;
            })
        );

        this.qttParams$
            .pipe(
                filter(params => params != null),
                take(1)
            )
            .subscribe(qttParams => {
                if (!qttParams.origin && !qttParams.destination) {
                    this.qttParamsCleared = true;
                } else {
                    this.qttParamsCleared = false;
                }
                this._setFormValues(qttParams);
            });

        this.addSubscription(
            this.searchService.searchCriteria$?.subscribe(searchCriteria => {
                this.clearFormValues();

                // set parameters in QTT standard tab if user has selection only
                if (!searchCriteria.params.startDate && !this.qttParamsCleared) {
                    this._setFormValues(searchCriteria.params);

                    const passengers = [String(searchCriteria.params.adults), String(searchCriteria.params.children)];
                    this.onPassengersChangeHandler(passengers.join("-"));
                }
            })
        );

        this.addSubscription(
            this.basketService.booking$?.subscribe(booking => {
                if (!booking.isEmpty && !booking.hasSeasonTicket()) {
                    // set parameters in QTT standard tab if user has booking
                    const passengers = [String(booking.numberOfAdultPassengers), String(booking.numberOfChildPassengers)];
                    this.onPassengersChangeHandler(passengers.join("-"));
                }
            })
        );

        this.addSubscription(
            this.flowStep$.subscribe(stepName => {
                this.flowStepName = stepName;
            })
        );
    }

    public ngOnDestroy(): void {
        this.updateParams();
    }

    public get isSavedNusExpired(): Observable<boolean> {
        return this._railcardService.nusStatus$.pipe(map(status => status === NusStatusEnum.EXPIRED));
    }

    public get isNusGroupNotificationVisible(): boolean {
        const nusCard = this._railcardService.currentValidNusCard$.getValue();
        const cardNumber = nusCard ? nusCard.cardNumber : "";
        return Boolean(this.adults + this.children > 1 && cardNumber);
    }

    public setOutboundTime(timeOption: Date): void {
        this.outboundDateTime = set(this.outboundDateTime, { hours: getHours(timeOption), minutes: getMinutes(timeOption), seconds: 0 });
    }

    public setReturnTime(timeOption: Date): void {
        if (timeOption && getHours(timeOption)) {
            this.returnDateTime = set(this.returnDateTime, { hours: getHours(timeOption), minutes: getMinutes(timeOption), seconds: 0 });
        }
    }

    public showEndDateCalendar(): void {
        super.showEndDateCalendar();
    }

    public removeReturnJourney(): void {
        super.removeReturnJourney();

        if (this.isOpenReturn) {
            this.isOpenReturn = false;
        }

        if (this.isMobile === false) {
            setTimeout(() => {
                this.endDateCalendar.nativeElement?.focus();
            }, 150);
        }
    }

    public closeStartDateCalendar(closeOrigin: CalendarCloseOrigin): void {
        super.closeStartDateCalendar(closeOrigin);

        if (this.isMobile === false && closeOrigin !== "clicked-outside") {
            setTimeout(() => {
                this.startDateCalendar.nativeElement?.focus();
            }, 150);
        }
    }

    public closeEndDateCalendar(closeOrigin: CalendarCloseOrigin): void {
        super.closeEndDateCalendar(closeOrigin);

        if (this.isMobile === false && closeOrigin !== "clicked-outside") {
            setTimeout(() => {
                this.endDateCalendar.nativeElement?.focus();
            }, 150);
        }
    }

    public openReturnSelect(state): void {
        this.isOpenReturn = state;
        this.closeEndDateCalendar("other");
    }

    public search(): void {
        const formValues = this.getFormValues();
        if (this.validateSearchAndDisplayErrors(formValues)) {
            this.updateParams();
            this.store.dispatch(new ClearFareFilter());
            if (this.flowStepName !== "SEARCH" && this.flowStepName !== "MOBILE_SEARCH_OUTBOUND") {
                this.store.dispatch(new UpdateJourneyAmend(false));
            }
            this.searchSubmit.emit(formValues);
        }
    }

    public onValidationAlertClose(): void {
        setTimeout(() => {
            this.submitButton?.focus();
        }, 150);
    }

    protected _setFormValues(params: IQueryParams): void {
        super.setFormValues(params);

        if (params.origin && params.destination && params.origin.length < 2 && params.destination.length < 2) {
            return;
        }

        if (params.outboundTime) {
            const paramoutboundDateTime = TimeHelper.normaliseTime(parse(params.outboundTime, DATE_TIME_STRING_FORMAT, new Date()));

            if (isAfter(paramoutboundDateTime, this.outboundDateTime)) {
                this.outboundDateTime = paramoutboundDateTime;
            }

            if (params.outboundTimeType) {
                this.searchService.searchResults$.pipe(take(1)).subscribe(results => {
                    const isEarilierResultsAvailable = results.isEmpty;

                    const timeTypeParam = params.outboundTimeType === TravelDepartOptions.ARRIVAL ? TravelDepartOptions.ARRIVAL : TravelDepartOptions.DEPARTURE;

                    this.outboundDepartOption = isEarilierResultsAvailable ? TravelDepartOptions.DEPARTURE : timeTypeParam;
                });
            }

            if (this.isReturn && !params.inboundTime) {
                this.returnDateTime = TimeHelper.normaliseTime(addHours(this.outboundDateTime, 4));
            }
        }

        if (params.inboundTime) {
            this.returnDateTime = TimeHelper.normaliseTime(parse(params.inboundTime, DATE_TIME_STRING_FORMAT, new Date()));

            this.isReturn = true;

            if (params.inboundTimeType) {
                this.returnDepartOption = params.inboundTimeType === TravelDepartOptions.ARRIVAL ? TravelDepartOptions.ARRIVAL : TravelDepartOptions.DEPARTURE;
            }
        }

        if (params.direct) {
            this.isDirect = true;
        }

        if (params.openReturn) {
            this.isOpenReturn = params.openReturn === "true";
        }
    }

    private updateParams(): void {
        const { season } = initialState.params;
        this._store.dispatch(new UpdateSearchCriteria({ standard: this.getFormValues(), season }));
    }

    private setDefaultDateTimes(maxDaysToStartFromToday: number, params: IQueryParams): void {
        if (maxDaysToStartFromToday != null) {
            const outboundDate = params.outboundTime ? parse(params.outboundTime, DATE_TIME_STRING_FORMAT, new Date()) : this.today;
            this.outboundDateTime = TimeHelper.normaliseTime(setSeconds(this.today, 0));
            this.returnDateTime = TimeHelper.normaliseTime(setSeconds(addDays(outboundDate, 4), 0));
            this.minStartDate = new Date(this.today.getTime());
            this.maxDate = addDays(this.minStartDate, maxDaysToStartFromToday);
        }
    }

    private getFormValues(): IQueryParams {
        const queryOptions: IQueryParams = {
            origin: this.originTravelPoint,
            destination: this.destinationTravelPoint,
            outboundTime: format(this.outboundDateTime, DATE_TIME_STRING_FORMAT),
            outboundTimeType: <DataModel.TravelDateTimeType>this.outboundDepartOption,
            adults: this.adults,
            children: this.children
        };

        if (this.isReturn && !this.isOpenReturn) {
            queryOptions.inbound = true;
            queryOptions.inboundTimeType = <DataModel.TravelDateTimeType>this.returnDepartOption;
            queryOptions.inboundTime = format(this.returnDateTime, DATE_TIME_STRING_FORMAT);
        }

        if (this.isOpenReturn) {
            queryOptions.openReturn = "true";
        }

        if (this.isDirect) {
            queryOptions["direct"] = true;
        }

        if (this.hasViaAvoid && this.viaAvoidTravelPoint && this.viaAvoidTravelPoint.length > 0) {
            queryOptions[this.viaAvoidSelectedOption] = this.viaAvoidTravelPoint;
        }

        queryOptions["railcards"] = this.getRailcards(this._railcardService.getRailcardsSelection().getValue());

        if (this._store.selectSnapshot(state => ConfigState.featureFlag("nus")(ConfigState.aceConfig(state.config)))) {
            const currentNusCard = this._railcardService.currentValidNusCard$.getValue();
            queryOptions["nus"] = currentNusCard ? `${currentNusCard.cardNumber}` : "";
        }

        return queryOptions;
    }
}
