import moment from "moment";
import { Promotion } from "../../enums/Promotion";
import { BookingOrderVoucher, VoucherBase } from "../discount-voucher";
import { TicketDeliveryCode } from "../entities/ticket";
import { AceBookingOrder } from "./ace-booking-order";
import { AceBookingPassenger } from "./ace-booking-passenger.model";

export class AceUserBooking {
    public booking: DataModel.UserBooking;
    public config: any;
    public bookingRecordLocator: string;
    public bookingStatus: string;
    public createdAt: moment.Moment;
    public details: DataModel.BookingRecord;
    public cancellationSummary: DataModel.CancellationSummary;
    public revenueTotal: number;
    public updatedAt: moment.Moment;
    public financials: DataModel.Financials;
    public passengers: AceBookingPassenger[];
    public numberOfAdultPassengers: number = 0;
    public numberOfChildPassengers: number = 0;
    public allOrdersReleased: boolean = false;
    public customInformation: DataModel.CustomInformationPair[];
    public context: string;
    public orders: AceBookingOrder[] = [];
    public vouchers: VoucherBase[] = [];

    private totalPaid: number;
    private readonly MAX_SEASON_REFUND_AMOUNT: number = 300;

    constructor(booking, config, context = null) {
        if (booking.recordLocator) {
            this.config = config;

            this.bookingRecordLocator = booking.recordLocator;
            this.bookingStatus = booking.status;
            this.createdAt = moment(new Date(booking.bookingDate));
            this.revenueTotal = booking.revenueTotal;
            this.updatedAt = moment(booking.updatedAt);
            this.financials = booking.financials;
            this.details = booking;
            const order = booking.orders.filter(Boolean).shift();
            this.vouchers = booking.orders.map(order => new BookingOrderVoucher(booking.discountInformation, order.orderID)).filter(voucher => Object.keys(voucher).length !== 0);

            if (order) {
                this.cancellationSummary = order.cancellationSummary;
            }

            this.allOrdersReleased = booking.orders.every(order => order.status === "RELEASED");
            this.context = context;

            this._parseOrdersRetrieveBooking(booking);
            this._parsePassengersRetrieveBooking(booking);
            this._customInfoRetrieveBooking(booking);

            this.totalPaid = this.calculateTotalPaidRetrieveBooking(booking);
        } else {
            this.booking = booking;
            this.config = config;
            this.bookingRecordLocator = this.booking.bookingRecordLocator;
            this.bookingStatus = this.booking.bookingStatus;
            this.createdAt = moment(this.booking.createdAt);
            this.revenueTotal = this.booking.revenueTotal;
            this.updatedAt = moment(this.booking.updatedAt);
            this.financials = this.booking.details?.financials;
            this.details = this.booking.details;
            const order = this.booking.orders.filter(Boolean).shift();
            this.vouchers = this.booking.orders
                .map(order => new BookingOrderVoucher(this.booking.details?.discountInformation, order.orderId))
                .filter(voucher => Object.keys(voucher).length !== 0);

            if (order && order.details) {
                this.cancellationSummary = order.details.cancellationSummary;
            }

            this.allOrdersReleased = this.booking.orders.every(order => order.orderStatus === "RELEASED");
            this.context = this.booking.context;

            this._parseOrders();
            this._parsePassengers();
            this._customInfo(booking);

            this.totalPaid = this.calculateTotalPaid(this.booking);
        }
    }

    public isOpenReturn(orderId: string): boolean {
        const type = this.customInformation.find(it => it.key === `sourceSearchType-${orderId}`);
        return type ? type.value === "OPEN" : false;
    }

    public isReturn(orderId: string): boolean {
        const type = this.customInformation.find(it => it.key === `sourceSearchType-${orderId}`);
        return type ? type.value === "RETURN" && !this.isOrderSingleReturn(orderId) : false;
    }

    public hasDiscount(orderId: string): boolean {
        return this.customInformation.some(type => type.key.includes(`${Promotion.TYPE}${Promotion.SEPARATOR}${orderId}`));
    }

    public hasTotalDiscount(orderId: string): boolean {
        const voucher = this.vouchers.find(voucher => voucher.orderId === orderId);
        return voucher ? voucher.percent === 100 : false;
    }

    public getVoucher(orderId: string) {
        return this.vouchers.find(voucher => voucher.orderId === orderId);
    }

    public get hasITSOFulfilment(): boolean {
        return this.orders.some((order: AceBookingOrder) => order.fulfillmentInformation && order.fulfillmentInformation.selectedTicketingOption.code === TicketDeliveryCode.SCT);
    }

    public get isNewSmartcardRequested(): boolean {
        return (
            this.hasITSOFulfilment &&
            this.passengers.some((passenger: AceBookingPassenger) => passenger.smartCardNumber && passenger.smartCardNumber.indexOf("111111111111111111") > -1)
        );
    }

    public get isSTNRSeasonTicket(): boolean {
        return this.hasITSOFulfilment && this.hasSeasonTicket();
    }

    public get isSTNRSeasonTicketOverMaxRefundAmount(): boolean {
        const isOverMaxSeasonRefundAmount =
            this.cancellationSummary != null && this.cancellationSummary.priceReversals
                ? this.cancellationSummary.priceReversals.total.value > this.MAX_SEASON_REFUND_AMOUNT
                : false;

        return this.isSTNRSeasonTicket && isOverMaxSeasonRefundAmount;
    }

    public get getTotalPaid(): number {
        return this.totalPaid;
    }

    public hasSeasonTicket(): boolean {
        return this.orders.length ? this.orders[0].isSeasons : false;
    }

    public isOrderSingleReturn(orderId: string): boolean {
        const order = this.orders.find(order => order.orderID === orderId);
        return order != null ? order.isSinglesReturn : false;
    }

    public ticketableFaresAmount(orderId: string): number {
        return this.orders.find(order => order.orderID === orderId).ticketableFares.length;
    }

    public hasAdvancedFare(orderId: string): boolean {
        const ticketableFares = this.orders.find(order => order.orderID === orderId)?.ticketableFares ?? null;
        return ticketableFares ? ticketableFares.every(fare => fare.isAdvanced === true && fare.fareClass === "ADVANCE") : false;
    }

    public hasSingleReturnsAdvancedFare(orderId: string): boolean {
        return this.ticketableFaresAmount(orderId) > 0 ? this.orders.find(order => order.orderID === orderId).ticketableFares.every(fare => fare.isAdvanced === true) : false;
    }

    public hasRefundAlerts(orderId: string): boolean {
        return this.hasAdvancedFare(orderId) || this.isReturn(orderId) || this.isOrderSingleReturn(orderId) || this.isSeasonTicket(orderId) || this.hasDiscount(orderId);
    }

    public isSeasonTicket(orderId: string): boolean {
        const type = this.customInformation.find(it => it.key === `sourceSearchType-${orderId}`);
        return type ? type.value === "SEASONS" : false;
    }

    public canRefund(orderId: string): boolean {
        return !this.hasAdvancedFare(orderId) || !this.hasDiscount(orderId) || (this.isOrderSingleReturn(orderId) && !this.hasSingleReturnsAdvancedFare(orderId));
    }

    private _parseOrders(): void {
        this.orders = this.booking.orders.map(order => new AceBookingOrder(order.details, this.config.ticketingOptions));
    }

    private _parseOrdersRetrieveBooking(booking): void {
        this.orders = booking.orders.map((order: DataModel.BookingRecordOrder) => new AceBookingOrder(order, this.config.ticketingOptions));
    }

    private _parsePassengers(): void {
        this.passengers = this.booking.details?.passengers.map(passenger => new AceBookingPassenger(passenger));
        this.numberOfAdultPassengers = this.passengers?.filter(passenger => passenger.isAdult).length;
        this.numberOfChildPassengers = this.passengers?.filter(passenger => passenger.isChild).length;
    }

    private _parsePassengersRetrieveBooking(booking): void {
        this.passengers = booking.passengers.map(passenger => new AceBookingPassenger(passenger));
        this.numberOfAdultPassengers = this.passengers?.filter(passenger => passenger.isAdult).length;
        this.numberOfChildPassengers = this.passengers?.filter(passenger => passenger.isChild).length;
    }

    private _customInfo(booking: DataModel.UserBooking): void {
        this.customInformation = booking.details?.customInformation;
    }

    private _customInfoRetrieveBooking(booking): void {
        this.customInformation = booking.customInformation;
    }

    private calculateTotalPaid(booking: DataModel.UserBooking): number {
        const partiallyUsed: boolean = booking.details.financials.prices.find(price => price.type === "Used_Amount") !== undefined;

        // a refund for the unused part of an STNR season ticket - match return value to regular ticket refund scenario
        // i.e. if a ticket has been refunded, we want to show you paid £0.00 for it, regardless of how much we refunded
        if (this.isSTNRSeasonTicket && booking.bookingStatus === "CLOSED" && partiallyUsed) {
            return 0.0;
        }

        return booking.revenueTotal;
    }

    private calculateTotalPaidRetrieveBooking(booking): number {
        const partiallyUsed: boolean = booking.financials.prices.find(price => price.type === "Used_Amount") !== undefined;

        // a refund for the unused part of an STNR season ticket - match return value to regular ticket refund scenario
        // i.e. if a ticket has been refunded, we want to show you paid £0.00 for it, regardless of how much we refunded
        if (this.isSTNRSeasonTicket && booking.status === "CLOSED" && partiallyUsed) {
            return 0.0;
        }

        return booking.revenueTotal;
    }
}
