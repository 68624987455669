import { AceTicketingOption } from "../ace-ticketing-option.model";
import { AceModel } from "./ace-model";

export abstract class AceBookingOrderBase extends AceModel {
    public travelSegments: DataModel.TravelSegment[];
    public ticketingOptions: AceTicketingOption[];
    public originTravelPoint: DataModel.TravelPoint;

    protected _init() {
        this.travelSegments = this._parseTravelSegments(this.data.travelSegments);
        this.ticketingOptions = this._parseTicketingOptions(this.data.ticketingOptions);
    }

    private _parseTravelSegments(data: DataModel.TravelSegment[]): DataModel.TravelSegment[] {
        const result: DataModel.TravelSegment[] = [];

        if (data.length) {
            this.originTravelPoint = data[0].originTravelPoint;
        }

        return result;
    }

    private _parseTicketingOptions(data: DataModel.TicketingOption[]): AceTicketingOption[] {
        const result: AceTicketingOption[] = [];
        for (const element of data) {
            result.push(new AceTicketingOption(element));
        }
        return result;
    }
}
