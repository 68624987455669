import { Injectable } from "@angular/core";
import { environment } from "../../environments/environment";

// TODO: This service can be unnecessary

@Injectable({
    providedIn: "root"
})
export class EnvironmentService {
    public valueOf(name: string) {
        if (environment.hasOwnProperty(name)) {
            return environment[name];
        } else {
            throw new Error(`Property: '${name}' does not exists`);
        }
    }
}
