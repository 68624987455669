import moment from "moment";
import { flatten, isEmpty, isObject } from "../../../shared/utilities/Utils";
import { IAmenity } from "../interfaces/ISearchResult";
import { ReservationType } from "../entities/reservation-type";
import { AceLegSolution } from "./ace-leg-solution.model";
import { AceTicketableFareCollection } from "./ace-ticketable-fare-collection.model";
import { Utils } from "./ace-utils";

export class AcePointToPointPrice {
    private static _priceMap: { [key: string]: AcePointToPointPrice } = {};
    private static _compoundKeyToFareClassMap: { [key: string]: string } = {};
    private static _compoundKeyToServiceClassMap: { [key: string]: string } = {};
    private static _compoundKeyToFareOriginMap: { [key: string]: string } = {};
    private static COMPOUND_KEY_SERVICE_CLASS_EXEMPTIONS = ["CHEAPEST_SINGLE"];
    private static COMPOUND_KEY_FARE_CODE_EXEMPTIONS = ["ADVANCE", "CHEAPEST_SINGLE", "BUSINESS_ZONE"];
    private static COMPOUND_KEY_RESTRICTION_CODE_EXEMPTIONS = ["CHEAPEST_SINGLE"];
    private static GROUPSAVE = "UK_GROUPSAVE";

    public priceID: string;
    public promoHash: string;
    public totalPrice: DataModel.TotalPrice;
    public restrictiveFareClass: string;
    public holdExpiration: moment.Moment;
    public ticketableFares: AceTicketableFareCollection;
    public fareDescription: string;
    public ticketOptions: any;
    public serviceClass: DataModel.ServiceClass;

    /**
     * This price *could* be part of multiple fareClasses due to the facets, eg CHEAPEST_SINGLE and ADVANCE.
     * Hence this is an array, the will typically only have 1 item
     */
    public fareClasses: string[];
    public compoundRowKeys: string[];
    public ticketRulesHTML: any[];
    public ticketRulesText: string[];
    public rules: DataModel.Rule[] = [];
    public routeRule: any;
    public fareOrigin: string;
    public fareDestination: string;
    public fareOriginTerminals: string[];
    public fareDestinationTerminals: string[];
    public fareQualifiers: any;
    public appliedRailcardDiscount: string[];
    public hasGroupsave: boolean = false;
    public hasRailcard: boolean = false;
    public hasMTicket: boolean = false;
    public amenities: IAmenity[];
    public isSingleFare: boolean = false;
    public isNusPromoOverriden: boolean = false;
    public isOutgoingSingleFare: boolean = false;
    public isReturnSingleFare: boolean = false;
    public seatsAvailable: number = Infinity;
    public restrictionCode: string = "";
    public legReferences: string[];
    public legSolutions: AceLegSolution[];
    public reservationType: {
        outgoing: ReservationType;
        return: ReservationType;
    };

    constructor(data: DataModel.PointToPointPrice) {
        if (!isObject(data) || isEmpty(data)) {
            throw new Error("Constructor argument must be an object");
        }
        this._init(data);
    }

    public get hasStnr(): boolean {
        return this.ticketOptions.sct;
    }

    public static getById(id: string): AcePointToPointPrice {
        return AcePointToPointPrice._priceMap[id];
    }

    public static compoundToFareClass(key) {
        return AcePointToPointPrice._compoundKeyToFareClassMap[key];
    }

    public static compoundToServiceClass(key) {
        return AcePointToPointPrice._compoundKeyToServiceClassMap[key];
    }

    public static compoundToFareOrigin(key) {
        return AcePointToPointPrice._compoundKeyToFareOriginMap[key];
    }

    public getRefundAllowedRule(): DataModel.Rule {
        return this.rules.find(
            rule => rule.type === "REFUND_ALLOWED" && rule.priceType === "TICKET" && (rule.applicableOrderStatus === "CONFIRMED" || rule.applicableOrderStatus === "TICKETED")
        );
    }

    private _init(data: DataModel.PointToPointPrice) {
        this.priceID = data.priceID;
        this.totalPrice = data.totalPrice;
        this.promoHash = data.promoHash;
        this.restrictiveFareClass = data.restrictiveFareClass;
        this.holdExpiration = moment(data.holdExpiration);
        this.ticketableFares = new AceTicketableFareCollection(data.ticketableFares);
        this.fareDescription = this.ticketableFares.fareDescription.split(",")[0];
        this.ticketOptions = this.ticketableFares.ticketingOptions;

        if (data.facets) {
            const facetService = data.facets.find(facet => facet.keyname === "class");
            this.serviceClass = facetService != null ? facetService.value : null;
            this.fareClasses = data.facets.filter(facet => facet.keyname === "fareclass").map(facet => facet.value);

            const ticketLegs = data.facets.find(facet => facet.keyname === "ticketLegs");
            this.isSingleFare = ticketLegs != null ? ticketLegs.value === "SINGLE" : false;
            this.isNusPromoOverriden = this.getIsNusPromoOverrides(data.facets);
            this.appliedRailcardDiscount = flatten(data.facets.filter(facet => facet.keyname === "ace:fareQualifiers").map(facet => facet.value)).filter(
                facet => facet !== AcePointToPointPrice.GROUPSAVE
            );

            const seatsAvaiableFacet = data.facets.find(facet => facet.keyname === "seatsAvailable");
            this.seatsAvailable = (seatsAvaiableFacet && seatsAvaiableFacet.value) || Infinity;
        }

        this.legReferences = data.legReferences;
        this.legSolutions = this.legReferences.map(AceLegSolution.getById);
        this.amenities = this.ticketableFares.amenities;
        this.fareOrigin = this.ticketableFares.all[0].fareOrigin.code;
        this.fareDestination = this.ticketableFares.all[0].fareDestination.code;
        this.fareOriginTerminals = this.ticketableFares.all[0].fareOriginTerminals;
        this.fareDestinationTerminals = this.ticketableFares.all[0].fareDestinationTerminals;
        this.hasMTicket = this.ticketOptions["xvd"] === true || false;

        const tocRestriction = this.ticketableFares.all[0].fareTextRules
            .filter(rule => rule.facets != null && rule.facets.length)
            .find(rule => rule.facets.some(facet => facet.keyname === "route_valid"));

        if (tocRestriction) {
            this.restrictionCode = tocRestriction.description.split(" ").join("_").split(".").join("").toUpperCase();
        }

        let outgoingLeg = this.legSolutions.find(leg => leg != null && leg.isOutgoing === true);

        if (!outgoingLeg) {
            outgoingLeg = this.legSolutions.find(leg => leg != null && leg.isReturn === true);
        }

        if (this.isSingleFare) {
            // Singles only ever have 1 legReference. If we examine the code we can determine OUT/RTN
            const legSolutionRef = this.legReferences[0];
            this.isOutgoingSingleFare = legSolutionRef.indexOf("LS_1") === 0;
            this.isReturnSingleFare = legSolutionRef.indexOf("LS_2") === 0;
        }

        this.compoundRowKeys = this.fareClasses.map(fare => {
            let key = fare;

            // Cheapest singles are exempt from the service class key, in order to ensure they are presented as a single row
            // (occasionally a first class price is cheaper than standard class)
            if (!AcePointToPointPrice.COMPOUND_KEY_SERVICE_CLASS_EXEMPTIONS.includes(fare)) {
                key += "_" + this.serviceClass;
            }

            // Advance fares (and CHEAPEST_SINGLE/BUSINESS_ZONE which can be the same thing) include a lot of noise regarding the underlying
            // Ticketable fare codes. Hence, lets exempt them from the use of fare codes to makeup the compound key.
            if (!AcePointToPointPrice.COMPOUND_KEY_FARE_CODE_EXEMPTIONS.includes(fare)) {
                key += "_" + this.ticketableFares.compoundKey;
            }

            // Return fares need to be split out by where they begin from and also their price
            if (!this.isSingleFare) {
                key += "_" + this.fareOrigin;
                key += "_" + String(this.totalPrice.value);
            }

            if (this.restrictionCode !== "" && !AcePointToPointPrice.COMPOUND_KEY_RESTRICTION_CODE_EXEMPTIONS.includes(fare)) {
                key += "_" + this.restrictionCode;
            }

            AcePointToPointPrice._compoundKeyToFareClassMap[key] = fare; // This is needed in order to determine fare code from compound key
            AcePointToPointPrice._compoundKeyToServiceClassMap[key] = this.serviceClass;
            AcePointToPointPrice._compoundKeyToFareOriginMap[key] = this.fareOrigin;
            return key;
        });

        this.fareQualifiers = flatten(data.ticketableFares.map(fare => fare.fareQualifiers));
        this.hasRailcard = this.ticketableFares.all.some(fare => fare.hasRailcard === true);
        this.hasGroupsave = this.ticketableFares.all.some(fare => fare.hasGroupsave === true);
        this.reservationType = {
            outgoing: this._getReservationType(),
            return: this._getReservationType(true)
        };

        this.ticketableFares.all.forEach(fare => this.rules.push(...fare.rules));

        this.ticketRulesHTML = this.ticketableFares.all.map(fare => {
            return fare.fareTextRules.map(line => {
                return `<p>${line.description.replace(/(?:(http(s)?:\/\/[^\s]+))/m, '<a href="$1" target="_blank">$1</a>')}</p>`;
            });
        })[0];

        const refundAllowedRule = this.getRefundAllowedRule();
        if (refundAllowedRule) {
            let refundableText = `Ticket is refundable`;
            if (refundAllowedRule.penalty && refundAllowedRule.penalty.value !== 0) {
                refundableText += `, penalty: ${this._transformPrice(refundAllowedRule.penalty.value)}`;
            }
            this.ticketRulesHTML.push(`<p class="penalty-info">${refundableText}</p>`);
        }

        this.ticketRulesText = this.ticketRulesHTML.map(a => Utils.extractTextNodeFromHTML(a));

        this.routeRule = this.ticketRulesHTML.filter(rule => rule.indexOf("via") > -1 || rule.indexOf("valid") > -1 || rule.indexOf("Valid") > -1)[0];
        if (this.routeRule) {
            this.routeRule = Utils.extractTextNodeFromHTML(this.routeRule);
        }

        // Global reference for later convenience
        AcePointToPointPrice._priceMap[this.priceID] = this;
    }

    private getIsNusPromoOverrides(facets?: DataModel.FacetBase[]): boolean {
        if (!facets) {
            return false;
        }

        const nusPromoOverriden = facets.find(facet => facet.keyname === "nusPromoOverriden");
        return nusPromoOverriden ? nusPromoOverriden.value === true : false;
    }

    private _getReservationType(isReturn: boolean = false): ReservationType {
        const fareCodes = this.ticketableFares.all
            .map(fare => fare.fareCodes)
            .reduce((previous, next) => [...previous, ...next])
            .filter(fareCode => fareCode.travelSegmentIDRef.indexOf(isReturn ? "LS_2" : "LS_1") > -1);

        const reservationTypes: ReservationType[] = fareCodes.map(fare => fare.reservable as ReservationType);
        let priceReservationType;

        if (reservationTypes.indexOf(ReservationType.MANDATORY) > -1) {
            priceReservationType = ReservationType.MANDATORY;
        } else if (reservationTypes.indexOf(ReservationType.OPTIONAL) > -1) {
            priceReservationType = ReservationType.OPTIONAL;
        } else if (reservationTypes.indexOf(ReservationType.INCLUDED) > -1) {
            priceReservationType = ReservationType.INCLUDED;
        } else if (reservationTypes.indexOf(ReservationType.NOT_POSSIBLE) > -1) {
            priceReservationType = ReservationType.NOT_POSSIBLE;
        }

        return priceReservationType;
    }

    private _transformPrice(price: number) {
        return isNaN(price) ? `£0.00` : `£${parseFloat(`${price}`).toFixed(2)}`;
    }
}
