import { Observable, of } from "rxjs";
import { uniq } from "../../../shared/utilities/Utils";
import { IAceProjectionRow } from "../interfaces/IAceProjectionRow";
import { AceLegSolution } from "./ace-leg-solution.model";
import { AcePointToPointPrice } from "./ace-point-to-point-price.model";
import { AceSearchProjection } from "./ace-search-projection.model";
import { AceSearchResult } from "./ace-search-result.model";
import { AceSnapshot } from "./ace-snapshot";

export class AceDesktopSearchProjection extends AceSearchProjection {
    private static readonly CHEAPEST_SINGLE = "CHEAPEST_SINGLE";
    private static readonly FILTER_CLASS_CODES: string[] = ["CHEAPEST_SINGLE_THIRD_"];
    private static readonly FIRST_CLASS_CODES = {
        SINGLES: "CHEAPEST_SINGLE_FIRST",
        RETURNS: "CHEAPEST_RETURN_FIRST"
    };

    public fareClasses: string[];
    public serviceClasses: string[];
    public compoundRowKeyMap: { [key: string]: IAceProjectionRow };
    public rankedCompoundRow: IAceProjectionRow[];
    public singleRows: IAceProjectionRow[] = [];
    public returnRows: IAceProjectionRow[] = [];
    public recommendedRows: IAceProjectionRow[] = [];

    private selectedCompoundKeys: string[];
    private selectedPrices: AcePointToPointPrice[];
    private selectedLegSolutions: string[];

    constructor(searchResult: AceSearchResult, configSnapshot: AceSnapshot) {
        super(searchResult, configSnapshot);
        this.initDesktop(configSnapshot);
    }

    public reset() {
        this.selectedLegSolutions = [];
        this.selectedPrices = [];
    }

    public updateAndRetrieveSelections() {
        let selectedLegs = this.selectedLegSolutions;

        // If only one leg solution, and a return price selected, alway return both leg solutions for the price
        if (selectedLegs.length === 1 && !this.selectedPrices[0].isSingleFare) {
            selectedLegs = this.selectedPrices[0].legReferences;
        }

        return {
            prices: this.selectedPrices,
            legSolutions: selectedLegs.map(AceLegSolution.getById),
            fareClasses: uniq(this.selectedCompoundKeys.map(AcePointToPointPrice.compoundToFareClass)),
            serviceClasses: uniq(this.selectedCompoundKeys.map(AcePointToPointPrice.compoundToServiceClass))
        };
    }

    public buildDesktopRowGroups(): void {
        this.recommendedRows = this.getDesktopRecommendedRows();
        this.singleRows = this.getDesktopSingleRows();
        this.returnRows = this.getDesktopReturnRows();
    }

    public autoSelectFare(legIDs: string[] = [], priceIDs: string[] = []): Observable<{ legIDs: string[]; priceIDs: string[] }> {
        // get recommended fare rows based on cheapest based on facets
        let rows = this.getDesktopRecommendedRows();

        // if we have no recommended price rows get all other fares
        if (rows.length === 0) {
            rows = this.getDesktopNonRecommendedRows();
        }

        // if user has no current selections make find the cheapest and pre-select
        if (legIDs && legIDs.length === 0 && priceIDs && priceIDs.length === 0) {
            // get the cheapest row
            let cheapestRow = this.getCheapestRow(rows);

            // get cheapest selected row compoundkey
            let compoundKey = cheapestRow.compoundKey;

            // reference for selected legs
            let outboundPrice: AcePointToPointPrice;
            let returnPrice: AcePointToPointPrice;

            const hasSingleReturns = this.hasSingleReturnFares(cheapestRow.prices);
            const hasReturn = this.hasReturnFare(cheapestRow.prices);

            // if prices are single returns get the cheapest single for the return leg
            if (hasSingleReturns) {
                if (cheapestRow.prices.length === 1) {
                    cheapestRow = rows.filter(row => row.compoundKey === AceDesktopSearchProjection.CHEAPEST_SINGLE)[0];
                    compoundKey = cheapestRow.compoundKey;
                }

                outboundPrice = this.getCheapestSingleOutboundFare(cheapestRow.prices);
                returnPrice = this.getCheapestSingleReturnFare(cheapestRow.prices);
            } else {
                // if cheapestRow has returnFares
                if (hasReturn) {
                    outboundPrice = this.getCheapestReturnFare(cheapestRow.prices);
                } else {
                    outboundPrice = this.getCheapestSingleOutboundFare(cheapestRow.prices);
                }
            }
            // select fares
            if (outboundPrice) {
                this.applySelection(compoundKey, outboundPrice.legReferences[0]);
            }
            if (returnPrice) {
                this.applySelection(compoundKey, returnPrice.legReferences[0]);
            }
        } else {
            const allRows = rows.concat(this.getDesktopNonRecommendedRows());
            priceIDs
                .map(priceID =>
                    allRows
                        .map(row => ({
                            legReferences: row.prices.find(price => price.priceID === priceID && price.legReferences.every(legReference => legIDs.includes(legReference)))
                                ?.legReferences,
                            compoundKey: row.compoundKey
                        }))
                        .find(selection => Array.isArray(selection.legReferences) && selection.legReferences.length > 0)
                )
                .filter(selection => selection)
                .forEach(selection => selection.legReferences.forEach(legReference => this.applySelection(selection.compoundKey, legReference)));
        }

        const result = this.updateAndRetrieveSelections();
        if (result && result.legSolutions && result.legSolutions.length > 0 && result.prices && result.prices.length > 0) {
            return of({
                legIDs: result.legSolutions.map(l => l.legSolutionID),
                priceIDs: result.prices.map(p => p.priceID)
            });
        }
    }

    public getCheapestRow(rows: IAceProjectionRow[]): IAceProjectionRow {
        return rows.reduce((a, b) => (a.priceFrom.value < b.priceFrom.value ? a : b));
    }

    public isServiceCancelled(price: AcePointToPointPrice): boolean {
        return price.legSolutions.some(leg => leg.isCancelled);
    }

    public hasReturnFare(prices: AcePointToPointPrice[]): boolean {
        return prices.some(price => !price.isSingleFare);
    }

    public getCheapestReturnFare(prices: AcePointToPointPrice[]): AcePointToPointPrice {
        return prices
            .filter(price => !price.isSingleFare && !this.isServiceCancelled(price))
            .reduce((a, b) => (this._getValueOfThePriceWithPromotion(a) < this._getValueOfThePriceWithPromotion(b) ? a : b));
    }

    public hasSingleReturnFares(prices: AcePointToPointPrice[]): boolean {
        return prices != null ? prices.some(price => price.isReturnSingleFare) : false;
    }

    public getCheapestSingleOutboundFare(prices: AcePointToPointPrice[]): AcePointToPointPrice {
        return prices
            .filter(price => !price.isReturnSingleFare && !this.isServiceCancelled(price))
            .reduce((a, b) => (this._getValueOfThePriceWithPromotion(a) < this._getValueOfThePriceWithPromotion(b) ? a : b));
    }

    public getCheapestSingleReturnFare(prices: AcePointToPointPrice[]): AcePointToPointPrice {
        return prices
            .filter(price => price.isReturnSingleFare && !this.isServiceCancelled(price))
            .reduce((a, b) => (this._getValueOfThePriceWithPromotion(a) < this._getValueOfThePriceWithPromotion(b) ? a : b));
    }

    public getDesktopRecommendedRows(): IAceProjectionRow[] {
        return this.rankedCompoundRow.filter(r => r.rank > AceDesktopSearchProjection.FARE_CLASS_RANK_THRESHOLD && r.rank !== AceDesktopSearchProjection.FARE_CLASS_RANK_NEGATIVE);
    }

    public getDesktopNonRecommendedRows(): IAceProjectionRow[] {
        return this.rankedCompoundRow.filter(r => r.rank <= AceDesktopSearchProjection.FARE_CLASS_RANK_THRESHOLD && r.rank !== AceDesktopSearchProjection.FARE_CLASS_RANK_NEGATIVE);
    }

    public getDesktopSingleRows(): IAceProjectionRow[] {
        return this.getDesktopNonRecommendedRows().filter(r => r.isSingle);
    }

    public getDesktopReturnRows(): IAceProjectionRow[] {
        return this.getDesktopNonRecommendedRows().filter(r => !r.isSingle);
    }

    public isPriceSelected(price: AcePointToPointPrice | string): boolean {
        if (price == null) {
            return false;
        }

        if (typeof price !== "string") {
            price = price.priceID;
        }
        return this.selectedPrices.some(it => it.priceID === price);
    }

    public selectOppositeSingle(compoundKey, selectedIsOutgoing) {
        const oppositePredicate = (price: AcePointToPointPrice) => price.legReferences.length > 0 && price.legSolutions[0]?.isOutgoing !== selectedIsOutgoing;

        const reversePrice = this.compoundRowKeyMap[compoundKey].prices.find(oppositePredicate);

        if (reversePrice) {
            this.applySelection(compoundKey, reversePrice.legReferences[0]);
            return;
        }

        const alternateRow = this.recommendedRows.concat(this.getDesktopNonRecommendedRows()).find(row => row.isSingle && row.prices.find(oppositePredicate));
        const alternateCompoundKey = alternateRow?.compoundKey;
        const alternateReversePrice = alternateRow?.prices?.find(oppositePredicate);

        if (alternateCompoundKey && alternateReversePrice) {
            this.applySelection(alternateCompoundKey, alternateReversePrice.legReferences[0]);
        }
    }

    public applySelection(compoundKey: string, legSolutionId: string): void {
        const compoundKeyRow = this.compoundRowKeyMap[compoundKey];
        if (!compoundKeyRow) {
            throw new Error(`Invalid compund fare class key: "${compoundKey}"`);
        }

        const newLegSolution = this.allLegSolutions.find(leg => leg.legSolutionID === legSolutionId);
        if (!newLegSolution) {
            throw new Error(`Invalid leg solution ID: "${legSolutionId}"`);
        }

        // Ok, from here we have a valid row, with a price and a valid leg solution, so lets clear out selected prices and fares
        if (this.selectedPrices.length > 0) {
            if (compoundKeyRow.isSingle) {
                // If single is being selected, filter out any existing selected prices in the same direction or any existing return selections
                this.selectedPrices = this.selectedPrices.filter(price => price.isOutgoingSingleFare !== newLegSolution.isOutgoing);

                // Any returns in the current selection?
                const returnPriceToBeRemoved: AcePointToPointPrice = this.selectedPrices.find(price => price.isSingleFare === false);
                if (returnPriceToBeRemoved) {
                    // Deselect the price and clear out the leg solution selections
                    this.selectedPrices = this.selectedPrices.filter(price => price.priceID !== returnPriceToBeRemoved.priceID);
                    this.selectedLegSolutions = [];
                }
                this.selectedPrices = this.selectedPrices.filter(price => price.isSingleFare === true);
            } else {
                // If a return is being selected, clear it current selection. Only one return allowed (which is the selection about to be made)
                this.selectedPrices = [];
            }
        }

        // Leg solutions are pretty straightforward, just add it but ensure directions are unique
        if (this.selectedLegSolutions.length === 0) {
            // Empty? Easy, just add our selection
            this.selectedLegSolutions.push(newLegSolution.legSolutionID);
        } else {
            this.selectedLegSolutions = this.selectedLegSolutions
                .map(id => this.findLegSolutionById(id))
                .filter(leg => leg.isOutgoing !== newLegSolution.isOutgoing)
                .map(leg => leg.legSolutionID);

            this.selectedLegSolutions.push(legSolutionId);
        }

        // Compound key row
        // Let single to have multiple compound keys selected
        this.selectedCompoundKeys = [compoundKeyRow.compoundKey];

        // OK lets pick a price
        if (compoundKeyRow.isSingle) {
            // One single for each leg solution selected

            // Just find the cheapest for each leg solution (which is always the first item (already sorted)
            const cheapestPrice = this.compoundRowKeyMap[compoundKey].pricesByLegSolution[newLegSolution.legSolutionID].prices[0];
            if (cheapestPrice) {
                this.selectedPrices.push(cheapestPrice);
                this.compoundRowKeyMap[compoundKey].pricesByLegSolution[legSolutionId].renderPrice = [cheapestPrice];
            } else {
                // No price for this leg solution, deselect it
                this.selectedLegSolutions = this.selectedLegSolutions.filter(legID => legID !== legSolutionId);
            }

            if (this.searchResult.searchParams.inbound && this.selectedPrices.length === 1) {
                this.selectOppositeSingle(compoundKey, newLegSolution.isOutgoing);
            }
        } else {
            // One return...
            let matchingPrices;

            if (this.selectedLegSolutions.length === 1) {
                matchingPrices = this.compoundRowKeyMap[compoundKey].pricesByLegSolution[legSolutionId].prices;
            }
            if (this.selectedLegSolutions.length === 2) {
                matchingPrices = this.findPricesForAllLegSolutions(this.selectedLegSolutions, this.compoundRowKeyMap[compoundKey].pricesByLegSolution[legSolutionId].prices);
                if (matchingPrices.length === 0) {
                    // In this scenario we have two leg solutions selected and a compound key that doesn't have a single price that works with both leg solutions
                    // So lets pick the first and set the selected leg solutions to ones it is compatible with
                    matchingPrices = this.compoundRowKeyMap[compoundKey].pricesByLegSolution[legSolutionId].prices;
                    this.selectedLegSolutions = matchingPrices[0].legReferences;
                }
            }

            const matchingPrice = matchingPrices[0];
            this.selectedPrices.push(matchingPrice);
            uniq([...this.selectedLegSolutions, ...matchingPrice.legReferences]).forEach(tLegSolutionId => {
                this.compoundRowKeyMap[compoundKey].pricesByLegSolution[tLegSolutionId].renderPrice = [matchingPrice];
            });
        }
    }

    private groupPricesAsRowsBasedOnCompoundKey(prices): void {
        prices.forEach(price => {
            price.compoundRowKeys.forEach(key => {
                if (!this.compoundRowKeyMap[key]) {
                    this.compoundRowKeyMap[key] = {
                        compoundKey: key,
                        serviceClass: AcePointToPointPrice.compoundToServiceClass(key),
                        fareClass: AcePointToPointPrice.compoundToFareClass(key),
                        rank: AceSearchProjection.FARE_CLASS_RANK[AcePointToPointPrice.compoundToFareClass(key)]
                            ? AceSearchProjection.FARE_CLASS_RANK[AcePointToPointPrice.compoundToFareClass(key)]
                            : AceSearchProjection.DEFAULT_FARE_CLASS_RANK,
                        prices: [],
                        pricesByLegSolution: {},
                        isSingle: price.isSingleFare,
                        fareOrigin: AcePointToPointPrice.compoundToFareOrigin(key),
                        rowDescription: [],
                        priceFrom: {
                            value: 0,
                            currency: price.totalPrice.currency
                        }
                    };
                }
                this.compoundRowKeyMap[key].prices.push(price);
            });
        });
    }

    private groupCheapestFirstClassRow(fareClassName: string, fareClassesMap: { code: string }[]): void {
        if (fareClassesMap?.find(fare => fare.code === fareClassName)) {
            const projectionRowMap = Object.assign({}, this.compoundRowKeyMap);
            const firstClassRowMap = Object.entries(projectionRowMap)
                .filter(([key, _]) => key.includes(fareClassName))
                .map(([_, value]) => value);

            if (firstClassRowMap && firstClassRowMap.length > 0) {
                const firstClassRowKeyMap = Object.keys(projectionRowMap).filter(key => key.includes(fareClassName));
                firstClassRowKeyMap.forEach(key => this.filterRow(key));

                firstClassRowMap[0].prices = firstClassRowMap.map(row => row.prices.map(price => price)).reduce((a, b) => a.concat(b), []);
                this.compoundRowKeyMap[firstClassRowKeyMap[0]] = firstClassRowMap[0];
            }
        } else {
            this.filterRow(fareClassName);
        }
    }

    private presentFirstClassRowByCheaperPrice(legSolutions: AceLegSolution[]): void {
        const LEG_CODES = { SINGLES: "LS_1", RETURNS: "LS_2" };
        const hasReturnLeg = legSolutions != null && legSolutions.some(leg => leg.legSolutionID.includes(LEG_CODES.RETURNS));

        if (hasReturnLeg && this.compoundRowKeyMap != null) {
            const firstClassSinglesPrices: AcePointToPointPrice[] = [].concat.apply(
                [],
                ...Object.keys(this.compoundRowKeyMap)
                    .filter(key => key.includes(AceDesktopSearchProjection.FIRST_CLASS_CODES.SINGLES))
                    .map(key => this.compoundRowKeyMap[key])
                    .map(row => row.prices)
            );

            const outboundSinglePrice = [...firstClassSinglesPrices]
                .filter(price => price.legSolutions.some(leg => leg.legSolutionID.includes(LEG_CODES.SINGLES)))
                .sort((a, b) => (a.totalPrice.value > b.totalPrice.value ? 1 : -1))[0];

            const inboundSinglePrice = [...firstClassSinglesPrices]
                .filter(price => price.legSolutions.some(leg => leg.legSolutionID.includes(LEG_CODES.RETURNS)))
                .sort((a, b) => (a.totalPrice.value > b.totalPrice.value ? 1 : -1))[0];

            const firstClassReturnsPrices: AcePointToPointPrice[] = [].concat.apply(
                [],
                ...Object.keys(this.compoundRowKeyMap)
                    .filter(key => key.includes(AceDesktopSearchProjection.FIRST_CLASS_CODES.RETURNS))
                    .map(key => this.compoundRowKeyMap[key])
                    .map(row => row.prices.sort((a, b) => (a.totalPrice.value > b.totalPrice.value ? 1 : -1)))
            );

            if (firstClassReturnsPrices != null && firstClassReturnsPrices.length && !this.searchResult.isOpenReturn) {
                const cheapestPrice = [
                    { key: "SINGLES", value: Number([outboundSinglePrice, inboundSinglePrice].reduce((a, b) => a + b.totalPrice.value, 0).toFixed(2)) },
                    { key: "RETURNS", value: Number(firstClassReturnsPrices[0].totalPrice.value.toFixed(2)) }
                ].reduce((a, b) => (a.value >= b.value ? a : b));

                this.filterRow(AceDesktopSearchProjection.FIRST_CLASS_CODES[cheapestPrice.key]);
            } else if (this.searchResult.isOpenReturn) {
                this.filterRow(AceDesktopSearchProjection.FIRST_CLASS_CODES.SINGLES);
            } else {
                this.filterRow(AceDesktopSearchProjection.FIRST_CLASS_CODES.RETURNS);
            }
        }
    }

    private initDesktop(configSnapshot: AceSnapshot): void {
        if (this._prices != null) {
            // The new hotness. Group prices into rows based on a compound key
            this.compoundRowKeyMap = {};

            const fareClassesMap: { code: string }[] = configSnapshot.get("maps.fareClasses");
            this.groupPricesAsRowsBasedOnCompoundKey(this._prices);
            this.groupCheapestFirstClassRow(AceDesktopSearchProjection.FIRST_CLASS_CODES.SINGLES, fareClassesMap);
            this.groupCheapestFirstClassRow(AceDesktopSearchProjection.FIRST_CLASS_CODES.RETURNS, fareClassesMap);
            this.filterRow(AceDesktopSearchProjection.FILTER_CLASS_CODES[0]);
            this.presentFirstClassRowByCheaperPrice(this.allLegSolutions);

            // Organise the prices into leg solutions to make things easier later
            Object.entries(this.compoundRowKeyMap).forEach(([key, projectionRow]) => {
                this.allLegSolutions.forEach(legSolution => {
                    const ps = projectionRow.prices
                        .filter(price => price.legSolutions.includes(legSolution))
                        .sort((a, b) => (this._getValueOfThePriceWithPromotion(a) < this._getValueOfThePriceWithPromotion(b) ? -1 : 1));

                    this.compoundRowKeyMap[key].pricesByLegSolution[legSolution.legSolutionID] = {
                        prices: ps,
                        renderPrice: ps.slice(0, 1) // This is set later by the update method. Array makes the templating easier
                    };

                    // Calculate cheapest per compound row...
                    if (projectionRow.isSingle) {
                        const outPrices = this.compoundRowKeyMap[key].prices.filter(price => price.isOutgoingSingleFare === true);
                        const returnPrices = this.compoundRowKeyMap[key].prices.filter(price => price.isOutgoingSingleFare === false);

                        this.compoundRowKeyMap[key].priceFrom.value =
                            outPrices.length > 0
                                ? Math.min.apply(
                                      Math,
                                      outPrices.map(p => this._getValueOfThePriceWithPromotion(p))
                                  )
                                : 0;
                        if (returnPrices.length > 0) {
                            this.compoundRowKeyMap[key].priceFrom.value =
                                this.compoundRowKeyMap[key].priceFrom.value +
                                Math.min.apply(
                                    Math,
                                    returnPrices.map(p => this._getValueOfThePriceWithPromotion(p))
                                );
                        }
                    } else {
                        this.compoundRowKeyMap[key].priceFrom.value = Math.min.apply(
                            Math,
                            this.compoundRowKeyMap[key].prices.map(p => this._getValueOfThePriceWithPromotion(p))
                        );
                    }

                    // Add the row descriptions
                    const price = this.compoundRowKeyMap[key].prices[0];
                    const multipleStartLocations = uniq(this.outgoingLegSolutions.map(l => l.originTravelPoint.code)).length > 1;
                    const multipleEndLocations = uniq(this.outgoingLegSolutions.map(l => l.destinationTravelPoint.code)).length > 1;
                    let representativeLeg = price.legSolutions.find(leg => leg.isOutgoing === true);

                    if (!representativeLeg) {
                        representativeLeg = price.legSolutions.find(leg => leg.isOutgoing === false);
                    }

                    if (price && price.routeRule) {
                        for (const rule of price.ticketRulesText) {
                            this.compoundRowKeyMap[key].rowDescription.push(rule);
                        }
                    }

                    if (price.fareOriginTerminals.length === 0 && multipleStartLocations) {
                        this.compoundRowKeyMap[key].rowDescription.push(`Journey begins at ${representativeLeg.originTravelPoint.code}`);
                    }
                    if (price.fareDestinationTerminals.length === 0 && multipleEndLocations) {
                        this.compoundRowKeyMap[key].rowDescription.push(`Journey terminates at ${representativeLeg.destinationTravelPoint.code}`);
                    }
                    if (price.fareOriginTerminals.length > 0) {
                        this.compoundRowKeyMap[key].rowDescription.push(`Fare allows journey start from ${price.fareOriginTerminals.join(", ")}`);
                    }
                    if (price.fareDestinationTerminals.length > 0) {
                        this.compoundRowKeyMap[key].rowDescription.push(`Fare allows journey termination at ${price.fareDestinationTerminals.join(", ")}`);
                    }
                });
            });

            // Store a ranked row for convenience - rank[desc], fareClass[asc], serviceClass[desc]
            this.rankedCompoundRow = Object.values(this.compoundRowKeyMap)
                .concat()
                .sort((a, b) => {
                    return b["rank"] - a["rank"] || (a["fareClass"] > b["fareClass"] ? 1 : -1) || (b["serviceClass"] > a["serviceClass"] ? 1 : -1);
                });

            this.buildDesktopRowGroups();
            this.reset();
        }
    }

    private filterRow(compoundRowKey: string): void {
        Object.keys(this.compoundRowKeyMap).forEach(key => {
            if (key.includes(compoundRowKey)) {
                delete this.compoundRowKeyMap[key];
            }
        });
    }
}
