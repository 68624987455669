<div class="numerical-stepper">
    <div
        #sliderElement
        tabindex="0"
        class="numerical-stepper-body"
        role="slider"
        [attr.aria-valuemin]="min"
        [attr.aria-valuemax]="max"
        [attr.aria-valuenow]="value"
        [attr.aria-valuetext]="currentValueLabel"
        [attr.aria-label]="ariaLabel"
        (keydown.arrowleft)="stepDown()"
        (keydown.arrowright)="stepUp()"
    >
        <div class="subtract" aria-hidden="true">
            <ace-button class="background-circle left" [class.disabled]="min === value" tabindex="-1" (click)="stepDown()">
                <svg viewBox="0 0 24 24" aria-hidden="true">
                    <title>minus</title>
                    <use xlink:href="#minus" />
                </svg>
                <span class="sr-only">Decrease</span>
            </ace-button>
        </div>
        <div aria-hidden="true">
            <ng-content select="numerical-stepper-label"></ng-content>
        </div>
        <div class="add" aria-hidden="true">
            <ace-button class="background-circle right" [class.disabled]="max === value" tabindex="-1" (click)="stepUp()">
                <svg viewBox="0 0 24 24" aria-hidden="true">
                    <title>plus</title>
                    <use xlink:href="#plus" />
                </svg>
                <span class="sr-only">Increase</span>
            </ace-button>
        </div>
    </div>
</div>
