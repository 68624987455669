import { Injectable } from "@angular/core";
import { CanActivate, Router } from "@angular/router";
import { Observable } from "rxjs";
import { tap } from "rxjs/operators";
import { ConfigService } from "../../services/config.service";

@Injectable()
export class SeasonTicketsEnabledGuard implements CanActivate {
    constructor(private router: Router, private configService: ConfigService) {}

    public canActivate(): Observable<boolean> {
        return this.configService.hasFeature("isSeasonTicketEnabled").pipe(
            tap(isSeasonTicketEnabled => {
                if (isSeasonTicketEnabled === false) {
                    this.router.navigate(["/"]);
                }
            })
        );
    }
}
