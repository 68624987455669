import moment from "moment";
import { environment } from "../../../../environments/environment";
import { CapacityIndicator, CapacityIndicatorTypes } from "../api/capacity-indicator";
import { get, isEmpty } from "../../../shared/utilities/Utils";

export class AceTravelSegment implements DataModel.TravelSegment {
    // TODO: Fill out this map with all travel segment types
    public static typeMap = {
        TRAIN: "Train",
        FERRY: "Ferry",
        BUS: "Bus"
    };

    public travelSegmentID: string;
    public isTrain: boolean = false;
    public isBus: boolean = false;
    public isPedestrian: boolean = false;
    public isTransfer: boolean = false;
    public isLondonUnderground: boolean = false;
    public isLondonOverground: boolean = false;
    public isFerry: boolean = false;
    public isUnknown: boolean = true;
    public arrivalDateMoment: moment.Moment;
    public arrivalDateTime: string;
    public delayedArrivalDateTime: moment.Moment;
    public delayedDepartureDateTime: moment.Moment;
    public arrivalTimestamp: number;
    public departureDateMoment: moment.Moment;
    public departureDateTime: string;
    public departureTimestamp: number;
    public isTocTravelSegment: boolean;
    public type: string;
    public rule: string;
    public typeLabel: string;
    public originTravelPoint: DataModel.TravelPoint;
    public destinationTravelPoint: DataModel.TravelPoint;
    public description: string;
    public carrierCode: string;
    public amenities: any[];
    public carrierName: string;
    public duration: string;
    public complexDuration: { label: string; shortLabel: string; value: number };
    public designator: string;
    public marketingCarrier: string;
    public operatingCarrier: string;
    public supplierEquipmentType: string;
    public onTimePercentage: number;
    public crossBorderInfo: boolean;
    public supplierRecordLocator: string;
    public equipmentType: DataModel.EquipmentType;
    public carbonOffset: number;
    public viaTravelPoints: DataModel.TravelPoint[];
    public marketingServiceName: string;
    public marketingInformation: DataModel.MarketingInformation;
    public sequence: number;
    public legGrouping: string;
    public seatAssignment: ISegmentSeat[];
    public capacityIndicator: CapacityIndicator;

    constructor(data: DataModel.TravelSegment, revisions?: DataModel.Revision[]) {
        if (isEmpty(data)) {
            throw new Error("TravelSegment constructor argument is required");
        }

        this._init(data);

        if (revisions && revisions.length !== 0) {
            this._initRevisions(revisions);
        }
    }

    public getTravelSegmentConnectionTime(start: AceTravelSegment, end: AceTravelSegment) {
        if (end === undefined) {
            return;
        }
        return "Connection time " + end.departureDateMoment.diff(start.arrivalDateMoment, "minutes");
    }

    private _init(data: DataModel.TravelSegment): void {
        this.travelSegmentID = data.travelSegmentID;
        this.amenities = data.facets;
        this.departureDateTime = data.departureDateTime;
        this.departureDateMoment = moment(data.departureDateTime);
        this.departureTimestamp = this.departureDateMoment.unix();
        this.arrivalDateTime = data.arrivalDateTime;
        this.arrivalDateMoment = moment(data.arrivalDateTime);
        this.arrivalTimestamp = this.arrivalDateMoment.unix();
        this.originTravelPoint = data.originTravelPoint;
        this.destinationTravelPoint = data.destinationTravelPoint;
        this.isTocTravelSegment = data.marketingCarrier === environment.tocMarketingCarrierName;
        this.type = data.type;
        this.typeLabel = AceTravelSegment.typeMap[this.type] ? AceTravelSegment.typeMap[this.type] : this.type;
        this.description = data.marketingServiceName;
        this.carrierCode = data.marketingCarrier;
        this.carrierName = data.marketingCarrier;
        this.marketingCarrier = data.marketingCarrier;
        this.operatingCarrier = data.operatingCarrier;
        this.designator = data.designator;
        this.supplierEquipmentType = data.supplierEquipmentType;
        this.duration = data.duration;
        this.onTimePercentage = data.onTimePercentage;
        this.crossBorderInfo = data.crossBorderInfo;
        this.supplierRecordLocator = data.supplierRecordLocator;
        this.equipmentType = data.equipmentType;
        this.carbonOffset = data.carbonOffset;
        this.viaTravelPoints = data.viaTravelPoints;
        this.marketingServiceName = data.marketingServiceName;
        this.marketingInformation = data.marketingInformation;
        this.sequence = data.sequence;
        this.legGrouping = data.legGrouping;

        const avgTrainLoading: DataModel.AvgTrainLoading = data.avgTrainLoading;
        if (avgTrainLoading && avgTrainLoading.originName) {
            const { originName } = avgTrainLoading;
            this.capacityIndicator = {
                label: originName,
                icon: <CapacityIndicatorTypes>originName.toLowerCase().split(" ").join("-")
            };
        }

        // Type
        const serviceCode = get(data, "marketingInformation.serviceCode");
        if (data.type === "TRAIN") {
            this.isTrain = true;
            this.isUnknown = false;
        }

        if (data.type === "FERRY") {
            this.isFerry = true;
            this.isUnknown = false;
        }

        if (data.type === "BUS" || get(data, "equipmentType.code") === "BUS") {
            this.isBus = true;
            this.isUnknown = false;
        }

        if (data.type === "TRANSFER") {
            this.isTransfer = true;
        }

        if (serviceCode === "LonUnderground") {
            this.isLondonUnderground = true;
            this.typeLabel = "London Underground";
            this.isUnknown = false;
        }

        if (serviceCode === "LonOverground") {
            this.isLondonOverground = true;
            this.typeLabel = "London Overground";
            this.isUnknown = false;
        }

        if (this.isTransfer && get(data, "equipmentType.code") === "PED") {
            this.isPedestrian = true;
            this.typeLabel = "Walking";
            this.isUnknown = false;
        }

        // Duration
        const duration = this.arrivalDateMoment.diff(this.departureDateMoment, "minute");
        let durationLabel = "";
        if (duration < 60) {
            durationLabel = duration + " minute" + (duration === 1 ? "" : "s");
        }

        if (duration === 60) {
            durationLabel = "1 hour";
        }

        if (duration > 60) {
            const hours = Math.floor(duration / 60);
            const remainder = duration - hours * 60;
            durationLabel = hours + " hour" + (hours === 1 ? "" : "s") + " " + remainder + " minute" + (remainder === 1 ? "" : "s");
        }
        this.complexDuration = {
            label: durationLabel,
            shortLabel: durationLabel.replace(" hour", "h").replace(" minute", "m").replace("hs", "h").replace("ms", "m"),
            value: duration
        };
    }

    private _initRevisions(revisions: DataModel.Revision[]): void {
        const departureRevision = revisions.find(revision => revision.revisedDepartureDateTime != null);
        const arrivalRevision = revisions.find(revision => revision.revisedArrivalDateTime != null);

        if (departureRevision) {
            this.delayedDepartureDateTime = moment(departureRevision.revisedDepartureDateTime);
        }

        if (arrivalRevision) {
            this.delayedArrivalDateTime = moment(arrivalRevision.revisedArrivalDateTime);
        }
    }
}

interface ISegmentSeat {
    travelSeg: string;
    seats: any;
}
