import { format, set, setDate, setISODay, subDays, addMonths, getISODay, isToday, addDays, isSameMonth, isBefore, isAfter } from "date-fns";
import { DATE_TIME_STRING_FORMAT } from "../constants/date";
import { CalendarDay, CalendarDayHeading } from "../models/entities/calendar";

export class CalendarMonthCard {
    public readonly id: string;
    public readonly headings: CalendarDayHeading[] = [];
    public label: string;
    public date: Date;
    public isSelected: boolean = false;
    public weeks: CalendarDay[][] = [];
    private minDate: Date;
    private maxDate: Date;

    constructor(date: Date, minDate?: Date, maxDate?: Date, isSelected?: boolean) {
        if (!date) {
            date = new Date();
        }

        this.date = date;
        this.minDate = this.scrapTime(minDate);
        this.maxDate = this.scrapTime(maxDate);
        this.isSelected = isSelected;
        this.label = format(date, "MMMM");
        this.id = this.label.toLocaleLowerCase();
        this.initialise();
    }

    private initialise() {
        this.generateHeadings();
        this.generateWeeks();
    }

    private generateHeadings() {
        let newDate: Date;

        for (let i = 1; i <= 7; i++) {
            newDate = setISODay(this.date, i);

            this.headings.push({
                shortName: format(newDate, "EEEEEE"),
                fullName: format(newDate, "EEEE")
            });
        }
    }

    private scrapTime(date: Date): Date {
        return set(date, { hours: 0, minutes: 0, seconds: 0, milliseconds: 0 });
    }

    private generateWeeks() {
        const firstDayOfTheMonth: Date = setDate(this.scrapTime(this.date), 1);
        const lastDayOfTheMonth: Date = subDays(addMonths(firstDayOfTheMonth, 1), 1);

        // find out what day of the week the first day of the month is and calculate difference x from the first day of the week
        const dayOfTheWeekDiff = Math.abs(1 - getISODay(firstDayOfTheMonth));

        // set beginning to the 1st day of the month minus the difference x
        let iterativeDay = subDays(firstDayOfTheMonth, dayOfTheWeekDiff);

        // iteratively create weeks
        do {
            const week: CalendarDay[] = [];
            // fill it with 7 days each day being
            for (let i = 0; i < 7; i++) {
                const day: CalendarDay = {
                    dateAsString: format(iterativeDay, DATE_TIME_STRING_FORMAT),
                    label: format(iterativeDay, "d"),
                    isEnabled: this.shouldDayBeEnabled(iterativeDay, firstDayOfTheMonth, lastDayOfTheMonth),
                    isToday: isToday(iterativeDay)
                };

                week.push(day);

                iterativeDay = addDays(iterativeDay, 1);
            }

            this.weeks.push(week);
        } while (isSameMonth(iterativeDay, this.date));
    }

    private shouldDayBeEnabled(day: Date, firstDayOfTheMonth: Date, lastDayOfTheMonth: Date) {
        if (this.minDate) {
            if (isBefore(day, this.minDate)) {
                return false;
            }
        }

        if (this.maxDate) {
            if (isAfter(day, this.maxDate)) {
                return false;
            }
        }

        if (isBefore(day, firstDayOfTheMonth) || isAfter(day, lastDayOfTheMonth)) {
            return false;
        }

        return true;
    }
}
