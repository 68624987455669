import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from "@angular/core";
import { format, isSameHour, isSameMinute } from "date-fns";
import { TravelDepartOptions } from "../../classes/TravelDepartOptions";
import { TimeHelper } from "../../utilities/TimeHelper";
import { ISelectOption } from "../select/select.component";

@Component({
    selector: "ace-travel-time-option",
    templateUrl: "travel-time-option.component.html",
    styleUrls: ["travel-time-option.component.scss"]
})
export class TravelTimeOptionComponent implements OnChanges {
    @Input() public disabled: boolean = false;
    @Input() public theme: string = "";
    @Input() public departureOption: string = TravelDepartOptions.DEPARTURE;
    @Input() public timeOption: Date = new Date();
    @Input() public directionId: string;
    @Output() public departureOptionChange: EventEmitter<ISelectOption> = new EventEmitter<ISelectOption>(null);
    @Output() public timeOptionChange: EventEmitter<Date> = new EventEmitter<Date>(null);
    public TRAVEL_OPTIONS = TravelDepartOptions;
    public departureOptions: ISelectOption[] = [];
    public timeOptions: ISelectOption[] = [];

    constructor() {
        this.initialiseTimeOptions();
        this.initialiseDepartureOptions();
    }

    public ngOnChanges(changes: SimpleChanges) {
        if (changes.departureOption && changes.departureOption.currentValue) {
            this.departureOptions.map(item => (item.selected = item.value === changes.departureOption.currentValue));
        }

        if (changes.timeOption && changes.timeOption.currentValue) {
            const currentTimeOption = changes.timeOption.currentValue as Date;
            const tTimeLabel = format(currentTimeOption, "HH:mm");

            this.timeOptions.map(item => (item.selected = item.label === tTimeLabel));
        }
    }

    public onDepartureOptionSelected(option: ISelectOption) {
        this.departureOptionChange.emit(option.value);
    }

    public onTimeOptionSelected(option: ISelectOption) {
        this.timeOptionChange.emit(option.value);
    }

    private initialiseTimeOptions() {
        TimeHelper.generateTimeOptions().forEach(option => {
            this.timeOptions.push({
                label: format(option, "HH:mm"),
                value: option,
                selected: isSameHour(option, this.timeOption) && isSameMinute(option, this.timeOption)
            });
        });
    }

    private initialiseDepartureOptions() {
        this.departureOptions = [
            { label: "Depart after", value: TravelDepartOptions.DEPARTURE, selected: true },
            { label: "Arrive before", value: TravelDepartOptions.ARRIVAL, selected: false }
        ];
    }
}
