import { Injectable } from "@angular/core";
import { Store } from "@ngxs/store";
import moment from "moment";
import { CookieService } from "ngx-cookie-service";
import { BehaviorSubject, combineLatest, from, Observable, of, ReplaySubject, throwError } from "rxjs";
import { catchError, map, mergeMap, take, tap } from "rxjs/operators";
import { HttpClient } from "@angular/common/http";
import { AceSearchResult } from "../shared/models/ace/ace-search-result.model";
import { SearchPayloadCriteria } from "../shared/models/criterias/search-payload-criteria";
import { TicketSearchPayloadCriteria } from "../shared/models/criterias/ticket-search-payload-criteria";
import { IQueryParams } from "../shared/models/interfaces/IQueryParams";
import { SilverRailRailcard } from "../shared/models/api/railcard";
import { UpdateSearchCriteria } from "../shared/state/qtt/qtt.actions";
import { RailcardsState } from "../shared/state/railcards/railcards.state";
import { SearchTypes } from "../shared/models/entities/journey-search";
import { initialState, QttState } from "../shared/state/qtt/qtt.state";
import { CACHE_RESULT_DURATION, SEARCH_CACHE_KEY } from "../shared/constants/cache-keys";
import { findPromotedService } from "../search/utilities/filters";
import { PromotedLegSolutionsType } from "../shared/models/entities/promoted-service";
import { promotedServicesConverter } from "../shared/converters/promoted-services.converter";
import { AceSaveUserRecentStaionRequestModel } from "../shared/models/ace/ace-save-user-recent-station-request";
import { AceLegSolution } from "../shared/models/ace/ace-leg-solution.model";
import { AcePointToPointPrice } from "../shared/models/ace/ace-point-to-point-price.model";
import { AceCoreApiService } from "./ace-core-api.service";
import { ConfigService } from "./config.service";
import { GoogleAnalyticsService } from "./google-analytics.service";
import { LocalCacheService } from "./local-cache.service";
import { RouteValidatorService } from "./route-validator.service";
import { environment } from "./../../environments/environment";

@Injectable({
    providedIn: "root"
})
export class SearchService {
    public railcards$: Observable<SilverRailRailcard[]>;
    public isSearchInProgress$: BehaviorSubject<boolean>;
    public searchCriteria$: ReplaySubject<SearchPayloadCriteria>;
    public searchResults$: ReplaySubject<AceSearchResult>;
    public currentPrice$: BehaviorSubject<any>;
    public isSelectionAvailable$: BehaviorSubject<boolean> = new BehaviorSubject(false);
    public isSearchAvailable$: BehaviorSubject<boolean> = new BehaviorSubject(false);
    public nextPromoLegSolutions$: ReplaySubject<Record<"promotedOutLeg" | "promotedRtnLeg", PromotedLegSolutionsType>> = new ReplaySubject(1);
    public nextSearchCriteria$: ReplaySubject<SearchPayloadCriteria> = new ReplaySubject(1);
    private lastCacheHash: string;

    constructor(
        private aceCoreApi: AceCoreApiService,
        private cookieService: CookieService,
        private cache: LocalCacheService,
        private config: ConfigService,
        private routeValidatorService: RouteValidatorService,
        private analyticsService: GoogleAnalyticsService,
        private store: Store,
        private http: HttpClient
    ) {
        this.isSearchInProgress$ = new BehaviorSubject(false);
        this.searchResults$ = new ReplaySubject<AceSearchResult>(1);
        this.currentPrice$ = new BehaviorSubject({});
        this.searchCriteria$ = new ReplaySubject<SearchPayloadCriteria>(1);
        this.searchResults$.pipe(take(1)).subscribe(() => this.isSearchAvailable$.next(true));
        this.railcards$ = this.store.select(state => RailcardsState.railcards(state.railcards));
    }

    public searchResultsCall(searchParams: SearchPayloadCriteria, searchReq: string) {
        this.searchCriteria$.next(searchParams);
        this.isSearchInProgress$.next(true);
        this.lastCacheHash = searchParams.generateHash();

        return this.cache
            .observable(
                SEARCH_CACHE_KEY,
                of(1).pipe(
                    mergeMap(() => this.aceCoreApi.call(searchReq, searchParams.generatePayloadData())),
                    catchError(res => {
                        console.error(res);
                        this.isSearchInProgress$.next(false);
                        return throwError(() => res);
                    })
                ),
                CACHE_RESULT_DURATION,
                this.lastCacheHash
            )
            .pipe(map(data => new AceSearchResult(data.id, data.data, this.lastCacheHash, this.config.configSnapshot, searchParams.params)));
    }

    public searchLaterResultsCall(searchParams: SearchPayloadCriteria, searchReq: string) {
        const { tocMarketingCarrierName } = environment;
        this.isSearchInProgress$.next(true);

        const carrierRestrictions = {
            type: "PERMITTED",
            carrier: {
                value: tocMarketingCarrierName
            }
        };

        return of(1)
            .pipe(
                mergeMap(() => this.aceCoreApi.call(searchReq, searchParams.generatePayloadData(carrierRestrictions, false))),
                catchError(res => {
                    console.error(res);
                    this.isSearchInProgress$.next(false);
                    return throwError(() => res);
                })
            )
            .pipe(
                map(data => data.data),
                map(data => promotedServicesConverter(<DataModel.PointToPointShoppingResponse>data))
            );
    }

    public searchResults(searchParams: SearchPayloadCriteria, searchReq: string, searchTypeSource: string) {
        return this.searchResultsCall(searchParams, searchReq)
            .pipe(take(1))
            .pipe(
                tap(currentResults => {
                    this.searchResults$.next(currentResults);
                    this.isSearchInProgress$.next(false);
                    this.isSelectionAvailable$.next(false);
                    this.analyticsService.searchAnalytics(searchParams.params, searchTypeSource);
                })
            );
    }

    public promotedSearchResults(currentSearchParams: SearchPayloadCriteria, nextSearchCriteria: SearchPayloadCriteria, searchReq: string, searchTypeSource: string) {
        return combineLatest([this.searchResultsCall(currentSearchParams, searchReq).pipe(take(1)), this.searchLaterResultsCall(nextSearchCriteria, searchReq).pipe(take(1))]).pipe(
            tap(([currentResults, nextResults]) => {
                const { outgoingResults, returnResults } = nextResults;
                this.nextSearchCriteria$.next(nextSearchCriteria);
                this.nextPromoLegSolutions$.next(findPromotedService(currentSearchParams, currentResults, { outgoingResults, returnResults }));
                this.searchResults$.next(currentResults);
                this.isSearchInProgress$.next(false);
                this.isSelectionAvailable$.next(false);
                this.analyticsService.searchAnalytics(currentSearchParams.params, searchTypeSource);
            })
        );
    }

    public exchangeSearchResults(searchParams: any, searchReq: string, searchTypeSource: string = "", context: string, direction: string) {
        this.isSearchInProgress$.next(true);

        return of(1)
            .pipe(
                mergeMap(() => this.aceCoreApi.call(searchReq, searchParams, searchTypeSource, context)),
                catchError(res => {
                    console.error(res);
                    this.isSearchInProgress$.next(false);
                    return throwError(() => res);
                })
            )
            .pipe(
                map(data => {
                    return new AceSearchResult(data.id, data.data, this.lastCacheHash, this.config.configSnapshot, searchParams.exchangeSets[0].exchangeShoppingQuery, direction);
                })
            )
            .pipe(
                tap(currentResults => {
                    this.searchResults$.next(currentResults);
                    this.isSearchInProgress$.next(false);
                    this.isSelectionAvailable$.next(false);
                })
            );
    }

    public restoreFromCache(cacheHash) {
        if (cacheHash === this.lastCacheHash) {
            return this.searchResults$;
        }

        this.isSearchInProgress$.next(true);

        const params = this.store.selectSnapshot(state => QttState.params(state.qtt));

        return this.cache.getValue(SEARCH_CACHE_KEY, cacheHash).pipe(
            map((data: any) => {
                if (!data) {
                    throw new Error("Your search results have expired");
                }
                return new AceSearchResult(data.id, data.data, cacheHash, this.config.configSnapshot, params);
            }),
            tap(data => {
                this.searchResults$.next(data);
                this.isSearchInProgress$.next(false);
                this.isSelectionAvailable$.next(false);
            })
        );
    }

    public updateParams(searchParams: IQueryParams): void {
        const { origin, startDate } = searchParams;

        if (origin === undefined) {
            return;
        }

        const currentSearchType = startDate ? "season" : "standard";
        const initialSearchType = startDate ? "standard" : "season";

        this.store.dispatch(
            new UpdateSearchCriteria({
                [currentSearchType]: searchParams,
                [initialSearchType]: initialState.params[initialSearchType]
            })
        );
        this.handleAnalyticalParams(searchParams);
        this.searchCriteria$.next(new TicketSearchPayloadCriteria(searchParams, this.store));
    }

    public setDefaultParams(params: IQueryParams, searchType: SearchTypes) {
        if (searchType === "STANDARD") {
            return Object.assign(
                {
                    origin: params.origin,
                    destination: params.destination,
                    outboundTime: moment().format("YYYY-MM-DDTHH:mm:ss"),
                    outboundTimeType: "DEPARTURE",
                    adults: 1,
                    children: 0,
                    railcards: "[]"
                },
                params
            );
        }

        if (searchType === "SEASON") {
            return Object.assign(
                {
                    origin: params.origin,
                    destination: params.destination,
                    startDate: moment().format("YYYY-MM-DD"),
                    adults: 1,
                    children: 0
                },
                params
            );
        }
    }

    public clearSearch(): void {
        this.cache.expire(SEARCH_CACHE_KEY);
    }

    private handleAnalyticalParams(params: IQueryParams): void {
        params = this.routeValidatorService.validateCjAffiliate(params);

        if (params.cjevent) {
            this.cookieService.set("cjevent", `cjevent-${params.cjevent}`, moment().add(1, "years").toDate());
        }
    }

    public saveUserSearchObject(searchParams: AceSaveUserRecentStaionRequestModel, searchReq: string) {
        return this.aceCoreApi.call(searchReq, searchParams);
    }

    public updatePrice(price: AcePointToPointPrice) {
        this.currentPrice$.next(price);
    }
}
