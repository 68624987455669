import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { ReactiveFormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { ModalModule } from "../modal/modal.module";
import { SharedModule } from "../shared/shared.module";
import { DebugUiComponent } from "./components/debug-ui/debug-ui.component";
import { FooterComponent } from "./components/footer/footer.component";
import { HeaderComponent } from "./components/header/header.component";
import { AppComponent } from "./containers/app/app.component";
import { ChangePasswordComponent } from "./containers/change-password/change-password.component";
import { HomepageComponent } from "./containers/homepage/homepage.component";
import { NationRailHandoffComponent } from "./containers/national-rail-handoff/national-rail-handoff.component";
import { NotFoundComponent } from "./containers/not-found/not-found.component";
import { VerifyEmailComponent } from "./containers/verify-email/verify-email.component";

@NgModule({
    declarations: [
        AppComponent,
        ChangePasswordComponent,
        HomepageComponent,
        NationRailHandoffComponent,
        NotFoundComponent,
        VerifyEmailComponent,
        DebugUiComponent,
        HeaderComponent,
        FooterComponent
    ],
    imports: [CommonModule, RouterModule, ModalModule, SharedModule, ReactiveFormsModule]
})
export class CoreModule {}
