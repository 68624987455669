import { ChangeDetectorRef, Directive, Input, OnChanges, Optional, SimpleChanges, TemplateRef, ViewContainerRef } from "@angular/core";
import { BehaviorSubject, Subscription } from "rxjs";
import { ConfigService } from "../../services/config.service";

@Directive({
    selector: "[aceHasFeature]",
    exportAs: "hasFeature"
})
export class HasFeatureDirective implements OnChanges {
    @Input() public aceHasFeature: string;
    public status: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    private _configSub: Subscription;
    constructor(
        private _viewContainerRef: ViewContainerRef,
        @Optional() private _templateRef: TemplateRef<any>,
        private _configService: ConfigService,
        private _changeDetectorRef: ChangeDetectorRef
    ) {}

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes && changes.aceHasFeature && changes.aceHasFeature.currentValue && changes.aceHasFeature.currentValue !== changes.aceHasFeature.previousValue) {
            this._checkFeature(changes.aceHasFeature.currentValue);
        }
    }

    public hasFeature(): boolean {
        return this.status.getValue();
    }

    private _checkFeature(key: string) {
        if (this._configSub) {
            this._configSub.unsubscribe();
        }
        this._configSub = this._configService.hasFeature(key).subscribe(feature => {
            if (this._templateRef) {
                this._viewContainerRef.clear();
            }
            if (feature === true && this._templateRef) {
                this._viewContainerRef.createEmbeddedView(this._templateRef);
            }
            this.status.next(feature);
            this._changeDetectorRef.detectChanges();
        });
    }
}
