import { enableProdMode } from "@angular/core";
import { platformBrowserDynamic } from "@angular/platform-browser-dynamic";
import { AppModule } from "./app/app.module";
import { environment } from "./environments/environment";

declare global {
    interface Window {
        isIE: boolean;
    }
}

if (environment.production) {
    enableProdMode();
}

if (!window.isIE) {
    platformBrowserDynamic()
        .bootstrapModule(AppModule)
        .catch(err => console.error(err));
}
