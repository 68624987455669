import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core";

@Component({
    selector: "ace-select",
    templateUrl: "select.component.html",
    styleUrls: ["select.component.scss"]
})
export class SelectComponent implements OnInit {
    @Input() public selectId: string;
    @Input() public options: ISelectOption[];
    @Input() public placeholder: string;
    @Input() public icon: string;
    @Input() public disabled: boolean = false;
    @Input() public required: boolean = false;
    @Input() public amend: boolean;
    @Input() public theme: string = "";
    @Input() public preSelected: number;
    @Input() public error: boolean;
    @Input() public isPlaceholderSet?: boolean = true;
    @Input() public ariaDescribedby: string;
    @Output() public value: string | number;
    @Output() public optionSelect: EventEmitter<ISelectOption> = new EventEmitter<ISelectOption>();
    @ViewChild("selectElement") public selectElement: ElementRef<HTMLSelectElement>;

    public ngOnInit() {
        // Sanitise the data
        if (this.options) {
            for (let i = 0; i < this.options.length; i++) {
                // Default to label is value not supplied
                if (typeof this.options[i].value === "undefined") {
                    this.options[i].value = this.options[i].label;
                }

                // Set selected to false if not supplied
                if (typeof this.options[i].selected === "undefined") {
                    this.options[i].selected = false;
                }

                if (this.options[i].selected) {
                    this.onSelect(i);
                }
            }

            // preSelected can have index eq 0, placeholder is always 0
            if (String(this.preSelected) !== "undefined") {
                this.onSelect(this.preSelected);
                this.isPlaceholderSet = false;
            }

            // If no value, set it to the first option by default. This guarantees that this element ALWAYS has a value
            if (!this.placeholder && this.value === undefined && this.options.length > 0) {
                this.onSelect(0);
            }
        }
    }

    public onSelect(index) {
        // We use index for selection, because when setting the <option> value property, everything becomes a string.
        // By using and index, we can return the value in the correct type to the parent component
        const selectedOption = this.options[Number(index)];
        this.updateOptions(selectedOption);
        this.value = selectedOption.value;
        this.optionSelect.emit(selectedOption);
    }

    public updateOptions(selectedOption: ISelectOption) {
        this.options = this.options.map(opt => {
            opt.selected = opt.value === selectedOption.value;
            return opt;
        });
    }

    public focus() {
        setTimeout(() => {
            this.selectElement.nativeElement.focus();
        }, 0);
    }
}

export interface ISelectOption<V = any, T = any> {
    label: string | number;
    value?: V;
    type?: T;
    selected?: boolean;
}
