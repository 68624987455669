// TODO: unnecessary abstraction layer

export class AceStationMap {
    private static _stationMap = [
        {
            gcxCode: "ASC",
            name: "Alternative Station Code",
            structureAttribute: [
                {
                    code: "UK_ASC_T_AI",
                    name: "Alternate Identifiers",
                    type: "text",
                    label: [
                        {
                            name: "National Location Code",
                            subCode: "NLC"
                        },
                        {
                            name: "Sixteen Character Name",
                            subCode: "SCN"
                        }
                    ]
                }
            ]
        },
        {
            gcxCode: "SLD",
            name: "Station Location & Directions",
            structureAttribute: [
                {
                    code: "UK_SLD_T_LI",
                    name: "Location Information",
                    type: "text",
                    label: [
                        {
                            name: "Latitude",
                            subCode: "LAT"
                        },
                        {
                            name: "Longitude",
                            subCode: "LON"
                        }
                    ]
                }
            ]
        },
        {
            gcxCode: "STH",
            name: "Station Hours",
            structureAttribute: [
                {
                    code: "UK_STH_S_SH",
                    name: "Station Hours",
                    type: "schedule"
                }
            ]
        },
        {
            gcxCode: "STI",
            name: "Station Information",
            structureAttribute: [
                {
                    code: "UK_STI_L_SIA",
                    name: "Station Information Availability",
                    type: "list",
                    label: [
                        {
                            name: "ATM",
                            subCode: "A"
                        },
                        {
                            name: "Baby Change",
                            subCode: "BC"
                        },
                        {
                            name: "Bureau De Change",
                            subCode: "BDC"
                        },
                        {
                            name: "Lost Property",
                            subCode: "LP"
                        },
                        {
                            name: "Post Box",
                            subCode: "PB"
                        },
                        {
                            name: "Seated Area",
                            subCode: "SA"
                        },
                        {
                            name: "Shops",
                            subCode: "S"
                        },
                        {
                            name: "Showers",
                            subCode: "SW"
                        },
                        {
                            name: "Station Buffet",
                            subCode: "SB"
                        },
                        {
                            name: "Trolley",
                            subCode: "T"
                        },
                        {
                            name: "Waiting Room",
                            subCode: "WR"
                        },
                        {
                            name: "Wifi",
                            subCode: "WF"
                        }
                    ]
                },
                {
                    code: "UK_STI_T_SID",
                    name: "Station Information Details",
                    type: "text",
                    label: [
                        {
                            name: "Customer Service Note",
                            subCode: "CS"
                        },
                        {
                            name: "Lost Property Note",
                            subCode: "LP"
                        },
                        {
                            name: "Lost Property Number",
                            subCode: "LPN"
                        },
                        {
                            name: "Staffing Level",
                            subCode: "SL"
                        },
                        {
                            name: "Information Systems",
                            subCode: "IS"
                        }
                    ]
                },
                {
                    code: "UK_STI_T_LP",
                    name: "Lost Property",
                    type: "text",
                    label: {
                        name: "URL",
                        subCode: "URL"
                    }
                },
                {
                    code: "UK_STI_S_LP",
                    name: "Lost Property",
                    type: "schedule"
                }
            ]
        },
        {
            gcxCode: "TSI",
            name: "Ticketing Services & Information",
            structureAttribute: [
                {
                    code: "UK_TSI_L_TS",
                    name: "Ticketing Services",
                    type: "list",
                    label: [
                        {
                            name: "Pre-Purchase Collection",
                            subCode: "PPC"
                        },
                        {
                            name: "Ticket Machine",
                            subCode: "TM"
                        },
                        {
                            name: "Oyster Pre-Pay",
                            subCode: "OPP"
                        }
                    ]
                },
                {
                    code: "UK_TSI_T_TS",
                    name: "Ticketing Services",
                    type: "text",
                    label: [
                        {
                            name: "Customer Service Note",
                            subCode: "CSN"
                        },
                        {
                            name: "Penalty Fares",
                            subCode: "PF"
                        },
                        {
                            name: "Oyster Notes",
                            subCode: "ON"
                        },
                        {
                            name: "Smartcard Notes",
                            subCode: "SN"
                        }
                    ]
                }
            ]
        },
        {
            gcxCode: "TKH",
            name: "Ticketing Hours",
            structureAttribute: [
                {
                    code: "UK_TKH_S_TOH",
                    name: "Ticket Office Hours",
                    type: "schedule"
                }
            ]
        },
        {
            gcxCode: "BGS",
            name: "Baggage Services",
            structureAttribute: [
                {
                    code: "UK_BGS_T_LL",
                    name: "Left Luggage",
                    type: "text",
                    label: [
                        {
                            name: "Available",
                            subCode: "A"
                        },
                        {
                            name: "Phone",
                            subCode: "P"
                        },
                        {
                            name: "URL",
                            subCode: "URL"
                        }
                    ]
                }
            ]
        },
        {
            gcxCode: "SSA",
            name: "Station Seating Areas",
            structureAttribute: [
                {
                    code: "UK_SSA_T_SA",
                    name: "Seated Area",
                    type: "text",
                    label: {
                        name: "Note",
                        subCode: "N"
                    }
                },
                {
                    code: "UK_SSA_S_FCLH",
                    name: "First Class Lounge Hours",
                    type: "schedule"
                }
            ]
        },
        {
            gcxCode: "LNG",
            name: "Lounges"
        },
        {
            gcxCode: "PLG",
            name: "Priority Lounge Information",
            structureAttribute: [
                {
                    code: "UK_PLG_T_FCL",
                    name: "First Class Lounge",
                    type: "text",
                    label: {
                        name: "Note",
                        subCode: "N"
                    }
                }
            ]
        },
        {
            gcxCode: "MES",
            name: "Mail and Express Services",
            structureAttribute: [
                {
                    code: "UK_MES_T_PB",
                    name: "Post Box",
                    type: "text",
                    label: {
                        name: "Note",
                        subCode: "N"
                    }
                }
            ]
        },
        {
            gcxCode: "ACC",
            name: "Accessibility",
            structureAttribute: [
                {
                    code: "UK_ACC_L_IAA",
                    name: "Impaired Access Availibility",
                    type: "list",
                    label: [
                        {
                            name: "Help Points",
                            subCode: "HP"
                        },
                        {
                            name: "Induction Loop",
                            subCode: "IL"
                        },
                        {
                            name: "Impaired Accessible Ticket Machines",
                            subCode: "IATM"
                        },
                        {
                            name: "Impaired Accessible Booking Office Counter",
                            subCode: "IABOC"
                        },
                        {
                            name: "Ramp for Train Access",
                            subCode: "RTA"
                        },
                        {
                            name: "Accessible Taxis",
                            subCode: "AT"
                        },
                        {
                            name: "Accessible Public Telephones",
                            subCode: "APT"
                        },
                        {
                            name: "National Key Toilets",
                            subCode: "NKT"
                        },
                        {
                            name: "Impaired Mobility Set Down",
                            subCode: "IMSD"
                        },
                        {
                            name: "Wheel chairs Available",
                            subCode: "WA"
                        },
                        {
                            name: "Impared Access Staff",
                            subCode: "IAS"
                        },
                        {
                            name: "Impared Access Ticket Gate",
                            subCode: "IATG"
                        },
                        {
                            name: "First Class Lounge",
                            subCode: "FCL"
                        }
                    ]
                },
                {
                    code: "UK_ACC_T_IA",
                    name: "Impaired Access",
                    type: "text",
                    label: [
                        {
                            name: "Staff Help",
                            subCode: "SH"
                        },
                        {
                            name: "Accessible Taxis",
                            subCode: "AT"
                        },
                        {
                            name: "Step Free Access",
                            subCode: "SFA"
                        },
                        {
                            name: "Step Free Access Coverage",
                            subCode: "SFAC"
                        },
                        {
                            name: "Accessible Public Telephone",
                            subCode: "APT"
                        },
                        {
                            name: "Booking Office Counter",
                            subCode: "BOC"
                        },
                        {
                            name: "Accesible Ticket Machine",
                            subCode: "ATM"
                        },
                        {
                            name: "Customer Help Point",
                            subCode: "CHP"
                        },
                        {
                            name: "Helpline",
                            subCode: "H"
                        },
                        {
                            name: "Helpline Contact URL",
                            subCode: "HCURL"
                        },
                        {
                            name: "Helpline Contact Details",
                            subCode: "HCD"
                        },
                        {
                            name: "Impared Mobility Set Down",
                            subCode: "IMSD"
                        },
                        {
                            name: "National Key Toilets",
                            subCode: "NKT"
                        },
                        {
                            name: "Nearest Stations with Facilities",
                            subCode: "NSF"
                        },
                        {
                            name: "Impared Access Ticket Gate",
                            subCode: "IATG"
                        }
                    ]
                },
                {
                    code: "UK_ACC_S_IAHH",
                    name: "Impaired Access Helpline Hours",
                    type: "schedule"
                },
                {
                    code: "UK_ACC_S_IASHH",
                    name: "Impaired Access Staff Help Hours",
                    type: "schedule"
                },
                {
                    code: "UK_ACC_S_IAWCOH",
                    name: "Impaired Access Wheel Chair Open Hours",
                    type: "schedule"
                },
                {
                    code: "UK_ACC_T_IAWCOH",
                    name: "Impaired Access Wheel Chair Open Hours",
                    type: "text"
                }
            ]
        },
        {
            gcxCode: "EMG",
            name: "Emergency Services"
        },
        {
            gcxCode: "STA",
            name: "Station Alerts"
        },
        {
            gcxCode: "PKG",
            name: "Parking",
            structureAttribute: {
                code: "UK_PKG_T_CH",
                name: "Car Hire",
                type: "text",
                label: {
                    name: "Note",
                    subCode: "N"
                }
            }
        },
        {
            gcxCode: "GRP",
            name: "Group Handling"
        },
        {
            gcxCode: "CYS",
            name: "Cycle Storage",
            structureAttribute: [
                {
                    code: "UK_CYS_T_CH",
                    name: "Cycle Hire",
                    type: "text",
                    label: {
                        name: "Note",
                        subCode: "N"
                    }
                },
                {
                    code: "UK_CYS_T_CS",
                    name: "Cycle Storage",
                    type: "text",
                    label: [
                        {
                            name: "Location",
                            subCode: "L"
                        },
                        {
                            name: "Note",
                            subCode: "N"
                        },
                        {
                            name: "Type",
                            subCode: "T"
                        },
                        {
                            name: "Spaces",
                            subCode: "SP"
                        },
                        {
                            name: "Sheltered",
                            subCode: "SH"
                        }
                    ]
                },
                {
                    code: "UK_CYS_L_CS",
                    name: "Cycle Storage",
                    type: "list",
                    label: [
                        {
                            name: "Availability",
                            subCode: "A"
                        },
                        {
                            name: "Closed Circuit TV",
                            subCode: "CCTV"
                        }
                    ]
                }
            ]
        },
        {
            gcxCode: "TXI",
            name: "Taxi Information",
            structureAttribute: [
                {
                    code: "UK_TXI_T_T",
                    name: "Taxi",
                    type: "text",
                    label: {
                        name: "Note",
                        subCode: "N"
                    }
                },
                {
                    code: "UK_TXI_L_T",
                    name: "Taxi",
                    type: "list",
                    label: {
                        name: "Availability",
                        subCode: "A"
                    }
                }
            ]
        },
        {
            gcxCode: "BUS",
            name: "Bus information",
            structureAttribute: [
                {
                    code: "UK_BUS_L_BS",
                    name: "Bus Service",
                    type: "list",
                    label: {
                        name: "Availability",
                        subCode: "A"
                    }
                },
                {
                    code: "UK_BUS_T_BS",
                    name: "Bus Service",
                    type: "text",
                    label: {
                        name: "Note",
                        subCode: "N"
                    }
                }
            ]
        },
        {
            gcxCode: "APT",
            name: "Airport",
            structureAttribute: [
                {
                    code: "UK_APT_T_A",
                    name: "Airport",
                    type: "text",
                    label: {
                        name: "Note",
                        subCode: "N"
                    }
                },
                {
                    code: "UK_APT_L_A",
                    name: "Airport",
                    type: "list",
                    label: {
                        name: "Availability",
                        subCode: "A"
                    }
                }
            ]
        },
        {
            gcxCode: "RCR",
            name: "Rental Car",
            structureAttribute: [
                {
                    code: "UK_RCR_L_RC",
                    name: "Rental Car",
                    type: "list",
                    label: {
                        name: "Availability",
                        subCode: "A"
                    }
                },
                {
                    code: "UK_RCR_T_RC",
                    name: "Rental Car",
                    type: "text",
                    label: {
                        name: "Note",
                        subCode: "N"
                    }
                }
            ]
        },
        {
            gcxCode: "SUB",
            name: "Subway/transit",
            structureAttribute: [
                {
                    code: "UK_SUB_T_M",
                    name: "Metro",
                    type: "text",
                    label: {
                        name: "Note",
                        subCode: "N"
                    }
                },
                {
                    code: "UK_SUB_L_M",
                    name: "Metro",
                    type: "list",
                    label: {
                        name: "Availability",
                        subCode: "A"
                    }
                }
            ]
        },
        {
            gcxCode: "CRL",
            name: "Commuter Rail/Subway"
        },
        {
            gcxCode: "OTR",
            name: "Other transportation",
            structureAttribute: [
                {
                    code: "UK_OTR_L_OT",
                    name: "Other Transportation",
                    type: "list",
                    label: [
                        {
                            name: "Port",
                            subCode: "P"
                        },
                        {
                            name: "Cycle Hire",
                            subCode: "CH"
                        }
                    ]
                },
                {
                    code: "UK_OTR_T_OT",
                    name: "Other Transportation",
                    type: "text",
                    label: [
                        {
                            name: "Port",
                            subCode: "P"
                        },
                        {
                            name: "Rail Replacement Services",
                            subCode: "RRS"
                        },
                        {
                            name: "Rail Replacement Map",
                            subCode: "RRM"
                        }
                    ]
                }
            ]
        },
        {
            gcxCode: "HTL",
            name: "Hotels/Lodging Information"
        },
        {
            gcxCode: "CCT",
            name: "Convention Center Information"
        },
        {
            gcxCode: "ATT",
            name: "Area Attractions"
        },
        {
            gcxCode: "ATM",
            name: "ATM & Currency Exchange",
            structureAttribute: [
                {
                    code: "UK_ATM_T_ATMMN",
                    name: "ATM Machine Note",
                    type: "text"
                }
            ]
        },
        {
            gcxCode: "PIW",
            name: "Phones, Internet & Wi-fi",
            structureAttribute: [
                {
                    code: "UK_PIW_T_SA",
                    name: "Station Amenities",
                    type: "text",
                    label: {
                        name: "Wi-Fi",
                        subCode: "WF"
                    }
                },
                {
                    code: "UK_PIW_T_T",
                    name: "Telephone",
                    type: "text",
                    label: {
                        name: "Usage Type",
                        subCode: "UT"
                    }
                },
                {
                    code: "UK_PIW_L_SA",
                    name: "Station Amenities",
                    type: "list",
                    label: {
                        name: "Telephone",
                        subCode: "T"
                    }
                },
                {
                    code: "UK_PIW_L_WK",
                    name: "Web Kiosk",
                    type: "list",
                    label: {
                        name: "Available",
                        subCode: "A"
                    }
                },
                {
                    code: "UK_PIW_T_WKO",
                    name: "Web Kiosk Open",
                    type: "text",
                    label: {
                        name: "Note",
                        subCode: "N"
                    }
                },
                {
                    code: "UK_PIW_S_WKH",
                    name: "Web Kiosk Hours",
                    type: "schedule"
                }
            ]
        },
        {
            gcxCode: "DAS",
            name: "Dining and Shopping",
            structureAttribute: [
                {
                    code: "UK_DAS_T_DS",
                    name: "Dining & Shopping",
                    type: "text",
                    label: [
                        {
                            name: "Station Buffet",
                            subCode: "SB"
                        },
                        {
                            name: "Shops",
                            subCode: "S"
                        }
                    ]
                }
            ]
        },
        {
            gcxCode: "RRS",
            name: "Restrooms",
            structureAttribute: [
                {
                    code: "UK_RRS_L_R",
                    name: "Restrooms",
                    type: "list",
                    label: {
                        name: "Toilets",
                        subCode: "T"
                    }
                },
                {
                    code: "UK_RRS_T_R",
                    name: "Restrooms",
                    type: "text",
                    label: [
                        {
                            name: "Baby Change",
                            subCode: "BC"
                        },
                        {
                            name: "Toilets Note",
                            subCode: "TN"
                        }
                    ]
                }
            ]
        },
        {
            gcxCode: "OTH",
            name: "Other amenities",
            structureAttribute: [
                {
                    code: "UK_OTH_T_O",
                    name: "Other",
                    type: "text",
                    label: [
                        {
                            name: "Waiting Room Note",
                            subCode: "WRN"
                        },
                        {
                            name: "Tourist Information Office Note",
                            subCode: "TION"
                        },
                        {
                            name: "Waiting Room Open Note",
                            subCode: "WRON"
                        }
                    ]
                },
                {
                    code: "UK_OTH_L_O",
                    name: "Other",
                    type: "list",
                    label: [
                        {
                            name: "Waiting Room",
                            subCode: "WR"
                        },
                        {
                            name: "Tourist Information Office",
                            subCode: "TIO"
                        }
                    ]
                },
                {
                    code: "UK_OTH_S_O",
                    name: "Other",
                    type: "schedule"
                }
            ]
        },
        {
            gcxCode: "TVH",
            name: "Ticket Vending Hours",
            structureAttribute: [
                {
                    code: "UK_TVH_S_TVH",
                    name: "Ticket Vending Hours",
                    type: "schedule"
                }
            ]
        }
    ];

    public getMappedData() {
        return AceStationMap._stationMap;
    }
}
