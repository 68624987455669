import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { Select, Store } from "@ngxs/store";
import { addDays, addYears, format, isAfter, parse, subDays } from "date-fns";
import isBefore from "date-fns/isBefore";
import isSameDay from "date-fns/isSameDay";
import { filter, take } from "rxjs/operators";
import { BasketService } from "../../../services/basket.service";
import { NotifyToastService } from "../../../services/notify-toast.service";
import { RailcardService } from "../../../services/railcard.service";
import { SearchService } from "../../../services/search.service";
import { QttBase } from "../../classes/QttBase";
import { IQueryParams } from "../../models/interfaces/IQueryParams";
import { ConfigState } from "../../state/config/config.state";
import { UpdateSearchCriteria } from "../../state/qtt/qtt.actions";
import { initialState, QttState } from "../../state/qtt/qtt.state";
import { ButtonComponent } from "../button/button.component";
import { ISelectOption } from "../select/select.component";
import { combineLatest, Observable } from "rxjs";
import { MetadataState } from "../../state/metadata/metadata.state";
import { DATE_STRING_FORMAT } from "../../constants/date";

@Component({
    selector: "ace-qtt-st",
    templateUrl: "./qtt-st.component.html",
    styleUrls: ["./qtt-st.component.scss"]
})
export class QttSeasonTicketComponent extends QttBase implements OnInit, OnDestroy {
    @Select(MetadataState.isMobile) public isMobile$: Observable<boolean>;
    @ViewChild("startDateCalendar") public startDateCalendar: ElementRef<HTMLDivElement>;
    @ViewChild("endDateCalendar") public endDateCalendar: ElementRef<HTMLDivElement>;
    @ViewChild("submitButton") public submitButton: ButtonComponent;
    public readonly MAX_PASSENGERS = 1;
    public readonly ASSIST_WEEKLY_TICKETS_DAYS = 9;
    public startDate: Date;
    public endDate: Date;
    public today: Date;
    public adultOptions: ISelectOption[] = [];
    public adultOptionSelection: ISelectOption;
    public childOptions: ISelectOption[] = [];
    public childOptionSelection: ISelectOption;
    public maxStartDate: Date;
    public minStartDate: Date;
    public maxEndDate: Date;
    public minEndDate: Date;
    public showCopy: boolean = false;

    constructor(
        private searchService: SearchService,
        private basketService: BasketService,
        protected railcardService: RailcardService,
        public store: Store,
        public toastService: NotifyToastService
    ) {
        super(store, toastService, railcardService);
        this.today = this.shaveTimeOfDate(new Date());

        this.addSubscription(
            combineLatest([
                this._store.select(state => ConfigState.bookingWindow(state.config)).pipe(filter(bookingWindow => bookingWindow != null)),
                this._store.select(state => QttState.params(state.qtt))
            ]).subscribe(([maxDaysToStartFromToday, params]) => this.setDefaultDateTimes(maxDaysToStartFromToday, params))
        );
    }

    public ngOnInit() {
        this.addSubscription(this.isMobile$?.subscribe(mobile => (this.totalCards = mobile ? 1 : 2)));

        this.qttParams$
            .pipe(
                filter(params => params != null),
                take(1)
            )
            .subscribe(qttParams => this.setFormValues(qttParams));

        this.addSubscription(
            this.searchService.searchCriteria$?.subscribe(searchCriteria => {
                this.clearFormValues();

                // set parameters in QTT seasons tab if user has selection only
                if (searchCriteria.params.startDate) {
                    this.setFormValues(searchCriteria.params);
                    this.onStationPickerFocus();

                    const passengers = [String(searchCriteria.params.adults), String(searchCriteria.params.children)];
                    this.onPassengersChangeHandler(passengers.join("-"));
                }
            })
        );

        this.addSubscription(
            this.basketService.booking$?.subscribe(booking => {
                if (!booking.isEmpty && booking.hasSeasonTicket()) {
                    // set parameters in QTT seasons tab if user has booking
                    const passengers = [String(booking.numberOfAdultPassengers), String(booking.numberOfChildPassengers)];
                    this.onPassengersChangeHandler(passengers.join("-"));
                }
            })
        );
    }

    public ngOnDestroy(): void {
        this._updateParams();
    }

    public closeStartDateCalendar() {
        this.isStartDateCalendarOn = false;

        setTimeout(() => {
            this.startDateCalendar.nativeElement?.focus();
        }, 150);
    }

    public closeEndDateCalendar() {
        this.isEndDateCalendarOn = false;

        setTimeout(() => {
            this.endDateCalendar.nativeElement?.focus();
        }, 150);
    }

    public setOutboundDate(date: Date) {
        this.setStartDate(date);

        if (this.endDate) {
            if (isAfter(this.startDate, this.endDate)) {
                this.endDate = this.startDate;
            }
            if (isAfter(this.endDate, this.maxEndDate)) {
                this.endDate = this.maxEndDate;
            }
        }

        const maxWeeklyTicketsDate = addDays(this.today, this.ASSIST_WEEKLY_TICKETS_DAYS);
        this.showCopy = isAfter(this.startDate, maxWeeklyTicketsDate) ? false : true;
    }

    public setReturnDate(date: Date) {
        this.endDate = date;
    }

    public showEndDateCalendar() {
        super.showEndDateCalendar();

        if (!this.endDate) {
            this.endDate = new Date(this.minEndDate.getTime());
        }
    }

    public removeReturnJourney() {
        super.removeReturnJourney();

        if (this.endDate) {
            this.endDate = null;
        }
    }

    public search() {
        const formValues = this._getFormValues();
        const isValid = this.validateSearchAndDisplayErrors(formValues);
        if (isValid) {
            this._updateParams();
            this.searchSubmit.emit(formValues);
        }
    }

    public onValidationAlertClose(): void {
        setTimeout(() => {
            this.submitButton?.focus();
        }, 150);
    }

    protected setFormValues(params: IQueryParams): void {
        super.setFormValues(params);

        if (params.origin && params.destination && params.origin.length < 2 && params.destination.length < 2) {
            return;
        }

        if (params.adults + params.children > this.MAX_PASSENGERS) {
            this.adults = 1;
            this.children = 0;
        }

        if (params.startDate) {
            const startDate = parse(params.startDate, DATE_STRING_FORMAT, new Date());

            if (isAfter(startDate, this.minStartDate) && isBefore(startDate, this.maxStartDate)) {
                this.setStartDate(startDate);
            } else {
                if (isSameDay(startDate, this.minStartDate) || isSameDay(startDate, this.maxStartDate)) {
                    this.setStartDate(startDate);
                }
            }
        }

        if (params.endDate) {
            const endDate = parse(params.endDate, DATE_STRING_FORMAT, new Date());

            this.isReturn = true;

            if (isAfter(endDate, this.minEndDate) && isBefore(endDate, this.maxEndDate)) {
                this.setEndDate(endDate);
            } else {
                if (isSameDay(endDate, this.minEndDate) || isSameDay(endDate, this.maxEndDate)) {
                    this.setEndDate(endDate);
                }
            }
        }
    }

    private _updateParams(): void {
        const { standard } = initialState.params;
        this._store.dispatch(new UpdateSearchCriteria({ season: this._getFormValues(), standard }));
    }

    private _getFormValues(): any {
        const queryOptions = {
            origin: this.originTravelPoint,
            destination: this.destinationTravelPoint,
            startDate: format(this.startDate, DATE_STRING_FORMAT),
            adults: this.adults,
            children: this.children
        };

        if (this.endDate) {
            queryOptions["endDate"] = format(this.endDate, DATE_STRING_FORMAT);
        }

        if (this.hasViaAvoid && this.viaAvoidTravelPoint && this.viaAvoidTravelPoint.length > 0) {
            queryOptions[this.viaAvoidSelectedOption] = this.viaAvoidTravelPoint;
        }

        queryOptions["railcards"] = this.getRailcards(this._railcardService.getRailcardsSelection().getValue());

        return queryOptions;
    }

    private setDefaultDateTimes(maxDaysToStartFromToday: number, params: IQueryParams): void {
        if (maxDaysToStartFromToday != null) {
            this.maxStartDate = addDays(this.today, maxDaysToStartFromToday);
            this.minStartDate = new Date(this.today.getTime());
            this.startDate = new Date(this.minStartDate.getTime());
            this.setMaxEndDate();
            this.minEndDate = params.endDate ? addDays(parse(params.endDate, DATE_STRING_FORMAT, new Date()), 7) : this.today;
        }
    }

    private setStartDate(date: Date): void {
        this.startDate = new Date(date.getTime());
        this.setMaxEndDate();
        this.setMinEndDate();
    }

    private setEndDate(endDate: Date): void {
        this.endDate = new Date(endDate.getTime());
    }

    private setMaxEndDate() {
        this.maxEndDate = subDays(addYears(this.startDate, 1), 1);
    }

    private setMinEndDate() {
        this.minEndDate = addDays(this.startDate, 7);
    }
}
