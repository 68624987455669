import { Injectable } from "@angular/core";
import { Action, Selector, State, StateContext } from "@ngxs/store";
import { INREStateModel, NREStatus } from "./nre-state.model";
import { NRERedirected, NRERedirecting, SetHandoffID } from "./nre.actions";

@State<INREStateModel>({
    name: "nre"
})
@Injectable()
export class NREState {
    @Selector([NREState])
    static nre(state: INREStateModel): INREStateModel {
        return state;
    }

    @Selector([NREState])
    static handoffId(state: INREStateModel): string {
        return state.handoffId;
    }

    @Action(NRERedirecting)
    nreRedirecting({ patchState }: StateContext<INREStateModel>) {
        patchState({ status: NREStatus.REDIRECTING });
    }

    @Action(NRERedirected)
    nreRedirected({ patchState }: StateContext<INREStateModel>) {
        patchState({ status: NREStatus.REDIRECTED });
    }

    @Action(SetHandoffID)
    setHandoffID({ patchState }: StateContext<INREStateModel>, action: SetHandoffID) {
        patchState({ handoffId: action.handoffId });
    }
}
