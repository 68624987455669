import { Component, ElementRef, Inject, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute, NavigationEnd, Params, Router } from "@angular/router";
import { Select, Store } from "@ngxs/store";
import { combineLatest, Observable } from "rxjs";
import { filter } from "rxjs/operators";
import { environment } from "../../../../environments/environment";
import { HelpModalComponent } from "../../../modal/components/modals/help-modal/help-modal.component";
import { ModalService } from "../../../modal/services/modal.service";
import { AccountService } from "../../../services/account.service";
import { BasketService } from "../../../services/basket.service";
import { WINDOW } from "../../../services/window.service";
import { AceUser } from "../../../shared/models/ace/ace-user.model";
import { Utils } from "../../../shared/models/ace/ace-utils";
import { RxjsComponent } from "../../../shared/RxjsComponent";
import { NavigationState } from "../../../shared/state/navigation/navigation.state";
import { UpdateJourneyAmend } from "../../../shared/state/journey/journey.actions";
import { JourneyState } from "../../../shared/state/journey/journey.state";

@Component({
    selector: "ace-header",
    templateUrl: "header.component.html",
    styleUrls: ["header.component.scss"]
})
export class HeaderComponent extends RxjsComponent implements OnInit {
    @Select(NavigationState.flowStep) public flowStep$: Observable<string>;
    @Select(JourneyState.isJourneyAmendable) public isJourneyAmendable$: Observable<boolean>;
    @Select(JourneyState.exchangeSummaryOption) public exchangeSummarySelection$: Observable<DataModel.RetrieveExchangeSummaryResponse>;
    @ViewChild("helpButton") public helpButton: ElementRef<HTMLButtonElement>;
    public userReady: boolean;
    public tocId: string = environment.tocId;
    public skipPath: string;
    public tocMarketingSiteUrl = environment.msUrl;
    public userIsApproved = false;
    public userIsGuest = false;
    private user: AceUser;
    isJourneyAmendableFlag: boolean;
    exchangeSummaryOptions: DataModel.ExchangeOption;
    isbasketEmpty: boolean;

    constructor(
        @Inject(WINDOW) private window: Window,
        public basketService: BasketService,
        public route: ActivatedRoute,
        public router: Router,
        public accountService: AccountService,
        private modalService: ModalService,
        private store: Store
    ) {
        super();
    }

    public ngOnInit() {
        this.addSubscription(this.isJourneyAmendable$.subscribe(flag => (this.isJourneyAmendableFlag = flag)));
        this.addSubscription(
            this.exchangeSummarySelection$.subscribe(exchangeSummaryResponse => {
                this.exchangeSummaryOptions = exchangeSummaryResponse?.exchangeSummary?.exchangeOptions[0];
            })
        );
        this.addSubscription(
            this.basketService.booking$.subscribe(booking => {
                this.isbasketEmpty = booking.isEmpty;
            })
        );
        this.addSubscription(
            combineLatest([this.flowStep$, this.router.events.pipe(filter(event => event instanceof NavigationEnd))]).subscribe(
                ([flowStep, routerEvent]: [string, NavigationEnd]) => {
                    const url = routerEvent.urlAfterRedirects;
                    const step = flowStep.toLowerCase().replace("_", "-");
                    this.skipPath = url.includes("#skip") ? url : `${url}#skip-${step}`;
                }
            )
        );

        this.addSubscription(
            this.accountService.currentUser$.pipe(filter(user => user != null)).subscribe(user => {
                if (user.isApproved) {
                    this.userIsApproved = true;
                    this.userIsGuest = false;
                } else {
                    this.userIsApproved = false;
                    this.userIsGuest = user.userIsGuest();
                }

                this.user = user;
                this.userReady = !!user;
            })
        );
    }

    public routeToBasketSummary() {
        this.router.navigate(["/booking/summary"]);
    }

    public onAccountClick(): void {
        if (this.accountService.mobileMenu$.getValue() && this.router.url.includes("account")) {
            this.accountService.toggleMobileNav();
        } else if (!this.router.url.includes("account")) {
            if (this.user && !this.user.isApproved && !this.user.userIsGuest()) {
                const path = Utils.getPathFromSnapshot(this.route.snapshot);
                const currentQueryParams = this.route.snapshot.queryParams;
                const newQueryParams: Params = {
                    redirect: path.length ? `/${path.join("/")}` : null
                };

                if (Object.keys(currentQueryParams).length !== 0) {
                    newQueryParams.redirectParams = encodeURIComponent(JSON.stringify(this.route.snapshot.queryParams));
                }

                this.router.navigate(["/account/login"], {
                    queryParams: newQueryParams
                });
            } else if (this.user && this.user.userIsGuest()) {
                this.router.navigate(["/account/claim"]);
            } else {
                this.router.navigate(["/account/details/bookings"]);
                if (this.user.isLoggedIn && this.accountService.mobileMenu$.getValue()) {
                    this.accountService.toggleMobileNav();
                }
            }
        }
    }

    public routeToHome(event: MouseEvent | KeyboardEvent) {
        event.preventDefault();

        if (this.tocId === "GC") {
            this.window.location.href = environment.msUrl;
        } else {
            this.router.navigate(["/"]);
        }
    }

    public onHelpModal() {
        this.modalService.create(HelpModalComponent, {
            cta: "Close",
            group: this.router.url,
            handleCTAClick: instance => {
                instance.destroy();

                setTimeout(() => {
                    this.helpButton?.nativeElement?.focus();
                }, 150);
            }
        });
    }

    public openCookiePolicy(event) {
        if (this.tocId === "GC") {
            event.preventDefault();
            const button = document.querySelector(".ot-floating-button .ot-floating-button__front .ot-floating-button__open");
            const clickEvent = new MouseEvent("click", {
                bubbles: true,
                cancelable: true,
                view: window
            });
            button.dispatchEvent(clickEvent);
        }
    }
}
