import { AceContactInformation } from "./ace-contact-information";
import { AceTravelDocumentInformation } from "./ace-travel-documents-payload";

export class AcePassenger {
    public isAdult: boolean = false;
    public isChild: boolean = false;
    public smartCardNumber: string = "";
    public cardReference: number;

    private _passengerID: string;
    private _title: string;
    private _nameFirst: string;
    private _nameLast: string;
    private _contactInformation: AceContactInformation[];
    private _ageAtTimeOfTravel: number;
    private _travelDocuments: AceTravelDocumentInformation[];
    private _dob: any;

    public toJSON(): object {
        return {
            isAdult: this.isAdult,
            isChild: this.isChild,
            passengerID: this.passengerID,
            title: this._title,
            nameFirst: this.nameFirst,
            nameLast: this.nameLast,
            contactInformation: this.contactInformation,
            ageAtTimeOfTravel: this._ageAtTimeOfTravel,
            travelDocuments: this._travelDocuments,
            dob: this._dob,
            smartCardNumber: this.smartCardNumber,
            cardReference: this.cardReference
        };
    }

    public toDataModel(): DataModel.Passenger {
        const dataModel: DataModel.Passenger = {
            passengerID: this.passengerID,
            title: this._title,
            nameFirst: this.nameFirst,
            nameLast: this.nameLast,
            contactInformation: this.contactInformation,
            ageAtTimeOfTravel: this._ageAtTimeOfTravel,
            dob: this._dob,
            smartCardNumber: this.smartCardNumber
        };

        if (this._travelDocuments && this._travelDocuments.length > 0) {
            dataModel.travelDocuments = this._travelDocuments.map<DataModel.TravelDocument>(aceTravelDocument => aceTravelDocument.toJSON());
        }

        return dataModel;
    }

    // firstName and lastName are prepared due
    // to incompatibility with AceBookingPassenger
    public get firstName(): string {
        return this._nameFirst;
    }

    public get lastName(): string {
        return this._nameLast;
    }

    public get isDefined(): boolean {
        return this._nameFirst !== "EXAMPLE_FIRST_NAME" && this._nameLast !== "EXAMPLE_LAST_NAME";
    }

    // EXPLICIT GETTERS
    public get passengerID(): string {
        return this._passengerID;
    }

    public get nameFirst(): string {
        return this._nameFirst;
    }

    public get nameLast(): string {
        return this._nameLast;
    }

    public get contactInformation(): AceContactInformation[] {
        return this._contactInformation;
    }

    public get ageAtTimeOfTravel(): number {
        return this._ageAtTimeOfTravel;
    }

    // IMPLICIT SETTERS, METHOD CHAINING ENABLED
    public setPassengerID(value: string): AcePassenger {
        this._passengerID = value;
        return this;
    }

    public setPassengerTitle(value: string): AcePassenger {
        this._title = value;
        return this;
    }

    public setContactDocumentInformation(value: AceTravelDocumentInformation[]) {
        this._travelDocuments = value;
        return this;
    }

    public removeContactDocumentInformation() {
        this._travelDocuments = null;
        return this;
    }

    public setDateOfBirth(value: any) {
        this._dob = value;
        return this;
    }

    public setNameFirst(value: string): AcePassenger {
        this._nameFirst = value;
        return this;
    }

    public setNameLast(value: string): AcePassenger {
        this._nameLast = value;
        return this;
    }

    public setEmail(value: string): AcePassenger {
        const email = this._contactInformation.find(it => it.contactMedium === "EMAIL");

        if (email) {
            email.setContactInfo(value);
        }

        return this;
    }

    public setContactInformation(value: AceContactInformation[]): AcePassenger {
        this._contactInformation = value;
        return this;
    }

    public setAgeAtTimeOfTravel(value: number): AcePassenger {
        this._ageAtTimeOfTravel = value;
        this.isAdult = value >= 16;
        this.isChild = value < 16;
        return this;
    }
}
