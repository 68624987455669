import { Injectable } from "@angular/core";
import { Observable, Subject } from "rxjs";
import { IToastEvent, ToastEventType } from "../shared/models/entities/toast";
import { IToast } from "../shared/models/interfaces/IToast";

@Injectable({
    providedIn: "root"
})
export class NotifyToastService {
    public eventSource: Subject<IToastEvent> = new Subject<IToastEvent>();
    public events: Observable<IToastEvent> = this.eventSource.asObservable();
    public toastCounter: number = 0;
    public defaultTimer = 5000;
    public defaultTheme = "default";
    public defaultShowClose = false;

    public create(option: IToast): void {
        this.add(option);
    }

    public clearAll(): void {
        this.emitEvent({ type: ToastEventType.CLEAR_ALL });
    }

    public clear(id: number): void {
        this.emitEvent({
            type: ToastEventType.CLEAR,
            value: id
        });
    }

    private emitEvent(event: IToastEvent): void {
        if (this.eventSource) {
            this.eventSource.next(event);
        }
    }

    private add(options: IToast | string | number): void {
        let toastOptions: IToast;

        if ((options !== "" && typeof options === "string") || typeof options === "number") {
            toastOptions = {
                msg: options.toString()
            } as IToast;
        } else {
            toastOptions = options as IToast;
        }

        if (!toastOptions || !toastOptions.msg) {
            throw new Error("No message supplied!");
        }

        this.toastCounter++;

        const onRemove = toastOptions.onRemove;
        const toast: IToast = {
            id: toastOptions.id || this.toastCounter,
            msg: toastOptions.msg,
            icon: toastOptions.icon,
            theme: "toast-theme-" + toastOptions.theme,
            showClose: toastOptions.showClose ? toastOptions.showClose : this.defaultShowClose,
            onRemove: onRemove && !!(onRemove && onRemove.constructor && onRemove.call && onRemove.apply) ? onRemove : null
        } as IToast;

        toast.timeout = this.applyTimeout(toastOptions);

        this.emitEvent({
            type: ToastEventType.ADD,
            value: toast
        });
    }

    private applyTimeout(toastOptions): number {
        return toastOptions.hasOwnProperty("timeout") ? toastOptions.timeout : this.defaultTimer;
    }
}
