import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { routerTracingEnabled } from "../environments/loggerConfig";
import { ChangePasswordComponent } from "./core/containers/change-password/change-password.component";
import { HomepageComponent } from "./core/containers/homepage/homepage.component";
import { NationRailHandoffComponent } from "./core/containers/national-rail-handoff/national-rail-handoff.component";
import { NotFoundComponent } from "./core/containers/not-found/not-found.component";
import { VerifyEmailComponent } from "./core/containers/verify-email/verify-email.component";
import { SeasonTicketsEnabledGuard } from "./shared/guards/season-tickets-enabled.guard";
import { SingleBookingOnlyGuard } from "./shared/guards/single-booking-only.guard";

export const routes: Routes = [
    { path: "", component: HomepageComponent, pathMatch: "full" },
    { path: "search", loadChildren: () => import("./search/search.module").then(m => m.SearchModule), canActivate: [SingleBookingOnlyGuard] },
    {
        path: "season",
        loadChildren: () => import("./season/season.module").then(m => m.SeasonModule),
        canActivate: [SeasonTicketsEnabledGuard, SingleBookingOnlyGuard]
    },
    { path: "booking", loadChildren: () => import("./booking/booking.module").then(m => m.BookingModule) },
    { path: "account", loadChildren: () => import("./account/account.module").then(m => m.AccountModule) },
    { path: "verifyUser", component: VerifyEmailComponent },
    { path: "verifyEmail", component: VerifyEmailComponent },
    { path: "change-password", component: ChangePasswordComponent },
    { path: "nre", component: NationRailHandoffComponent },
    { path: "404", component: NotFoundComponent },
    { path: "**", redirectTo: "/404" }
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { enableTracing: routerTracingEnabled })],
    exports: [RouterModule]
})
export class AppRoutingModule {}
