import { Injectable } from "@angular/core";
import * as localforage from "localforage";
import { from as fromPromise, Observable } from "rxjs";
import { LoggerService } from "./logger.service";

@Injectable({
    providedIn: "root"
})
export class LocalStorageService {
    constructor(private logger: LoggerService) {
        this.logger.loggerContext = "LocalStorageService";
    }

    public setItem<T>(key: string, value: T): Observable<void | T> {
        return fromPromise(localforage.setItem(key, value).catch(err => this.logger.error(err, "SetItem error ")));
    }

    public getItem<T>(key: string): Observable<T> {
        return fromPromise(localforage.getItem<T>(key));
    }

    public removeItem(key: string): Observable<void> {
        return fromPromise(localforage.removeItem(key));
    }

    public clear(): Observable<void> {
        return fromPromise(localforage.clear());
    }
}
