import { Store } from "@ngxs/store";
import { environment } from "../../../../environments/environment";
import { ConfigState } from "../../state/config/config.state";
import { IQueryParams } from "../interfaces/IQueryParams";
import { generateRailcardManifest } from "../../utilities/Utils";
import { JourneyCriteria } from "./journey-criteria";
import { SearchPayloadCriteria } from "./search-payload-criteria";

export class TicketSearchPayloadCriteria extends SearchPayloadCriteria {
    public static readonly FARE_FILTER = { CHEAPEST: "CHEAPEST_ONEWAY_AND_ROUNDTRIP_WITH_TC_GROUPS", GB_FARE: "GB_FARES" };
    public isGC: boolean = environment.tocId === "GC";

    constructor(query: IQueryParams, private store: Store) {
        super(query);
    }

    public generatePayloadData(carrierRestrictions?: DataModel.CarrierRestrictions, addFareQualifiers = true): object {
        return {
            pointToPointShoppingQuery: {
                travelPointPairs: this.generateTravelPointPairs(),
                passengerSpecs: this.generatePassengersManifest(),
                isTravelAssistanceRequired: this.generateTravelAssistance(),
                fareFilter: <DataModel.FareFilter>TicketSearchPayloadCriteria.FARE_FILTER.GB_FARE,
                ...(addFareQualifiers && { fareQualifiers: generateRailcardManifest(this.params) }),
                carrierRestrictions
            },
            responseSpec: {
                includeOptionalPrices: {
                    reservations: false,
                    accommodations: false,
                    onboardServices: false,
                    localServices: false
                },
                customCards: this.generateCustomCards()
            },
            searchResultType: this.generateSearchResultType()
        };
    }

    protected generateTravelPointPairs(): object[] {
        const maxDaysToStartFromToday = this.store.selectSnapshot(state => ConfigState.bookingWindow(state.config));
        const result = [];
        result.push(new JourneyCriteria(maxDaysToStartFromToday, this.params));

        if (this.isReturn || this.isOpenReturn) {
            result.push(new JourneyCriteria(maxDaysToStartFromToday, this.params, true, this.isOpenReturn));
        }

        return result;
    }

    private generateTravelAssistance(): boolean {
        return this.params.assistedTravel || false;
    }

    protected generateCustomCards(): DataModel.UserCustomCard[] {
        const customCards: DataModel.UserCustomCard[] = [];

        if (this.params.nus) {
            customCards.push({
                type: "NUS",
                data: {
                    number: this.params.nus
                }
            });
        }

        return customCards;
    }

    private generateSearchResultType(): DataModel.SearchResultType {
        if (this.params.inbound && this.params.inboundTime && this.params.inboundTimeType) {
            return "RETURN";
        }

        if (this.params.openReturn) {
            return "OPENRETURN";
        }

        return "SINGLE";
    }
}
