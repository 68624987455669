import { Injectable } from "@angular/core";
import { Store } from "@ngxs/store";
import { BehaviorSubject, combineLatest, Observable, of, ReplaySubject } from "rxjs";
import { filter, map, mergeMap, startWith, tap } from "rxjs/operators";
import { StationSortGroup } from "../shared/enums/StationSortGroup";
import { AceNearByStation } from "../shared/models/ace/ace-nearby-station-model";
import { AceSaveUserRecentStaionRequestModel } from "../shared/models/ace/ace-save-user-recent-station-request";
import { AceSnapshot } from "../shared/models/ace/ace-snapshot";
import { AceStationDetails } from "../shared/models/ace/ace-station-details.model";
import { AceUser } from "../shared/models/ace/ace-user.model";
import { FindLocationCriteria, RecentStationResponse, StationApiResponse } from "../shared/models/entities/station";
import { IQueryParams } from "../shared/models/interfaces/IQueryParams";
import { ConfigState } from "../shared/state/config/config.state";
import { QttState } from "../shared/state/qtt/qtt.state";
import { get, sortByKey } from "../shared/utilities/Utils";
import { AceCoreApiService } from "./ace-core-api.service";
import { LocalCacheService } from "./local-cache.service";

@Injectable({
    providedIn: "root"
})
export class StationService {
    public isSeason$: Observable<boolean>;
    public recommendedCodes$: Observable<string[]>;
    public regularFilterOutCodes$: Observable<string[]>;
    public seasonFilterOutCodes$: Observable<string[]>;
    public stations$: ReplaySubject<StationApiResponse[]> = new ReplaySubject(1);
    public stationSnapshot: AceSnapshot<StationApiResponse[]> = new AceSnapshot();
    public isStationsLoading$: BehaviorSubject<boolean>;
    public recommendedStations$: ReplaySubject<StationApiResponse[]> = new ReplaySubject(1);
    private stationCodeMap: object = {};

    constructor(private cache: LocalCacheService, private aceCoreApi: AceCoreApiService, private store: Store) {
        this.isStationsLoading$ = new BehaviorSubject<boolean>(false);

        this.recommendedCodes$ = this.store.select(state => ConfigState.preferredStations(state.config));
        this.regularFilterOutCodes$ = this.store.select(state => ConfigState.filterOutStations(state.config));
        this.seasonFilterOutCodes$ = this.store.select(state => ConfigState.seasonFilterOutStations(state.config));
        this.isSeason$ = this.store.select(state => QttState.isSeasonSearch(state.qtt));

        combineLatest([
            of(1).pipe(
                tap(() => this.isStationsLoading$.next(true)),
                mergeMap(() => this.cache.observable("stationList", this.getStations())),
                tap(() => this.isStationsLoading$.next(false))
            ),
            this.isSeason$,
            this.seasonFilterOutCodes$,
            this.regularFilterOutCodes$.pipe(filter(it => it != null && it.length > 0))
        ]).subscribe(([stationList, isSeason, seasonStationFilter, regularStationFilter]) => {
            const hasSeasonCodes = seasonStationFilter != null && seasonStationFilter.length > 0;
            const stationCodesFilter = isSeason && hasSeasonCodes ? seasonStationFilter : regularStationFilter;
            this.stations$.next(stationList.filter(station => !stationCodesFilter.includes(station.code)));
        });

        combineLatest([this.recommendedCodes$.pipe(filter(codes => codes != null)), this.stations$])
            .pipe(map(([codes, stations]) => stations.filter(station => codes.includes(station.code)).sort((a, b) => codes.indexOf(a.code) - codes.indexOf(b.code))))
            .subscribe(stations => this.recommendedStations$.next(stations));

        // Maintain snapshot of station data
        this.stations$?.subscribe(data => this.stationSnapshot.update(data));
    }

    public fetchStation(stationCode: string): Observable<StationApiResponse> {
        if (this.stationCodeMap[stationCode]) {
            return of(this.stationCodeMap[stationCode]);
        }

        return this.stations$.pipe(
            map(data => {
                const station = data.find(stationIteration => stationIteration.code === stationCode);

                if (station) {
                    return station;
                }
            }),
            tap(station => {
                this.stationCodeMap[stationCode] = station;
            })
        );
    }

    public fetchStationAttribute(stationCode: string, attributePath: string): Observable<any> {
        return this.fetchStation(stationCode).pipe(
            map(station => get(station, attributePath || "name", stationCode)),
            startWith(stationCode)
        );
    }

    public search(query: string): Observable<StationApiResponse[]> {
        const regex = new RegExp(query.replace(/[-\/\\^$*+?.()|[\]{}]/g, "\\$&").replace(/[ ]+/, "[ -]"), "i");
        return this.stations$.pipe(
            map(data =>
                sortByKey(
                    data.filter(item => {
                        let sortGroup = regex.exec(item.crsCode) ? StationSortGroup.MATCHING_CRS : StationSortGroup.NOT_MATCHING;
                        if (sortGroup === StationSortGroup.NOT_MATCHING) {
                            const match = regex.exec(item.name);
                            if (match) {
                                const precedingLetter = item.name[match.index - 1];
                                if (match.index === 0) {
                                    sortGroup = StationSortGroup.STARTS_WITH;
                                } else if (precedingLetter === "(") {
                                    sortGroup = StationSortGroup.CONTAINS_BRACKET_STARTING_WITH;
                                } else if (precedingLetter === " ") {
                                    sortGroup = StationSortGroup.WORD_WITHIN_STARTS_WITH;
                                } else if (precedingLetter === "-") {
                                    sortGroup = StationSortGroup.FOUND_MIDWORD;
                                }
                            }
                        }
                        if (sortGroup !== StationSortGroup.NOT_MATCHING) {
                            item["sortOrder"] = 10 - sortGroup + item.name;
                        }
                        return sortGroup !== StationSortGroup.NOT_MATCHING;
                    }),
                    "sortOrder"
                )
            )
        );
    }

    public find(findObj: FindLocationCriteria): Observable<StationApiResponse[]> {
        return this.stations$.pipe(
            map(data =>
                data.filter(station => {
                    let result = true;
                    if (findObj.code) {
                        result = result && station.code === findObj.code;
                    }
                    if (findObj.name) {
                        result = result && station.name === findObj.name;
                    }
                    return result;
                })
            )
        );
    }

    public findByStationName(stationName: string): Observable<StationApiResponse> {
        return this.stations$.pipe(map(data => data.filter(station => station.name === stationName)[0]));
    }

    public findOne(findObj: FindLocationCriteria): Observable<StationApiResponse> {
        return this.find(findObj).pipe(map(items => items[0]));
    }

    public getStationDetails(stationCode: string) {
        const stationRequestObservable = this.aceCoreApi
            .call("DataRequest", {
                entityName: "StationRichContent",
                method: "GET",
                data: {
                    code: [stationCode]
                }
            })
            .pipe(map(res => new AceStationDetails(res.data.stationRichContent)));

        return this.cache.observable(`stationDetails-${stationCode}`, stationRequestObservable);
    }

    private getStations(): Observable<StationApiResponse[]> {
        return this.aceCoreApi
            .call("DataRequest", {
                entityName: "Station",
                method: "GET",
                options: {
                    attributes: ["code", "crsCode", "nlcCode", "itineraryStation", "name", "type", "smartCardSupport"]
                }
            })
            .pipe(map(res => res.data.map((stationFullApiData: StationApiResponse) => stationFullApiData)));
    }

    public getNearByStation(latitude: string, longitude: string): Observable<AceNearByStation> {
        const nearByStationRequestObservable = this.aceCoreApi
            .call("NearbyStationsListRequest", {
                lat: latitude,
                lon: longitude
            })
            .pipe(map(res => new AceNearByStation(res.data)));
        return nearByStationRequestObservable;
    }

    public getRecentStation(currentUserId: number): Observable<RecentStationResponse[]> {
        return this.aceCoreApi
            .call("DataRequest", {
                entityName: "UserRecentStation",
                method: "GET",
                data: {
                    userId: currentUserId
                },
                options: {
                    pagginationLimit: 4,
                    pagginationOffset: 0,
                    sortField: "id",
                    sortOrder: "DESC",
                    group: "code"
                }
            })
            .pipe(map(res => res.data.items.map((stationRecentApiData: RecentStationResponse) => stationRecentApiData)));
    }

    public getPrepareSaveUserSearchObject(currentUser: AceUser, params: IQueryParams, currentOriginStation: any, currentDestinationStation: any) {
        if (currentUser.isFetchedFromApi) {
            const saveUserSearchObject: AceSaveUserRecentStaionRequestModel = {
                userId: currentUser?.userId,
                recentStations: [
                    {
                        userId: currentUser?.userId,

                        code: params.origin,

                        crsCode: currentOriginStation.crsCode,

                        name: currentOriginStation.name,

                        type: currentOriginStation.type.toUpperCase(),

                        sequenceId: 1
                    },

                    {
                        userId: currentUser?.userId,

                        code: params.destination,

                        crsCode: currentDestinationStation.crsCode,

                        name: currentDestinationStation.name,

                        type: currentOriginStation.type.toUpperCase(),

                        sequenceId: 2
                    }
                ]
            };

            return saveUserSearchObject;
        }
    }
}
