<div id="skip-qtt" class="qtt-toggle" [class.is-horizontal]="!isVertical">
    <div class="qtt-toggle-controls" [attr.role]="sections?.length > 1 ? 'tablist' : undefined" [attr.aria-label]="sections?.length > 1 ? 'Ticket type' : undefined">
        <ng-container *ngIf="sections?.length > 1">
            <button
                *ngFor="let section of sections; let i = index"
                #tabController
                (click)="selectSection(section.id)"
                (keyup)="onTabKeyDown($event)"
                [attr.id]="'qtt-tab-control-' + section.id"
                class="font-emphasis"
                [class.selected]="section.id === selectedSectionId"
                role="tab"
                [attr.tabindex]="section.id === selectedSectionId ? undefined : '-1'"
                [attr.aria-label]="section.label"
                [attr.aria-selected]="section.id === selectedSectionId"
                [attr.aria-controls]="'qtt-tab-' + section.id"
                [attr.data-elid]="section.name"
            >
                <span>
                    {{ section.name }}
                    <svg viewBox="0 0 24 24" aria-hidden="true">
                        <title>checkmark</title>
                        <use xlink:href="#checkmark" />
                    </svg>
                </span>
            </button>
        </ng-container>
    </div>
    <div class="qtt-toggle-content" aceAnnounce="Choose your journey page">
        <div
            class="qtt-toggle-content-item"
            [ngClass]="{ hide: selectedSectionId === seasonQttId }"
            [attr.role]="sections?.length > 1 ? 'tabpanel' : undefined"
            [attr.id]="'qtt-tab-' + standardQttId"
            [attr.aria-labelledby]="sections?.length > 1 ? 'qtt-tab-control-' + standardQttId : undefined"
        >
            <ace-qtt [isVertical]="isVertical" (searchSubmit)="onTicketsSearchSubmit($event)"></ace-qtt>
        </div>
        <div
            class="qtt-toggle-content-item"
            [ngClass]="{ hide: selectedSectionId === standardQttId }"
            [attr.role]="sections?.length > 1 ? 'tabpanel' : undefined"
            [attr.id]="'qtt-tab-' + seasonQttId"
            [attr.aria-labelledby]="sections?.length > 1 ? 'qtt-tab-control-' + seasonQttId : undefined"
        >
            <ace-qtt-st [isVertical]="isVertical" (searchSubmit)="onSeasonTicketsSearchSubmit($event)"></ace-qtt-st>
        </div>
    </div>
</div>
