import { Injectable } from "@angular/core";
import { Action, createSelector, Selector, State, StateContext, Store } from "@ngxs/store";
import { tap } from "rxjs/operators";
import { SmartcardService } from "../../../services/smartcard.service";
import { AceSmartcard } from "../../models/ace/ace-smartcard.model";
import { AceUserType } from "../../models/ace/ace-user-type";
import { AceUser } from "../../models/ace/ace-user.model";
import { IUserCard } from "../../models/interfaces/IUserCard";
import { DiscountHelper } from "../../utilities/DiscountHelper";
import { ConfigState } from "../config/config.state";
import { IAccountStateModel } from "./account-state.model";
import { FetchSmartcards, RemoveSmartcard, UpdateSmartcards, UserLoggedIn, UserLoggedOut } from "./account.actions";

export const initialState = {
    type: AceUserType.ANONYMOUS,
    userType: "ANONYMOUS",
    smartCards: [],
    isFetchedFromApi: false
};

@State<IAccountStateModel>({
    name: "account",
    defaults: initialState as AceUser
})
@Injectable()
export class AccountState {
    constructor(private store: Store, private smartcardService: SmartcardService) {}

    static smartcard(smartcardId?: number) {
        return createSelector([AccountState.account], (account: AceUser) => account.smartCards.find(card => card.smartCardId === smartcardId) || null);
    }

    @Selector([AccountState])
    static account(state: IAccountStateModel): AceUser {
        return state ? state : null;
    }

    @Selector([AccountState.account])
    static nusCard(account: AceUser) {
        return (account && (account.userCustomCards.find(card => card.type === DiscountHelper.DISCOUNT_TYPE_NUS) as IUserCard)) || null;
    }

    @Selector([AccountState.nusCardNumber])
    static nusCardNumber(nusCard: IUserCard) {
        return (nusCard && nusCard.data && nusCard.data.number) || null;
    }

    @Selector([AccountState.account])
    static smartcards(account: AceUser) {
        return (account && account.smartCards) || null;
    }

    @Selector([AccountState.account])
    static addresses(account: AceUser) {
        return (account && account.addresses) || null;
    }

    @Action(UserLoggedIn)
    userLoggedIn({ patchState, dispatch }: StateContext<IAccountStateModel>, action: UserLoggedIn) {
        patchState({ ...action.payload, smartCards: action.payload.smartCards.map(card => new AceSmartcard(card)), isFetchedFromApi: true });
        dispatch(new FetchSmartcards());
    }

    @Action(UserLoggedOut)
    userLoggedOut({ setState }: StateContext<IAccountStateModel>) {
        setState({ ...initialState, isFetchedFromApi: true } as AceUser);
    }

    @Action(FetchSmartcards)
    fetchSmartcards({ getState, dispatch }: StateContext<IAccountStateModel>) {
        const state = getState();
        if (state.type === AceUserType.MEMBER && this.store.selectSnapshot(state => ConfigState.featureFlag("stnr")(ConfigState.aceConfig(state.config)))) {
            return this.smartcardService.getUserSmartcards(state.userId).pipe(tap(smartcards => dispatch(new UpdateSmartcards(smartcards))));
        }
    }

    @Action(UpdateSmartcards)
    updateSmartcards({ getState, patchState }: StateContext<IAccountStateModel>, action: UpdateSmartcards) {
        const state = getState();
        patchState({
            smartCards: [
                ...state.smartCards.filter(card => !action.payload.some(elem => elem.smartCardId === card.smartCardId)),
                ...action.payload.map(card => new AceSmartcard(card))
            ]
        });
    }

    @Action(RemoveSmartcard)
    removeSmartcard({ getState, patchState }: StateContext<IAccountStateModel>, action: RemoveSmartcard) {
        const state = getState();
        patchState({ smartCards: state.smartCards.filter(card => card.smartCardId !== action.payload) });
    }
}
