import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { NotifyToastService } from "./notify-toast.service";

@Injectable({
    providedIn: "root"
})
export class SessionManagementService {
    constructor(private _router: Router, private _toastService: NotifyToastService) {}

    public handleExpiredSession() {
        switch (this._stripParams(this._router.url)) {
            case "/":
            case "/search":
            case "/search/season":
            case "/booking/seats-and-extras":
                this._displayExpirationMessage("You have been logged out because your previous session has expired");
                break;
            case "/booking/summary":
                this._displayExpirationMessage("Your previous session has expired but you can search for a new journey on this page");
                this._router.navigate(["/"]);
                break;
            default:
                this._displayExpirationMessage("Your previous session has expired, you need to log in to continue");
                this._router.navigate(["/account/login"], { queryParams: { redirect: "/account/details" } });
        }
    }

    private _displayExpirationMessage(msg: string, delay: number = 500, timeout: number = 7000) {
        setTimeout(() => {
            this._toastService.create({
                msg,
                timeout,
                theme: "warning",
                icon: "info"
            });
        }, delay);
    }

    private _stripParams(url: string): string {
        return url.split("?")[0];
    }
}
