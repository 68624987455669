import { Injectable } from "@angular/core";
import { Store } from "@ngxs/store";
import { Observable } from "rxjs";
import { filter, map, tap } from "rxjs/operators";
import { FeatureFlagTypes } from "../shared/constants/feature-flags";
import { AceSnapshot } from "../shared/models/ace/ace-snapshot";
import { IAceConfig } from "../shared/models/interfaces/IAceConfig";
import { ConfigState } from "../shared/state/config/config.state";
import { get } from "../shared/utilities/Utils";

@Injectable({
    providedIn: "root"
})
export class ConfigService {
    public readonly config$: Observable<IAceConfig>;
    public configSnapshot: AceSnapshot = new AceSnapshot();

    constructor(private store: Store) {
        this.config$ = this.store
            .select<IAceConfig>(state => state.config)
            .pipe(
                filter(config => typeof config === "object" && Object.keys(config).length > 0),
                tap(config => this.configSnapshot.update(config))
            );
    }

    public get<T>(path: string, defaultValue?): Observable<T> {
        return this.config$.pipe(map(config => get<IAceConfig, T>(config, path, defaultValue)));
    }

    public getContactForKey(key: string) {
        return this.get<any[]>("toc.contact").pipe(map(addresses => addresses.find(addr => Boolean(addr[key]))));
    }

    public getPrimaryContact() {
        return this.get<any[]>("toc.contact").pipe(map(addresses => addresses.find(addr => addr.primary)));
    }

    public getPrimaryTel() {
        return this.getPrimaryContact().pipe(map(address => get(address, "tel")));
    }

    public getPrimaryAddress() {
        return this.getPrimaryContact().pipe(map(address => get(address, "address")));
    }

    public hasFeature(name: FeatureFlagTypes | string): Observable<boolean> {
        return this.store.select(state => ConfigState.featureFlag(name)(state.config));
    }
}
