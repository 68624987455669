import { ReplacedReason } from "schema-enums/ReplacedReason";
import { SmartCardStatus } from "schema-enums/SmartCardStatus";
import { SmartCardType } from "schema-enums/SmartCardType";
import { AceSmartCardStatus } from "../../../../../lib/ace.datamodel-v2/dist/ts/enums/AceSmartCardStatus";

export class AceSmartcard implements ORM.Core.Transport.SmartCard {
    public aceStatus: AceSmartCardStatus;
    public cardholderName: string;
    public cardNumber: string;
    public cardReference: string;
    public createdAt: Date;
    public deliveryAddress: ORM.Core.Transport.Address;
    public expiresAt: Date;
    public forename: string;
    public issuedAt: Date;
    public journeys: ORM.Core.Transport.SmartCardJourney[];
    public label: string;
    public lastActivityAt: Date;
    public primary: boolean;
    public products: ORM.Core.Transport.SmartCardProduct[];
    public smartCardId: number;
    public status: SmartCardStatus;
    public surname: string;
    public title: string;
    public type: SmartCardType;
    public updatedAt: Date;
    public userId: number;

    public businessId?: string;
    public replacedReason?: ReplacedReason;
    public replacedCardReference?: string;

    constructor(data: ORM.Core.Transport.SmartCard) {
        this._init(data);
    }

    public get isRegistered(): boolean {
        return this.type === SmartCardType.Registered;
    }

    public get isSaved(): boolean {
        return this.type === SmartCardType.Saved;
    }

    public get isIssued(): boolean {
        return this.status === SmartCardStatus.Issued;
    }

    public get isCanceled(): boolean {
        return this.aceStatus === AceSmartCardStatus.Hotlisted || this.aceStatus === AceSmartCardStatus.Replaced || this.status === SmartCardStatus.Cancelled;
    }

    public get isReplaceable(): boolean {
        return this.isIssued && this.isRegistered && !this.isCanceled;
    }

    public get statusName(): string {
        // the card does not have a custom status following Hotlist/Replace
        if (!this.aceStatus || this.aceStatus === AceSmartCardStatus.Default) {
            const statusName: string = String(this.status);
            return statusName.charAt(0) + statusName.slice(1).toLowerCase();
        }
        return "Cancelled";
    }

    private _init(data: ORM.Core.Transport.SmartCard): void {
        this.aceStatus = data.aceStatus ? data.aceStatus : null;
        this.cardholderName = data.cardholderName;
        this.cardNumber = data.cardNumber;
        this.cardReference = data.cardReference;
        this.createdAt = data.createdAt;
        this.deliveryAddress = data.deliveryAddress;
        this.expiresAt = data.expiresAt;
        this.forename = data.forename;
        this.issuedAt = data.issuedAt;
        this.journeys = data.journeys;
        this.label = data.label;
        this.lastActivityAt = data.lastActivityAt;
        this.primary = data.primary;
        this.products = data.products;
        this.smartCardId = data.smartCardId;
        this.status = data.status;
        this.surname = data.surname;
        this.title = data.title;
        this.type = data.type;
        this.updatedAt = data.updatedAt;
        this.userId = data.userId;
        this.businessId = data.businessId;
        this.replacedReason = data.replacedReason;
        this.replacedCardReference = data.replacedCardReference;
    }
}
