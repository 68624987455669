import { Component } from "@angular/core";
import { NavigationEnd, RouteConfigLoadEnd, RouteConfigLoadStart, Router, RoutesRecognized } from "@angular/router";
import { ConnectionService } from "../../../services/connection.service";
import { EnvironmentService } from "../../../services/environment.service";
import { FlowService } from "../../../services/flow.service";
import { GoogleAnalyticsService } from "../../../services/google-analytics.service";
import { NotifyToastService } from "../../../services/notify-toast.service";
import { RailcardService } from "../../../services/railcard.service";
import { TrackService } from "../../../services/track.service";
import { VersionCheck } from "../../../services/versioncheck.service";
import { RxjsComponent } from "../../../shared/RxjsComponent";
import { environment } from "./../../../../environments/environment";

@Component({
    selector: "ace-root",
    templateUrl: "./app.component.html",
    styleUrls: ["./app.component.scss"]
})
export class AppComponent extends RxjsComponent {
    public static readonly NO_INTERNET_CONNECTION_TOAST_ID: number = 503;
    public isHome: boolean = false;
    public loading: boolean = false;
    public showElids: boolean = false;
    public showDebugUi: boolean = false;
    public isGenericBannerOn: boolean = false;

    constructor(
        analytics: GoogleAnalyticsService,
        trackService: TrackService,
        flowService: FlowService,
        versionCheck: VersionCheck,
        environmentService: EnvironmentService,
        connectionService: ConnectionService,
        notifyToastService: NotifyToastService,
        railcardService: RailcardService,
        private router: Router
    ) {
        super();
        versionCheck.check();
        flowService.createFlowStep();
        this.showDebugUi = environmentService.valueOf("showDebugUi");
        this.isGenericBannerOn = environment.isGenericBannerOn;

        this.addSubscription(
            this.router.events.subscribe(routerEvent => {
                if (routerEvent instanceof RouteConfigLoadStart) {
                    this.loading = true;
                }
                if (routerEvent instanceof RouteConfigLoadEnd) {
                    this.loading = false;
                }
                // track page views
                if (routerEvent instanceof NavigationEnd) {
                    versionCheck.check();
                    analytics.gtmTrackPage(window.location);
                    routerEvent.url === "/" ? (this.isHome = true) : (this.isHome = false);
                }
                if (routerEvent instanceof RoutesRecognized) {
                    trackService.event(routerEvent as RoutesRecognized);
                }
            })
        );
        this.addSubscription(
            connectionService.hasInternetAccess$?.subscribe(hasInternetAccess => {
                if (hasInternetAccess) {
                    notifyToastService.clear(AppComponent.NO_INTERNET_CONNECTION_TOAST_ID);
                } else {
                    notifyToastService.create({
                        id: AppComponent.NO_INTERNET_CONNECTION_TOAST_ID,
                        msg: "Looks like your internet connection has dropped out. Please wait while we try and reconnect.",
                        timeout: 0,
                        theme: "warning",
                        icon: "info",
                        showClose: false
                    });
                }
            })
        );
    }
}
