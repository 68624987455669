import { AfterViewInit, Component, ElementRef, EventEmitter, HostListener, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from "@angular/core";
import { Select } from "@ngxs/store";
import { addMonths, endOfMonth, format, isAfter, isBefore, isSameDay, isValid, parse, set, startOfMonth, subMonths } from "date-fns";
import { Observable } from "rxjs";
import { CalendarMonthCard } from "../../classes/CalendarMonthCard";
import { DATE_TIME_STRING_FORMAT } from "../../constants/date";
import { RxjsComponent } from "../../RxjsComponent";
import { MetadataState } from "../../state/metadata/metadata.state";
import { CheckboxComponent } from "../checkbox/checkbox.component";

export type CalendarCloseOrigin = "clicked-outside" | "escape" | "other";

@Component({
    selector: "ace-calendar",
    templateUrl: "calendar.component.html",
    styleUrls: ["calendar.component.scss"]
})
export class CalendarComponent extends RxjsComponent implements OnInit, OnChanges, AfterViewInit {
    @Select(MetadataState.isMobile) public isMobile$: Observable<boolean>;
    @Input() public date: Date;
    @Input() public maxDate: Date;
    @Input() public minDate: Date;
    @Input() public visibleCardsNum: number = 2;
    @Input() public displayCardsBeforeMinDate: boolean = false;
    @Input() public displayCardsAfterMaxDate: boolean = false;
    @Input() public isDayBeforeTodayAllowed: boolean = false;
    @Input() public disabled: boolean;
    @Input() public isOpenReturn: boolean;
    @Input() public withOpenReturn: boolean;
    @Input() public toggleElement: HTMLElement;
    @Output() public dateChange: EventEmitter<Date> = new EventEmitter(null);
    @Output() public closeRequest: EventEmitter<CalendarCloseOrigin> = new EventEmitter(null);
    @Output() public selectOpenReturn: EventEmitter<boolean> = new EventEmitter(null);
    @ViewChild("calendarContainer") private calendarContainer: ElementRef<HTMLDivElement>;
    @ViewChild("openReturnCheckbox") private openReturnCheckbox: CheckboxComponent;
    public readonly MOUSE_CLICK_DELAY: number = 301;
    public selectedDateString: string;
    public isMobile: boolean;
    public cards: CalendarMonthCard[];
    private dateInView: Date;

    constructor() {
        super();
    }

    @HostListener("window:keyup", ["$event"])
    public onWindowKeyUp(event: KeyboardEvent): void {
        if (event.key === "Escape") {
            this.onCloseCalendar("escape");
        }
    }

    @HostListener("document:click", ["$event"])
    public onDocumentClick(event: MouseEvent): void {
        if (this.calendarContainer?.nativeElement?.contains(event.target as Node) === false && this.toggleElement?.contains(event.target as Node) === false) {
            this.onCloseCalendar("clicked-outside");
        }
    }

    public ngOnInit(): void {
        this.addSubscription(this.isMobile$?.subscribe(mobile => (this.isMobile = mobile)));

        if (this.date == null) {
            this.date = new Date();
        }

        this.dateInView = new Date(this.date.getTime());
        this.generateCards(this.dateInView);
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes.date) {
            const currentValue: Date = changes.date.currentValue;
            const previousValue: Date = changes.date.previousValue;

            if (isValid(currentValue) && isSameDay(currentValue, previousValue) === false) {
                this.setDateInView(currentValue);
            }
        }
    }

    public ngAfterViewInit(): void {
        this.openReturnCheckbox?.focus();
    }

    public onDaySelectedHandler(dayAsString: string): void {
        const date = parse(dayAsString, DATE_TIME_STRING_FORMAT, new Date());
        const newDateEnabled = this.isNewDateEnabled(date);

        if (newDateEnabled === true) {
            this.dateChange.emit(date);
            this.setDateInView(date);
        }
    }

    public onPrevButtonClick(isDateInRange: boolean): void {
        if (isDateInRange) {
            const newMonthDate = subMonths(this.dateInView, 1);
            const endOfNewMonth = endOfMonth(newMonthDate);

            this.selectedDateString = format(set(endOfNewMonth, { hours: 0, minutes: 0, seconds: 0, milliseconds: 0 }), DATE_TIME_STRING_FORMAT);
            this.dateChange.emit(endOfNewMonth);
            this.setDateInView(newMonthDate);
        }
    }

    public onNextButtonClick(isDateInRange: boolean): void {
        if (isDateInRange) {
            const newMonthDate = addMonths(this.dateInView, 1);
            const startOfNewMonth = startOfMonth(newMonthDate);

            this.selectedDateString = format(set(startOfNewMonth, { hours: 0, minutes: 0, seconds: 0, milliseconds: 0 }), DATE_TIME_STRING_FORMAT);
            this.dateChange.emit(startOfNewMonth);
            this.setDateInView(newMonthDate);
        }
    }

    public onSelectOpenReturn(): void {
        this.selectOpenReturn.emit(!this.isOpenReturn);
    }

    public isPrevEnabled(date?: Date): boolean {
        const dateToCheck = date ?? subMonths(this.dateInView, 1);
        const lastDayInMonthToCheck = endOfMonth(dateToCheck);
        const lastDayInMonthMin = endOfMonth(this.minDate);

        if (this.minDate && !this.displayCardsBeforeMinDate && isBefore(lastDayInMonthToCheck, lastDayInMonthMin)) {
            return false;
        }

        return true;
    }

    public isNextEnabled(date?: Date): boolean {
        const dateToCheck = date ?? addMonths(this.dateInView, 1);
        const lastDayInMonthToCheck = endOfMonth(dateToCheck);
        const lastDayInMonthMax = endOfMonth(this.maxDate);

        if (this.maxDate && !this.displayCardsAfterMaxDate && isAfter(lastDayInMonthToCheck, lastDayInMonthMax)) {
            return false;
        }

        return true;
    }

    public onCloseCalendar(closeOrigin: CalendarCloseOrigin): void {
        this.closeRequest.emit(closeOrigin);
    }

    private isNewDateEnabled(newDate: Date): boolean {
        return (isAfter(newDate, this.minDate) || isSameDay(newDate, this.minDate)) && (isBefore(newDate, this.maxDate) || isSameDay(newDate, this.maxDate));
    }

    private setDateInView(date: Date): void {
        this.dateInView = date;
        this.generateCards(this.dateInView);
    }

    private generateCards(requestedDate: Date): void {
        let date = new Date(requestedDate.getTime());
        let isSelectedCard: boolean;

        this.cards = [];
        // generate initial cards based on amount of visibleCards

        for (let i = 0; i < this.visibleCardsNum; i++) {
            isSelectedCard = isSameDay(date, this.date);
            this.cards.push(new CalendarMonthCard(new Date(date.getTime()), this.isDayBeforeTodayAllowed ? undefined : this.minDate, this.maxDate, isSelectedCard));

            if (isSelectedCard === true) {
                this.selectedDateString = format(set(date, { hours: 0, minutes: 0, seconds: 0, milliseconds: 0 }), DATE_TIME_STRING_FORMAT);
            }

            date = addMonths(date, 1);
        }
    }
}
