import { Injectable } from "@angular/core";
import { Action, Selector, State, StateContext } from "@ngxs/store";
import { IOrderStateModel } from "./order-state.model";
import { AmendOrder, ClearOrders, RemoveOrder, UpdateOrder } from "./order.actions";

export const initialState = {
    validators: []
};

@State<IOrderStateModel>({
    name: "order",
    defaults: initialState as IOrderStateModel
})
@Injectable()
export class OrderState {
    @Selector([OrderState])
    static order(state: IOrderStateModel): IOrderStateModel {
        return state ? state : null;
    }

    @Action(UpdateOrder)
    updateOrder({ getState, patchState }: StateContext<IOrderStateModel>, action: UpdateOrder) {
        patchState({ ...getState(), ...action.payload });
    }

    @Action(AmendOrder)
    amendOrder({ patchState }: StateContext<IOrderStateModel>) {
        patchState({});
    }

    @Action(RemoveOrder)
    removeOrder({ patchState }: StateContext<IOrderStateModel>) {
        patchState({});
    }

    @Action(ClearOrders)
    clearOrders({ setState }: StateContext<IOrderStateModel>) {
        setState({ ...initialState } as IOrderStateModel);
    }
}
