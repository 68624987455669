import { QttTabSection } from "../models/entities/qtt-tab";

export const STANDARD_QTT_TAB: QttTabSection = {
    id: "standard",
    name: "Buy tickets",
    label: "Buy tickets"
};

export const SEASON_QTT_TAB: QttTabSection = {
    id: "season",
    name: "Flexi/Season tickets",
    label: "Buy flexi season tickets"
};

export const QTT_TABS: QttTabSection[] = [STANDARD_QTT_TAB, SEASON_QTT_TAB];
