import { SeatPreferenceLegType } from "../../entities/seat-preference-leg-type";

export class AceCreateBookingPayload {
    constructor(
        private _conversationToken: string,
        private _pointToPointRequestId: string,
        private _passengers: DataModel.Passenger[],
        private _legSolutionIds: string[],
        private _priceIds: string[],
        private _transactionFeeIds: string[],
        private _seatPreferences: DataModel.SeatPreference[] | null,
        private _seatReservationLegs: SeatPreferenceLegType
    ) {}

    public toJSON(): DataModel.AceCreateValidateBookingRecordRequest {
        return {
            conversationToken: this._conversationToken,
            pointToPointRequestId: this._pointToPointRequestId,
            passengers: this._passengers,
            legSolutionIds: this._legSolutionIds,
            priceIds: this._priceIds,
            transactionFeeIds: this._transactionFeeIds,
            seatPreferences: this._seatPreferences,
            seatReservationLegs: this._seatReservationLegs
        };
    }
}
