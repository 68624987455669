import { Injectable } from "@angular/core";
import { Action, createSelector, NgxsOnInit, Selector, State, StateContext } from "@ngxs/store";
import { tap } from "rxjs/operators";
import { ConfigAPIService } from "../../../services/config-api.service";
import { IAceConfig, ILocalConfig } from "../../models/interfaces/IAceConfig";
import { get } from "../../utilities/Utils";
import { IConfigStateModel } from "./config-state.model";
import { FetchConfig } from "./config.actions";

export const localConfig: ILocalConfig = {
    viewport: {
        mobileSmallBreakpoint: 576,
        mobileBreakpoint: 766,
        tabletBreakpoint: 991,
        desktopBreakpoint: 1200
    },
    defaultCurrency: "GBP",
    defaultCurrencySymbol: "£",
    defaultAccountForm: "login"
};

@State<IConfigStateModel>({
    name: "config"
})
@Injectable()
export class ConfigState implements NgxsOnInit {
    public static readonly STNR_FEATURE_CODE: string = "stnr";
    constructor(private configAPIService: ConfigAPIService) {}

    static featureFlag(featureName: string): (config: IAceConfig) => boolean {
        const findStnr = (obj: IConfigStateModel["features"], result: any[]) => {
            for (const key in obj) {
                if (obj.hasOwnProperty(key)) {
                    if (key === this.STNR_FEATURE_CODE) {
                        result.push(obj[key]);
                    } else {
                        findStnr(obj[key], result);
                    }
                }
            }
        };

        return createSelector([ConfigState.aceConfig], (config: IAceConfig) => {
            if (featureName && config && config.features) {
                switch (featureName) {
                    case this.STNR_FEATURE_CODE:
                        let res = [];
                        findStnr(config.features, res);
                        res = res.reduce((prev, curr) => prev.concat(Object.values(curr)), []);
                        return res.includes(true);

                    default:
                        const featureFlag = get(config.features, featureName) as boolean;
                        return featureFlag !== undefined ? featureFlag : false;
                }
            }
        });
    }

    @Selector([ConfigState])
    static aceConfig(state: IConfigStateModel): IAceConfig {
        return state ? state : null;
    }

    @Selector([ConfigState.aceConfig])
    static toc(config: IAceConfig) {
        return config && config.toc ? config.toc : null;
    }

    @Selector([ConfigState.aceConfig])
    static filterOutStations(config: IAceConfig) {
        return config && config.toc && config.toc.filterOutStations ? config.toc.filterOutStations : null;
    }

    @Selector([ConfigState.aceConfig])
    static bookingWindow(config: IAceConfig) {
        const context: string = window.innerWidth >= localConfig.viewport.desktopBreakpoint ? "web" : "mweb";
        return config && config.bookingWindow != null ? config.bookingWindow[context] : 84;
    }

    @Selector([ConfigState.aceConfig])
    static seasonbookingWindow(config: IAceConfig) {
        const context: string = window.innerWidth >= localConfig.viewport.desktopBreakpoint ? "web" : "mweb";
        return config && config.seasonBookingWindow != null ? config.seasonBookingWindow[context] : 14;
    }

    @Selector([ConfigState.aceConfig])
    static seasonFilterOutStations(config: IAceConfig) {
        return config && config.toc && config.toc.seasonFilterOutStations ? config.toc.seasonFilterOutStations : null;
    }

    @Selector([ConfigState.aceConfig])
    static preferredStations(config: IAceConfig) {
        return config && config.toc && config.toc.preferredStations ? config.toc.preferredStations : null;
    }

    @Selector([ConfigState.aceConfig])
    static maxPassenger(config: IAceConfig) {
        return config && config.maxPassengers && config.maxPassengers.default ? config.maxPassengers.default : 9;
    }

    @Selector([ConfigState.aceConfig])
    static stnr(config: IAceConfig) {
        return config && config.stnr ? config.stnr : null;
    }

    @Selector([ConfigState.aceConfig])
    static showSeatsCountdown(config: IAceConfig) {
        return config && config.showSeatsCountdown ? config.showSeatsCountdown : null;
    }

    @Selector([ConfigState.aceConfig])
    static guestCheckoutAclDelMethods(config: IAceConfig) {
        return config && config.permissions && config.permissions.guest && config.permissions.guest.allowedDeliveryMethods.length > 0
            ? config.permissions.guest.allowedDeliveryMethods
            : null;
    }

    @Action(FetchConfig)
    fetchConfig({ patchState }: StateContext<IConfigStateModel>) {
        return this.configAPIService.fetchConfig().pipe(tap(config => patchState({ ...localConfig, ...config })));
    }

    ngxsOnInit({ dispatch }: StateContext<IConfigStateModel>) {
        dispatch(new FetchConfig());
    }
}
