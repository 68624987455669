<div class="container">
    <div class="error-page">
        <div class="error-page-image">
            <div class="icon-background"><span>404</span></div>

            <div class="icon-foreground">
                <svg viewBox="0 0 24 24"><use xlink:href="#cross-roads" /></svg>
            </div>
        </div>

        <div class="error-page-heading">
            <h1 aceAnnounce="Oh no! You've stopped at the signal! Let's get you back on track. Go back to the homepage or search for a ticket.">
                Oh no! You've stopped at the signal!
            </h1>
        </div>

        <div class="error-page-content">
            <span>
                Let's get you back on track. Go back to the
                <ace-button #homepageButton id="navigate-home" ariaLabel="Go back to homepage" appearance="link" (action)="redirect()" data-elid="homepage-button"
                    >homepage</ace-button
                >
                or search for a ticket.
            </span>
        </div>

        <div class="error-page-action"><ace-button id="navigate-search" appearance="commercial" size="primary" (click)="redirect()">Search tickets</ace-button></div>
    </div>
</div>
