<div class="container">
    <div class="content-box content-floored">
        <form aceFocusInvalidField [formGroup]="form">
            <h3 data-elid="password-reset-copy">Use the fields below to reset your password.</h3>
            <div class="row">
                <div class="col col-left input-group">
                    <div class="input-label"><label>Password</label></div>
                    <input class="input" formControlName="password" type="password" placeholder="Password" data-elid="edit-password" />
                    <span class="input-error-text" *ngIf="form.get('password').hasError('restrictedChars') && form.get('password').touched">Invalid characters</span>
                    <span class="input-error-text" *ngIf="form.get('password').touched && errorMessage">{{ errorMessage }}</span>
                </div>
                <div class="col col-right input-group">
                    <div class="input-label"><label>Confirm Password</label></div>
                    <input class="input" formControlName="confirmPassword" type="password" placeholder="Confirm password" data-elid="edit-confirm-password" />
                    <span class="input-error-text" *ngIf="form.get('confirmPassword').hasError('restrictedChars') && form.get('confirmPassword').touched">Invalid characters</span>
                    <span class="input-error-text" *ngIf="form.get('confirmPassword').touched && form.hasError('nomatch')"> Passwords must match </span>
                </div>
            </div>
            <ace-button
                appearance="editorial"
                size="primary"
                buttonType="submit"
                (action)="onChangePassword()"
                [disabled]="!form.valid || inProgress"
                id="sumbit-changes-password"
                data-elid="change-password-btn"
            >
                Change password
            </ace-button>
        </form>
    </div>
</div>
