import moment from "moment";
import { AssistanceCategory, AssistanceCode } from "../entities/travel-assistance";
import { DiscountHelper } from "../../../shared/utilities/DiscountHelper";
import { isDeeplyEqual } from "../../../shared/utilities/Utils";
import { AddressValidators } from "../../validators/AddressValidators";
import { IUserCard } from "../interfaces/IUserCard";
import { AceSmartcard } from "./ace-smartcard.model";
import { AceUserBooking } from "./ace-user-booking";
import { AceUserType } from "./ace-user-type";

export class AceUser {
    public isLoggedIn: boolean;
    public userId: number;
    public username: string;
    public password: string;
    public userType: string;
    public firstName: string;
    public lastName: string;
    public dateOfBirth: string;
    public comment: string;
    public emails: DataModel.UserEmail[] = [];
    public addresses: any[] = [];
    public cards: DataModel.UserCard[] = [];
    public userCustomCards: IUserCard[] = [];
    public telephones: DataModel.UserTelephone[] = [];
    public devices: any[] = [];
    public seatPreferences: any[] = [];
    public deliveryPreferences: any[];
    public travelAssistancePreference: { id: number; value: boolean };
    public travelAssistanceInformation: { id: number; value: string };
    public bookings: AceUserBooking[] = [];
    public type: AceUserType;
    public bookingsCount: any;
    public isApproved: boolean = false;
    public age: number;
    public marketingPreferences: any[];
    public smartCards: AceSmartcard[] = [];
    public isFetchedFromApi: boolean = false;

    constructor(data?: DataModel.User) {
        if (data) {
            this.init(data);
        }
    }

    public getDateOfBirth(dateFormat: string) {
        return moment(this.dateOfBirth).format(dateFormat);
    }

    public formatDateOfBirth(day: string, month: string, year: string) {
        return moment(`${year}-${month}-${day}`);
    }

    public phoneNumber(primaryNumber: boolean = true) {
        return this.telephones.find(phone => phone.primary === primaryNumber);
    }

    public phoneNumberId(tel: string) {
        return this.telephones.find(phone => phone.telephoneNumber === tel).telephoneId;
    }

    // eslint-disable-next-line @typescript-eslint/ban-types
    public hasFormChanged(a: Object, b: Object): boolean {
        return !isDeeplyEqual(a, b);
    }

    public createPostPreferencePayload(userId: number, data: IPreferencePostInterface): DataModel.DataRequest {
        return {
            entityName: data.type,
            method: "POST",
            data: {
                userId,
                category: data.category,
                code: data.value.toUpperCase(),
                codeLabel: data.value,
                value: data.value.toUpperCase(),
                valueLabel: data.value.toUpperCase()
            }
        };
    }

    public createPutPreferencePayload(userId: number, data: IPreferencePutInterface): DataModel.DataRequest {
        return {
            entityName: data.type,
            method: "PUT",
            data: {
                preferenceId: data.preferenceId,
                values: {
                    userId,
                    category: data.category,
                    code: data.value.toUpperCase(),
                    codeLabel: data.value,
                    value: data.value.toUpperCase(),
                    valueLabel: data.value.toUpperCase()
                }
            }
        };
    }

    public createDeletePreferencePayload(type: string, preferenceId: number): DataModel.DataRequest {
        return {
            entityName: type,
            method: "DELETE",
            data: {
                preferenceId
            }
        };
    }

    public createUpdateNUSCardPayload(userId: number, cardNumber: string): DataModel.DataRequest {
        return {
            entityName: "UserCustomCard",
            method: "POST",
            data: {
                data: {
                    number: cardNumber
                },
                type: DiscountHelper.DISCOUNT_TYPE_NUS,
                userId
            }
        };
    }

    public userIsGuest(): boolean {
        return this.userType === "GUEST";
    }

    public setUserBookings(bookings: [any], config) {
        this.bookings = [];
        return bookings.map(booking => this.bookings.push(new AceUserBooking(booking, config)));
    }

    public getUserBookings() {
        const bookings = this.bookings.filter(booking => booking.bookingStatus !== "DEBIT");
        return bookings.filter(booking => !booking.orders.every(order => ["RELEASED", "ABANDONED"].includes(order.status)));
    }

    public getNusCardId(): string {
        const nusCard = this.userCustomCards.find((customCard: DataModel.UserCustomCard) => customCard.type === DiscountHelper.DISCOUNT_TYPE_NUS);
        if (!nusCard) {
            return "";
        }
        return nusCard.data.number;
    }

    public getDeliveryPreference() {
        return this.hasDeliveryPreference() ? this.deliveryPreferences[this.deliveryPreferences.length - 1] : null;
    }

    public hasDeliveryPreference() {
        return this.deliveryPreferences.length > 0;
    }

    public getDefaultAddress() {
        const address = this.addresses.find(it => it.primary === true) || this.addresses[0];
        if (address) {
            const address1Components: string[] = address.addressLine1 ? address.addressLine1.split(", ") : [""];
            const address2Components: string[] = address.addressLine2 ? address.addressLine2.split(", ") : [""];
            return {
                address: {
                    line1: address1Components[0],
                    line2: address1Components.length > 1 ? address1Components[1] : "",
                    line3: address2Components.length ? address2Components[0] : "",
                    line4: address2Components.length > 1 ? address2Components[1] : "",
                    stateProv: address.county ? address.county : null,
                    city: address.city,
                    country: address.country,
                    addressType: "BUSINESS",
                    postcode: address.postCode
                },
                name: `${this.firstName} ${this.lastName}`
            };
        } else {
            return null;
        }
    }

    public updateSmartCards(smartCards: Array<ORM.Core.Transport.SmartCard>): void {
        this.smartCards = smartCards.map(card => new AceSmartcard(card));
    }

    public isPaymentCardTokenExpired(userCardId: string) {
        const paymentCard = this.cards && this.cards.find(it => it.cardId === userCardId);
        if (paymentCard != null) {
            return !paymentCard.token || moment().diff(moment(paymentCard.createdAt), "months") >= 12 ? true : false;
        }
    }

    private init(data: DataModel.User): void {
        this.userId = data.userId;
        this.firstName = data.firstName;
        this.lastName = data.lastName;
        this.userType = data.type;
        this.username = data.userName;
        this.password = data.password;
        this.comment = data.comment;
        this.addresses = data.addresses;
        this.emails = data.emails;
        this.cards = data.cards;
        this.userCustomCards = data.userCustomCards as IUserCard[];
        this.devices = data.devices;
        this.dateOfBirth = data.dateOfBirth;
        this.telephones = data.telephones;
        this.isApproved = typeof data.isApproved === "boolean" && data.isApproved;

        this.bookingsCount = data.bookingsCount;

        if (data.smartCards && data.smartCards.length > 0) {
            this.updateSmartCards(data.smartCards);
        }

        if (data.type === "MEMBER" && data.addresses != null && data.addresses.length > 0) {
            this.addresses = this.filterOutInvalidAddresses(this.stripWhitespacesFromAddresses(data.addresses));
        }

        switch (data.type) {
            case "MEMBER":
                this.isLoggedIn = true;
                this.type = AceUserType.MEMBER;
                break;
            case "GUEST":
                this.isLoggedIn = true;
                this.type = AceUserType.GUEST;
                break;
            case "ANONYMOUS":
                this.isLoggedIn = false;
                this.type = AceUserType.ANONYMOUS;
                break;
            default:
                throw new Error(`Unknown user type encountered: '${data.type}'`);
        }

        this.seatPreferences = this.parseSeatingPreferences(data.preferences);
        this.deliveryPreferences = this.parseDeliveryPreferences(data.preferences);
        this.travelAssistancePreference = this.parseTravelAssistancePreference(data.preferences);
        this.travelAssistanceInformation = this.parseTravelAssistanceNotes(data.preferences);
        this.marketingPreferences = this.parseMarketingPreferences(data.preferences);
    }

    private stripWhitespacesFromAddresses(addresses: DataModel.UserAddress[]): DataModel.UserAddress[] {
        addresses
            .filter(it => it != null)
            .forEach(address => {
                Object.keys(address).forEach(key => {
                    if (address[key] != null && typeof address[key] === "string") {
                        address[key] = address[key].replace(/\s+/g, " ").trim();
                    }
                });
            });

        return addresses;
    }

    private filterOutInvalidAddresses(addresses: DataModel.UserAddress[]): DataModel.UserAddress[] {
        return addresses.filter(
            address =>
                address.addressLine1.length > 0 &&
                address.country &&
                address.country.length > 0 &&
                address.city &&
                address.city.length > 0 &&
                address.postCode &&
                address.postCode.length > 0 &&
                AddressValidators.postcodeRegexpMatch(address.postCode, address.country)
        );
    }

    private parseMarketingPreferences(preferences: DataModel.UserPreference[]): DataModel.UserPreference[] {
        const result = [];

        preferences.map(pref => {
            if (["MARKETING"].indexOf(pref.category) > -1) {
                result.push({
                    category: pref.category,
                    code: pref.code,
                    codeLabel: pref.codeLabel,
                    value: pref.value,
                    valueLabel: pref.valueLabel,
                    userId: pref.userId
                });
            }
        });

        return result;
    }

    private parseSeatingPreferences(preferences: DataModel.UserPreference[]): DataModel.UserPreference[] {
        const result = [];

        preferences.map(pref => {
            if (["SEAT_AMENITY", "POSITION", "COACH", "DIRECTION"].indexOf(pref.category) > -1) {
                result.push({
                    code: pref.code,
                    label: pref.codeLabel,
                    id: pref.preferenceId
                });
            }
        });

        return result;
    }

    private parseDeliveryPreferences(preferences: DataModel.UserPreference[]): DataModel.UserPreference[] {
        const result = [];

        preferences.map(pref => {
            if (["DELIVERY_METHOD"].indexOf(pref.category) > -1) {
                result.push({
                    code: pref.code,
                    id: pref.preferenceId
                });
            }
        });

        return result;
    }

    private parseTravelAssistancePreference(preferences: DataModel.UserPreference[]): { id: number; value: boolean } {
        const assistance = preferences
            .filter(preference => preference.category === AssistanceCategory.TRAVEL_ASSISTANCE && preference.code === AssistanceCode.NONE)
            .sort((a, b) => b.preferenceId - a.preferenceId)
            .sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime())
            .sort((a, b) => new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime())
            .find(preference => preference);

        return {
            id: assistance && assistance.preferenceId,
            value: assistance && ["1", "true"].includes(assistance?.value)
        };
    }

    private parseTravelAssistanceNotes(preferences: DataModel.UserPreference[]): { id: number; value: string } {
        const assistance = preferences
            .filter(preference => preference.category === AssistanceCategory.TRAVEL_ASSISTANCE && preference.code === AssistanceCode.ADDITIONAL_INFORMATION)
            .sort((a, b) => b.preferenceId - a.preferenceId)
            .sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime())
            .sort((a, b) => new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime())
            .find(preference => preference);

        return {
            id: assistance && assistance.preferenceId,
            value: assistance && assistance.value
        };
    }
}

interface IPreferencePostInterface {
    type: string;
    category: string;
    code: string;
    value: string;
}

interface IPreferencePutInterface {
    type: string;
    category: string;
    value: string;
    preferenceId: number;
}
