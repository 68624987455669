import { IQueryParams } from "../interfaces/IQueryParams";
import { PassengerCriteria } from "./passenger-criteria";

export abstract class SearchPayloadCriteria {
    public params: IQueryParams;
    public isSeasons: boolean;
    public isReturn: boolean;
    public isOpenReturn: boolean;

    private ADULT_AGE: number = 18;
    private CHILD_AGE: number = 10;

    constructor(query: IQueryParams) {
        this.params = query;
        this.isReturn = this.params.inbound === "true";
        this.isOpenReturn = this.params.openReturn === "true";
        this.isSeasons = this.params.startDate !== undefined;
    }

    public generateHash(): string {
        let hash = 0;
        const payloadString = JSON.stringify(this.generatePayloadData());

        for (let i = 0; i < payloadString.length; i++) {
            const char = payloadString.charCodeAt(i);
            hash = (hash << 5) - hash + char;
            hash = hash & hash; // Convert to 32bit integer
        }
        return String(Math.abs(hash));
    }

    protected generatePassengersManifest(): DataModel.PointToPointShoppingQueryPassengerSpec[] {
        const manifest = [];
        let passengerCount = 1;

        for (let i = 0; i < this.params.adults; i++, passengerCount++) {
            manifest.push(new PassengerCriteria(passengerCount.toString(), this.ADULT_AGE));
        }

        for (let i = 0; i < this.params.children; i++, passengerCount++) {
            manifest.push(new PassengerCriteria(passengerCount.toString(), this.CHILD_AGE));
        }

        return manifest;
    }

    public abstract generatePayloadData(carrierRestrictions?: DataModel.CarrierRestrictions, addFareQualifiers?: boolean): object;
}
