import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ReplaySubject } from "rxjs";
import { map } from "rxjs/operators";

@Injectable({
    providedIn: "root"
})
export class TocContentService {
    // todo: swap lines below to remove static content JSON loading
    // private contentUrl: string = environment.cmsUrl + this.contentPath;
    private contentUrl: string = "//" + location.host + "/assets/json/content.json";

    private staticContent$: ReplaySubject<Object> = new ReplaySubject(1);

    constructor(private http: HttpClient) {
        const contentRequest$ = this.http.get<DataModel.StandardResponse>(this.contentUrl).pipe(map(res => res.data as IDrupalDictionaryItem[]));

        contentRequest$?.subscribe(data => {
            const dictionary = new Object();
            data.forEach(item => (dictionary[item.attributes.name] = item.attributes.description.value));
            this.staticContent$.next(dictionary);
        });
    }

    public get content$() {
        return this.staticContent$;
    }
}

export interface IDrupalDictionaryItem {
    attributes: IDrupalAttribute;
}

export interface IDrupalAttribute {
    name: string;
    description: IDrupalContent;
}

export interface IDrupalContent {
    value: string;
    format: string;
}
