import { AfterViewInit, Component, Input, QueryList, ViewChildren } from "@angular/core";
import { Router } from "@angular/router";
import { Observable } from "rxjs";
import { IDrupalFaqItem, TocFaqService } from "../../../../services/toc-faq.service";
import { AccordionComponent } from "../../../../shared/components/accordion/accordion.component";
import { ModalContainer } from "../modal-container.component";

@Component({
    selector: "ace-modal-help",
    templateUrl: "help-modal.component.html",
    styleUrls: ["help-modal.component.scss"]
})
export class HelpModalComponent extends ModalContainer implements AfterViewInit {
    @Input() public handleCTAClick: Function;
    @Input() public cta: string = "cta";
    @Input() public group: string;
    @ViewChildren("accordionItem") public accordionItem: QueryList<AccordionComponent>;
    public body: any[];
    public data: Observable<IDrupalFaqItem[]>;

    constructor(private tocFaqService: TocFaqService, private router: Router) {
        super();

        this.data = this.tocFaqService.reduceGroup(this.urlLookup(this.router.url));
    }

    public ngAfterViewInit() {
        this.addSubscription(
            this.data.subscribe(() => {
                setTimeout(() => {
                    this.focusFirstAccordionItem();
                }, 0);
            })
        );
    }

    public onButtonClicked() {
        this.handleCTAClick(this);
    }

    private focusFirstAccordionItem(): void {
        const accordionItemsArray = this.accordionItem?.toArray();

        if (accordionItemsArray.length > 0) {
            accordionItemsArray[0].headElement?.nativeElement?.focus();
        }
    }

    private urlLookup(url: string) {
        let urlSegment = "";

        url.match(/B-[A-Za-z0-9]+-[A-Za-z0-9]+/) ? (urlSegment = url) : (urlSegment = /[^\/(?!booking)\??]*\/(?!booking)\??[^\/\??]*/.exec(url).toString());

        switch (urlSegment) {
            case "/":
                return "General";
            case "/search":
            case "/season":
                return "Tickets";
            case "/account":
                return "Accounts";
            case "/seats-and-extras":
                return "Delivery and Extras";
            case "/summary":
                return "Review and Pay";
            case "/confirmation":
                return "Confirmation";
            case urlSegment:
                return "Refunds";
            default:
                return;
        }
    }
}
