<div role="grid" [attr.id]="'calendar-' + card.id + '-card'" [attr.aria-labelledby]="'calendar-' + card.id + '-card-label'">
    <div class="month-name" data-elid="month-name" [attr.id]="'calendar-' + card.id + '-card-label'">{{ card.label }}</div>
    <div class="week-heading" role="row">
        <div *ngFor="let header of card.headings" class="day-heading" role="colheader" [attr.abbr]="header.fullName">{{ header.shortName }}</div>
    </div>
    <div *ngFor="let week of card.weeks" class="week" role="row">
        <ng-template ngFor let-day [ngForOf]="week">
            <button
                *ngIf="!disabled; else disabledTemplate"
                role="gridcell"
                class="day"
                aceCalendarDay
                [dateString]="day.dateAsString"
                (updateDate)="onUpdateDate($event)"
                (closeCalendar)="onCloseCalendar($event)"
                [isDaySelected]="day.dateAsString === selectedDateString && day.isEnabled !== false"
                [disabled]="!day.isEnabled"
                [class.day-today]="day.isToday"
                [class.day-selected]="day.dateAsString === selectedDateString && day.isEnabled !== false"
                [attr.aria-selected]="day.dateAsString === selectedDateString && day.isEnabled !== false"
                [tabindex]="day.dateAsString === selectedDateString && day.isEnabled !== false ? 0 : -1"
                (click)="dayClickHandler(day)"
            >
                <span class="label-wrap">
                    {{ day.label }}
                </span>
            </button>

            <ng-template #disabledTemplate>
                <div class="day" [class.day-disabled]="true" role="gridcell">
                    <span class="label-wrap">
                        {{ day.label }}
                    </span>
                </div>
            </ng-template>
        </ng-template>
    </div>
</div>
