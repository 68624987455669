const CANCELLED_STATUS = "cancelled";

export const promotedServicesConverter = (data: DataModel.PointToPointShoppingResponse) => {
    const legs = data?.results?.legs ?? [];
    const [outLeg, rtnLeg] = legs;

    const outgoingResults = outLeg
        ? [
              ...outLeg.legSolutions
                  .map(leg => ({
                      arrivalDateTime: leg.travelSegments[0].arrivalDateTime,
                      departureDateTime: leg.travelSegments[leg.travelSegments.length - 1].departureDateTime,
                      isCancelled: leg.facets != null && leg.facets.some(facet => facet.keyname === CANCELLED_STATUS),
                      travelSegments: leg.travelSegments
                  }))
                  .sort((a, b) => (a.departureDateTime < b.departureDateTime ? -1 : 1))
          ]
        : undefined;

    const returnResults = rtnLeg
        ? [
              ...rtnLeg.legSolutions
                  .map(leg => ({
                      arrivalDateTime: leg.travelSegments[0].arrivalDateTime,
                      departureDateTime: leg.travelSegments[leg.travelSegments.length - 1].departureDateTime,
                      isCancelled: leg.facets != null && leg.facets.some(facet => facet.keyname === CANCELLED_STATUS),
                      travelSegments: leg.travelSegments
                  }))
                  .sort((a, b) => (a.departureDateTime < b.departureDateTime ? -1 : 1))
          ]
        : undefined;

    return { outgoingResults, returnResults };
};
