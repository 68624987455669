import moment from "moment";
import { flatten, uniqDeepEqual } from "../../../shared/utilities/Utils";
import { AceTicketableFare } from "./ace-ticketable-fare.model";
import { AceTravelSegment } from "./ace-travel-segment.model";

export class AceBookingLeg {
    public legID: string;
    public originTravelPoint: DataModel.TravelPoint;
    public destinationTravelPoint: DataModel.TravelPoint;
    public departureDateTime: moment.Moment;
    public arrivalDateTime: moment.Moment;
    public changes: number;
    public duration: { label: string; value: string };
    public travelSegments: AceTravelSegment[] = [];
    public fares: AceTicketableFare[] = [];
    public totalPrice: IPrice;
    public seatAssignment: ISegmentSeat[];

    constructor(legID: string, segments: DataModel.TravelSegment[], fares: DataModel.TicketableFare[] | AceTicketableFare[]) {
        this.legID = legID;

        // only apply if segments exist otherwise
        // its a seasons order.
        if (segments.length) {
            this.travelSegments = segments.map(seg => new AceTravelSegment(seg));
            this.originTravelPoint = this.travelSegments[0].originTravelPoint;
            this.destinationTravelPoint = this.travelSegments[this.travelSegments.length - 1].destinationTravelPoint;

            this.departureDateTime = this.travelSegments[0].departureDateMoment;
            this.arrivalDateTime = this.travelSegments[this.travelSegments.length - 1].arrivalDateMoment;
            this.changes = this.travelSegments.length - 1;

            // TODO: this duration calculation needs investigation - looks like it did not work after better typing
            let durationMin: string | number = this.travelSegments[this.changes].arrivalDateMoment.diff(this.departureDateTime, "minutes");

            if (durationMin > 60) {
                durationMin = Math.floor(durationMin / 60) + "h " + (durationMin % 60) + "m";
            } else if (Math.floor(durationMin % 60) === 0) {
                durationMin = Math.floor(durationMin / 60) + "h";
            } else {
                durationMin = (durationMin % 60) + "m";
            }

            this.duration = { label: durationMin, value: durationMin };

            if (fares[0] instanceof AceTicketableFare) {
                this.fares = fares as AceTicketableFare[];
            } else {
                fares.map((fare, index) => {
                    return fare.passengerReferences.map(passenger => {
                        return passenger.fareCodes.map(code => {
                            this.travelSegments.map(seg => {
                                if (code.travelSegmentIDRef === seg.travelSegmentID) {
                                    this.fares.push(new AceTicketableFare(fares[index] as DataModel.TicketableFare));
                                }
                            });
                        });
                    });
                });
            }

            this.fares = uniqDeepEqual(this.fares);
            this.seatAssignment = flatten(this.fares.map(fare => fare.seatAssignment));
            this.travelSegments.forEach(seg => {
                seg.seatAssignment = this.seatAssignment.filter(seat => seat.travelSeg === seg.travelSegmentID);
            });
        }

        const price = this.fares
            .reduce((prev, current) => {
                return prev + current.totalPrice.value;
            }, 0)
            .toFixed(2);

        this.totalPrice = {
            currency: "GBP",
            isEstimated: false,
            value: segments.length === 1 && this.legID.includes("_2") && fares.length <= 1 ? 0 : Number(price)
        };
    }
}

interface IPrice {
    currency: string;
    isEstimated: boolean;
    value: number;
}

interface ISegmentSeat {
    travelSeg: string;
    seats: any;
}
