import { Pipe, PipeTransform } from "@angular/core";

/*
 * Returns plural/singular form of noun according to number
 * Useful for UI messages based on a numeric value
 *
 * Usage:
 *   num | pluralise: "noun" => return amount and plural or singular
 *   num | pluralise: "singularNoun" => with 'Noun' suffix, return pluarl or singular only
 *
 * Example:
 *   {{ order.amount | pluralise: "order" }} => formats to: "1 order" or "2 orders"
 *   {{ order.amount | pluralise: "orderNoun" }} => formats to: "order" or "orders"
 */
@Pipe({ name: "pluralise" })
export class PluralisePipe implements PipeTransform {
    public transform(num: number, value: string): string {
        const noun = value;
        const suffix = num > 1 ? "s" : ""; // pluralise
        num = Math.floor(num);

        if (noun.toLowerCase() === "change" && num === 0) {
            return "Direct";
        } else if (noun.toLowerCase() === "child" && num !== 1) {
            return num + " " + noun[0] + "hildren"; // return same first letter case
        } else if (noun.toLowerCase() === "adult" && num !== 1) {
            return num + " " + noun[0] + "dults"; // return same first letter case
        } else if (noun.toLowerCase() === "person" && num > 1) {
            return num + " people";
        } else if (noun.includes("Noun")) {
            return noun.replace(/Noun/g, "") + suffix;
        } else {
            return num + " " + noun + suffix;
        }
    }
}
