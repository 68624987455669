import { Component, Input } from "@angular/core";
import { environment } from "./../../../../environments/environment";

@Component({
    selector: "ace-loading-indicator",
    templateUrl: "loading-indicator.component.html",
    styleUrls: ["loading-indicator.component.scss"]
})
export class LoadingIndicatorComponent {
    @Input() public size: number = 16;
    @Input() public default: boolean;
    public tocId: string = environment.tocId;
}
