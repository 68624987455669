import { Injectable } from "@angular/core";
import { Action, Selector, State, StateContext } from "@ngxs/store";
import { DeliveryModel } from "./delivery-state.model";
import { UpdatePhotocard, ClearDelivery } from "./delivery.actions";

export const initialState: DeliveryModel = {
    photocard: true
};

@State<DeliveryModel>({
    name: "delivery",
    defaults: initialState
})
@Injectable()
export class DeliveryState {
    @Selector([DeliveryState])
    static delivery(state: DeliveryModel): DeliveryModel {
        return state ? state : null;
    }

    @Selector([DeliveryState.delivery])
    static photocard(delivery: DeliveryModel) {
        const { photocard } = delivery;
        return photocard != null ? photocard : true;
    }

    @Action(UpdatePhotocard)
    updatePhotocard({ patchState }: StateContext<DeliveryModel>, action: UpdatePhotocard) {
        patchState(action.payload);
    }

    @Action(ClearDelivery)
    clearDelivery({ setState }: StateContext<DeliveryModel>) {
        setState({ ...initialState });
    }
}
