<div class="row trave-time-container">
    <div class="col col-8">
        <label class="sr-only" [attr.for]="directionId != null ? 'travel-option-' + directionId + '-select' : undefined"
            ><span class="sr-only">{{ directionId }} </span>Travel option</label
        >
        <ace-select
            [selectId]="directionId != null ? 'travel-option-' + directionId + '-select' : undefined"
            [theme]="theme"
            [disabled]="disabled"
            [options]="departureOptions"
            (optionSelect)="onDepartureOptionSelected($event)"
            data-elid="qtt-departure-options"
        ></ace-select>
    </div>
    <div class="col col-4">
        <label class="sr-only" [attr.for]="directionId != null ? 'travel-option-' + directionId + '-time-select' : undefined"
            ><span class="sr-only">{{ directionId }} </span>{{ departureOption === TRAVEL_OPTIONS.ARRIVAL ? "Arrival time" : "Departure time" }}</label
        >
        <ace-select
            [selectId]="directionId != null ? 'travel-option-' + directionId + '-time-select' : undefined"
            [theme]="theme"
            [disabled]="disabled"
            [options]="timeOptions"
            (optionSelect)="onTimeOptionSelected($event)"
            data-elid="qtt-time-select"
        ></ace-select>
    </div>
</div>
