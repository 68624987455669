import { Injectable } from "@angular/core";
import { Action, Selector, State, StateContext } from "@ngxs/store";
import { DIRECT_FARE_FILTER, FASTEST_FARE_FILTER, OFFPEAK_FARE_FILTER, OPERATOR_FARE_FILTER } from "../../constants/fare-filters";
import { FareFilterType, FareFilterEvent } from "../../models/entities/fare";
import { AceBookingOrder } from "../../models/ace/ace-booking-order";
import { Direction } from "../../enums/Direction";
import { AceSelection } from "../../models/ace/ace-selection.model";
import { JourneyStateModel } from "./journey-state.model";
import {
    ActiveJourneyAmend,
    FilterFares,
    UpdateJourneyDetails,
    ClearFareFilter,
    UpdateJourneyAmend,
    UpdateJourneyAmendOrder,
    UpdateJourneyAmendBooking,
    UpdateJourneyAmendDirection,
    UpdateJourneyExchangeSummary,
    ClearJourneyState,
    UpdateJourneyExchangeSelection,
    UpdateQTTDialog
} from "./journey.actions";

export const initialState: JourneyStateModel = {
    fareFilters: {
        operator: { ...OPERATOR_FARE_FILTER, enabled: false },
        direct: { ...DIRECT_FARE_FILTER, enabled: false },
        offpeak: { ...OFFPEAK_FARE_FILTER, enabled: false },
        fastest: { ...FASTEST_FARE_FILTER, enabled: false }
    },
    details: {
        fastestLegs: {
            out: "",
            rtn: ""
        }
    },
    amendable: {
        isJourneyAmendable: false,
        openQTTDialog: false
    },
    currentAmendingOrder: new AceBookingOrder(),
    currentAmendingBooking: {},
    amendedJourneyDirection: Direction.OUT,
    exchangeSummary: {},
    exchangeSelection: {}
};

@State<JourneyStateModel>({
    name: "journey",
    defaults: initialState
})
@Injectable()
export class JourneyState {
    @Selector([JourneyState])
    static journey(state: JourneyStateModel): JourneyStateModel {
        return state ? state : null;
    }

    @Selector([JourneyState.journey])
    static fareFilters(journey: JourneyStateModel): Record<FareFilterType, FareFilterEvent> | null {
        return (journey && journey.fareFilters) || null;
    }

    @Selector([JourneyState.journey])
    static fastestLegs(journey: JourneyStateModel): { out: string; rtn: string } | null {
        return (journey && journey.details.fastestLegs) || null;
    }

    @Selector([JourneyState.journey])
    static isJourneyAmendable(journey: JourneyStateModel): boolean | null {
        return (journey && journey.amendable.isJourneyAmendable) || null;
    }

    @Selector([JourneyState.journey])
    static canOpenQTTDialog(journey: JourneyStateModel): boolean | null {
        return (journey && journey.amendable.openQTTDialog) || null;
    }

    @Selector([JourneyState.journey])
    static currentAmendOrder(journey: JourneyStateModel): AceBookingOrder | null {
        return (journey && journey.currentAmendingOrder) || null;
    }

    @Selector([JourneyState.journey])
    static currentAmendBooking(journey: JourneyStateModel): object | null {
        return (journey && journey.currentAmendingBooking) || null;
    }

    @Selector([JourneyState.journey])
    static journeyAmendDirection(journey: JourneyStateModel): Direction | null {
        return journey.amendedJourneyDirection;
    }

    @Selector([JourneyState.journey])
    static exchangeSummaryOption(journey: JourneyStateModel): object | null {
        return (journey && journey.exchangeSummary) || null;
    }

    @Selector([JourneyState.journey])
    static journeyExchangeSelection(journey: JourneyStateModel): object | null {
        return (journey && journey.exchangeSelection) || null;
    }

    @Action(FilterFares)
    filterFares({ getState, patchState }: StateContext<JourneyStateModel>, action: FilterFares) {
        const { id } = action.payload;
        const state = getState();

        patchState({
            fareFilters: {
                ...state.fareFilters,
                [id]: action.payload
            }
        });
    }

    @Action(UpdateJourneyDetails)
    updateJourneyDetails({ getState, patchState }: StateContext<JourneyStateModel>, action: UpdateJourneyDetails) {
        const state = getState();

        patchState({
            details: {
                ...state.details,
                ...action.payload
            }
        });
    }

    @Action(ActiveJourneyAmend)
    activeJourneyAmend({ getState, patchState }: StateContext<JourneyStateModel>, action: ActiveJourneyAmend) {
        const state = getState();

        patchState({
            amendable: {
                isJourneyAmendable: action.payload,
                openQTTDialog: state.amendable.openQTTDialog
            }
        });
    }

    @Action(UpdateJourneyAmend)
    updateJourneyAmend({ getState, patchState }: StateContext<JourneyStateModel>, action: ActiveJourneyAmend) {
        const state = getState();

        patchState({
            amendable: {
                isJourneyAmendable: action.payload,
                openQTTDialog: state.amendable.openQTTDialog
            }
        });
    }

    @Action(UpdateQTTDialog)
    updateQTTDialog({ getState, patchState }: StateContext<JourneyStateModel>, action: UpdateQTTDialog) {
        const state = getState();

        patchState({
            amendable: {
                isJourneyAmendable: state.amendable.isJourneyAmendable,
                openQTTDialog: action.payload
            }
        });
    }

    @Action(UpdateJourneyAmendOrder)
    updateJourneyAmendOrder({ getState, patchState }: StateContext<JourneyStateModel>, action: UpdateJourneyAmendOrder) {
        const state = getState();
        const journeyDetails = state.details;

        patchState({
            currentAmendingOrder: action.payload
        });
    }

    @Action(UpdateJourneyAmendBooking)
    updateJourneyAmendBooking({ getState, patchState }: StateContext<JourneyStateModel>, action: UpdateJourneyAmendBooking) {
        const state = getState();

        patchState({
            currentAmendingBooking: action.payload
        });
    }

    @Action(UpdateJourneyAmendDirection)
    updateJourneyAmendDirection({ getState, patchState }: StateContext<JourneyStateModel>, action: UpdateJourneyAmendDirection) {
        const state = getState();

        patchState({
            amendedJourneyDirection: action.payload
        });
    }

    @Action(UpdateJourneyExchangeSummary)
    updateJourneyExchangeSummary({ getState, patchState }: StateContext<JourneyStateModel>, action: UpdateJourneyExchangeSummary) {
        const state = getState();

        patchState({
            exchangeSummary: action.payload
        });
    }

    @Action(UpdateJourneyExchangeSelection)
    UpdateJourneyExchangeSelection({ getState, patchState }: StateContext<JourneyStateModel>, action: UpdateJourneyExchangeSelection) {
        const state = getState();

        patchState({
            exchangeSelection: action.payload
        });
    }

    @Action(ClearFareFilter)
    clearDelivery({ getState, patchState }: StateContext<JourneyStateModel>, action: UpdateJourneyDetails) {
        const state = getState();
        patchState({
            fareFilters: initialState.fareFilters,
            details: state.details
        });
    }

    @Action(ClearJourneyState)
    clearJourneyState({ setState }: StateContext<JourneyStateModel>) {
        setState({ ...initialState });
    }
}
