export class PassengerCriteria {
    private id: string;
    private age: number;

    constructor(id: string, age: number) {
        this.id = id;
        this.age = age;
    }

    public toJSON(): object {
        return {
            passengerSpecID: "pax_" + this.id,
            age: this.age
        };
    }
}
