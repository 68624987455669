<div class="container">
    <ace-loading-indicator [default]="false" *ngIf="isLoading"></ace-loading-indicator>

    <div class="content-box content-floored" *ngIf="!isLoading">
        <ng-container *ngIf="!verifyUserErrorMessage">
            <h1>Verification Success</h1>
            <p>Thanks for verifying your account. You can now use all the features on our site and the mobile app.</p>
        </ng-container>

        <ng-container *ngIf="verifyUserErrorMessage">
            <h1>Verification Error</h1>
            <p>{{ verifyUserErrorMessage }}</p>
        </ng-container>

        <ace-button appearance="commercial" size="primary" internalLink="/" id="navigate-home">Buy Tickets</ace-button>
    </div>
</div>
