import { Directive, ElementRef, EventEmitter, HostListener, Input, OnChanges, Output, SimpleChanges } from "@angular/core";
import { addDays, endOfMonth, format, isValid, parse, startOfMonth, subDays } from "date-fns";
import { DATE_TIME_STRING_FORMAT } from "../constants/date";

@Directive({
    selector: "[aceCalendarDay]"
})
export class CalendarDayDirective implements OnChanges {
    @Input() public isDaySelected: boolean;
    @Input() public dateString: string;
    @Output() public updateDate: EventEmitter<string> = new EventEmitter();
    @Output() public closeCalendar: EventEmitter<undefined> = new EventEmitter();

    constructor(private el: ElementRef<HTMLButtonElement>) {}

    @HostListener("keydown", ["$event"])
    public onKeyDown(event: KeyboardEvent) {
        switch (event.key) {
            case "ArrowUp":
            case "Up":
            case "ArrowDown":
            case "Down":
            case "ArrowLeft":
            case "Left":
            case "ArrowRight":
            case "Right":
                event.preventDefault();
                break;

            default:
                break;
        }
    }

    @HostListener("keyup", ["$event"])
    public onKeyUp(event: KeyboardEvent) {
        const parsedDate = parse(this.dateString, DATE_TIME_STRING_FORMAT, new Date());
        let newDate: Date;

        if (isValid(parsedDate) === false) {
            return;
        }

        switch (event.key) {
            case "ArrowUp":
            case "Up":
                newDate = subDays(parsedDate, 7);

                break;
            case "ArrowDown":
            case "Down":
                newDate = addDays(parsedDate, 7);

                break;
            case "ArrowLeft":
            case "Left":
                newDate = subDays(parsedDate, 1);

                break;
            case "ArrowRight":
            case "Right":
                newDate = addDays(parsedDate, 1);

                break;
            case "Home":
                newDate = startOfMonth(parsedDate);

                break;
            case "End":
                newDate = endOfMonth(parsedDate);

                break;
            case "Enter":
            case " ":
            case "Spacebar":
                this.closeCalendar.emit();

                break;
            default:
                break;
        }

        if (newDate != null) {
            this.updateDate.emit(format(newDate, DATE_TIME_STRING_FORMAT));
        }
    }

    public ngOnChanges(changes: SimpleChanges): void {
        const currentIsDaySelected = (changes?.isDaySelected?.currentValue as boolean) ?? undefined;

        if (currentIsDaySelected === true) {
            setTimeout(() => {
                this.el.nativeElement?.focus();
            }, 0);
        }
    }
}
