import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { Store } from "@ngxs/store";
import { merge as mergeObj } from "lodash";
import moment from "moment";
import { BehaviorSubject, combineLatest, forkJoin, merge, Observable, of, Subject, throwError } from "rxjs";
import { catchError, distinctUntilChanged, filter, finalize, map, mapTo, mergeMap, switchMap, take, tap, withLatestFrom } from "rxjs/operators";
import { PromptComponent } from "../modal/components/modals/prompt/prompt.component";
import { StandardErrorModalComponent } from "../modal/components/modals/standard-error/standard-error-modal.component";
import { ModalService } from "../modal/services/modal.service";
import { BookingPassengerSmartCardTicket } from "../shared/classes/BookingPassengerSmartCardTicket";
import { AceBooking } from "../shared/models/ace/ace-booking";
import { AceBookingRecordInformation } from "../shared/models/ace/ace-booking-record-information.model";
import { AceSelection } from "../shared/models/ace/ace-selection.model";
import { AceTicketingOption } from "../shared/models/ace/ace-ticketing-option.model";
import { AceUser } from "../shared/models/ace/ace-user.model";
import { AceCreateBookingPayload } from "../shared/models/ace/payloads/ace-create-booking-payload";
import { AceCreateSeasonsBookingPayload } from "../shared/models/ace/payloads/ace-create-season-booking-payload";
import { AceCreateTicketingOptionPayload } from "../shared/models/ace/payloads/ace-create-ticketing-option-payload";
import { AcePassenger } from "../shared/models/ace/payloads/ace-passenger";
import { UpdateSmartcards } from "../shared/state/account/account.actions";
import { AccountState } from "../shared/state/account/account.state";
import { RemoveOrder, ClearOrders } from "../shared/state/order/order.actions";
import { OrderState } from "../shared/state/order/order.state";
import { DeliveryPreferencesHelper } from "../shared/utilities/DeliveryPreferencesHelper";
import { get } from "../shared/utilities/Utils";
import { AceRetrieveExchangeSummaryPayload } from "../shared/models/ace/payloads/ace-retrieve-exchange-summary-payload";
import { AceBookingOrder } from "../shared/models/ace/ace-booking-order";
import { AceUserType } from "../shared/models/ace/ace-user-type";
import { AceUserBooking } from "../shared/models/ace/ace-user-booking";
import { BookingService } from "./booking.service";
import { LocalCacheService } from "./local-cache.service";
import { LocalStorageService } from "./local-storage.service";
import { LoggerService } from "./logger.service";
import { NotifyToastService } from "./notify-toast.service";
import { SearchSelectionContextService } from "./search-selection-context.service";
import { SmartcardService } from "./smartcard.service";
import { ConfigService } from "./config.service";

@Injectable({
    providedIn: "root"
})
export class BasketService {
    public static readonly CACHE_KEY = "bookingId";
    public isBasketReady$: Observable<boolean>;
    public isQuickBuy$ = new BehaviorSubject<boolean>(false);
    public isBasketRefreshing$: Observable<boolean>;
    public booking$: BehaviorSubject<AceBooking>;
    public bookingId$: Observable<string>;
    public bookingRecord$: BehaviorSubject<AceBookingRecordInformation>;
    public canContinue$ = new BehaviorSubject<boolean>(null);
    public bookingSucceeded$ = new Subject<boolean>();
    private refreshBasketSubject: Subject<number>;
    private bookingIdSubject$: BehaviorSubject<string>;
    public isBasketRefreshingSubject$: BehaviorSubject<boolean>;

    constructor(
        public bookingService: BookingService,
        private store: Store,
        private cache: LocalCacheService,
        private localStorage: LocalStorageService,
        private modalService: ModalService,
        private router: Router,
        private selectionContext: SearchSelectionContextService,
        private toastService: NotifyToastService,
        private loggerService: LoggerService,
        private smartcardService: SmartcardService,
        private _config: ConfigService
    ) {
        this.refreshBasketSubject = new Subject<number>();

        // basket
        this.booking$ = new BehaviorSubject(new AceBooking());

        // booking record
        this.bookingRecord$ = new BehaviorSubject(null);

        this.bookingIdSubject$ = new BehaviorSubject(null);
        this.bookingId$ = merge(this.bookingIdSubject$, this.cache.getValue(BasketService.CACHE_KEY));
        this.isBasketRefreshingSubject$ = new BehaviorSubject<boolean>(true);
        this.isBasketRefreshing$ = this.isBasketRefreshingSubject$.pipe(distinctUntilChanged());

        const basketReady = new BehaviorSubject(false);
        this.isBasketReady$ = merge(basketReady, this.booking$.pipe(mapTo(true)));

        // Observable to know if basket is being refreshed (wired into the refresh stream)
        merge(this.refreshBasketSubject, this.cache.getValue(BasketService.CACHE_KEY))
            .pipe(
                mergeMap(bookingId => {
                    this.isBasketRefreshingSubject$.next(true);
                    return typeof bookingId === "string" ? this.bookingService.retrieveBooking(bookingId, "", null, 0) : of(new AceBooking());
                }),
                finalize(() => this.isBasketRefreshingSubject$.next(false))
            )
            .subscribe({
                next: booking => {
                    this.booking$.next(booking);
                    this.isBasketRefreshingSubject$.next(false);
                },
                complete: () => {
                    this.clearBookingId().subscribe();
                    this.booking$.next(new AceBooking());
                }
            });

        // Persist the booking to id to the local cache

        combineLatest([this.isBasketRefreshing$.pipe(filter(refreshing => !refreshing)), this.booking$.pipe(filter(booking => booking != null))]).subscribe(([_, booking]) => {
            if (!booking.isEmpty && booking.status !== "CLOSED") {
                this.cache.value(BasketService.CACHE_KEY, booking.bookingID, booking.expires.toDate()).subscribe();
            } else {
                // if basket has status of CLOSED remove ID.
                this.cache.expire(BasketService.CACHE_KEY).subscribe(() => {
                    if (!booking.isEmpty) {
                        this.emptyCachedBasket(booking);
                    } else {
                        this.bookingRecord$.next(null);
                        this.bookingIdSubject$.next(null);
                        this.setBookingId(null);
                    }
                });
            }
        });

        // check if basket expire has passed
        let timeout;
        this.booking$?.subscribe(b => {
            // Empty booking has no expiry
            if (!b.expires) {
                return;
            }

            // Get milliseconds to when this booking expires
            let diff = b.expires.valueOf() - moment().valueOf();

            if (diff < 0) {
                diff = 0;
            }

            clearTimeout(timeout);
            if (!b.isBookingForJourneyAmends) {
                timeout = setTimeout(this.onBookingExpires.bind(this), diff);
            }
        });
    }

    public onBookingExpires(): void {
        this.router.navigate(["/"]);
        this.toastService.create({
            msg: "Your booking has expired. Please start your search again",
            timeout: 5000,
            theme: "warning",
            icon: "info"
        });

        this.store.dispatch(new ClearOrders());
        this.clearBookingId().subscribe();
    }

    public clearBasket(): void {
        this.router.navigate(["/"]);
        this.store.dispatch(new ClearOrders());
        this.clearBookingId().subscribe();
        this.isQuickBuy$.next(false);
    }

    public setBookingId(bookingId: string): void {
        this.bookingIdSubject$.next(bookingId);
    }

    public clearBookingId() {
        return this.cache.expire(BasketService.CACHE_KEY).pipe(
            tap(() => {
                this.setBookingId(null);
                this.isQuickBuy$.next(false);
                this.refresh();
            })
        );
    }

    public removeBooking(booking: AceBooking, isJourneyAmendable: boolean = false) {
        const payload = {
            recordLocator: booking.bookingID,
            cancellationReason: "DISRUPTION",
            expectedCancellationFee: {
                currency: "GBP",
                value: 0
            }
        };

        if (!isJourneyAmendable) {
            return this.bookingService.cancelBooking(payload).pipe(
                tap(() => {
                    this.bookingIdSubject$.next(null);
                    this.setBookingId(null);
                    this.isQuickBuy$.next(false);
                    this.refresh();
                })
            );
        } else {
            this.bookingIdSubject$.next(null);
            this.setBookingId(null);
            this.isQuickBuy$.next(false);
            this.refresh();
            const newBooking = new AceBooking();
            return of(newBooking);
        }
    }

    public removeAllMultipleBookings() {
        return this.clearBookingId();
    }

    public removeBookingOrder(bookingId: string, orderId: string, requestInBackground = false, isJourneyAmendable: boolean = false): Observable<AceBooking> {
        const payload = {
            recordLocator: bookingId,
            orderLocator: orderId,
            cancellationReason: "DISRUPTION",
            expectedCancellationFee: {
                currency: "GBP",
                value: 0
            }
        };

        if (requestInBackground === true) {
            return this.bookingService.cancelBooking(payload);
        }
        if (!isJourneyAmendable) {
            return this.bookingService.cancelBooking(payload).pipe(
                tap({
                    next: booking => {
                        this.booking$.next(booking);
                        this.store.dispatch(new RemoveOrder());
                    },
                    error: error => {
                        this.onError(error);
                    }
                })
            );
        } else {
            const newBooking = new AceBooking();
            this.booking$.next(newBooking);
            this.store.dispatch(new RemoveOrder());
            return of(newBooking);
        }
    }

    /**
     * refreshes the basket with api if bookingID exists locally
     */
    public refresh(): void {
        this.refreshBasketSubject.next(1);
    }

    public finishRefresh(): void {
        this.isBasketRefreshingSubject$.next(false);
    }

    public createBooking(selection: AceSelection, user: AceUser) {
        this.isBasketRefreshingSubject$.next(true);

        return this.bookingService
            .createBooking(this.assembleBookingPayload(selection, user))
            .pipe(
                switchMap(booking => this.storeSmartCard(booking).pipe(map(smartcards => ({ smartcards, booking })))),
                switchMap(({ smartcards, booking }) => {
                    const payload = mergeObj(
                        { recordLocator: booking.bookingID },
                        selection.ticketingOption,
                        selection.getSearchTypeData(booking.bookingOrders[booking.bookingOrders.length - 1].orderID),
                        selection.getPromotionsData(booking.bookingOrders[booking.bookingOrders.length - 1].orderID)
                    ) as any;

                    if (payload.fulfillmentInformation.ticketOption.code === "SCT") {
                        this.extendSCTPayload(payload, booking, smartcards);
                    }

                    payload.responseSpec = {
                        returnReservationDetails: "true"
                    };
                    return this.bookingService.updateBooking(payload);
                })
            )
            .subscribe({
                next: booking => {
                    this.selectionContext.clearSelection();

                    // set bookingID locally to stop _refreshBasketSubject
                    // triggering when basket is already available in memory
                    this.localStorage.setItem(BasketService.CACHE_KEY, booking.bookingID);
                    this.booking$.next(booking);
                    this.isBasketRefreshingSubject$.next(false);
                    this.selectionContext.clearSelection();
                    this.bookingSucceeded$.next(true);
                },
                error: error => {
                    this.onError(error);
                    this.isBasketRefreshingSubject$.next(false);
                    this.bookingSucceeded$.next(false);
                }
            });
    }

    public createSeasonBooking(selection: AceSelection) {
        this.isBasketRefreshingSubject$.next(true);

        return this.bookingService
            .createPassBooking(this.assembleSeasonsPayload(selection))
            .pipe(
                switchMap(booking => this.storeSmartCard(booking).pipe(map(smartcards => ({ smartcards, booking })))),
                switchMap(({ smartcards, booking }) => {
                    const payload = mergeObj(
                        { recordLocator: booking.bookingID },
                        selection.ticketingOption,
                        selection.getSearchTypeData(booking.bookingOrders[booking.bookingOrders.length - 1].orderID)
                    );

                    if (payload.fulfillmentInformation.ticketOption.code === "SCT") {
                        this.extendSCTPayload(payload, booking, smartcards);
                    }
                    return this.bookingService.updateBooking(payload);
                })
            )
            .subscribe({
                next: booking => {
                    this.selectionContext.clearSelection();

                    // set bookingID locally to stop _refreshBasketSubject
                    // triggering when basket is already available in memory
                    this.localStorage.setItem(BasketService.CACHE_KEY, booking.bookingID);
                    this.booking$.next(booking);
                    this.isBasketRefreshingSubject$.next(false);
                    this.selectionContext.clearSelection();
                    this.bookingSucceeded$.next(true);
                },
                error: error => {
                    this.selectionContext.clearSelection();
                    this.onError(error);
                    this.isBasketRefreshingSubject$.next(false);
                    this.bookingSucceeded$.next(false);
                }
            });
    }

    public updateTicketingOption(booking: AceBooking, orderID: string, ticketingOption: AceCreateTicketingOptionPayload): void {
        this.isBasketRefreshingSubject$.next(true);

        const payload = {
            recordLocator: booking.bookingID,
            removeSelectedTicketingOption: true
        };

        if (booking.hasMultipleOrders()) {
            payload["orderLocator"] = orderID;
        }

        // when changing a delivery option on an existing
        // order we need to make two calls
        // 1. remove the TicketingOption
        // 2. update the TicketingOption
        this.bookingService
            .updateBooking(payload)
            .pipe(
                withLatestFrom(this.selectionContext.selection$),
                mergeMap(([booking, selection]) => {
                    return this.updateBookingOrderTicketingOption(booking, selection, booking.hasMultipleOrders() ? orderID : null);
                })
            )
            .subscribe({
                next: tBooking => {
                    this.localStorage.setItem(BasketService.CACHE_KEY, tBooking.bookingID);
                    this.booking$.next(tBooking);
                    this.isBasketRefreshingSubject$.next(false);
                },
                error: error => {
                    this.onError(error);
                    this.isBasketRefreshingSubject$.next(false);
                }
            });
    }

    public updateBookingOrder(booking: AceBooking, selection: AceSelection): void {
        this.isBasketRefreshingSubject$.next(true);

        // TODO: Passenger problem sits here, we update the entire booking with the first set of passenges defined

        const payload = {
            recordLocator: booking.bookingID,
            pointToPointRequestId: selection.searchResults.searchResultId,
            passengerIds: booking.passengers.map((p: AcePassenger) => p.passengerID) // those must match an existing passengers, already defined in the booking
        };

        if (selection.travelPass) {
            payload["priceIds"] = selection.getPriceId();
        } else {
            payload["legSolutionIds"] = selection.getAllLegSolutionIds();
            payload["priceIds"] = selection.getAllPriceIds();
            payload["seatPreferences"] = this.selectionContext.seatSelection;
            payload["seatReservationLegs"] = this.selectionContext.seatPreferenceLegType;
        }

        let bookingWithNewOrder: AceBooking;

        this.bookingService
            .updateBookingOrder(payload)
            .pipe(
                mergeMap(booking => {
                    bookingWithNewOrder = booking;
                    return this.updateBookingOrderTicketingOption(
                        booking,
                        selection,
                        booking.hasMultipleOrders() ? booking.bookingOrders[booking.bookingOrders.length - 1].orderID : null
                    );
                })
            )
            .subscribe({
                next: booking => {
                    this.selectionContext.clearSelection();
                    this.booking$.next(booking);
                    this.isBasketRefreshingSubject$.next(false);
                },
                error: error => {
                    this.isBasketRefreshingSubject$.next(false);

                    this.selectionContext.clearSelection();
                    this.onError(error);

                    if (bookingWithNewOrder != null) {
                        this.booking$.next(bookingWithNewOrder);
                        this.removeFailedBooking(bookingWithNewOrder);
                    }
                }
            });
    }

    public validateBooking(selection: AceSelection, user: AceUser = null) {
        this.isBasketRefreshingSubject$.next(true);
        const payload = this.assembleBookingPayload(selection, user);
        return this.bookingService.validateBooking(payload, user).pipe(
            map(booking => {
                this.bookingRecord$.next(booking);
                this.isBasketRefreshingSubject$.next(false);
                return booking;
            })
        );
    }

    public validateExchangeBooking(selection: AceSelection, user: AceUser = null, currentBooking: any, bookingorder: AceBookingOrder, direction: string) {
        this.isBasketRefreshingSubject$.next(true);
        const payload = this.assembleExchangeBookingPayload(selection, user, currentBooking, bookingorder, direction);
        return this.bookingService.validateExchangeBooking(payload, user, "", currentBooking.context).pipe(
            map((resp: DataModel.RetrieveExchangeSummaryResponse) => resp),
            withLatestFrom(this._config.config$),
            map(([response, config]) => {
                this.isBasketRefreshingSubject$.next(false);
                const userDeliveryPreference = response.bookingRecord ? this.getUserDeliveryPreference(user, response.bookingRecord.orders[0].ticketingOptions) : null;

                const bookingRecord = new AceBookingRecordInformation(
                    response.bookingRecord,
                    config.deliveryMethodsOrder.standard,
                    false,
                    this._config,
                    config.ticketingOptions.minOptionCountToPrioritise,
                    config.ticketingOptions.optionDetails,
                    userDeliveryPreference
                );

                this.bookingRecord$.next(bookingRecord);
                return response;
            })
        );
    }

    public getUserDeliveryPreference(user: AceUser, ticketingOptions: Array<DataModel.TicketingOption>): DataModel.TicketingOptionCode {
        let userDeliveryPreference = null;
        if (user && user.type === AceUserType.MEMBER && user.hasDeliveryPreference()) {
            const aceTicketingOptions = ticketingOptions.map(option => new AceTicketingOption(option));
            const userPreference: AceTicketingOption = DeliveryPreferencesHelper.getDeliveryOptionByUserPreference(user.getDeliveryPreference(), aceTicketingOptions);
            userDeliveryPreference = userPreference ? userPreference.code : null;
        }
        return userDeliveryPreference;
    }

    public validatePassBooking(selection: AceSelection, user: AceUser = null) {
        this.isBasketRefreshingSubject$.next(true);
        const payload = this.assembleSeasonsPayload(selection);
        return this.bookingService.validatePassBooking(payload, user).pipe(
            map(booking => {
                this.bookingRecord$.next(booking);
                this.isBasketRefreshingSubject$.next(false);
                return booking;
            }),
            catchError(error => {
                this.isBasketRefreshingSubject$.next(false);
                return throwError(() => error);
            })
        );
    }

    public isSelectionAlreadyInTheBasket(selection: AceSelection): Observable<boolean> {
        return this.booking$.pipe(
            take(1),
            map(booking => {
                return booking.bookingOrders.some(bookingOrder => {
                    const selectionOutgoingLeg = selection.outgoingLegSolution;
                    const selectionReturnLeg = selection.returnLegSolution;
                    const orderOutgoingLeg = bookingOrder.outgoingLeg;
                    const orderReturnLeg = bookingOrder.returnLeg;
                    let result = false;

                    if (
                        selectionOutgoingLeg &&
                        selection.outgoingPrice &&
                        orderOutgoingLeg &&
                        selectionOutgoingLeg.originTravelPoint.code === orderOutgoingLeg.originTravelPoint.code &&
                        selectionOutgoingLeg.departureDateMoment.isSame(orderOutgoingLeg.departureDateTime) &&
                        selectionOutgoingLeg.destinationTravelPoint.code === orderOutgoingLeg.destinationTravelPoint.code &&
                        selectionOutgoingLeg.arrivalDateMoment.isSame(orderOutgoingLeg.arrivalDateTime) &&
                        selection.outgoingPrice.totalPrice.value === orderOutgoingLeg.totalPrice.value
                    ) {
                        result = true;

                        if (selectionReturnLeg && orderReturnLeg) {
                            if (
                                selectionReturnLeg.originTravelPoint.code === orderReturnLeg.originTravelPoint.code &&
                                selectionReturnLeg.departureDateMoment.isSame(orderReturnLeg.departureDateTime) &&
                                selectionReturnLeg.destinationTravelPoint.code === orderReturnLeg.destinationTravelPoint.code &&
                                selectionReturnLeg.arrivalDateMoment.isSame(orderReturnLeg.arrivalDateTime)
                            ) {
                                result = true;
                            } else {
                                result = false;
                            }
                        }
                    }

                    return result;
                });
            })
        );
    }

    private storeSmartCard(booking: AceBooking): Observable<Array<null | ORM.Core.Transport.SmartCard>> {
        const apiCalls = new Array<Observable<null | ORM.Core.Transport.SmartCard>>();
        const order = this.store.selectSnapshot(state => OrderState.order(state.order));
        const options = order && order.passengerDeliveryOptions ? order.passengerDeliveryOptions : null;

        if (options) {
            Object.keys(options)
                .map(key => options[key] as BookingPassengerSmartCardTicket<any>)
                .filter(smartcardDetails => smartcardDetails.requireApi)
                .forEach(smartcardDetails => {
                    const request = {
                        ...smartcardDetails.request,
                        data: {
                            ...smartcardDetails.request.data,
                            userId: this.store.selectSnapshot(state => AccountState.account(state.account).userId)
                        }
                    };

                    apiCalls.push(
                        this.smartcardService.bookingSmartCard(request, booking.bookingID).pipe(
                            take(1),
                            tap({
                                next: (response: any) => {
                                    this.loggerService.info(response);
                                    if (response.smartCard) {
                                        this.store.dispatch(new UpdateSmartcards([response.smartCard]));
                                    }
                                },
                                error: err => {
                                    this.toastService.create({
                                        msg: err.errorMessage || "Issue with Smartcard Ticket fulfilment.",
                                        timeout: 5000,
                                        theme: "warning",
                                        icon: "info"
                                    });
                                }
                            }),
                            map(response => (response.smartCard ? response.smartCard : null))
                        )
                    );
                });
        }

        if (apiCalls.length === 0) {
            apiCalls.push(of(null));
        }

        return forkJoin([...apiCalls]);
    }

    private assembleSeasonsPayload(selection: AceSelection) {
        return new AceCreateSeasonsBookingPayload()
            .setPointToPointRequestId(selection.searchResults.searchResultId)
            .setPassengers(selection.getPassengerSelection())
            .setTravelPassQuery(selection.searchResults.searchParams, selection.getSeasonPriceId());
    }

    private assembleBookingPayload(selection: AceSelection, user: AceUser | null = null) {
        return new AceCreateBookingPayload(
            "",
            selection.searchResults.searchResultId,
            selection.getPassengerSelection(user),
            selection.getAllLegSolutionIds(),
            selection.getAllPriceIds(),
            [],
            this.selectionContext.seatSelection as DataModel.SeatPreference[],
            this.selectionContext.seatPreferenceLegType
        );
    }

    private assembleExchangeBookingPayload(selection: AceSelection, user: AceUser | null = null, currentBooking: AceUserBooking, bookingOrder: AceBookingOrder, direction: string) {
        const ticketableFareIDs = [];
        if (direction === "RTN") {
            bookingOrder.returnLeg.fares.forEach(fare => {
                ticketableFareIDs.push(fare.ticketableFareID);
            });
        } else {
            bookingOrder.outgoingLeg.fares.forEach(fare => {
                ticketableFareIDs.push(fare.ticketableFareID);
            });
        }
        return new AceRetrieveExchangeSummaryPayload(
            selection.searchResults.searchResultId,
            currentBooking.bookingRecordLocator ? currentBooking.bookingRecordLocator : currentBooking.booking.bookingRecordLocator,
            bookingOrder.orderID,
            selection.getSelectedPassengerIds(user),
            [
                {
                    exchangeSetId: "EXCH-1",
                    ticketableFareLocators: ticketableFareIDs,
                    legSolutionIds: selection.getAllLegSolutionIds(),
                    priceIds: selection.getAllPriceIds(),
                    seatPreferences: this.selectionContext.seatSelection as DataModel.SeatPreference[],
                    seatReservationLegs: this.selectionContext.seatPreferenceLegType
                }
            ],
            bookingOrder
        );
    }

    private updateBookingOrderTicketingOption(booking: AceBooking, selection: AceSelection, orderId?: string): Observable<AceBooking> {
        const payload: DataModel.UpdateBookingRecordRequest = {
            recordLocator: booking.bookingID,
            fulfillmentInformation: {
                ...selection.ticketingOption.fulfillmentInformation
            },
            passengers: [...booking.passengers.map(acePassenger => acePassenger.toDataModel())],
            responseSpec: {
                returnReservationDetails: "true" as any // TODO: Incorrect DataModel type (casting as a workaround)
            }
        };

        if (booking.hasMultipleOrders() && orderId) {
            payload.orderLocator = orderId;
        }

        if (DeliveryPreferencesHelper.isSmartTicket(selection.ticketingOption.fulfillmentInformation.ticketOption)) {
            this.extendSCTPayload(payload, booking, null);
        }

        // TODO: Workaround because delivery options overwrite the selection.fulfillmentInformation.ticketOption through AceCreateTicketingOptionPayload which consists of a custom model for ticketOption (AceTicketingOption) including unrelated FE data
        const ticketOption = payload.fulfillmentInformation.ticketOption;

        payload.fulfillmentInformation = {
            ...payload.fulfillmentInformation,
            ticketOption: {
                code: ticketOption.code,
                departureStation: ticketOption.departureStation,
                description: (ticketOption as AceTicketingOption).title || ticketOption.description,
                destination: ticketOption.destination,
                fee: ticketOption.fee,
                redeliveryAllowed: ticketOption.redeliveryAllowed,
                type: ticketOption.type
            } // Getting actual ticketOption instead of a malformed object
        };

        return this.bookingService.updateBooking(payload).pipe(mergeMap(booking => this.updateBookingOrderSearchType(booking, selection)));
    }

    private updateBookingOrderSearchType(booking: AceBooking, selection: AceSelection): Observable<AceBooking> {
        return this.bookingService.updateBooking(
            mergeObj(
                { recordLocator: booking.bookingID },
                selection.getSearchTypeData(booking.bookingOrders[booking.bookingOrders.length - 1].orderID),
                selection.getPromotionsData(booking.bookingOrders[booking.bookingOrders.length - 1].orderID)
            )
        );
    }

    private extendSCTPayload(payload: any, booking: AceBooking, smartcards: Array<null | ORM.Core.Transport.SmartCard>): void {
        let pickupLocationCode = "";
        if (get(payload, "fulfillmentInformation.ticketOption.passengerDeliveryOptions.all")) {
            pickupLocationCode = get<any, any>(payload, "fulfillmentInformation.ticketOption.passengerDeliveryOptions.all").dataStore.pickupLocation;
        } else {
            // This part here should actually never happen but it is possible to trick FE to not send data
            // so for sake of error handling this plain data will be send

            // TODO in case of such incident we are sending random station just to please validation
            // but this should be something more meanful like {type: null, code: null}
            // yet it cannot be done yet as i do not have SR docs access to know what can be send
            pickupLocationCode = "GBMYH";
        }

        payload.fulfillmentInformation.pickupLocation = {
            type: "STATION",
            code: pickupLocationCode
        };

        payload.passengers = booking.passengers.map((passenger, index) => {
            const deliveryOptions: BookingPassengerSmartCardTicket<any> = payload.fulfillmentInformation.ticketOption.passengerDeliveryOptions[index + 1];
            passenger.smartCardNumber = deliveryOptions.dataStore.cardNumber;
            passenger.cardReference = deliveryOptions.dataStore.cardReference;

            return {
                smartCardNumber: passenger.smartCardNumber,
                passengerID: passenger.passengerID,
                nameFirst: passenger.nameFirst,
                nameLast: passenger.nameLast,
                ageAtTimeOfTravel: passenger.ageAtTimeOfTravel,
                cardReference: smartcards && smartcards[index] ? smartcards[index].cardReference : null
            };
        });

        // TODO: it's temporary workaround should be removed when on the BE side cardReference in Passenger will be supported
        this.localStorage.setItem(`${booking.bookingID}_passengers`, payload.passengers);
    }

    private emptyCachedBasket(booking: AceBooking): void {
        this.modalService.create(PromptComponent, {
            title: "Existing booking in basket",
            description: "There's a booking in your basket from a previous session.\n" + "To continue, you must remove this booking.",
            confirmCTA: "Remove booking",
            confirm: instance => {
                if (booking.hasMultipleOrders() && !booking.hasSeasonTicket()) {
                    this.bookingRecord$.next(null);
                    this.setBookingId(null);
                    this.bookingIdSubject$.next(null);
                    instance.destroy();
                    this.canContinue$.next(true);
                } else {
                    this.removeBooking(booking).subscribe(() => {
                        instance.destroy();
                        this.canContinue$.next(true);
                    });
                }
            }
        });
    }

    private onError(err: DataModel.StandardError): void {
        console.error("An error occurred:", err);
        this.modalService.create(StandardErrorModalComponent, {
            error: err,
            handleCTAClick: instance => {
                // do something that results with the QTT being on the page
                instance.destroy();
                this.router.navigate(["/"]);
            }
        });
    }

    private removeFailedBooking(booking: AceBooking): void {
        if (booking.hasMultipleOrders()) {
            const bookedOrders = booking.bookingOrders.filter(order => order.status === "BOOKED");
            const orderToRemove = bookedOrders[bookedOrders.length - 1];

            if (orderToRemove != null) {
                this.removeBookingOrder(booking.bookingID, orderToRemove.orderID, true).subscribe();
            }

            this.checkConfirmedOrders(booking);
        } else {
            this.removeBooking(booking).subscribe();
        }
    }

    private checkConfirmedOrders(booking: AceBooking): void {
        // "CONFIRMED" order means that it was already paid and a user should not be able to add new orders to such booking
        const hasConfirmedOrders = Boolean(booking.bookingOrders.find(order => order.status === "CONFIRMED"));

        if (hasConfirmedOrders === true) {
            this.clearBookingId().subscribe();
        }
    }
}
