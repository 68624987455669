import { Component, Input, ViewChild, AfterViewInit } from "@angular/core";
import { ModalContainer } from "../modal-container.component";
import { ButtonComponent } from "../../../../shared/components/button/button.component";

@Component({
    selector: "ace-modal-generic",
    templateUrl: "generic-modal.component.html",
    styleUrls: ["generic-modal.component.scss"]
})
export class GenericModalComponent extends ModalContainer implements AfterViewInit {
    @Input() public handleCTAClick: Function;
    @Input() public title: string = "title";
    @Input() public body: string = "body";
    @Input() public cta: string = "cta";
    @Input() public link: string = "#";
    @Input() public theme: string = "";
    @ViewChild("ctaBtn") private ctaButton!: ButtonComponent;

    public ngAfterViewInit(): void {
        setTimeout(() => {
            this.ctaButton.focus();
        }, 0);
    }

    public onButtonClicked() {
        this.handleCTAClick(this);
    }
}
