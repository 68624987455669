import { Directive, ElementRef, HostListener, Input } from "@angular/core";
import { NgForm } from "@angular/forms";

@Directive({
    selector: "[aceFocusInvalidField]"
})
export class FocusInvalidFieldDirective {
    @Input() formGroup: NgForm;

    constructor(private el: ElementRef<HTMLFormElement>) {}

    @HostListener("submit", ["$event"])
    public onSubmit(event: Event): void {
        if (this.formGroup.invalid === true) {
            event.preventDefault();

            const formGroupInvalid = this.el.nativeElement.querySelectorAll("input.ng-invalid, select.select-input-error");

            setTimeout(() => {
                (<HTMLInputElement>formGroupInvalid[0])?.focus();
            }, 100);
        }
    }
}
