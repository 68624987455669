export type NreRailcardCodes = "TSU" | "YNG" | "TST" | "NGC" | "DCG" | "DCR" | "DIS" | "FAM" | "HMF" | "HRC" | "JCP" | "NEW" | "NDJ" | "SRN" | "2TR" | "VET";

export enum SilverRailRailcardCode {
    RAILCARD_1617 = "UK_YOUTH_16_17",
    RAILCARD_1625 = "UK_YOUTH"
}

export interface SilverRailRailcard {
    supplier: string;
    code: string;
    nreCode: NreRailcardCodes;
    type: string;
    name: string;
    limits: string;
    passengerRules: string;
    maxAdult: number;
    minAdult: number;
    maxChild: number;
    minChild: number;
    isInput: boolean;
    seasons: boolean;
}

export interface RailcardQuery<T> {
    Code: T;
    Number: string;
    Type: string;
}
