import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from "@angular/core";

@Component({
    selector: "ace-accordion",
    templateUrl: "./accordion.component.html",
    styleUrls: ["./accordion.component.scss"]
})
export class AccordionComponent {
    @Input() public id: string;
    @Input() public isOpen: boolean = false;
    @Input() public tabindex: number = 0;
    @Input() public disabled: boolean = false;
    @Output() public toggle = new EventEmitter<boolean>(false);
    @ViewChild("headElement") public headElement: ElementRef<HTMLButtonElement>;

    public onClickHandler(): void {
        if (this.disabled) {
            return;
        }

        this.isOpen = !this.isOpen;
        this.toggle.emit(this.isOpen);
    }
}
