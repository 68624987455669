import { Pipe, PipeTransform } from "@angular/core";
import { AceSelection } from "../models/ace/ace-selection.model";
import { AceSelectionState } from "../models/ace/ace-selection-state";
import { PriceLabelPipe } from "./price-label.pipe";
import { DepartureArrivalDescriptionPipe } from "./departure-arrival-description.pipe";
import { PriceSeasonLabelPipe } from "./price-season-label.pipe";

@Pipe({
    name: "selectionDescription"
})
export class SelectionDescriptionPipe implements PipeTransform {
    constructor(
        private priceLabelPipe: PriceLabelPipe,
        private priceSeasonLabelPipe: PriceSeasonLabelPipe,
        private departureArrivalDescriptionPipe: DepartureArrivalDescriptionPipe
    ) {}

    transform(selection: AceSelection): string {
        if (selection == null || selection.state === AceSelectionState.NONE) {
            return null;
        }

        const { seasonPrice, outgoingPrice, outgoingLegSolution, returnPrice, returnLegSolution, isOpenReturn } = selection;

        if (seasonPrice) {
            return `Your selection - Season / flexi ticket: ${this.priceSeasonLabelPipe.transform(seasonPrice)}`;
        }

        if (outgoingPrice) {
            let result = `Your selection - Outgoing: ${this.priceLabelPipe.transform(outgoingPrice, false)}, ${this.departureArrivalDescriptionPipe.transform(
                outgoingLegSolution,
                "LLL"
            )}`;

            if (returnPrice && !isOpenReturn) {
                result += `. Return: ${this.priceLabelPipe.transform(returnPrice, true)}, ${this.departureArrivalDescriptionPipe.transform(returnLegSolution, "LLL")}`;
            }

            if (isOpenReturn) {
                result += ". You have selected Open Return and can return within one month";
            }

            return result;
        }

        return null;
    }
}
