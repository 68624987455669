import { AbstractControl, AsyncValidatorFn, ValidationErrors, ValidatorFn } from "@angular/forms";
import { Observable, of, timer } from "rxjs";
import { catchError, finalize, first, map, switchMap } from "rxjs/operators";
import { SmartCardStatus } from "schema-enums/SmartCardStatus";
import { SmartcardService } from "../../services/smartcard.service";
import { BookingPassengerSmartCardTicket } from "../classes/BookingPassengerSmartCardTicket";
import { IAceConfig } from "../models/interfaces/IAceConfig";
import { ISmartcardPassengers } from "../models/interfaces/ISmartcardPassenegers";

export class SmartcardValidator {
    public static validCardLength(stnrRules: IAceConfig["stnr"]): ValidatorFn {
        return (cardNumberControl: AbstractControl): { [key: string]: boolean } => {
            if (cardNumberControl.value !== null) {
                const isInvalid = cardNumberControl.value.length !== stnrRules.numberlength - stnrRules.prefix.length;
                return isInvalid ? { invalidSmartcardLength: true } : null;
            } else {
                return undefined;
            }
        };
    }

    public static validateCardNumber(prefix: string, selectedOptionsPerPassenger: ISmartcardPassengers = {}, passengerOrderNumber: number = null): ValidatorFn {
        return (cardNumberControl: AbstractControl): { [key: string]: boolean } => {
            const currentCardNumber = `${prefix}${cardNumberControl.value}`;
            const isCardNumberDuplicated = (cardNumber: string, smartCardNumbers: Array<string>): boolean =>
                smartCardNumbers.some(smartCardNumber => smartCardNumber === cardNumber);

            const existingCardNumbers = Object.keys(selectedOptionsPerPassenger)
                .filter(key => parseInt(key, 10) !== passengerOrderNumber)
                .map(key => selectedOptionsPerPassenger[key])
                .filter((options: BookingPassengerSmartCardTicket<any>) => !!options && options.retrieve("cardNumber"))
                .map((options: BookingPassengerSmartCardTicket<any>) => options.retrieve("cardNumber"));

            return isCardNumberDuplicated(currentCardNumber, existingCardNumbers) ? { cardNumberDuplicated: true } : null;
        };
    }

    public static validateCardOwnership(prefix: string, userSmartCards: Array<ORM.Core.Transport.SmartCard> = [], registration: boolean = false): ValidatorFn {
        return (cardNumberControl: AbstractControl): { [key: string]: boolean } =>
            SmartcardValidator.isOwnCard(`${prefix}${cardNumberControl.value}`, userSmartCards) ? (registration ? { isOwnCardRegistration: true } : { isOwnCard: true }) : null;
    }

    public static isOwnCard(currentSmartCardNumber: string, userSmartCards: Array<ORM.Core.Transport.SmartCard> = []) {
        const existingCardNumbers = userSmartCards.map(smartCard => smartCard.cardNumber);
        const isCardNumberDuplicated = (cardNumber: string, smartCardNumbers: Array<string>): boolean => smartCardNumbers.some(smartCardNumber => smartCardNumber === cardNumber);
        return isCardNumberDuplicated(currentSmartCardNumber, existingCardNumbers);
    }

    public static validateMinLength(minLength: number): ValidatorFn {
        return (control: AbstractControl): { [key: string]: boolean } => {
            return control.value && control.value.trim().length >= minLength ? null : { minlength: true };
        };
    }

    public static validateCardName(
        selectedOptionsPerPassenger: ISmartcardPassengers = {},
        userSmartCards: Array<ORM.Core.Transport.SmartCard> = [],
        passengerOrderNumber: number = null,
        dependControl: AbstractControl = null
    ): ValidatorFn {
        return (cardNameControl: AbstractControl): { [key: string]: boolean } => {
            if (cardNameControl.value && ((dependControl && dependControl.valid) || !dependControl)) {
                const existingCardNames = userSmartCards.map(smartCard => smartCard.label);

                existingCardNames.push(
                    ...Object.keys(selectedOptionsPerPassenger)
                        .filter(key => passengerOrderNumber === null || parseInt(key, 10) !== passengerOrderNumber)
                        .map(key => selectedOptionsPerPassenger[key])
                        .filter((passenger: BookingPassengerSmartCardTicket<any>) => !!passenger && passenger.retrieve("label"))
                        .map(passenger => passenger.retrieve("label"))
                );
                if (existingCardNames.some(smartCardName => smartCardName === cardNameControl.value.trim())) {
                    return {
                        cardNameInvalid: true
                    };
                } else {
                    return null;
                }
            } else {
                return null;
            }
        };
    }

    public static cardRegistrationValidator(
        smartcardService: SmartcardService,
        smartCardValidation: IAceConfig["stnr"],
        validateCardOwnership: boolean,
        existingSmartcards: Array<ORM.Core.Transport.SmartCard> = [],
        smartcardNumberControl: string,
        postcodeControl: string
    ): AsyncValidatorFn {
        return (control: AbstractControl): Observable<ValidationErrors | null> | any => {
            const number = control.get(smartcardNumberControl);
            const postcode = control.get(postcodeControl);
            const smartcardNumber = `${smartCardValidation.prefix}${number.value}`;
            const isOwnCard = SmartcardValidator.isOwnCard(smartcardNumber, existingSmartcards);

            return timer(500).pipe(
                switchMap(() => {
                    return smartcardService.checkCardStatus(smartcardNumber, postcode.value).pipe(
                        finalize(() => {
                            number.markAsTouched();
                            postcode.markAsTouched();
                        }),
                        map(res => {
                            if (res.status !== SmartCardStatus.Issued) {
                                return { hotlisted: true };
                            } else if (res.isRegistered && isOwnCard && validateCardOwnership) {
                                return { isOwnCardRegistration: true };
                            } else if (res.isRegistered && !isOwnCard) {
                                return { alreadyRegistered: true };
                            } else if (res.businessId !== smartCardValidation.businessId) {
                                return { canRegister: true };
                            } else {
                                return null;
                            }
                        }),
                        catchError((err: { errorCode: string; errorMessage: string }) => {
                            if (err.errorCode === "SC0004") {
                                return of({ invalidSmartcardPostcode: true });
                            } else if (err.errorMessage) {
                                return of({ invalidSTNRSmartCard: true });
                            } else {
                                return of({ canRegister: true, cardExists: true });
                            }
                        }),
                        first()
                    );
                })
            );
        };
    }
}
