import { mergeArraysOfObjectsIdentifiedByField, sortArrayOfObjectsByPriorities } from "./../../../shared/utilities/Utils";
import { AceBookingOrderBase } from "./abstract/ace-booking-order-base.model";
import { AceTicketingOption } from "./ace-ticketing-option.model";

export class AceBookingOrderInformation extends AceBookingOrderBase {
    public confirmationOptions: any[];
    public passengerInformationRequired: Array<{ type: string }>;

    constructor(
        data: DataModel.OrderInformation,
        ticketingOptionsDefaults: any,
        optionDetails: Array<{ [key: string]: any }>,
        userDeliveryPreference: DataModel.TicketingOptionCode
    ) {
        super(data);
        this.ticketingOptions = this._getOptionsSortedByPriorities(ticketingOptionsDefaults, userDeliveryPreference);
        this.ticketingOptions = this._getOptionsWithAdditionalData(optionDetails);
        this.passengerInformationRequired = data.passengerInformationRequired;
    }

    protected _initEmpty() {
        return;
    }

    private _getOptionsWithAdditionalData(optionsDetails: Array<any>): Array<AceTicketingOption> {
        return mergeArraysOfObjectsIdentifiedByField<AceTicketingOption>(this.ticketingOptions, optionsDetails, "code");
    }

    private _getOptionsSortedByPriorities(priorities: Array<string>, userDeliveryPreference: DataModel.TicketingOptionCode): Array<AceTicketingOption> {
        const order = priorities.map(priorityCode => priorityCode.toUpperCase());
        if (userDeliveryPreference) {
            order.unshift(userDeliveryPreference);
        }
        return sortArrayOfObjectsByPriorities<AceTicketingOption>(this.ticketingOptions, "code", order);
    }
}
