import { Injectable, OnDestroy } from "@angular/core";
import { Subscription } from "rxjs";

@Injectable()
export abstract class RxjsComponent implements OnDestroy {
    protected subscriptions: Subscription[] = [];

    public ngOnDestroy(): void {
        this.subscriptions?.forEach(subscription => subscription?.unsubscribe());
    }

    protected addSubscription(item: Subscription): number {
        return this.subscriptions.push(item);
    }
}
