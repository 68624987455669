import { IQueryParams } from "../../models/interfaces/IQueryParams";

export class UpdateSearchCriteria {
    static readonly type = "[QTT] Update search criteria";
    constructor(public payload: { [key: string]: IQueryParams }) {}
}

export class ClearSearchCriteria {
    static readonly type = "[QTT] Clear search criteria";
}

export class ToggleQtt {
    static readonly type = "[QTT] Toggle Qtt";
    constructor(public payload: boolean) {}
}

export class SetSeasonSearch {
    static readonly type = "[QTT] Set season search";
    constructor(public payload: boolean) {}
}

export class LockStationPicker {
    static readonly type = "[QTT] Lock station picker";
    constructor(public payload: boolean) {}
}
