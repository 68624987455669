import { CommonModule } from "@angular/common";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { CUSTOM_ELEMENTS_SCHEMA, ErrorHandler, NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { NgxsReduxDevtoolsPluginModule } from "@ngxs/devtools-plugin";
import { NgxsRouterPluginModule } from "@ngxs/router-plugin";
import { NgxsStoragePluginModule, StorageOption } from "@ngxs/storage-plugin";
import { NGXS_PLUGINS, NgxsModule } from "@ngxs/store";
import { CookieService } from "ngx-cookie-service";
import { environment } from "../environments/environment";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./core/containers/app/app.component";
import { CoreModule } from "./core/core.module";
import { ModalModule } from "./modal/modal.module";
import { AccountService } from "./services/account.service";
import { WINDOW_PROVIDERS } from "./services/window.service";
import { LogoutMiddleware } from "./shared/middlewares/logout.middleware";
import { SharedModule } from "./shared/shared.module";
import { AccountState } from "./shared/state/account/account.state";
import { ConfigState } from "./shared/state/config/config.state";
import { MetadataState } from "./shared/state/metadata/metadata.state";
import { NavigationState } from "./shared/state/navigation/navigation.state";
import { NREState } from "./shared/state/nre/nre.state";
import { OrderState } from "./shared/state/order/order.state";
import { QttState } from "./shared/state/qtt/qtt.state";
import { RailcardsState } from "./shared/state/railcards/railcards.state";
import { DeliveryState } from "./shared/state/delivery/delivery.state";
import { LoaderService } from "./services/loader.service";
import { LoaderInterceptor } from "./interceptors/loader.interceptor";
import { TooltipState } from "./shared/state/tooltip/tooltip.state";
import { JourneyState } from "./shared/state/journey/journey.state";

@NgModule({
    imports: [NgxsModule.forFeature([AccountState])]
})
export class AccountModule {
    constructor(private accountService: AccountService) {
        this.accountService.init();
    }
}

@NgModule({
    imports: [
        AppRoutingModule,
        BrowserAnimationsModule,
        BrowserModule,
        CommonModule,
        FormsModule,
        FormsModule,
        HttpClientModule,
        ModalModule.forRoot(),
        ReactiveFormsModule,
        SharedModule.forRoot(),
        CoreModule,
        AccountModule,
        NgxsModule.forRoot([ConfigState, NREState, MetadataState, RailcardsState, NavigationState, QttState, OrderState, DeliveryState, TooltipState, JourneyState], {
            developmentMode: !environment.production,
            selectorOptions: {
                suppressErrors: false,
                injectContainerState: false
            }
        }),
        NgxsRouterPluginModule.forRoot(),
        NgxsStoragePluginModule.forRoot({
            key: [NavigationState, QttState, MetadataState, JourneyState],
            storage: StorageOption.SessionStorage
        }),
        NgxsReduxDevtoolsPluginModule.forRoot()
    ],
    providers: [
        { provide: NGXS_PLUGINS, useClass: LogoutMiddleware, multi: true },
        WINDOW_PROVIDERS,
        CookieService,
        LoaderService,
        { provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true }
    ],
    bootstrap: [AppComponent],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule {}
