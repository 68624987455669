import { AceTicketableFare } from "./ace-ticketable-fare.model";

export class AcePassTicketableFare extends AceTicketableFare {
    public fareStartDate?: string;
    public fareExpirationDate?: string;
    public passengerFareInfo: any;

    constructor(data: DataModel.PassTicketableFare) {
        super(data);
        this.fareStartDate = data.fareStartDate;
        this.fareExpirationDate = data.fareExpirationDate;
        this.passengerFareInfo = data.passengerReferences.map(passenger => passenger.fareCodes)[0][0];
    }
}
