import { Injectable } from "@angular/core";
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { catchError, finalize } from "rxjs/operators";
import { Router } from "@angular/router";
import { LoaderService } from "../services/loader.service";
import { AccountService } from "../services/account.service";
import { NotifyToastService } from "../services/notify-toast.service";
import { ToastIconTypes, ToastThemeTypes } from "../shared/models/interfaces/IToast";

@Injectable()
export class LoaderInterceptor implements HttpInterceptor {
    constructor(public loaderService: LoaderService, private accountService: AccountService, private router: Router, private toastService: NotifyToastService) {}
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        this.loaderService.isLoading$.next(true);
        return next.handle(req).pipe(
            catchError((error: HttpErrorResponse) => {
                let errorMessage = "";

                if (error.error instanceof ErrorEvent) {
                    // Client-side errors
                    errorMessage = `Error: ${error.error.message}`;
                } else {
                    // Server-side errors
                    errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
                }

                // Handle the error as needed - logging, displaying a user-friendly message, etc.
                console.error(errorMessage);

                if (error.error.errorCode === "401" || (error.error.errorCode === "400" && error.error.errorMessage.includes("Bad request: Caller is not authorized"))) {
                    this.accountService.logOut();
                    this.router.navigate([""]);
                    // this.onHandleRequest("Account Deleted Already", "warning", "info");
                    return throwError(() => (error.error ? error.error : error.message));
                }

                // Pass the error along to be handled by the calling code
                return throwError(() => error);
            }),
            finalize(() => this.loaderService.isLoading$.next(false))
        );
    }

    public onHandleRequest(message: string, theme: ToastThemeTypes, icon: ToastIconTypes) {
        this.toastService.create({
            msg: message,
            timeout: 5000,
            theme,
            icon
        });
    }
}
