<div #calendarContainer class="calendar" cdkTrapFocus>
    <div class="calendar-body">
        <div *ngIf="withOpenReturn === true" class="qtt-open-return" [class.qtt-open-return-mobile]="isMobile">
            <ace-checkbox
                checkboxId="calendar-open-return-checkbox"
                #openReturnCheckbox
                [checked]="isOpenReturn"
                theme="theme-light"
                (check)="onSelectOpenReturn()"
                data-elid="open-return"
                >Open Return</ace-checkbox
            >
        </div>
        <div class="calendar-navigation">
            <ace-button
                class="calendar-navigation-prev"
                ariaLabel="Previous month"
                data-elid="left-arrow"
                (click)="onPrevButtonClick(isPrevEnabled())"
                [ngStyle]="{ opacity: isPrevEnabled() ? 1 : 0.25 }"
                [disabled]="isPrevEnabled() === false"
            >
                <svg viewBox="0 0 24 24" aria-hidden="true">
                    <title>arrow left</title>
                    <use xlink:href="#arrow-left" />
                </svg>
            </ace-button>
            <ace-button
                class="calendar-navigation-next"
                ariaLabel="Next month"
                data-elid="right-arrow"
                (click)="onNextButtonClick(isNextEnabled())"
                [ngStyle]="{ opacity: isNextEnabled() ? 1 : 0.25 }"
                [disabled]="isNextEnabled() === false"
            >
                <svg viewBox="0 0 24 24" aria-hidden="true">
                    <title>arrow right</title>
                    <use xlink:href="#arrow-right" />
                </svg>
            </ace-button>
        </div>
        <div class="calendar-content">
            <ace-calendar-card
                class="calendar-card"
                data-elid="calendar-card"
                *ngFor="let card of cards"
                [card]="card"
                [disabled]="disabled"
                [selectedDateString]="selectedDateString"
                (selectDate)="onDaySelectedHandler($event)"
                (closeCalendar)="onCloseCalendar('other')"
            ></ace-calendar-card>
        </div>
    </div>
</div>
