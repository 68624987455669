<form class="qtt" id="qtt">
    <div *ngIf="isJourneyAmendable" class="original-journey-details-banner qtt-wrapper">
        Your original journey was on &nbsp;{{ originalJourneyDepartureTime | date : "EEE d MMM" }} [{{ originalJourneyDepartureTime | date : "HH:mm" }} -
        {{ originalJourneyArrivalTime | date : "HH:mm" }}]. &nbsp;Please select your new journey
    </div>
    <div class="qtt-wrapper">
        <ace-qtt-station-pickers
            [baseId]="'regular-qtt'"
            [isJourneyAmendable]="isJourneyAmendable"
            [(originTravelPoint)]="originTravelPoint"
            [(destinationTravelPoint)]="destinationTravelPoint"
            [(viaAvoidTravelPoint)]="viaAvoidTravelPoint"
            [(viaAvoidSelectedOption)]="viaAvoidSelectedOption"
            [(hasViaAvoid)]="hasViaAvoid"
            [qttOpen]="qttOpen$ | async"
            (stationPickerFocus)="onStationPickerFocus()"
        ></ace-qtt-station-pickers>

        <div *ngIf="qttOpen$ | async" class="qtt-container">
            <div class="qtt-calendar-container">
                <div class="qtt-calendar-view" data-elid="calendar-modal" *ngIf="isStartDateCalendarOn">
                    <div class="dropdown-header dropdown-header-left qtt-dropdown-header"></div>
                    <ace-calendar
                        class="qtt-calendar"
                        [date]="outboundDateTime"
                        [minDate]="minStartDate"
                        [maxDate]="maxDate"
                        [visibleCardsNum]="totalCards"
                        [toggleElement]="startDateCalendar"
                        (closeRequest)="closeStartDateCalendar($event)"
                        (dateChange)="setOutboundDate($event)"
                    ></ace-calendar>
                    <ace-travel-time-option
                        *ngIf="isMobile"
                        directionId="outbound"
                        [timeOption]="outboundDateTime"
                        (timeOptionChange)="setOutboundTime($event)"
                        [(departureOption)]="outboundDepartOption"
                    ></ace-travel-time-option>
                    <div class="qtt-calendar-view-buttons">
                        <ace-button *ngIf="isMobile" appearance="commercial" size="primary" (action)="isStartDateCalendarOn = false" id="submit-changes-calendar-mobile">
                            Done
                        </ace-button>
                    </div>
                </div>

                <div class="qtt-calendar-view" *ngIf="isEndDateCalendarOn">
                    <div class="dropdown-header dropdown-header-right qtt-dropdown-header"></div>
                    <ace-calendar
                        class="qtt-calendar"
                        [date]="returnDateTime"
                        [minDate]="outboundDateTime"
                        [maxDate]="maxDate"
                        [visibleCardsNum]="totalCards"
                        [disabled]="isOpenReturn"
                        [isOpenReturn]="isOpenReturn"
                        [withOpenReturn]="true"
                        [toggleElement]="endDateCalendar"
                        (closeRequest)="closeEndDateCalendar($event)"
                        (dateChange)="setReturnDate($event)"
                        (selectOpenReturn)="openReturnSelect($event)"
                    ></ace-calendar>
                    <ace-travel-time-option
                        *ngIf="isMobile"
                        directionId="return"
                        [theme]="!isReturn ? 'dark' : ''"
                        [disabled]="!isReturn || isOpenReturn"
                        [timeOption]="returnDateTime"
                        (timeOptionChange)="setReturnTime($event)"
                        [(departureOption)]="returnDepartOption"
                    ></ace-travel-time-option>
                    <div class="qtt-calendar-view-buttons">
                        <ace-button *ngIf="isMobile" appearance="editorial" size="secondary" (action)="removeReturnJourney()" id="cancel-calendar-mobile-return">
                            Cancel return
                        </ace-button>
                        <ace-button *ngIf="isMobile" appearance="commercial" size="primary" (action)="isEndDateCalendarOn = false" id="submit-changes-calendar-mobile-return">
                            Done
                        </ace-button>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col col-left">
                    <div class="qtt-calendar outbound-inputs">
                        <div
                            #startDateCalendar
                            tabindex="0"
                            role="button"
                            class="input calendar-text"
                            data-elid="outbound-calendar"
                            (click)="showStartDateCalendar()"
                            (keyup.enter)="showStartDateCalendar()"
                            (keyup.space)="showStartDateCalendar()"
                            [attr.aria-label]="
                                'Outbound ' +
                                (isDateToday(outboundDateTime) ? 'Today' : isMobile ? (outboundDateTime | date : 'EEE, d MMM') : (outboundDateTime | date : 'EEEE, d MMMM'))
                            "
                        >
                            Outbound
                            <span *ngIf="!isDateToday(outboundDateTime)" data-elid="outward-date" class="qtt-calendar-date">
                                {{ isMobile ? (outboundDateTime | date : "EEE, d MMM") : (outboundDateTime | date : "EEEE, d MMMM") }}
                            </span>
                            <span *ngIf="isDateToday(outboundDateTime)" class="qtt-calendar-date">Today</span>
                            <span *ngIf="isMobile">{{ outboundDateTime | date : "HH:mm" }}</span>
                        </div>

                        <div class="qtt-depart" *ngIf="!isMobile">
                            <ace-travel-time-option
                                directionId="outbound"
                                [timeOption]="outboundDateTime"
                                (timeOptionChange)="setOutboundTime($event)"
                                [(departureOption)]="outboundDepartOption"
                            ></ace-travel-time-option>
                        </div>
                    </div>
                </div>
                <div class="col col-right">
                    <div class="qtt-calendar destination-inputs" [class.hidden]="isJourneyAmendable">
                        <div class="destination-inputs-toggle">
                            <div
                                #endDateCalendar
                                tabindex="0"
                                role="button"
                                class="input input-applicable calendar-text calendar-text-space"
                                [class.input-disabled]="!isReturn && !isOpenReturn"
                                (click)="showEndDateCalendar()"
                                (keyup.enter)="showEndDateCalendar()"
                                (keyup.space)="showEndDateCalendar()"
                                data-elid="return-calendar"
                                [attr.aria-label]="
                                    isReturn === false && isOpenReturn === false
                                        ? 'Add return journey'
                                        : isOpenReturn === true
                                        ? 'Open return journey'
                                        : 'Return ' +
                                          (isDateToday(returnDateTime) ? 'Today' : isMobile ? (returnDateTime | date : 'EEE, d MMM') : (returnDateTime | date : 'EEEE, d MMMM'))
                                "
                            >
                                <ng-container *ngIf="!isReturn && !isOpenReturn">Add</ng-container>
                                <ng-container *ngIf="isOpenReturn">Open</ng-container>
                                <span *ngIf="isMobile; else returnInline">Return</span>
                                <ng-template #returnInline> Return </ng-template>

                                <ng-container *ngIf="isReturn && !isOpenReturn">
                                    <span *ngIf="!isDateToday(returnDateTime)" data-elid="return-date" class="qtt-calendar-date">
                                        {{ isMobile ? (returnDateTime | date : "EEE, d MMM") : (returnDateTime | date : "EEEE, d MMMM") }}
                                    </span>
                                    <span *ngIf="isDateToday(returnDateTime)" class="qtt-calendar-date">Today</span>
                                    <span *ngIf="isMobile">{{ returnDateTime | date : "HH:mm" }}</span>
                                </ng-container>
                            </div>
                            <div
                                class="add-return"
                                aria-hidden="true"
                                role="button"
                                aria-label="Add return journey"
                                *ngIf="!isReturn && !isOpenReturn"
                                (click)="showEndDateCalendar()"
                            >
                                <svg viewBox="0 0 24 24" aria-hidden="true">
                                    <title>plus</title>
                                    <use xlink:href="#plus" />
                                </svg>
                            </div>
                            <div
                                class="cancel-return"
                                *ngIf="isReturn || isOpenReturn"
                                tabindex="0"
                                role="button"
                                aria-label="Remove return"
                                (click)="removeReturnJourney()"
                                (keyup.enter)="removeReturnJourney()"
                                (keyup.space)="removeReturnJourney()"
                                data-elid="cancel-return-btn"
                            >
                                <svg viewBox="0 0 24 24" aria-hidden="true">
                                    <title>close</title>
                                    <use xlink:href="#close" />
                                </svg>
                            </div>
                        </div>

                        <div class="qtt-depart" *ngIf="!isMobile">
                            <ace-travel-time-option
                                directionId="return"
                                [theme]="!isReturn || isOpenReturn ? 'dark' : ''"
                                [disabled]="!isReturn || isOpenReturn"
                                [timeOption]="returnDateTime"
                                (timeOptionChange)="setReturnTime($event)"
                                [(departureOption)]="returnDepartOption"
                            ></ace-travel-time-option>
                        </div>
                    </div>
                </div>
            </div>

            <!-- ADULTS/CHILDREN DESKTOP ONLY -->
            <div class="row">
                <div class="col col-12">
                    <div class="qtt-passenger">
                        <ace-passenger-picker
                            [disabled]="(!(basketService.booking$ | async).isEmpty && !(basketService.booking$ | async).bookingOrders[0]?.isSeasons) || isJourneyAmendable"
                            [max]="maxPassenger$ | async"
                            [adults]="adults"
                            [children]="children"
                            [showPassengerPicker]="true"
                            (init)="passengerPickerInitHandler($event)"
                            (passengersChange)="onPassengersChangeHandler($event)"
                        ></ace-passenger-picker>
                    </div>

                    <div class="qtt-submit">
                        <ace-button #submitButton appearance="commercial" size="primary" (action)="search()" id="search-qtt" data-elid="search-qtt">Search and Buy</ace-button>
                    </div>
                </div>
            </div>

            <div class="nus-notification" *aceHasFeature="'nus'">
                <ace-notification-box *ngIf="isNusGroupNotificationVisible" [error]="'NUSGROUP'" [type]="'light'" [icon]="'info'"></ace-notification-box>
                <ace-notification-box *ngIf="isSavedNusExpired | async" [error]="'NUSAUTOEXPIRED'" [type]="'light'" [icon]="'warning-circle'"></ace-notification-box>
            </div>
        </div>
    </div>
</form>
