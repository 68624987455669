import { DiscountBase, DiscountNus, DiscountPromotion } from "../models/discount.model";
import { Promotion } from "../models/promotion.model";

class DiscountHelper {
    public static readonly DISCOUNT_TYPE_NUS = "NUS";
    public static readonly DISCOUNT_TYPE_PROMOTION = "PROMOTION";
    public static readonly DISCOUNT_TYPE_VOUCHER = "VOUCHER";

    public static convertArray(customInformation: DataModel.CustomInformationPair[]): DiscountBase[] {
        if (!customInformation) {
            return [];
        }

        return customInformation.filter(it => it.key.indexOf("PromotionType#") === 0).map(ci => this.convert(ci));
    }

    public static convert(customInformation: DataModel.CustomInformationPair): DiscountBase {
        const items = customInformation.key.split("#");

        if (customInformation.key.indexOf("#" + DiscountHelper.DISCOUNT_TYPE_NUS) > -1) {
            return this.convertCustomInformationNus(items, customInformation);
        }

        return this.convertCustomInformationPromotion(items, customInformation);
    }

    public static isPromotionOfNUSType(promotions: Promotion[], promotionApplied: DataModel.PromotionApplied): boolean {
        if (promotionApplied) {
            const promotion: Promotion = this.getPromotionById(promotions, promotionApplied.promotionId);
            if (promotion) {
                return promotion.type === DiscountHelper.DISCOUNT_TYPE_NUS;
            }
        }

        return false;
    }

    public static getPromotionById(promotions: Promotion[], promoId: number): Promotion {
        for (const promotion of promotions) {
            if (promotion.id === promoId) {
                return promotion;
            }
        }

        return null;
    }

    private static convertCustomInformationPromotion(items: string[], customInformation: DataModel.CustomInformationPair): DiscountBase {
        const promotion = new DiscountPromotion(items, customInformation.key, customInformation.value, customInformation.createdDateTime);

        promotion.Hash = items[6];

        return promotion;
    }

    private static convertCustomInformationNus(items: string[], customInformation: DataModel.CustomInformationPair): DiscountBase {
        const promotion = new DiscountNus(items, customInformation.key, customInformation.value, customInformation.createdDateTime);

        promotion.Hash = items[6];
        promotion.Action = items[9];

        return promotion;
    }
}

export { DiscountHelper };
