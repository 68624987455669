import { Injectable } from "@angular/core";
import { Action, Selector, State, StateContext } from "@ngxs/store";
import { format } from "date-fns";
import { IQttStateModel } from "./qtt-state.model";
import { ClearSearchCriteria, LockStationPicker, SetSeasonSearch, ToggleQtt, UpdateSearchCriteria } from "./qtt.actions";

export const initialState: IQttStateModel = {
    params: {
        standard: {
            origin: "",
            destination: "",
            outboundTime: format(new Date(), "yyyy-MM-dd'T'HH:mm:ss"),
            outboundTimeType: "DEPARTURE",
            adults: 1,
            children: 0
        },
        season: {
            origin: "",
            destination: "",
            outboundTime: format(new Date(), "yyyy-MM-dd'T'HH:mm:ss"),
            outboundTimeType: "DEPARTURE",
            adults: 1,
            children: 0
        }
    },
    isSeason: false,
    open: true,
    stationPicker: {
        lock: false
    }
};

@State<IQttStateModel>({
    name: "qtt",
    defaults: initialState
})
@Injectable()
export class QttState {
    @Selector([QttState])
    static qtt(state: IQttStateModel): IQttStateModel {
        return state ? state : null;
    }

    @Selector([QttState.qtt])
    static params(qtt: IQttStateModel) {
        const { isSeason = false, params = null } = qtt;
        const searchParams = params && isSeason ? params.season : params.standard;
        return searchParams ? searchParams : null;
    }

    @Selector([QttState.qtt])
    static isOpen(qtt: IQttStateModel) {
        return (qtt && qtt.open) || false;
    }

    @Selector([QttState.qtt])
    static isSeasonSearch(qtt: IQttStateModel) {
        return (qtt && qtt.isSeason) || false;
    }

    @Selector([QttState.qtt])
    static nusCardNumber(qtt: IQttStateModel) {
        return (qtt && qtt.params && qtt.params.standard && qtt.params.standard.nus) || null;
    }

    @Selector([QttState.qtt])
    static stationPicker(qtt: IQttStateModel) {
        return (qtt && qtt.stationPicker && qtt.stationPicker.lock) || false;
    }

    @Action(UpdateSearchCriteria)
    updateSearchCriteria({ getState, patchState }: StateContext<IQttStateModel>, action: UpdateSearchCriteria) {
        patchState({
            params: {
                ...getState().params,
                ...action.payload
            }
        });
    }

    @Action(ClearSearchCriteria)
    clearSearchCriteria({ setState }: StateContext<IQttStateModel>) {
        setState({ ...initialState });
    }

    @Action(ToggleQtt)
    toggleQtt({ patchState }: StateContext<IQttStateModel>, action: ToggleQtt) {
        patchState({ open: action.payload });
    }

    @Action(SetSeasonSearch)
    setSeasonSearch({ patchState }: StateContext<IQttStateModel>, action: SetSeasonSearch) {
        patchState({ isSeason: action.payload });
    }

    @Action(LockStationPicker)
    lockStationPicker({ patchState }: StateContext<IQttStateModel>, action: LockStationPicker) {
        patchState({ stationPicker: { lock: action.payload } });
    }
}
