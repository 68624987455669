import { Injectable } from "@angular/core";
import { AbstractControl, UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { Store } from "@ngxs/store";
import moment from "moment";
import { Observable, throwError } from "rxjs";
import { catchError, filter, map, tap } from "rxjs/operators";
import { SmartCardStatus } from "schema-enums/SmartCardStatus";
import { SmartCardType } from "schema-enums/SmartCardType";
import { AceUser } from "../shared/models/ace/ace-user.model";
import { IAceConfig } from "../shared/models/interfaces/IAceConfig";
import { IHotlistReplacementForm } from "../shared/models/interfaces/IHotlistReplacementForm";
import { ISmartcardPassengers } from "../shared/models/interfaces/ISmartcardPassenegers";
import { RemoveSmartcard, UpdateSmartcards } from "../shared/state/account/account.actions";
import { BasicValidator } from "../shared/validators/BasicValidator";
import {
    CREATE_SMARTCARD_REQUEST,
    GET_SMART_CARD_PRODUCTS_REQUEST,
    HOTLIST_AND_REPLACE_SMARTCARD_REQUEST,
    REMOVE_SMARTCARD_REQUEST,
    SMART_CARD_JOURNEYS_REQUEST,
    STORE_SMART_CARDS_REQUEST,
    USER_SMART_CARDS_REQUEST,
    VALIDATE_SMARTCARD_REQUEST
} from "../shared/constants/api-calls";
import { SmartcardValidator } from "./../shared/validator";
import { UtilValidator } from "./../shared/validators/UtilValidator";
import { AceCoreApiService } from "./ace-core-api.service";

@Injectable({
    providedIn: "root"
})
export class SmartcardService {
    constructor(private aceCoreApiService: AceCoreApiService, private store: Store) {}

    public static createNumberControl(
        value: string = "",
        smartCardValidation: IAceConfig["stnr"],
        selectedOptionsPerPassenger: ISmartcardPassengers = {},
        passengerOrderNumber: number = null
    ): UntypedFormControl {
        const validators = [
            Validators.required,
            SmartcardValidator.validCardLength(smartCardValidation),
            SmartcardValidator.validateCardNumber(smartCardValidation.prefix, selectedOptionsPerPassenger, passengerOrderNumber)
        ];

        return new UntypedFormControl(value, {
            validators
        });
    }

    public static createNicknameControl(
        value: string = "",
        maxLength: number,
        existingSmartcards: Array<ORM.Core.Transport.SmartCard>,
        selectedOptionsPerPassenger: ISmartcardPassengers = {},
        passengerOrderNumber: number = null,
        dependControl: AbstractControl = null
    ): UntypedFormControl {
        const validators = [
            Validators.maxLength(maxLength),
            BasicValidator.checkInjection(),
            BasicValidator.checkChars(),
            SmartcardValidator.validateMinLength(2),
            SmartcardValidator.validateCardName(selectedOptionsPerPassenger, existingSmartcards, passengerOrderNumber, dependControl)
        ];

        validators.push(dependControl ? UtilValidator.requiredIf(dependControl) : Validators.required);

        return new UntypedFormControl(value, {
            validators
        });
    }

    public getSmartcardNicknameErrorMessage(smartcardNickname: AbstractControl): string {
        if (smartcardNickname.errors && (smartcardNickname.dirty || smartcardNickname.touched)) {
            if (smartcardNickname.getError("required")) {
                return "This field is required.";
            }

            if (smartcardNickname.getError("minlength")) {
                return "Nickname requires a minimum length of 2.";
            }

            if (smartcardNickname.getError("maxlength")) {
                return "Smartcard Nickname too long.";
            }

            if (smartcardNickname.getError("cardNameInvalid")) {
                return "Oops! The name you’ve chosen has already been used.";
            }

            if (smartcardNickname.getError("restrictedChars")) {
                return "Invalid characters";
            }
        }
        return null;
    }

    public getSmartcardPostcodeErrorMessage(smartcardPostcode: AbstractControl, form: UntypedFormGroup): string {
        const postcodeFieldMarked = smartcardPostcode.dirty || smartcardPostcode.touched;
        const formErrors = form.errors && postcodeFieldMarked;

        if (smartcardPostcode.errors && postcodeFieldMarked) {
            if (smartcardPostcode.getError("required")) {
                return "Postcode is a required field";
            }
        }

        if (formErrors && form.getError("invalidSmartcardPostcode")) {
            return "The postcode entered does not match the postcode linked to this smartcard. Please check and try again.";
        }

        return null;
    }

    public getSmartcardNumberErrorMessage(smartcardNumber: AbstractControl, smartCardValidation: IAceConfig["stnr"], form: UntypedFormGroup): string {
        const smartcardNumberFieldMarked = smartcardNumber.dirty || smartcardNumber.touched;
        const smartcardNumberErrors = smartcardNumber.errors && smartcardNumberFieldMarked;
        const formErrors = form.errors && smartcardNumberFieldMarked;

        if (smartcardNumberErrors) {
            if (smartcardNumber.getError("required")) {
                return "This field is required.";
            }

            if (smartcardNumber.getError("invalidSmartcardLength")) {
                return "Oops! The Smartcard number you have entered is not valid.";
            }

            if (smartcardNumber.getError("isOwnCard")) {
                return "The Smartcard number entered has already been assigned to your account. Consider selecting a card number from the existing Smartcard list.";
            }

            if (smartcardNumber.getError("cardNumberDuplicated")) {
                return "The Smartcard number entered has already been assigned to another passenger.";
            }
        }

        if (formErrors) {
            if (form.getError("invalidSTNRSmartCard")) {
                return smartCardValidation.validSTNR.errorMsg;
            }

            if (form.getError("isOwnCardRegistration")) {
                return "The Smartcard number entered has already been assigned to your account.";
            }

            if (form.getError("cardExists")) {
                return 'The card number entered is invalid - please check and try again. If you are having any problems with using your Smartcard, please <a target="_blank" href="https://www.chilternrailways.co.uk/contact-us">contact our customer services team</a>.';
            }

            if (form.getError("alreadyRegistered")) {
                return "The Smartcard you are attempting to register is already registered to another account.";
            }

            if (form.getError("hotlisted")) {
                return 'This Smartcard has been cancelled, please use another Smartcard. If you would like more information, you can <a target="_blank" href="https://www.chilternrailways.co.uk/smartcard">read more here</a>';
            }

            if (form.getError("canRegister")) {
                return "The Smartcard you are attempting to register is already registered to another account.";
            }
        }

        return null;
    }

    public validateCardNumberInput($event: KeyboardEvent, smartCardValidation: IAceConfig["stnr"]): void {
        /* eslint-disable import/no-deprecated */
        if ($event.keyCode !== undefined) {
            // Allow: backspace, delete, tab, escape, enter and .
            if (
                [46, 8, 9, 27, 13, 110, 190].includes($event.keyCode) ||
                // Allow: Ctrl+A,Ctrl+C,Ctrl+V, Command+A
                (($event.keyCode === 65 || $event.keyCode === 86 || $event.keyCode === 67) && ($event.ctrlKey === true || $event.metaKey === true)) ||
                // Allow: home, end, left, right, down, up
                ($event.keyCode >= 35 && $event.keyCode <= 40)
            ) {
                // let it happen, don't do anything
                return;
            }
            // Ensure that it is a number and hasn't reached the max chars limit
            if (
                (($event.shiftKey || $event.keyCode < 48 || $event.keyCode > 57) && ($event.keyCode < 96 || $event.keyCode > 105)) ||
                (<HTMLInputElement>$event.target).value.length === smartCardValidation.numberlength - smartCardValidation.prefix.length
            ) {
                $event.preventDefault();
            }
        }
    }

    public validateCardNumberOnPaste($event: ClipboardEvent, smartCardValidation: IAceConfig["stnr"], control: AbstractControl): void {
        if ($event.clipboardData && $event.clipboardData.getData) {
            $event.preventDefault();
            const text = $event.clipboardData.getData("text") ? $event.clipboardData.getData("text").trim() : "";
            if (text !== "") {
                const allowedCardNumberLength = smartCardValidation.numberlength - smartCardValidation.prefix.length;
                const shortenText = text.replace(/\s/g, "").slice(0, allowedCardNumberLength);
                const digitsRegex = /^\d+$/;
                if (digitsRegex.test(shortenText)) {
                    // input contains only digits we are good to paste it into the input
                    control.setValue(shortenText);
                }
            }
        }
    }

    public isCardNumberDuplicated(cardNumber: string, smartCardNumbers: Array<string>): boolean {
        return smartCardNumbers.some(smartCardNumber => smartCardNumber === cardNumber);
    }

    public isCardNameDuplicated(cardName: string, smartCardNames: Array<string>): boolean {
        return smartCardNames.some(smartCardName => smartCardName === cardName);
    }

    public defaultCardName(user: AceUser, selectedOptionsPerPassenger: ISmartcardPassengers = {}, passengerOrder: number = 1): string {
        const passengerCardLabels =
            Object.keys(selectedOptionsPerPassenger)
                .filter(key => passengerOrder === null || parseInt(key, 10) !== passengerOrder)
                .filter(key => selectedOptionsPerPassenger[key] && selectedOptionsPerPassenger[key].dataStore.label)
                .map(key => selectedOptionsPerPassenger[key].dataStore.label) || [];

        const cardsNames = [...user.smartCards.map(card => card.label), ...passengerCardLabels].filter(
            card =>
                card &&
                card
                    .trim()
                    .toLowerCase()
                    .match(/^my smartcard [0-9]+$/)
        );

        let newCardNumber: number;
        if (cardsNames.length) {
            const cardNumbers: number[] = cardsNames.map(name => parseInt(/\d+/.exec(name)[0], 10)).sort((a: any, b: any) => a - b);

            for (let i = 1; i <= cardNumbers.length; i++) {
                if (!cardNumbers.includes(i)) {
                    newCardNumber = i;
                    break;
                }
            }
            if (!newCardNumber) {
                newCardNumber = cardNumbers.length + 1;
            }
        } else {
            newCardNumber = 1;
        }

        return user && user.isLoggedIn ? `My Smartcard ${newCardNumber}` : null;
    }

    public checkCardStatus(cardNumber: string, postcode: string): Observable<DataModel.ValidateSmartCardResponse> {
        return this.aceCoreApiService
            .call(VALIDATE_SMARTCARD_REQUEST, {
                cardNumber,
                postcode
            })
            .pipe(map(res => res.data))
            .pipe(catchError(error => throwError(() => error)));
    }

    public getSmartCardJourneys(id: string, since?: Date, to?: Date): Observable<Array<ORM.Core.Transport.SmartCardJourney>> {
        const params: DataModel.GetSmartCardJourneysRequest = {
            smartCardId: id,
            since: since ? moment(since).format("YYYYMMDD") : null,
            to: to ? moment(to).format("YYYYMMDD") : null
        };

        return this.aceCoreApiService.call(SMART_CARD_JOURNEYS_REQUEST, params).pipe(map(res => res.data));
    }

    public static validateSmartcard(
        smartCardValidation: IAceConfig["stnr"],
        smartcardService: SmartcardService,
        existingSmartcards: Array<ORM.Core.Transport.SmartCard> = [],
        smartcardNumberControl: string,
        postcodeControl: string,
        validateCardOwnership: boolean = false
    ) {
        if (validateCardOwnership) {
            SmartcardValidator.validateCardOwnership(smartCardValidation.prefix, existingSmartcards, true);
        }

        return SmartcardValidator.cardRegistrationValidator(
            smartcardService,
            smartCardValidation,
            validateCardOwnership,
            existingSmartcards,
            smartcardNumberControl,
            postcodeControl
        );
    }

    public createSmartCardRequest(data: ORM.Core.Transport.CreateSmartCardRequest, user: AceUser): Observable<ORM.Core.Transport.CreateSmartCardResponse> {
        return this.aceCoreApiService
            .call(CREATE_SMARTCARD_REQUEST, {
                ...data,
                userId: user.userId
            })
            .pipe(
                map(response => response.data),
                filter(res => res != null),
                tap((response: ORM.Core.Transport.CreateSmartCardResponse) => this.store.dispatch(new UpdateSmartcards([response.smartCard])))
            );
    }

    public updateSmartCard(userId: number, values: ORM.Core.Transport.SmartCardsDetails): Observable<ORM.Core.Transport.SmartCard[] | ORM.Core.Transport.SmartCard> {
        const params: ORM.Core.Transport.StoreSmartCardsRequest = {
            userId,
            cards: [
                {
                    ...values
                }
            ]
        };

        return this.aceCoreApiService.call(STORE_SMART_CARDS_REQUEST, params).pipe(
            map(response => response.data),
            tap(response => {
                if (response) {
                    const dispatchArray = Array.isArray(response) ? response : [response];
                    this.store.dispatch(new UpdateSmartcards(dispatchArray));
                }
            })
        );
    }

    // TODO API doesn't return the correct API response. Either Datamodel is wrong or return is wrong
    public deleteSmartCard(smartCardId: number): Observable<{ smartCardId: number }> {
        return this.aceCoreApiService.call(REMOVE_SMARTCARD_REQUEST, { smartCardId }).pipe(
            map(response => response.data as { smartCardId: number }),
            tap(response => {
                if (response) {
                    this.store.dispatch(new RemoveSmartcard(response.smartCardId));
                }
            })
        );
    }

    public hotlistAndReplace(
        smartCard: ORM.Core.Transport.SmartCard,
        hotlistReplacementForm: IHotlistReplacementForm,
        userId: number
    ): Observable<ORM.Core.Transport.ReplaceSmartCardResponse> {
        const hotlistSmartCardRequest: ORM.Core.Transport.ReplaceSmartCardRequest = {
            cardNumber: smartCard.cardNumber,
            cardholderName: smartCard.cardholderName,
            crimeRef: hotlistReplacementForm.crimeRef,
            userId,
            replacedReason: hotlistReplacementForm.replacedReason,
            deliveryAddress: hotlistReplacementForm.deliveryAddress,
            label: hotlistReplacementForm.label
        };

        return this.aceCoreApiService.call(HOTLIST_AND_REPLACE_SMARTCARD_REQUEST, hotlistSmartCardRequest).pipe(
            map(response => response.data as ORM.Core.Transport.ReplaceSmartCardResponse),
            tap(response => {
                if (response && response.requestStatus.success) {
                    const selectedSmartCard = { ...smartCard, status: SmartCardStatus.Cancelled };
                    this.store.dispatch(new UpdateSmartcards([selectedSmartCard, response.smartCard]));
                    return { ...response, smartCard: selectedSmartCard };
                }
            })
        ) as Observable<ORM.Core.Transport.ReplaceSmartCardResponse>;
    }

    public bookingSmartCard(request: { name: string; data: any }, recordLocator: string): Observable<ORM.Core.Transport.SmartCard> {
        return this.aceCoreApiService.call(request.name, { ...request.data, recordLocator }).pipe(
            map(response => response.data),
            filter(data => data != null),
            tap(response => {
                let cards: ORM.Core.Transport.SmartCard[];
                if (request.name === CREATE_SMARTCARD_REQUEST) {
                    cards = [response.smartCard];
                } else {
                    cards = response;
                }
                return this.store.dispatch(new UpdateSmartcards(cards));
            })
        );
    }

    public saveSmartCard(cardNumber: string, label: string, userId: number, primary: boolean = false): Observable<ORM.Core.Transport.SmartCard[]> {
        const params: ORM.Core.Transport.StoreSmartCardsRequest = {
            userId,
            cards: [
                {
                    cardNumber,
                    label,
                    type: SmartCardType.Saved,
                    cardUserId: userId,
                    primary
                }
            ]
        };

        return this.aceCoreApiService.call(STORE_SMART_CARDS_REQUEST, params).pipe(
            map(response => response.data as ORM.Core.Transport.SmartCard[]),
            filter(data => data != null),
            tap(response => this.store.dispatch(new UpdateSmartcards(response)))
        );
    }

    public registerSmartcard(cardNumber: string, label: string, userId: number, primary: boolean = false): Observable<ORM.Core.Transport.SmartCard[]> {
        const params: ORM.Core.Transport.StoreSmartCardsRequest = {
            userId,
            cards: [
                {
                    cardNumber,
                    label,
                    type: SmartCardType.Registered,
                    cardUserId: userId,
                    primary
                }
            ]
        };

        return this.aceCoreApiService.call(STORE_SMART_CARDS_REQUEST, params).pipe(
            map(response => response.data as ORM.Core.Transport.SmartCard[]),
            filter(data => data != null),
            tap(response => this.store.dispatch(new UpdateSmartcards(response)))
        );
    }

    public getUserSmartcards(userId: number): Observable<Array<ORM.Core.Transport.SmartCard>> {
        return this.aceCoreApiService.call(USER_SMART_CARDS_REQUEST, { userId }).pipe(map(response => response.data));
    }

    public getSmartcardProducts(smartCardId: number): Observable<Array<ORM.Core.Transport.SmartCardProduct>> {
        return this.aceCoreApiService.call(GET_SMART_CARD_PRODUCTS_REQUEST, { smartCardId }).pipe(map(response => response.data));
    }
}
