import { environment } from "../../../environments/environment";
import { FareFilterType } from "../models/entities/fare";

export const SINGLE_FARE_ID = "PRICE_P_1";
export const RETURN_FARE_ID = "PRICE_P_2";
export const PROMO_SERVICE_COLUMNS_NUMBER = 5;

export const OPERATOR_FARE_FILTER: { id: FareFilterType; name: string } = {
    id: "operator",
    name: { CH: "Chiltern", GC: "Grand Central" }[environment.tocId]
};

export const OFFPEAK_FARE_FILTER: { id: FareFilterType; name: string } = {
    id: "offpeak",
    name: "Off-Peak"
};

export const DIRECT_FARE_FILTER: { id: FareFilterType; name: string } = {
    id: "direct",
    name: "Direct"
};

export const FASTEST_FARE_FILTER: { id: FareFilterType; name: string } = {
    id: "fastest",
    name: "Fastest"
};
