export type AssistanceRequestTypes = "UserPreference" | "UserTelephone";
export type AssistancePayloadTypes =
    | AssistanceNecessitySavePayloadType
    | AssistanceNecessityUpdatePayloadType
    | AssistanceNotesSavePayloadType
    | AssistanceNotesUpdatePayloadType
    | AssistancePhoneSavePayloadType
    | AssistancePhoneUpdatePayloadType;

export type AssistanceNecessitySavePayloadType = AssistanceRequest<AssistanceNecessitySavePayload>;
export type AssistanceNecessityUpdatePayloadType = AssistanceRequest<AssistanceNecessityUpdatePayload>;
export type AssistanceNotesSavePayloadType = AssistanceRequest<AssistanceNotesSavePayload>;
export type AssistanceNotesUpdatePayloadType = AssistanceRequest<AssistanceNotesUpdatePayload>;
export type AssistancePhoneSavePayloadType = AssistanceRequest<AssistancePhoneNumberSavePayload>;
export type AssistancePhoneUpdatePayloadType = AssistanceRequest<AssistancePhoneNumberUpdatePayload>;

export enum AssistanceCategory {
    TRAVEL_ASSISTANCE = "TRAVEL_ASSISTANCE"
}

export enum AssistanceCode {
    ADDITIONAL_INFORMATION = "ADDITIONAL_INFORMATION",
    NONE = ""
}

export interface AssistanceOption {
    id: string;
    isSelected: boolean;
    value: boolean;
    label: string;
}

interface AssistanceRequest<P = any> {
    entityName: AssistanceRequestTypes;
    method: "POST" | "PUT";
    data: P;
}

interface AssistanceNecessitySavePayload {
    userId: number;
    code: AssistanceCode.NONE;
    category: AssistanceCategory.TRAVEL_ASSISTANCE;
    value: string;
}

interface AssistanceNecessityUpdatePayload {
    preferenceId: number;
    values: AssistanceNecessitySavePayload;
}

interface AssistanceNotesSavePayload {
    userId: number;
    code: AssistanceCode.ADDITIONAL_INFORMATION;
    category: AssistanceCategory.TRAVEL_ASSISTANCE;
    value: string;
}

interface AssistanceNotesUpdatePayload {
    preferenceId: number;
    values: AssistanceNotesSavePayload;
}

interface AssistancePhoneNumberSavePayload {
    userId: number;
    telephoneNumber: number;
    primary: boolean;
}

interface AssistancePhoneNumberUpdatePayload {
    telephoneId: number;
    values: AssistancePhoneNumberSavePayload;
}
