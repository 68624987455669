import { Observable, of } from "rxjs";
import { AcePassPrice } from "./ace-pass-price.model";
import { AceSearchResult } from "./ace-search-result.model";
import { AceSnapshot } from "./ace-snapshot";

export class AceSeasonSearchProjectionModel {
    private prices: AcePassPrice[];
    private selectedPrice: AcePassPrice;

    constructor(searchResult: AceSearchResult, configSnapshot: AceSnapshot) {
        this.prices = searchResult.seasonPrices;
    }

    public getPriceRowGroups(filter: string) {
        return this.prices.filter(price => {
            return price.ticketableFares[0].farePassengers[0].fareCodes[0].fareClass === filter;
        });
    }

    public isSelectedValid(priceId: string): { complete: boolean; prices: AcePassPrice } {
        return {
            complete: true,
            prices: this.findPriceById(priceId)
        };
    }

    public findPriceById(priceId: string): AcePassPrice {
        return this.prices.find(price => price.priceID === priceId);
    }

    public updateAndRetrieveSelection(price: AcePassPrice): AcePassPrice {
        this.selectedPrice = price;
        return this.selectedPrice;
    }

    public autoSelectFare(price: AcePassPrice): Observable<AcePassPrice> {
        if (price) {
            this.selectedPrice = this.prices.find(it => it.priceID === price.priceID);
        } else {
            // if no selected fare exists selected the cheapest
            this.selectedPrice = this.prices.reduce((a, b) => (a.totalPrice.value < b.totalPrice.value ? a : b));
        }
        return of(this.selectedPrice);
    }

    public priceIdIsValid(priceId: string): boolean {
        return !!this.findPriceById(priceId);
    }

    public isPriceSelected(price: AcePassPrice): boolean {
        return this.selectedPrice && price && this.selectedPrice.priceID === price.priceID;
    }
}
