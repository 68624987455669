import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Store } from "@ngxs/store";
import { BehaviorSubject, Observable, of } from "rxjs";
import { catchError, map, mergeMap, shareReplay, tap } from "rxjs/operators";
import { SilverRailRailcard } from "../shared/models/api/railcard";
import { NusStatusEnum } from "../shared/models/nuscard";
import { Railcard } from "../shared/models/railcard";
import { SetRailcards } from "../shared/state/railcards/railcards.actions";
import { AceCoreApiService } from "./ace-core-api.service";
import { ConfigService } from "./config.service";
import { LocalCacheService } from "./local-cache.service";
import { LoggerService } from "./logger.service";

@Injectable({
    providedIn: "root"
})
export class RailcardService {
    public railcards$: Observable<SilverRailRailcard[]>;
    public nusValidating$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    public nusStatus$: BehaviorSubject<NusStatusEnum> = new BehaviorSubject<NusStatusEnum>(NusStatusEnum.PRE_VALIDATION);
    public currentValidNusCard$: BehaviorSubject<DataModel.ValidateNusCardResponse> = new BehaviorSubject<DataModel.ValidateNusCardResponse>(null);
    public isRailcardValid$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
    private saveNusCardChecked$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    private railcardSelection$: BehaviorSubject<Railcard[]> = new BehaviorSubject<Railcard[]>(null);

    constructor(
        private _cache: LocalCacheService,
        private logger: LoggerService,
        private _aceCoreApi: AceCoreApiService,
        private _configService: ConfigService,
        private _httpService: HttpClient,
        private _store: Store
    ) {
        this.logger.loggerContext = "RailcardService";
        const railcardRequestObservable = this._aceCoreApi.call("RailcardListRequest")?.pipe(map(res => res.data.railcards));

        // Get railcardList from cache instead of making second call
        // TODO: rewrite cache manager depends on appVersionRevision
        of(1)
            .pipe(
                mergeMap(() => this._cache.observable("railcardList", railcardRequestObservable)),
                shareReplay(1)
            )
            .subscribe(railcards => this._store.dispatch(new SetRailcards(railcards)));
    }

    public validateNusCard(nusId: string): Observable<NusStatusEnum> {
        this.logger.log(`validateNusCard: nusId: ${nusId}`);

        const nusUrl: string = this._configService.configSnapshot.get("aceCoreApi.nusDefault");
        const payload: DataModel.ValidateNusCardRequest = {
            cardNumber: nusId
        };

        this.logger.log(`validateNusCard: payload: ${JSON.stringify(payload)}`);
        this.clearNusCardValidationStatus();
        this.nusValidating$.next(true);

        return this._httpService.post<DataModel.StandardResponse>(nusUrl, payload).pipe(
            map(nusRes => {
                this.logger.log(`validateNusCard: res: ${JSON.stringify(nusRes)}`);
                return nusRes.data as DataModel.ValidateNusCardResponse;
            }),
            map(nusCard => {
                this.logger.log(`validateNusCard: nusCard: ${JSON.stringify(nusCard)}`);
                const isNusExpired = this._isNusCardExpired(nusCard);
                const isNusValid = this._isNusCardValid(nusCard);
                const nusStatus = this._setNusCardStatus(isNusValid, isNusExpired);

                if (isNusValid) {
                    this.currentValidNusCard$.next(nusCard);
                }

                return nusStatus;
            }),
            tap(nusStatus => {
                this.nusStatus$.next(nusStatus);
                this.nusValidating$.next(false);
            }),
            catchError(() => {
                this.nusStatus$.next(NusStatusEnum.EXCEPTION);
                this.nusValidating$.next(false);

                return of(NusStatusEnum.EXCEPTION);
            })
        );
    }

    public clearNusCardValidationStatus(): void {
        this.currentValidNusCard$.next(null);
        this.nusValidating$.next(false);
        this.nusStatus$.next(NusStatusEnum.PRE_VALIDATION);
    }

    public get shouldSaveNusCard(): boolean {
        return this.saveNusCardChecked$.getValue();
    }

    public set shouldSaveNusCard(checked: boolean) {
        this.saveNusCardChecked$.next(Boolean(checked));
    }

    public getRailcardsSelection(): BehaviorSubject<Railcard[]> {
        return this.railcardSelection$;
    }

    public set railcardsSelection(railcards: Railcard[]) {
        this.railcardSelection$.next(railcards);
    }

    private _isNusCardValid(nusCard: DataModel.ValidateNusCardResponse): boolean {
        return nusCard.cardExists && nusCard.cardNumber !== null;
    }

    private _isNusCardExpired(nusCard: DataModel.ValidateNusCardResponse): boolean {
        return nusCard.error === "Card Expired";
    }

    private _setNusCardStatus(valid: boolean, expired: boolean): NusStatusEnum {
        if (expired) {
            return NusStatusEnum.EXPIRED;
        }
        if (valid) {
            return NusStatusEnum.VALID;
        } else {
            return NusStatusEnum.INVALID;
        }
    }
}
