<header class="header">
    <a class="skip-main" data-elid="skip-main-content" [href]="skipPath">Skip to main content</a>
    <div class="container">
        <a
            [attr.href]="tocId === 'GC' ? tocMarketingSiteUrl : '/'"
            class="header-logo"
            title="Go to the home page"
            (keyup.enter)="routeToHome($event)"
            (keyup.space)="routeToHome($event)"
            (click)="routeToHome($event)"
            [class.header-logo-gc]="tocId === 'GC'"
        >
            <img [attr.alt]="tocId === 'GC' ? 'Grand Central logo' : 'Chiltern Railways logo'" src="/assets/images/svg/logo.svg" />
        </a>

        <nav aria-label="Main" role="navigation">
            <ul>
                <li class="font-emphasis" *ngIf="tocId === 'GC'">
                    <button #cookiePolicyButton (click)="openCookiePolicy($event)" title="open cookie policy">Cookie Policy</button>
                </li>
                <li class="font-emphasis">
                    <button #helpButton (click)="onHelpModal()" title="Open help modal"><span class="sr-only">Open </span>Help<span class="sr-only"> dialog</span></button>
                </li>
                <li *ngIf="basketService.isBasketRefreshing$ | async">
                    <button aria-label="Basket is loading" aria-hidden="true" class="font-emphasis" data-elid="header-basket-price" class="header-basket-price">
                        <ng-template [ngTemplateOutlet]="basketIcon"></ng-template> Loading
                    </button>
                </li>
                <li *ngIf="!(basketService.isBasketRefreshing$ | async) && (basketService.isBasketReady$ | async)">
                    <button
                        [attr.aria-label]="
                            isJourneyAmendableFlag
                                ? 'Tickets for ' + (exchangeSummaryOptions?.total?.value | currency : 'GBP' : 'symbol') + 'in the basket'
                                : 'Tickets for ' + ((basketService.booking$ | async)?.bookingTotal.value | currency : 'GBP' : 'symbol') + 'in the basket'
                        "
                        (click)="routeToBasketSummary()"
                    >
                        <ng-template [ngTemplateOutlet]="basketIcon"></ng-template>
                        <span aria-hidden="true" data-elid="header-basket-price" class="header-basket-price font-emphasis" *ngIf="!isJourneyAmendableFlag || this.isbasketEmpty">
                            {{ (basketService.booking$ | async)?.bookingTotal.value | currency : "GBP" : "symbol" }}
                        </span>
                        <span aria-hidden="true" data-elid="header-basket-price" class="header-basket-price font-emphasis" *ngIf="isJourneyAmendableFlag && !this.isbasketEmpty">
                            {{ exchangeSummaryOptions?.total?.value | currency : "GBP" : "symbol" }}
                        </span>
                    </button>
                </li>
                <li *ngIf="!userReady">
                    <button aria-label="Account is loading" class="header-account" showLoader="true" data-elid="sign-in-btn">Loading</button>
                </li>
                <li *ngIf="userReady">
                    <button title="Go to my account" (click)="onAccountClick()" class="header-account" data-elid="sign-in-btn">
                        <span *ngIf="userIsApproved === true">
                            <span class="sr-only">Go to </span>
                            My Account
                        </span>
                        <span *ngIf="userIsGuest === true">
                            <span class="sr-only">Claim your </span>
                            Guest
                            <span class="sr-only"> account</span>
                        </span>
                        <span *ngIf="userIsGuest === false && userIsApproved === false">
                            Sign in
                            <span class="sr-only">, go to my account</span>
                        </span>
                    </button>
                </li>
            </ul>
        </nav>

        <ng-template #basketIcon>
            <div class="header-basket-icon" aria-hidden="true">
                <svg viewBox="0 0 24 24" aria-hidden="true" focusable="false">
                    <title>basket</title>
                    <use xlink:href="#basket" />
                </svg>
            </div>
        </ng-template>
    </div>
</header>
