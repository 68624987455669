import { AceUser } from "../../models/ace/ace-user.model";

export class AuthenticateUser {
    static readonly type = "[Account] Authenticate user";
}

export class UserLoggedIn {
    static readonly type = "[Account] User logged in";

    constructor(public payload: AceUser) {}
}

export class UserLoggedOut {
    static readonly type = "[Account] User logged out";
}

export class FetchSmartcards {
    static readonly type = "[Account] Fetch smartcards";
}

export class UpdateSmartcards {
    static readonly type = "[Account] Update smartcards";

    constructor(public payload: ORM.Core.Transport.SmartCard[]) {}
}

export class RemoveSmartcard {
    static readonly type = "[Account] Remove smartcard";

    constructor(public payload: number) {}
}
