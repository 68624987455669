import moment from "moment";
import { asArray, difference } from "../../../shared/utilities/Utils";
import { ISearchResultType } from "../interfaces/ISearchResult";
import { AceLegSolution } from "./ace-leg-solution.model";
import { AcePointToPointPrice } from "./ace-point-to-point-price.model";
import { AceSearchResult } from "./ace-search-result.model";
import { AceSnapshot } from "./ace-snapshot";

export abstract class AceSearchProjection {
    public static readonly ERR_INVALID_COMBINATION = "Invalid combination of time and/or prices encountered";
    public static readonly ERR_INVALID_LEG_SOLUTION = "Invalid departure or return identifier encountered";
    public static readonly ERR_INVALID_PRICE = "Invalid price identifier encountered";
    public static readonly ERR_INVALID_SINGLE_PRICE_COMBINATION = "Invalid single price and/or time combination";
    public static readonly ERR_INVALID_RETURN_PRICE_COMBINATION = "Invalid return price and/or time combination";
    public static readonly DEFAULT_FARE_CLASS_RANK = 0;
    public static readonly FARE_CLASS_RANK_NEGATIVE = -1;
    public static FARE_CLASS_RANK_THRESHOLD;
    public static FARE_CLASS_RANK;

    public allLegSolutions: AceLegSolution[];
    public outgoingLegSolutions: AceLegSolution[];
    public outgoingDate: moment.Moment;
    public returnDate: moment.Moment;
    public returnLegSolutions: AceLegSolution[];
    public searchResult: AceSearchResult;

    protected _prices: AcePointToPointPrice[];
    protected _configSnapshot: AceSnapshot;

    constructor(searchResult: AceSearchResult, configSnapshot: AceSnapshot) {
        this.searchResult = searchResult;
        this._configSnapshot = configSnapshot;
        this._init();
    }

    public legSolutionIdIsValid(legSolutionId: string): boolean {
        return !!this.findLegSolutionById(legSolutionId);
    }

    public findLegSolutionById(legSolutionId: string): AceLegSolution {
        return this.allLegSolutions.find(legSolution => legSolution.legSolutionID === legSolutionId);
    }

    public priceIdIsValid(priceId: string): boolean {
        return !!this.findPriceById(priceId);
    }

    public findPriceById(priceId: string): AcePointToPointPrice {
        return this._prices.find(price => price.priceID === priceId);
    }

    /**
     * Finds any prices that matches all of the supplied fare classes
     *
     * @param fareClasses
     * @param prices
     * @returns {Array<AcePointToPointPrice>}
     */
    public findPricesForAllFareClasses(fareClasses: string[] | string, prices: AcePointToPointPrice[]): AcePointToPointPrice[] {
        return prices.filter((item: AcePointToPointPrice) => {
            return difference(asArray(fareClasses), item.fareClasses).length === 0;
        });
    }

    /**
     * Finds any price that matches at least one of the supplied fareClasses
     *
     * @param fareClasses
     * @param prices
     * @returns {Array<AcePointToPointPrice>}
     */
    public findPricesForAnyFareClasses(fareClasses: string[] | string, prices: AcePointToPointPrice[]): AcePointToPointPrice[] {
        return prices.filter((price: AcePointToPointPrice) => {
            return asArray(fareClasses).some(fareClass => price.fareClasses.includes(fareClass));
        });
    }

    public findPricesForAnyLegSolutions(legSolutions: string[] | string, prices: AcePointToPointPrice[]): AcePointToPointPrice[] {
        return prices.filter(price => asArray(legSolutions).some(legSolutions => price.legReferences.includes(legSolutions)));
    }

    public findPricesForAllLegSolutions(legSolutions: string[] | string, prices: AcePointToPointPrice[]): AcePointToPointPrice[] {
        return prices.filter(price => difference(asArray(legSolutions), price.legReferences).length === 0);
    }

    public validateCombination(
        prices: string[] | string,
        legSolutionIds: string[] | string
    ): {
        complete: boolean;
        prices: AcePointToPointPrice[];
        legSolutions: AceLegSolution[];
    } {
        // Enforce arrays
        prices = Array.from(new Set(asArray(prices)));
        legSolutionIds = Array.from(new Set(asArray(legSolutionIds)));

        // There must be the same or more leg solutions to prices; Also there cannot be more than two leg solutions or prices, and more than zero
        if (legSolutionIds.length < prices.length || prices.length === 0 || prices.length > 2 || legSolutionIds.length === 0 || legSolutionIds.length > 2) {
            throw new Error(AceSearchProjection.ERR_INVALID_COMBINATION);
        }

        // Verify everything exists
        if (!legSolutionIds.every(id => this.legSolutionIdIsValid(id))) {
            throw new Error(AceSearchProjection.ERR_INVALID_LEG_SOLUTION);
        }
        if (!prices.every(priceId => this.priceIdIsValid(priceId))) {
            throw new Error(AceSearchProjection.ERR_INVALID_PRICE);
        }

        const acePrices = prices.map(AcePointToPointPrice.getById);
        const aceLegSolutions = legSolutionIds.map(AceLegSolution.getById);

        // Examine first price to determine single/return
        if (acePrices[0].isSingleFare) {
            // Single

            if (prices.length === 1) {
                // One price selected

                // The specified price must have a direct reference to the leg solution
                if (difference(acePrices[0].legReferences, legSolutionIds).length !== 0) {
                    throw new Error(AceSearchProjection.ERR_INVALID_SINGLE_PRICE_COMBINATION);
                }

                this._updateLegState(aceLegSolutions);

                // This is a valid single combination, but incomplete if this is a return search
                return {
                    complete: this.searchResult.resultType === ISearchResultType.SINGLE,
                    prices: acePrices,
                    legSolutions: aceLegSolutions
                };
            }

            if (prices.length === 2) {
                // Two prices selected (which due to validation above, guarantees two legs are selected too)

                // Second price must be a single
                if (!acePrices[1].isSingleFare) {
                    throw new Error(AceSearchProjection.ERR_INVALID_SINGLE_PRICE_COMBINATION);
                }

                // Ensure that opposite direction leg solutions are specified
                if (aceLegSolutions[0].isOutgoing === aceLegSolutions[1].isOutgoing) {
                    throw new Error(AceSearchProjection.ERR_INVALID_SINGLE_PRICE_COMBINATION);
                }

                // Ensure that each price references a different leg solution, and they are in the specified leg solutions
                if (
                    acePrices[0].legReferences[0] === acePrices[1].legReferences[0] ||
                    !legSolutionIds.includes(acePrices[0].legReferences[0]) ||
                    !legSolutionIds.includes(acePrices[1].legReferences[0])
                ) {
                    throw new Error(AceSearchProjection.ERR_INVALID_SINGLE_PRICE_COMBINATION);
                }

                this._updateLegState(aceLegSolutions);

                // Ok combination is good and complete
                return {
                    complete: true,
                    prices: acePrices,
                    legSolutions: aceLegSolutions
                };
            }

            // Shouldnt be able to get down here...
            throw new Error(AceSearchProjection.ERR_INVALID_COMBINATION);
        } else {
            // Return

            // Returns must have 1 price and 2 leg solutions, but from validation POV a single leg is allowed, its just not complete
            if (prices.length !== 1 || legSolutionIds.length !== 2) {
                return {
                    complete: false,
                    prices: acePrices,
                    legSolutions: aceLegSolutions
                };
            }

            // Return prices have a direct reference to both our/rtn leg solutions, so they should match exactly
            if (difference(acePrices[0].legReferences, legSolutionIds).length !== 0) {
                throw new Error(AceSearchProjection.ERR_INVALID_RETURN_PRICE_COMBINATION);
            }

            this._updateLegState(aceLegSolutions);

            // Ok, the combination is good to go!
            return {
                complete: true,
                prices: acePrices,
                legSolutions: aceLegSolutions
            };
        }
    }

    protected _getValueOfThePriceWithPromotion(price: AcePointToPointPrice): number {
        const totalPrice = price && price.totalPrice;

        if (totalPrice) {
            const promotionPrice = totalPrice.promotion && totalPrice.promotion.promotionPrice;
            return promotionPrice != null ? promotionPrice : totalPrice.value;
        }
    }

    /**
     * Initial parsing of search result data
     *
     * The goal here is to do as much processing of the search result data as possible in order to make the Mixing deck
     * as simple as possible and as FAST as possible
     *
     * @private
     */
    private _init(): void {
        // Rank parsing.
        // We get this from the remote config. IF not set already, parse the config and set a rank map
        if (!AceSearchProjection.FARE_CLASS_RANK) {
            AceSearchProjection.FARE_CLASS_RANK = {};
            const fareClasses = this._configSnapshot.get("maps.fareClasses");

            if (fareClasses != null) {
                fareClasses.filter(it => it != null).forEach(fare => (AceSearchProjection.FARE_CLASS_RANK[fare.code] = fare.rank));
                AceSearchProjection.FARE_CLASS_RANK_THRESHOLD = this._configSnapshot.get("toc.mixingDeck.recommendedRankThreshold");
            }
        }

        // Leg solutions
        this.outgoingLegSolutions = [];
        this.returnLegSolutions = [];
        if (this.searchResult.resultType === ISearchResultType.SINGLE || this.searchResult.resultType === ISearchResultType.RETURN) {
            this.outgoingLegSolutions = this.searchResult.outgoingResults.legSolutions;
            this.outgoingDate = this.outgoingLegSolutions[0].arrivalDateMoment;
        }
        if (this.searchResult.resultType === ISearchResultType.RETURN) {
            this.returnLegSolutions = this.searchResult.returnResults.legSolutions;
            this.returnDate = this.returnLegSolutions[0].arrivalDateMoment;
        }
        this.allLegSolutions = [...this.outgoingLegSolutions, ...this.returnLegSolutions];

        // Prices
        this._prices = this._applyTocPriceFilters(this.searchResult.prices);
    }

    private _updateLegState(selectedLegs: AceLegSolution[]): void {
        for (const key of Object.keys(this.allLegSolutions)) {
            const solution = this.allLegSolutions[key];
            const solutionFound = selectedLegs.find(item => item === solution.legSolutionID);

            if (solutionFound) {
                solutionFound.isSelected = true;
            } else {
                solution.isSelected = false;
            }
        }
    }

    private _applyTocPriceFilters(prices: AcePointToPointPrice[]): AcePointToPointPrice[] {
        // Perform any custom TOC price filtering logic here...
        return prices;
    }
}
