import { AbstractControl, ValidatorFn } from "@angular/forms";
export class UtilValidator {
    public static requiredIfTrigger(targetControl: AbstractControl): ValidatorFn {
        return (control: AbstractControl): null => {
            if (targetControl) {
                targetControl.updateValueAndValidity();
            }
            return null;
        };
    }

    public static requiredIf(targetControl: AbstractControl): ValidatorFn {
        return (control: AbstractControl): { [key: string]: boolean } => {
            if (!targetControl) {
                return null;
            }

            const value = control.value;

            if (!value && targetControl.value) {
                return { required: true };
            }

            return null;
        };
    }

    public static hasAnyNonWhitespaceCharacter(control: AbstractControl): { [key: string]: boolean } | null {
        const pattern = new RegExp(/\S+/);
        return pattern.test(control.value) ? null : { required: true };
    }
}
