import { Component, OnInit } from "@angular/core";
import { Select, Store } from "@ngxs/store";
import { Observable } from "rxjs";
import { take } from "rxjs/operators";
import { GenericModalComponent } from "../../../modal/components/modals/generic/generic-modal.component";
import { ModalService } from "../../../modal/services/modal.service";
import { CloudWatchLoggingService } from "../../../services/cloudwatch-logger.service";
import { GoogleAnalyticsService } from "../../../services/google-analytics.service";
import { nreActionType } from "../../../shared/models/entities/nre";
import { INREAnalytics } from "../../../shared/models/interfaces/INREAnalytics";
import { IQueryParams } from "../../../shared/models/interfaces/IQueryParams";
import { RxjsComponent } from "../../../shared/RxjsComponent";
import { NREStatus } from "../../../shared/state/nre/nre-state.model";
import { NRERedirected } from "../../../shared/state/nre/nre.actions";
import { NREState } from "../../../shared/state/nre/nre.state";
import { QttState } from "../../../shared/state/qtt/qtt.state";

@Component({
    selector: "ace-homepage",
    templateUrl: "./homepage.component.html",
    styleUrls: ["./homepage.component.scss"]
})
export class HomepageComponent extends RxjsComponent implements OnInit {
    @Select(QttState.params) public qttParams$: Observable<IQueryParams>;
    constructor(
        private modalService: ModalService,
        private cloudWatchLoggingService: CloudWatchLoggingService,
        private analyticsService: GoogleAnalyticsService,
        private store: Store
    ) {
        super();
    }

    public ngOnInit() {
        const nre = this.store.selectSnapshot(state => NREState.nre(state.nre));
        if (nre && nre.status === NREStatus.REDIRECTING) {
            this.onRedirectFromNre();
        }
    }

    private onRedirectFromNre() {
        const title = "Requested ticket not found";
        const body = "There was a problem finding the ticket you wanted, please search again";

        this.addSubscription(
            this.qttParams$.pipe(take(1)).subscribe(params => {
                const logData: INREAnalytics = {
                    handoffId: params.requestID,
                    origin: params.origin,
                    destination: params.destination,
                    errormsg: title,
                    pagenotif: body,
                    value: (Number(params.basketTotal) / 100).toFixed(2)
                };

                this.analyticsService.nreAnalytics(nreActionType.REDIRECTION, logData);

                this.cloudWatchLoggingService.logEvent({
                    level: "ERROR",
                    message: JSON.stringify({ context: "[HOMEPAGE] Redirection from NRE", params: logData })
                });
            })
        );

        this.addSubscription(this.cloudWatchLoggingService.pushToCloudWatch().subscribe());

        this.modalService.create(GenericModalComponent, {
            title,
            body,
            cta: "Close",
            handleCTAClick: instance => {
                this.store.dispatch(new NRERedirected());
                instance.destroy();
            }
        });
    }
}
