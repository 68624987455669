import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { AccountService } from "../../../services/account.service";
import { RxjsComponent } from "./../../../shared/RxjsComponent";

@Component({
    selector: "ace-verify-email",
    templateUrl: "./verify-email.component.html",
    styleUrls: ["./verify-email.component.scss"]
})
export class VerifyEmailComponent extends RxjsComponent implements OnInit {
    public verifyUserErrorMessage: string = "";
    public isLoading: boolean = false;
    private _verificationType: string;

    constructor(private _router: Router, private _route: ActivatedRoute, private _accountService: AccountService) {
        super();
    }

    public ngOnInit() {
        this.isLoading = true;
        this.addSubscription(
            this._route.queryParams.subscribe(param => {
                if (param.verificationToken) {
                    this.verifyToken(param.verificationToken);
                } else {
                    this._router.navigate(["/"]);
                }
            })
        );
    }

    public verifyToken(token: string) {
        // TODO: get this properly through the ActivatedRoute on ngOnInit observable when there's more time, and use the verification
        // static variables from AccountService for conditional AccountService request selection
        this._verificationType = window.location.pathname.split("/")[1];

        setTimeout(() => {
            if (this._verificationType === "verifyUser") {
                this.addSubscription(
                    this._accountService.verifyUser(token).subscribe({
                        next: res => {
                            this.onVerificationSuccess(res);
                            this.addSubscription(this._accountService.whoAmI().subscribe());
                        },
                        error: err => this.onVerificationError(err)
                    })
                );
            } else if (this._verificationType === "verifyEmail") {
                this.addSubscription(
                    this._accountService.verifyEmail(token).subscribe({
                        next: res => {
                            this.onVerificationSuccess(res);
                            this._accountService.logOut();
                        },
                        error: err => this.onVerificationError(err)
                    })
                );
            }
        }, 2000);
    }

    public onVerificationSuccess(res) {
        if (res !== "Success") {
            this.verifyUserErrorMessage = "Sorry there was a problem verifying your email";
        }
        this.isLoading = false;
    }

    public onVerificationError(err) {
        this.verifyUserErrorMessage = err.errorMessage;
        this.isLoading = false;
    }
}
