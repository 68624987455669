<div class="modal t-row">
    <div class="t-col t-col-12">
        <div class="t-row">
            <h3 class="copy-title-2 text-center">Error</h3>
            <ng-container *ngIf="verboseErrors">
                <p>{{ error.errorMessage || error["message"] }}</p>
                <ace-accordion>
                    <accordion-head>
                        <span (click)="showDetails = !showDetails">Details</span>
                        <svg viewBox="0 0 24 24" *ngIf="showDetails"><use xlink:href="#carat-up" /></svg>
                        <svg viewBox="0 0 24 24" *ngIf="!showDetails"><use xlink:href="#carat-down" /></svg>
                    </accordion-head>
                    <accordion-content>
                        <pre>{{ errorJson }}</pre>
                    </accordion-content>
                </ace-accordion>
            </ng-container>
            <ng-container *ngIf="!verboseErrors">
                <br />
                <ng-container *ngIf="pastBookingHorizon">
                    <h3>Tickets are coming soon</h3>
                    <p>
                        <br />
                        Visit our
                        <a
                            href="https://www.grandcentralrail.com/travelling-with-gc/timetables/ticket-availability"
                            title="Ticket availability for our cheapest fares"
                            target="_blank"
                        >
                            ticket availability page
                        </a>
                        to see when the tickets will be released.
                    </p>
                </ng-container>
                <p *ngIf="!pastBookingHorizon">
                    We're sorry, we're experiencing technical problems with our online booking system.
                    <br />
                    Please try again, and if you still have problems call our team on {{ configService.getPrimaryTel() | async }}, lines open from 7am to 10pm every day.
                </p>
                <br />
            </ng-container>
        </div>
        <div class="t-row">
            <div class="t-col t-col-12 text-center">
                <ace-button appearance="editorial" size="primary" (action)="onButtonClicked()" id="error-modal-close">{{ cta }}</ace-button>
            </div>
        </div>
    </div>
</div>
