export enum TicketType {
    ON_DEPARTURE = "ON_DEPARTURE",
    MAIL = "MAIL",
    PAH_TICKET = "E_TICKET",
    E_TICKET = "XVD",
    S_TICKET = "S_TICKET"
}

export enum TicketDeliveryCode {
    TVM = "TVM", // Collect at Ticket Vending Machine
    TOF = "TOF", // Collect at Ticket Office
    TBM = "TBM", // Ticket by Royal Mail - First Class
    TBX = "TBX", // Ticket by Royal Mail - Special Delivery
    TBO = "TBO", // Ticket by International DHL Next Day
    XVD = "XVD", // XML value document (mobile ticket)
    PAH = "PAH", // Print at home
    SCT = "SCT" // ITSO Smart card
}
