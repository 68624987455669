import { AbstractControl } from "@angular/forms";

export class PasswordValidators {
    public static checkPasswordsMatch(control: AbstractControl) {
        const password = control.get("password");
        const confirm = control.get("confirmPassword");
        if (!(password && confirm)) {
            return null;
        }
        return password.value === confirm.value ? null : { nomatch: true };
    }

    public static checkPasswordNumerics(control: AbstractControl) {
        const password = control.get("password");
        // check for one or more numerical character
        const pattern = new RegExp(/(\d+)/);
        return pattern.test(password.value) ? null : { numerics: true };
    }

    public static checkPasswordCases(control: AbstractControl) {
        const password = control.get("password");
        // Check for at least one upper/lower case character
        const pattern = new RegExp(/(?=.*[A-Z])(?:.*[a-z])/);
        return pattern.test(password.value) ? null : { cases: true };
    }

    public static checkPasswordSymbols(control: AbstractControl) {
        const password = control.get("password");
        // Check for at least one special character
        const pattern = new RegExp(/([!”$%&@?#’()*+,.\/:;\[\\\]^_`'"{|}~=])+/);
        return pattern.test(password.value) ? null : { symbols: true };
    }

    public static checkPasswordLength(control: AbstractControl) {
        const password = control.get("password");
        const pattern = new RegExp(/.{8,}/);
        return pattern.test(password.value) ? null : { length: true };
    }
}
