<div
    role="dialog"
    class="prompt-modal t-row"
    data-elid="prompt-modal"
    aria-modal="true"
    aria-labelledby="prompt-modal-title"
    [attr.aria-description]="description"
    [class.default-card-confirmation]="isDefaultCardConfirmation"
    [ngClass]="{ 'width90-mobile': isDeviceMobile }"
    cdkTrapFocus
>
    <div class="t-col t-col-12">
        <div *ngIf="!isDefaultCardConfirmation" class="t-row">
            <svg viewBox="0 0 24 24" aria-hidden="true"><use xlink:href="#warning-triangle" /></svg>
        </div>
        <div class="t-row">
            <ace-error-sign *ngIf="isDefaultCardConfirmation"></ace-error-sign>
            <span id="prompt-modal-title" class="copy-title-2">{{ title }}</span>
            <p [innerHtml]="description" [ngClass]="{ 'center-align-desktop': isDeviceDesktop, 'left-align-mobile': isDeviceMobile }"></p>

            <div class="prompt-modal-extra" *ngIf="extra">
                <ng-container *ngFor="let info of extra">
                    <p>{{ info }}</p>
                </ng-container>
            </div>
        </div>
        <div class="t-row" [ngClass]="{ 'column-alignment-mobile': isDeviceMobile }">
            <div *ngIf="cancelCTAText" class="t-col t-col-6" [class.wide]="!hasDefaultValues" [ngClass]="{ 'width100-mobile': isDeviceMobile }">
                <ace-button
                    #cancelButton
                    id="prompt-modal-cancel"
                    class="cancel"
                    data-elid="prompt-modal-cancel"
                    size="primary"
                    appearance="editorial"
                    (action)="cancelPrompt()"
                    [disabled]="submitted"
                >
                    {{ cancelCTAText }}
                </ace-button>
            </div>
            <div class="t-col t-col-6" [class.wide]="!hasDefaultValues" [ngClass]="{ 'width100-mobile': isDeviceMobile }">
                <ace-button
                    #confirmButton
                    id="prompt-modal-confirm"
                    class="confirm"
                    data-elid="prompt-modal-confirm"
                    size="primary"
                    appearance="commercial"
                    (action)="confirmPrompt()"
                    [confirmLoadingLabel]="confirmLoadingLabel"
                    [disabled]="submitted"
                    [showLoader]="submitted"
                >
                    {{ confirmCTAText }}
                </ace-button>
            </div>
        </div>
    </div>
</div>
