import { Component, OnInit } from "@angular/core";
import { ToastEventType } from "../../models/entities/toast";
import { NotifyToastService } from "../../../services/notify-toast.service";
import { IToast } from "../../models/interfaces/IToast";
import { RxjsComponent } from "../../RxjsComponent";

@Component({
    selector: "ace-notify-toast",
    templateUrl: "notify-toast.component.html",
    styleUrls: ["notify-toast.component.scss"]
})
export class NotifyToastComponent extends RxjsComponent implements OnInit {
    public toasts: IToast[] = [];
    public toastLimit: 5;

    constructor(private toastService: NotifyToastService) {
        super();
    }

    public ngOnInit(): any {
        this.triggerToast();
    }

    public closeToast(toast: IToast): void {
        this.clear(toast.id);
    }

    public add(toast: IToast): void {
        // If limit reached, remove the earliest one from the array
        if (this.toasts.length >= this.toastLimit) {
            this.toasts.shift();
        }

        this.toasts.push(toast);

        if (toast.timeout) {
            this.setTimeout(toast);
        }
    }

    public clear(id: number): void {
        if (id) {
            this.toasts.forEach((value, key) => {
                if (value.id === id) {
                    const onRemove = value.onRemove;
                    if (!!(onRemove && onRemove.constructor && onRemove.call && onRemove.apply)) {
                        value.onRemove.call(this, value);
                    }

                    this.toasts.splice(key, 1);
                }
            });
        } else {
            throw new Error("Please provide id of Toast to close");
        }
    }

    public clearAll(): void {
        this.toasts.forEach(value => {
            const onRemove = value.onRemove;
            if (!!(onRemove && onRemove.constructor && onRemove.call && onRemove.apply)) {
                value.onRemove.call(this, value);
            }
        });
        this.toasts = [];
    }

    private setTimeout(toast: IToast): void {
        if (toast.timeout !== 0) {
            window.setTimeout(() => {
                this.clear(toast.id);
            }, toast.timeout);
        }
    }

    private preventMultiTap(toast: IToast): void {
        const prevToast = this.toasts[this.toasts.length - 1];

        if (prevToast && prevToast.msg === toast.msg) {
            return;
        }

        this.add(toast);
    }

    private triggerToast(): void {
        this.addSubscription(
            this.toastService.events.subscribe(event => {
                if (event.type === ToastEventType.ADD) {
                    const toast: IToast = event.value;
                    this.toasts.length ? this.preventMultiTap(toast) : this.add(toast);
                } else if (event.type === ToastEventType.CLEAR) {
                    const id: number = event.value;
                    this.clear(id);
                } else if (event.type === ToastEventType.CLEAR_ALL) {
                    this.clearAll();
                }
            })
        );
    }
}
