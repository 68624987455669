import { ComponentFactoryResolver, ComponentRef, Injectable, Injector, ViewContainerRef } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { ModalDisplayStack } from "../classes/ModalDisplayStack";

@Injectable()
export class ModalService {
    public readonly enabler$: BehaviorSubject<boolean>;
    private viewContainerRef: ViewContainerRef;
    private factoryContainerRef: ViewContainerRef;
    private injector: Injector;
    private displayStack: ModalDisplayStack;

    constructor(public resolver: ComponentFactoryResolver) {
        this.enabler$ = new BehaviorSubject(false);
    }

    /**
     * registers container that will serve as a main display controlled by display stack.
     * @param viewContainerRef
     */
    public registerViewContainerRef(viewContainerRef: ViewContainerRef): void {
        this.viewContainerRef = viewContainerRef;
        this.displayStack = new ModalDisplayStack(this.viewContainerRef);
        this.displayStack.stack$?.subscribe(value => this.enabler$.next(value));
    }

    /**
     * registers container in which creation of component will take place before being pushed to the display stack
     * @param factoryContainerRef
     */
    public registerFactoryContainerRef(factoryContainerRef: ViewContainerRef): void {
        this.factoryContainerRef = factoryContainerRef;
    }

    /**
     * Registers injector of the UIModal component
     * @param injector
     */
    public registerInjector(injector: Injector): void {
        this.injector = injector;
    }

    /**
     * Creates and instance of the component's class, hydrates its @Inputs and @outputs with values from parameters
     * and displays it in the modal space
     * @param component - Class of the component which instance will be displayed in the modal
     * @param parameters - object containing properties that map to components @Input - allowing to inject data in
     * and @Output bindings - allowing to receive data from the component
     * @returns {Observable<T>}
     */
    public create<T>(component: any, parameters?: any): Observable<ComponentRef<T>> {
        const componentFactory = this.resolver.resolveComponentFactory(component);
        const childInjector = Injector.create({ providers: [], parent: this.injector });
        const componentRef = this.factoryContainerRef.createComponent(componentFactory, 0, childInjector);
        // passes the bindings to the instance
        Object.assign(componentRef.instance, parameters);
        this.factoryContainerRef.detach(0);
        return this.displayStack.add(componentRef).asObservable();
    }
}
