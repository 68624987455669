export class AceTravelDocumentInformation {
    private _documentNumber: string = "";
    private _documentType: string = "";
    private _expirationDate: string = "";
    private _documentAffiliation: string = "";
    private _issuingAuthority: any;

    public toJSON(): DataModel.TravelDocument {
        return {
            documentNumber: this._documentNumber,
            documentType: this._documentType,
            expirationDate: this._expirationDate,
            documentAffiliation: this._documentAffiliation,
            issuingAuthority: this._issuingAuthority
        };
    }

    // IMPLICIT SETTERS, METHOD CHAINING ENABLED
    public setDocumentType(value: string): AceTravelDocumentInformation {
        this._documentType = value;
        return this;
    }

    public setDocumentnumber(value: string): AceTravelDocumentInformation {
        this._documentNumber = value;
        return this;
    }

    public setExpirationDate(value: string): AceTravelDocumentInformation {
        this._expirationDate = value;
        return this;
    }

    public setIssueAuthority(value: any): AceTravelDocumentInformation {
        this._issuingAuthority = value;
        return this;
    }

    public setDocumentAffiliation(value: string): AceTravelDocumentInformation {
        this._documentAffiliation = value;
        return this;
    }
}
