import { OnInit, OnDestroy, Directive, ElementRef } from "@angular/core";
import { Store } from "@ngxs/store";
import { BehaviorSubject, combineLatest, filter, map, distinctUntilChanged } from "rxjs";
import { ConfigService } from "../../services/config.service";
import { IAceConfig } from "../models/interfaces/IAceConfig";
import { IViewportSize, ViewportType } from "../models/entities/viewport";
import { UpdateMetadata } from "../state/metadata/metadata.actions";
import { inRange } from "../utilities/Utils";

@Directive({
    selector: "[aceViewportTracker]"
})
export class ViewportTrackerDirective implements OnInit, OnDestroy {
    private observer: ResizeObserver;

    constructor(private config: ConfigService, private host: ElementRef, private store: Store) {}

    public ngOnInit(): void {
        const windowSize$ = new BehaviorSubject<IViewportSize>({ width: 1920, height: 1200 });
        this.observer = new ResizeObserver(entries =>
            entries.forEach(entry => {
                const { width, height } = entry.contentRect;
                windowSize$.next({ width, height });
            })
        );

        this.observer.observe(this.host.nativeElement);

        combineLatest([windowSize$, this.config.get<IAceConfig["viewport"]>("viewport").pipe(filter(viewport => viewport != null))])
            .pipe(
                map(([size, viewport]) => this.mapDeviceType(size, viewport)),
                distinctUntilChanged((prev, curr) => prev === curr)
            )
            .subscribe(device => this.store.dispatch(new UpdateMetadata({ device })));
    }

    private mapDeviceType(size: IViewportSize, viewport: IAceConfig["viewport"]): ViewportType {
        const isMobileSmall = inRange(size.width, 0, viewport.mobileSmallBreakpoint);
        const isMobile = inRange(size.width, viewport.mobileSmallBreakpoint, viewport.mobileBreakpoint);
        const isTablet = inRange(size.width, viewport.mobileBreakpoint, viewport.tabletBreakpoint);
        const isDesktop = size.width >= viewport.tabletBreakpoint;

        if (isMobileSmall) {
            return ViewportType.MOBILE_SMALL;
        } else if (isMobile) {
            return ViewportType.MOBILE;
        } else if (isTablet) {
            return ViewportType.TABLET;
        } else if (isDesktop) {
            return ViewportType.DESKTOP;
        }
    }

    public ngOnDestroy(): void {
        this.observer.unobserve(this.host.nativeElement);
    }
}
