import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Select, Store } from "@ngxs/store";
import { Observable, combineLatest } from "rxjs";
import { take, filter } from "rxjs/operators";
import { CloudWatchLoggingService } from "../../../services/cloudwatch-logger.service";
import { NationalRailService } from "../../../services/national-rail.service";
import { SearchSelectionContextService } from "../../../services/search-selection-context.service";
import { SearchService } from "../../../services/search.service";
import { TicketSearchPayloadCriteria } from "../../../shared/models/criterias/ticket-search-payload-criteria";
import { IQueryParams } from "../../../shared/models/interfaces/IQueryParams";
import { SilverRailRailcard } from "../../../shared/models/api/railcard";
import { RxjsComponent } from "../../../shared/RxjsComponent";
import { NRERedirecting, SetHandoffID } from "../../../shared/state/nre/nre.actions";
import { RailcardsState } from "../../../shared/state/railcards/railcards.state";
import { POINT_TO_POINT_REQUEST } from "../../../shared/constants/api-calls";

@Component({
    selector: "ace-app-nre-handoff",
    templateUrl: "./national-rail-handoff.component.html",
    styleUrls: ["./national-rail-handoff.component.scss"]
})
export class NationRailHandoffComponent extends RxjsComponent implements OnInit {
    @Select(RailcardsState.railcards) public railcards$: Observable<SilverRailRailcard[]>;
    public isLoading: boolean = true;
    public criteria: TicketSearchPayloadCriteria;
    public params: IQueryParams;
    constructor(
        private _search: SearchService,
        private _route: ActivatedRoute,
        private _router: Router,
        private _nreService: NationalRailService,
        private _selectionContext: SearchSelectionContextService,
        private _cloudWatchLoggingService: CloudWatchLoggingService,
        private _store: Store
    ) {
        super();
    }

    public ngOnInit() {
        this.isLoading = true;
        this._store.dispatch(new NRERedirecting());
        const railcardsStream = this.railcards$.pipe(filter(railcards => railcards && railcards.length > 0));
        const searchQueryStream = this._route.queryParams.pipe(take(1));

        this.addSubscription(
            combineLatest([railcardsStream, searchQueryStream]).subscribe(([railcards, query]) => {
                this.search(query && query.railcards ? this._nreService.mapNreRailcards(query, railcards) : query);
            })
        );

        this.addSubscription(
            this._selectionContext.selectionComplete$?.subscribe(selection => {
                if (selection) {
                    this.routeToSeats();
                }
            })
        );

        this.addSubscription(this._nreService.selectionInvalid$?.subscribe(() => this.routeToSearch()));
    }

    public routeToSeats(): void {
        this.addSubscription(
            this._selectionContext
                .getParamsForCurrentSelection()
                .pipe(take(1))
                .subscribe(params => {
                    this._cloudWatchLoggingService.logEvent({
                        level: "INFO",
                        message: "[NRE] Redirect to seats and extras succeeded"
                    });
                    this._router.navigate(["/booking/seats-and-extras"], { queryParams: params });
                })
        );

        this.addSubscription(this._cloudWatchLoggingService.pushToCloudWatch().subscribe());
    }

    public search(params: any): void {
        this._store.dispatch(new SetHandoffID(params.requestID));
        const nreRequestId = params["requestID"];
        const nreParams = Object.assign({}, params);
        delete nreParams["requestID"];

        this._cloudWatchLoggingService.logEvents([
            {
                level: "INFO",
                message: JSON.stringify({ context: `[NRE-HANDOFF] NreRequestId: ${nreRequestId}`, nreParams })
            }
        ]);

        this.params = this._nreService.updateParams(params);
        this.criteria = new TicketSearchPayloadCriteria(this.params, this._store);

        this.addSubscription(
            this._search.searchResults(this.criteria, POINT_TO_POINT_REQUEST, "NRE").subscribe({
                next: res => this._nreService.findSelections(res),
                error: () => this.routeHome()
            })
        );

        this.addSubscription(this._cloudWatchLoggingService.pushToCloudWatch().subscribe());
    }

    public routeHome(): void {
        this._router.navigate(["/"]);
    }

    public routeToSearch(): void {
        this._router.navigate(["/search"], {
            queryParams: this.params,
            replaceUrl: true
        });
    }
}
