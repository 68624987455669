import { Injectable } from "@angular/core";
import { Action, Selector, State, StateContext } from "@ngxs/store";
import { ViewportType } from "../../models/entities/viewport";
import { IExtendedMetadata, IMetadataStateModel } from "./metadata-state.model";
import { UpdateMetadata } from "./metadata.actions";
import client from "../../../../../package.json";
import { MOBILE_CONTEXT } from "../../constants/context";

@State<IMetadataStateModel>({
    name: "metadata",
    defaults: {
        client: "WEB",
        clientVersion: client.version,
        device: ViewportType.DESKTOP
    }
})
@Injectable()
export class MetadataState {
    @Selector([MetadataState])
    static metadata(state: IMetadataStateModel): IExtendedMetadata {
        return state ? state : null;
    }

    @Selector([MetadataState.metadata])
    static deviceType(state: IMetadataStateModel): ViewportType {
        return state && state.device ? state.device : ViewportType.DESKTOP;
    }

    @Selector([MetadataState.metadata])
    static isMobile(state: IMetadataStateModel): boolean {
        return (state && state.device && MOBILE_CONTEXT.some(type => type === state.device)) || false;
    }

    @Selector([MetadataState.metadata])
    static deviceName(state: IMetadataStateModel): string {
        const context = state && state.device && MOBILE_CONTEXT.some(type => type === state.device) ? "mobile" : "desktop";
        return context !== null ? context : null;
    }

    @Action(UpdateMetadata)
    updateMetadata({ patchState }: StateContext<IMetadataStateModel>, action: UpdateMetadata) {
        patchState(action.payload);
    }
}
