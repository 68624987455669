export class AceContactInformation {
    private _contactType: string = "";
    private _contactMedium: string = "";
    private _contactInfo: string = "";

    public toJSON(): object {
        return {
            contactType: this.contactType,
            contactMedium: this.contactMedium,
            contactInfo: this.contactInfo
        };
    }

    // EXPLICIT GETTERS
    public get contactType(): string {
        return this._contactType;
    }

    public get contactMedium(): string {
        return this._contactMedium;
    }

    public get contactInfo(): string {
        return this._contactInfo;
    }

    // IMPLICIT SETTERS, METHOD CHAINING ENABLED
    public setContactType(value: string): AceContactInformation {
        this._contactType = value;
        return this;
    }

    public setContactMedium(value: string): AceContactInformation {
        this._contactMedium = value;
        return this;
    }

    public setContactInfo(value: string): AceContactInformation {
        this._contactInfo = value;
        return this;
    }
}
