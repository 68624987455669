import { Component, ElementRef, Input, OnInit, QueryList, ViewChildren } from "@angular/core";
import { Router } from "@angular/router";
import { Select, Store } from "@ngxs/store";
import { take } from "rxjs/operators";
import { Observable } from "rxjs";
import { ConfigService } from "../../../services/config.service";
import { SearchService } from "../../../services/search.service";
import { SEASON_QTT_TAB, STANDARD_QTT_TAB } from "../../constants/qtt-tabs";
import { IQueryParams } from "../../models/interfaces/IQueryParams";
import { QttTabSection } from "../../models/entities/qtt-tab";
import { ViewportType } from "../../models/entities/viewport";
import { RxjsComponent } from "../../RxjsComponent";
import { SetSeasonSearch } from "../../state/qtt/qtt.actions";
import { TicketPreviewService } from "../../../services/ticket-preview.service";
import { MetadataState } from "../../state/metadata/metadata.state";
import { RailcardService } from "../../../services/railcard.service";

@Component({
    selector: "ace-qtt-toggle",
    templateUrl: "qtt-toggle.component.html",
    styleUrls: ["qtt-toggle.component.scss"]
})
export class QttToggleComponent extends RxjsComponent implements OnInit {
    @Select(MetadataState.deviceType) public deviceType$: Observable<ViewportType>;
    @ViewChildren("tabController") public tabController: QueryList<ElementRef<HTMLButtonElement>>;
    @Input() public submitUrl: string = "";
    @Input() public isVertical: boolean = false;
    public sections: QttTabSection[] = [];
    public selectedSectionId: string;
    public standardQttId = STANDARD_QTT_TAB.id;
    public seasonQttId = SEASON_QTT_TAB.id;
    public isFromSeasonQuery = false;

    constructor(
        private router: Router,
        private configService: ConfigService,
        private store: Store,
        private searchService: SearchService,
        private ticketPreviewService: TicketPreviewService,
        private railCardService: RailcardService
    ) {
        super();
    }

    public ngOnInit() {
        this.sections.push(STANDARD_QTT_TAB);
        this.addSubscription(this.configService.hasFeature("isSeasonTicketEnabled").subscribe(value => this.toggleSeasonTicketsSection(value)));
        this.addSubscription(
            this.searchService.searchCriteria$?.subscribe(searchCriteria => {
                if (searchCriteria.params.startDate) {
                    this.isFromSeasonQuery = true;
                }
            })
        );
        this.ticketPreviewService.collapse();
    }

    public selectSection(sectionId): void {
        if (this.selectedSectionId && this.selectedSectionId !== sectionId) {
            this.railCardService.railcardsSelection = [];
        }
        this.selectedSectionId = sectionId;
        this.store.dispatch(new SetSeasonSearch(sectionId === SEASON_QTT_TAB.id));
    }

    public onTabKeyDown(event: KeyboardEvent): void {
        const currentTabIndex = this.sections.findIndex(section => section.id === this.selectedSectionId);

        switch (event.key) {
            case "Right":
            case "ArrowRight":
                if (currentTabIndex < this.sections.length - 1) {
                    this.selectedSectionId = this.sections[currentTabIndex + 1].id;
                }
                break;

            case "Left":
            case "ArrowLeft":
                if (currentTabIndex > 0) {
                    this.selectedSectionId = this.sections[currentTabIndex - 1].id;
                }
                break;

            case "Home":
                this.selectedSectionId = this.sections[0].id;
                break;

            case "End":
                this.selectedSectionId = this.sections[this.sections.length - 1].id;
                break;

            default:
                break;
        }

        const newTabIndex = this.sections.findIndex(section => section.id === this.selectedSectionId);

        if (newTabIndex !== currentTabIndex) {
            const tabControllers = this.tabController.toArray();

            setTimeout(() => {
                tabControllers[newTabIndex]?.nativeElement?.focus();
            }, 0);
        }
    }

    public onTicketsSearchSubmit(params: IQueryParams): void {
        this.addSubscription(
            this.deviceType$.pipe(take(1)).subscribe(viewType => {
                let searchUrl: string = "/search";

                if ([ViewportType.MOBILE_SMALL, ViewportType.MOBILE, ViewportType.TABLET].includes(viewType)) {
                    searchUrl = "/search/m/outbound";
                }

                if (this.submitUrl) {
                    window.location.href = this.submitUrl + searchUrl + this.convertParamsToQueryString(params);
                } else {
                    this.router.navigate([this.submitUrl + searchUrl], {
                        queryParams: params,
                        replaceUrl: false,
                        state: {
                            inAppNav: true
                        }
                    });
                }
            })
        );
    }

    public onSeasonTicketsSearchSubmit(params: IQueryParams): void {
        if (this.submitUrl) {
            window.location.href = this.submitUrl + "/season" + this.convertParamsToQueryString(params);
        } else {
            this.router.navigate([this.submitUrl + "/season"], {
                queryParams: params,
                replaceUrl: false,
                state: {
                    inAppNav: true
                }
            });
        }
    }

    private convertParamsToQueryString(params: IQueryParams) {
        const qs = Object.keys(params)
            .map(key => key + "=" + encodeURIComponent(params[key]))
            .join("&");

        return qs !== "" ? "?" + qs : "";
    }

    private toggleSeasonTicketsSection(isSeasonsEnabled: boolean): void {
        this.sections = this.sections.filter(section => section.id !== SEASON_QTT_TAB.id);

        if (isSeasonsEnabled) {
            this.sections.push(SEASON_QTT_TAB);
            this.selectSection(this.isFromSeasonQuery === true ? SEASON_QTT_TAB.id : STANDARD_QTT_TAB.id);
        } else {
            this.selectSection(STANDARD_QTT_TAB.id);
        }
    }
}
