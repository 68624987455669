import { Pipe, PipeTransform } from "@angular/core";
import { AceLegSolution } from "../../shared/models/ace/ace-leg-solution.model";

@Pipe({
    name: "departureArrivalDescription"
})
export class DepartureArrivalDescriptionPipe implements PipeTransform {
    transform(legSolution: AceLegSolution, format = "HH:mm"): string {
        if (legSolution == null) {
            return null;
        }

        const departure = legSolution.departureDateMoment?.format(format);
        const arrival = legSolution.arrivalDateMoment?.format(format);

        let result = "";

        if (legSolution.delayedDepartureDateTime) {
            result += `departure delayed from ${departure} to ${legSolution.delayedDepartureDateTime.format(format)}, `;
        } else {
            result += `departs at ${departure}, `;
        }

        if (legSolution.delayedArrivalDateTime) {
            result += `arrival delayed from ${arrival} to ${legSolution.delayedArrivalDateTime.format(format)}`;
        } else {
            result += `arrives at ${arrival}`;
        }

        return result;
    }
}
