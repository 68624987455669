import { Injectable } from "@angular/core";
import { RoutesRecognized } from "@angular/router";

@Injectable({
    providedIn: "root"
})
export class TrackService {
    private sourceCode: string = null;
    private isSessionStart?: boolean = null;

    public event(event: RoutesRecognized): void {
        if (event.url.indexOf("/booking/confirmation") === 0) {
            return this.resetSession();
        }

        const provderCode = this.getProviderCode(event);

        if (provderCode && !this.isSessionStart) {
            this.isSessionStart = true;
            this.sourceCode = provderCode;
        }
    }

    public getSource(): string {
        return this.sourceCode;
    }

    private getProviderCode(event: RoutesRecognized): string {
        if (this.isSessionStart) {
            return null;
        }

        return event.url.indexOf("/nre") === 0 ? "NRE" : null;
    }

    private resetSession(): void {
        this.isSessionStart = false;
        this.sourceCode = null;
    }
}
