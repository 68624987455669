import { Component, Input, OnInit } from "@angular/core";
import { ConfigService } from "../../../../services/config.service";
import { ModalContainer } from "../modal-container.component";

@Component({
    selector: "ace-modal-standard-error",
    templateUrl: "standard-error-modal.component.html",
    styleUrls: ["standard-error-modal.component.scss"]
})
export class StandardErrorModalComponent extends ModalContainer implements OnInit {
    @Input() public handleCTAClick: Function;
    @Input() public error: DataModel.StandardError;
    @Input() public cta: string = "Start again";

    public verboseErrors = false;
    public pastBookingHorizon = false;
    public showDetails = false;
    public errorJson: {};

    constructor(public configService: ConfigService) {
        super();
    }

    public ngOnInit() {
        if (this.error instanceof Error) {
            this.errorJson = JSON.stringify({ error: this.error.toString() }, null, "  ");
            // TODO: quick fix for endpoint timeout error
            // the ProgressEvent is coming from the http class instead of the API response so the best thing we can do temporarily is intercept it just before it hits the UI
        } else if (this.error instanceof ProgressEvent && this.error.type === "error") {
            this.errorJson = "This search has timed out. Please try again later.";
        } else {
            this.errorJson = JSON.stringify(this.error, null, "  ");
            if (this.error.errorCode === "400" && this.error.errorMessage === "Departure DateTime provided is outside allowed range.") {
                this.pastBookingHorizon = true;
            } else {
                this.pastBookingHorizon = false;
            }
        }
    }

    public onButtonClicked() {
        this.handleCTAClick(this);
    }
}
