const stnrFeatures = {
    STNR: "stnr",
    STNR_ENABLED: "isStnrEnabled"
};

const fulfilmentFeatures = {
    ETICKET_ENABLED: "isEticketEnabled",
    SEASON_ETICKET_ENABLED: "isSeasonEticketEnabled",
    TVM_MOBILE_DEFAULT_FULFILMENT: "tvmMobileDefaultFulfilment"
};

const searchFeatures = {
    CALLING_POINTS_ENABLED: "areCallingPointsEnabled",
    SEASON_TICKET_ENABLED: "isSeasonTicketEnabled",
    SAVE_RAILSCARDS: "saveRailscards",
    SEATS_AVAILABILITY_ENABLED: "isSeatsAvailabilityEnabled",
    TRAVEL_ASSISTANCE_ENABLED: "isTravelAssistanceOn"
};

const smartcardFeature = {
    EXISTING_CARD: "bookingFlow.deliveryOptions.stnr.existingCard",
    REQUEST_CARD: "bookingFlow.deliveryOptions.stnr.requestCard",
    WILDCARD_CARD: "bookingFlow.deliveryOptions.stnr.wildcardCard"
};

const discountFeatures = {
    NUS: "nus",
    DISCOUNT: "isDiscountCodesOn"
};

export const featureFlag = { ...stnrFeatures, ...fulfilmentFeatures, ...searchFeatures, ...discountFeatures, ...smartcardFeature };
export type FeatureFlagTypes = typeof featureFlag[keyof typeof featureFlag];
