import { Component, Input, OnInit, QueryList, ViewChild, ViewChildren } from "@angular/core";
import { Select, Store } from "@ngxs/store";
import { combineLatest, Observable } from "rxjs";
import { filter } from "rxjs/operators";
import { ConfigService } from "../../../services/config.service";
import { RailcardService } from "../../../services/railcard.service";
import { SilverRailRailcard } from "../../models/api/railcard";
import { Railcard, RailcardComponent } from "../../models/railcard";
import { RxjsComponent } from "../../RxjsComponent";
import { QttState } from "../../state/qtt/qtt.state";
import { RailcardsState } from "../../state/railcards/railcards.state";
import { ISelectOption, SelectComponent } from "../select/select.component";
import { ButtonComponent } from "../button/button.component";
import { AceBookingOrder } from "../../models/ace/ace-booking-order";
import { Direction } from "../../enums/Direction";
import { JourneyState } from "../../state/journey/journey.state";

@Component({
    selector: "ace-railcard-picker",
    templateUrl: "./railcard-picker.component.html",
    styleUrls: ["./railcard-picker.component.scss"]
})
export class RailcardPickerComponent extends RxjsComponent implements OnInit {
    @Input() public adult: number;
    @Input() public children: number;
    @ViewChildren("railcardSelects") public railcardSelects: QueryList<SelectComponent>;
    @ViewChild("addButton") public addButton: ButtonComponent;
    @Select(RailcardsState.railcards) public railcards$: Observable<SilverRailRailcard[]>;
    @Select(QttState.isSeasonSearch) public isSeason$: Observable<boolean>;
    @Select(JourneyState.isJourneyAmendable) public isJourneyAmendable$: Observable<boolean>;
    @Select(JourneyState.currentAmendOrder) public currentAmendingOrder$: Observable<AceBookingOrder>;
    @Select(JourneyState.journeyAmendDirection) public journeyAmendDirection$: Observable<Direction>;

    public railcardComponent: RailcardComponent;
    private localRailcardComponent: RailcardComponent;
    isJourneyAmendableFlag: boolean;
    private currentAmendingOrder: AceBookingOrder;
    private amendingJourneyDirection: string;

    constructor(public config: ConfigService, private railcardService: RailcardService, private store: Store) {
        super();
    }

    public ngOnInit() {
        this.addSubscription(this.isJourneyAmendable$.subscribe(flag => (this.isJourneyAmendableFlag = flag)));

        this.addSubscription(this.currentAmendingOrder$.subscribe(order => (this.currentAmendingOrder = order)));

        this.addSubscription(
            this.journeyAmendDirection$.subscribe(direction => {
                if (direction === Direction.OUT) {
                    this.amendingJourneyDirection = "OUT";
                } else {
                    this.amendingJourneyDirection = "RTN";
                }
            })
        );

        this.addSubscription(
            combineLatest([this.railcards$.pipe(filter(it => it && it.length > 0)), this.isSeason$]).subscribe(([railcards, isSeason]) => {
                const availableRailcards = isSeason ? railcards.filter(rc => rc.seasons) : railcards;
                this.createLocalRailcardComponent(this.convertRailcardsToSelectOptions(availableRailcards));
                this.createRailcardComponent(this.convertRailcardsToSelectOptions(availableRailcards));
            })
        );

        this.addSubscription(
            this.railcards$?.subscribe(railcard => {
                if (railcard && railcard.length === 0) {
                    this.localRailcardComponent ? this.localRailcardComponent.set([]) : null;
                }
            })
        );

        if (this.isJourneyAmendableFlag) {
            if (this.currentAmendingOrder.ticketableFares[0]?.fareQualifiers?.length > 0) {
                this.currentAmendingOrder.ticketableFares[0].fareQualifiers.forEach(fareQualifier => {
                    this.railcards$.subscribe(it =>
                        it.forEach(card => {
                            if (card.code === fareQualifier.program) {
                                this.railcardComponent.add();
                                const options: ISelectOption = {
                                    label: card.name,
                                    selected: true,
                                    value: card.code,
                                    type: card.type
                                };
                                this.railcardComponent.onSelectedOption(this.railcardComponent.railcards[0], options);
                                if (!this.localRailcardComponent) {
                                    this.localRailcardComponent = new RailcardComponent([options]);
                                }
                                const railcards = [new Railcard([options], true, card.type, card.code)];
                                this.railcardService.railcardsSelection = railcards;
                                this.railcardComponent.set(railcards);
                                this.copyRailcardComponent();
                                this.railcardService.isRailcardValid$.next(this.railcardComponent?.isValid);
                            }
                        })
                    );
                    this.railcardService.isRailcardValid$.next(this.railcardComponent?.isValid);
                });
            }
        }
    }

    public onRailcardSelected(railcard: Railcard, option: ISelectOption) {
        this.railcardComponent.onSelectedOption(railcard, option);
        this.railcardService.isRailcardValid$.next(this.railcardComponent.isValid);
    }

    public removeRailcard(index, $event?) {
        this.railcardComponent.remove(index);
        this.railcardService.isRailcardValid$.next(this.railcardComponent.isValid);
        this.railcardService.railcardsSelection = this.railcardComponent.railcards;

        if ($event) {
            $event.stopPropagation();
        }

        setTimeout(() => {
            this.addButton?.focus();
        }, 150);
    }

    public addRailcard($event) {
        this.railcardComponent.add();
        this.railcardService.isRailcardValid$.next(this.railcardComponent.isValid);
        $event.stopPropagation();

        this.focusLastSelectItem();
    }

    public populateSelect(railcard: Railcard) {
        if (railcard.value) {
            return railcard.options.findIndex(option => option.value === railcard.value);
        }
    }

    public updateRailcardComponent(): void {
        if (this.localRailcardComponent) {
            this.railcardComponent.set(this.localRailcardComponent.railcards);
            this.railcardService.railcardsSelection = this.localRailcardComponent.railcards;
        }
        this.railcardService.isRailcardValid$.next(this.railcardComponent.isValid);
    }

    public get showRailcard(): boolean {
        if (!this.railcardComponent) {
            return true;
        }

        const nonSelectedRailcards = this.railcardComponent.railcards.filter(railcard => !railcard.isSelected);
        return !nonSelectedRailcards.length;
    }

    public copyRailcardComponent(): void {
        if (this.localRailcardComponent) {
            this.localRailcardComponent = this.railcardComponent.clone();
            this.railcardService.railcardsSelection = this.localRailcardComponent.railcards;
        }
    }

    public removeNonSelectedRailcards(): void {
        if (this.railcardComponent) {
            this.railcardComponent.railcards.forEach((railcard, index) => {
                if (!railcard.isSelected) {
                    this.removeRailcard(index);
                }
            });
        }
    }

    private focusLastSelectItem(): void {
        setTimeout(() => {
            const selectItemsArray = this.railcardSelects.toArray();
            const lastSelectItem = selectItemsArray[selectItemsArray.length - 1];

            lastSelectItem?.selectElement.nativeElement?.focus();
        }, 150);
    }

    private createLocalRailcardComponent(options: ISelectOption[]): void {
        if (!this.localRailcardComponent) {
            this.localRailcardComponent = new RailcardComponent(options);
        }
    }

    private convertRailcardsToSelectOptions(railcards: SilverRailRailcard[]): ISelectOption[] {
        return railcards.map(selectOption => {
            return {
                label: selectOption.name,
                type: selectOption.type,
                value: selectOption.code,
                selected: false
            };
        });
    }

    private createRailcardComponent(railcardSelectOptions: ISelectOption[]): void {
        this.railcardComponent = new RailcardComponent(railcardSelectOptions);
        const qttParams = this.store.selectSnapshot(state => QttState.params(state.qtt));

        if (qttParams && qttParams.railcards) {
            const railcards: Railcard[] = JSON.parse(qttParams.railcards)
                .map(railcard => railcardSelectOptions.find(option => option.value === railcard.Code))
                .map(railcard => (railcard != null ? new Railcard(railcardSelectOptions, true, railcard.type, railcard.value) : null))
                .filter(railcard => railcard != null);

            if (railcards && railcards.length > 0) {
                this.railcardService.railcardsSelection = railcards;
                this.railcardComponent.set(railcards);
                this.copyRailcardComponent();
            }
        }
    }
}
