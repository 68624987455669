import moment from "moment";

export abstract class DiscountBase {
    public OrderId: string;
    public PriceId: string;
    public PromotionId: number;
    public LegNumber: number;
    public Discount?: number;
    public DiscountValue: number;
    public PriceDiscount: number;
    public Key: string;
    public CreatedDateTime: Date;

    constructor(items: string[], key: string, value: string, createdDateTime: string) {
        this.CreatedDateTime = moment(createdDateTime, "YYYY-MM-DDTHH:mm:ss").toDate();
        this.Key = key;
        this.OrderId = items[1];
        this.PriceId = items[2];
        this.PromotionId = Number(items[3]);
        this.PriceDiscount = Number(items[4]);
        this.Discount = items[5] === "null" ? null : Number(items[5]);
        this.LegNumber = Number(items[7]);
        this.DiscountValue = Number(value);
    }
}

export class DiscountNus extends DiscountBase {
    public Action: string;
    public Hash: string;

    constructor(items: string[], key: string, value: string, createdDateTime: string) {
        super(items, key, value, createdDateTime);
    }
}

export class DiscountPromotion extends DiscountBase {
    public Hash: string;

    constructor(items: string[], key: string, value: string, createdDateTime: string) {
        super(items, key, value, createdDateTime);
    }
}
