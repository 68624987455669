import { Injectable } from "@angular/core";
import { Action, Selector, State, StateContext } from "@ngxs/store";
import { INavigationStateModel } from "./navigation-state.model";
import { ClearNavigation, UpdateNavigation } from "./navigation.actions";

export const initialState = {
    flowStep: "QTT"
};

@State<INavigationStateModel>({
    name: "navigation",
    defaults: initialState
})
@Injectable()
export class NavigationState {
    @Selector([NavigationState])
    static flowStep(state: INavigationStateModel): string {
        return state && state.flowStep ? state.flowStep : null;
    }

    @Selector([NavigationState.flowStep])
    static accountFlowStep(step: string): string {
        const accountTabs = ["LOGIN", "GUEST", "REGISTER", "RESET_PASSWORD"];
        return step && accountTabs.some(tab => tab === step) ? step : null;
    }

    @Action(UpdateNavigation)
    updateNavigation({ patchState }: StateContext<INavigationStateModel>, action: UpdateNavigation) {
        patchState(action.payload);
    }

    @Action(ClearNavigation)
    clearNavigation({ setState }: StateContext<INavigationStateModel>) {
        setState({ ...initialState } as INavigationStateModel);
    }
}
