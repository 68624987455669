import { Injectable } from "@angular/core";
import AWS from "aws-sdk";

@Injectable({
    providedIn: "root"
})
export class AwsSdkService {
    constructor() {
        AWS.config.update({ region: "eu-west-1" });
    }

    public getAWS(): any {
        return AWS;
    }
}
