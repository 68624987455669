import { Injectable } from "@angular/core";
import { EMPTY, Observable } from "rxjs";
import { catchError, map, retry, tap, withLatestFrom } from "rxjs/operators";
import { AceBooking } from "../shared/models/ace/ace-booking";
import { AceBookingRecordInformation } from "../shared/models/ace/ace-booking-record-information.model";
import { AceTicketingOption } from "../shared/models/ace/ace-ticketing-option.model";
import { AceUserType } from "../shared/models/ace/ace-user-type";
import { AceUser } from "../shared/models/ace/ace-user.model";
import { DeliveryPreferencesHelper } from "../shared/utilities/DeliveryPreferencesHelper";
import { AceUserBooking } from "../shared/models/ace/ace-user-booking";
import { AceCoreApiService } from "./ace-core-api.service";
import { ConfigService } from "./config.service";
import { LoggerService } from "./logger.service";

@Injectable({
    providedIn: "root"
})
export class BookingService {
    public static readonly CREATE_PASS_BOOKING: string = "CreateTravelPassBookingRecordRequest";
    public static readonly CREATE_BOOKING: string = "CreateBookingRecordRequest";
    public static readonly CLAIM_DOCUMENT: string = "ClaimValueDocumentRequest";
    public static readonly RETRIEVE_BOOKING: string = "RetrieveBookingRecordRequest";
    public static readonly RETRIEVE_CANCELLATION_SUMMARY: string = "RetrieveCancellationSummaryRequest";
    public static readonly VALIDATE_BOOKING: string = "ValidateBookingRecordInformationRequest";
    public static readonly VALIDATE_EXCHANGE_BOOKING: string = "RetrieveExchangeSummaryRequest";
    public static readonly VALIDATE_PASS_BOOKING: string = "ValidateBookingRecordTravelPassInformationRequest";
    public static readonly UPDATE_BOOKING: string = "UpdateBookingRecordRequest";
    public static readonly BOOKING_UPDATE_ORDER: string = "UpdateBookingRecordOrderAdditionRequest";
    public static readonly CANCEL_BOOKING: string = "CancelBookingRecordRequest";

    constructor(private _aceCoreApiService: AceCoreApiService, private logger: LoggerService, private _config: ConfigService) {
        this.logger.loggerContext = "BookingService";
    }

    public createBooking(payload: object, stub: string = ""): Observable<AceBooking> {
        return this._aceCoreApiService.call(BookingService.CREATE_BOOKING, payload, stub).pipe(
            map(basketApiResponse => basketApiResponse.data.bookingRecord),
            withLatestFrom(this._config.config$),
            map(([bookingRecord, config]) => new AceBooking(bookingRecord, config))
        );
    }

    public createPassBooking(payload: object, stub: string = ""): Observable<AceBooking> {
        return this._aceCoreApiService.call(BookingService.CREATE_PASS_BOOKING, payload, stub).pipe(
            map(basketApiResponse => basketApiResponse.data.bookingRecord),
            withLatestFrom(this._config.config$),
            map(([bookingRecord, config]) => new AceBooking(bookingRecord, config))
        );
    }

    public retrieveBooking(recordLocator: string, stub: string = "", context = null, attempts: number = 1): Observable<AceBooking> {
        return this._aceCoreApiService
            .call(
                BookingService.RETRIEVE_BOOKING,
                {
                    recordLocator,
                    responseSpec: {
                        includeOrderDetails: true,
                        includeOrderCosts: true,
                        includePassengerDetails: true,
                        includeFinancials: true,
                        includePaymentRequirements: true,
                        includeTicketingOptions: true,
                        includeNotes: true,
                        includeFulfillmentInformation: true
                    }
                },
                stub,
                context
            )
            .pipe(
                retry(attempts),
                map(basketApiResponse => basketApiResponse.data.bookingRecord),
                withLatestFrom(this._config.config$),
                map(([bookingRecord, config]) => new AceBooking(bookingRecord, config))
            );
    }

    public retrieveBookingAsUserBooking(recordLocator: string, stub: string = "", context = null, attempts: number = 1): Observable<AceUserBooking> {
        return this._aceCoreApiService
            .call(
                BookingService.RETRIEVE_BOOKING,
                {
                    recordLocator,
                    responseSpec: {
                        includeOrderDetails: true,
                        includeOrderCosts: true,
                        includePassengerDetails: true,
                        includeFinancials: true,
                        includePaymentRequirements: true,
                        includeTicketingOptions: true,
                        includeNotes: true,
                        includeFulfillmentInformation: true
                    }
                },
                stub,
                context
            )
            .pipe(
                retry(attempts),
                map(basketApiResponse => basketApiResponse.data.bookingRecord),
                withLatestFrom(this._config.config$),
                map(([bookingRecord, config]) => new AceUserBooking(bookingRecord, config, context))
            );
    }

    public retrieveCancellationSummaryBooking(payload: object, stub: string = "", context = null, attempts: number = 1): Observable<any> {
        return this._aceCoreApiService.call(BookingService.RETRIEVE_CANCELLATION_SUMMARY, payload, stub, context).pipe(
            retry(attempts),
            map(basketApiResponse => basketApiResponse.data)
        );
    }

    public getUserDeliveryPreference(user: AceUser, ticketingOptions: Array<DataModel.TicketingOption>): DataModel.TicketingOptionCode {
        let userDeliveryPreference = null;
        if (user && user.type === AceUserType.MEMBER && user.hasDeliveryPreference()) {
            const aceTicketingOptions = ticketingOptions.map(option => new AceTicketingOption(option));
            const userPreference: AceTicketingOption = DeliveryPreferencesHelper.getDeliveryOptionByUserPreference(user.getDeliveryPreference(), aceTicketingOptions);
            userDeliveryPreference = userPreference ? userPreference.code : null;
        }
        return userDeliveryPreference;
    }

    public validateBooking(payload: object, user: AceUser, stub: string = ""): Observable<AceBookingRecordInformation> {
        return this._aceCoreApiService.call(BookingService.VALIDATE_BOOKING, payload, stub).pipe(
            map(response => <DataModel.ValidateBookingRecordInformationResponse>response.data),
            map(response => response.bookingRecordInformation),
            withLatestFrom(this._config.config$),
            map(([bookingRecordInformation, config]) => {
                const userDeliveryPreference = bookingRecordInformation
                    ? this.getUserDeliveryPreference(user, bookingRecordInformation.orderInformationSet[0].ticketingOptions)
                    : null;

                return new AceBookingRecordInformation(
                    bookingRecordInformation,
                    config.deliveryMethodsOrder.standard,
                    false,
                    this._config,
                    config.ticketingOptions.minOptionCountToPrioritise,
                    config.ticketingOptions.optionDetails,
                    userDeliveryPreference
                );
            }),
            retry(1)
        );
    }

    public validateExchangeBooking(payload: object, user: AceUser, stub: string = "", context: string): Observable<DataModel.RetrieveExchangeSummaryResponse> {
        return this._aceCoreApiService.call(BookingService.VALIDATE_EXCHANGE_BOOKING, payload, stub, context).pipe(
            map(response => <DataModel.RetrieveExchangeSummaryResponse>response.data),
            map(response => {
                return response;
            }),
            retry(1)
        );
    }

    public validatePassBooking(payload: object, user: AceUser, stub: string = ""): Observable<AceBookingRecordInformation> {
        return this._aceCoreApiService.call(BookingService.VALIDATE_PASS_BOOKING, payload, stub).pipe(
            map(response => <{ bookingRecordInformation: DataModel.BookingRecordInformation }>response.data),
            map(response => response.bookingRecordInformation),
            withLatestFrom(this._config.config$),
            map(([bookingRecordInformation, config]) => {
                const userDeliveryPreference = bookingRecordInformation
                    ? this.getUserDeliveryPreference(user, bookingRecordInformation.orderInformationSet[0].ticketingOptions)
                    : null;

                return new AceBookingRecordInformation(
                    bookingRecordInformation,
                    config.deliveryMethodsOrder.season,
                    true,
                    this._config,
                    config.ticketingOptions.minOptionCountToPrioritise,
                    config.ticketingOptions.optionDetails,
                    userDeliveryPreference
                );
            }),
            retry(1)
        );
    }

    public updateBooking(payload: DataModel.UpdateBookingRecordRequest, stub: string = ""): Observable<AceBooking> {
        return this._aceCoreApiService
            .call(BookingService.UPDATE_BOOKING, payload, stub)
            .pipe(
                map(response => <DataModel.UpdateBookingRecordResponse>response.data),
                map(response => response.bookingRecord),
                withLatestFrom(this._config.config$)
            )
            .pipe(map(([bookingRecord, config]) => new AceBooking(bookingRecord, config)));
    }

    public updateBookingOrder(payload, stub: string = ""): Observable<AceBooking> {
        return this._aceCoreApiService.call(BookingService.BOOKING_UPDATE_ORDER, payload, stub).pipe(
            map(basketApiResponse => basketApiResponse.data.bookingRecord),
            withLatestFrom(this._config.config$),
            map(([bookingRecord, config]) => new AceBooking(bookingRecord, config))
        );
    }

    public cancelBooking(payload: object, stub: string = ""): Observable<AceBooking> {
        return this._aceCoreApiService.call(BookingService.CANCEL_BOOKING, payload, stub).pipe(
            map(basketApiResponse => basketApiResponse.data.bookingRecord),
            withLatestFrom(this._config.config$),
            map(([bookingRecord, config]) => new AceBooking(bookingRecord, config))
        );
    }

    public claimValueDocument(payload: object, stub: string = ""): Observable<any> {
        return this._aceCoreApiService.call(BookingService.CLAIM_DOCUMENT, payload, stub);
    }

    public refundBooking(payload: object, stub: string = "", context = null): Observable<AceBooking> {
        return this._aceCoreApiService.call(BookingService.CANCEL_BOOKING, payload, stub, context).pipe(
            map(basketApiResponse => basketApiResponse.data.bookingRecord),
            withLatestFrom(this._config.config$),
            map(([bookingRecord, config]) => new AceBooking(bookingRecord, config))
        );
    }

    public amendBooking(data: { payload_cancel: object; payload_update: object }, stub: string = ""): Observable<any> {
        return this._aceCoreApiService.call(BookingService.CANCEL_BOOKING, data.payload_cancel, stub).pipe(
            tap(cancellationResponse => {
                this.logger.info(`amendBooking ${cancellationResponse}`);
                return this._aceCoreApiService.call(BookingService.UPDATE_BOOKING, data.payload_update, stub).pipe(
                    tap(updateResponse => this.logger.info(`updateResponse ${updateResponse}`)),
                    catchError(() => {
                        this.logger.error("There has been an error amending the booking - cancellation part failed");
                        return EMPTY;
                    })
                );
            }),
            catchError(() => {
                this.logger.error("There has been an error amending the booking - cancellation part failed");
                return EMPTY;
            })
        );
    }
}
