import { DeliveryModel } from "./delivery-state.model";

export class UpdatePhotocard {
    static readonly type = "[Delivery] Update photocard";

    constructor(public payload: DeliveryModel) {}
}

export class ClearDelivery {
    static readonly type = "[Delivery] Clear delivery fulfillment";
}
