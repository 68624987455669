import { flatten } from "../../../shared/utilities/Utils";
import { AceStationMap } from "./ace-station.model";

interface IOptions {
    name?: string;
    subCode?: string;
}

class StationAddress {
    public addressCity: string;
    public addressLine1: string;
    public addressLine2: string;
    public addressLine3: string;
    public addressPostalCode: string;
    public aliasStationCode: string;
    public phone: string;
    public phoneInternal: string;
    public ticketIssuingSystem: string;
    public ticketOffice: boolean;
    public ticketVendingMachine: boolean;
    public ticketPrintedName: string;
    public trainOperatingCompany: string;

    constructor(station) {
        this.addressCity = station.addressCity;
        this.addressLine1 = station.addressLine1;
        this.addressLine2 = station.addressLine2;
        this.addressLine3 = station.addressLine3;
        this.addressPostalCode = station.addressPostalCode;
        this.aliasStationCode = station.aliasStationCode;
        this.phone = station.phone;
        this.phoneInternal = station.phoneInternal;
        this.ticketIssuingSystem = station.ticketIssuingSystem;
        this.ticketOffice = station.ticketOffice;
        this.ticketVendingMachine = station.ticketVendingMachine;
        this.ticketPrintedName = station.ticketPrintedName;
        this.trainOperatingCompany = station.trainOperatingCompany;
    }
}

export class AceStationDetails {
    public address: StationAddress;
    public stationAttributes: any[];

    private _station: DataModel.StationRichContentStation;

    constructor(data: DataModel.StationRichContentStation[]) {
        this._station = data[0];

        if (this._station) {
            this.address = new StationAddress(this._station);
            this.parseStationAttributes();
        }
    }

    private parseStationAttributes(): void {
        const mappedData = new AceStationMap().getMappedData();
        const listCodes: any = flatten(mappedData.filter((data: any) => data.structureAttribute).map((attr: any) => attr.structureAttribute));
        const itemTypes: IOptions[] = flatten(listCodes.map(item => item.label).filter(item => Boolean(item)));

        this.stationAttributes = this._station.genericAttributes.map(attr => {
            const attrMap = mappedData.find(it => it.gcxCode === attr.gcxCode);

            const structureData = attr.structureData.map(option => {
                const optionValue = option;
                const type = listCodes.find(it => it.code === option.attributeCode);
                const result = {};

                result["name"] = type.name;
                result["code"] = option.attributeCode;
                result["type"] = option.type;

                if (optionValue.type === "list") {
                    result["list"] = optionValue.lists.map(item => {
                        const station = itemTypes.filter(it => it.name).find(it => it.subCode === item.subCode);

                        return {
                            name: station != null ? station.name : null,
                            flag: item.flag,
                            subCode: item.subCode
                        };
                    });
                }

                if (optionValue.type === "schedule") {
                    result["schedules"] = optionValue.schedules;
                }

                if (optionValue.type === "text") {
                    result["texts"] = optionValue.texts.map(item => {
                        const station = itemTypes.filter(it => it.name).find(it => it.subCode === item.subCode);

                        return {
                            name: station != null ? station.name : null,
                            subCode: item.subCode,
                            values: item.values
                        };
                    });
                }

                return result;
            });

            return {
                name: attrMap.name,
                structureData,
                isOpen: false
            };
        });
    }
}
