import { Location } from "@angular/common";
import { Injectable } from "@angular/core";
import { ActivatedRoute, ActivatedRouteSnapshot, NavigationEnd, Router, UrlSegment } from "@angular/router";
import { Store } from "@ngxs/store";
import { BehaviorSubject, combineLatest, Observable, of, ReplaySubject, Subject } from "rxjs";
import { filter, map, mergeMap, take, tap, withLatestFrom } from "rxjs/operators";
import { FlowStep } from "../shared/models/entities/flow-step";
import { AceLegSolution } from "../shared/models/ace/ace-leg-solution.model";
import { AcePassPrice } from "../shared/models/ace/ace-pass-price.model";
import { AcePointToPointPrice } from "../shared/models/ace/ace-point-to-point-price.model";
import { AceSelectionState } from "../shared/models/ace/ace-selection-state";
import { AceSelection } from "../shared/models/ace/ace-selection.model";
import { AceCreateTicketingOptionPayload } from "../shared/models/ace/payloads/ace-create-ticketing-option-payload";
import { SeatPreferenceLegType } from "../shared/models/entities/seat-preference-leg-type";
import { TicketDeliveryCode } from "../shared/models/entities/ticket";
import { ViewportType } from "../shared/models/entities/viewport";
import { ConfigState } from "../shared/state/config/config.state";
import { MetadataState } from "../shared/state/metadata/metadata.state";
import { NavigationState } from "../shared/state/navigation/navigation.state";
import { get } from "../shared/utilities/Utils";
import { URI_CACHE_PARAM_KEY, URI_LEG_PARAM_KEY, URI_ORDER_ID_PARAM_KEY, URI_PRICE_PARAM_KEY, URI_TICKETING_OPTION_PARAM_KEY } from "../shared/constants/query-params";
import { TICKETING_OPTION_CACHE_KEY } from "../shared/constants/cache-keys";
import { DeliveryPostData } from "../shared/models/entities/delivery-post-data";
import { LocalCacheService } from "./local-cache.service";
import { NotifyToastService } from "./notify-toast.service";
import { SearchService } from "./search.service";

@Injectable({
    providedIn: "root"
})
export class SearchSelectionContextService {
    public selectionComplete$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    public selection$: ReplaySubject<AceSelection> = new ReplaySubject(1);
    public prices$: Observable<AcePointToPointPrice[]>;
    public seasonsPrice$: Observable<AcePassPrice>;
    public legSolutions$: Observable<AceLegSolution[]>;
    public outgoingLegSolutionSelection$: Observable<AceLegSolution>;
    public returnLegSolutionSelection$: Observable<AceLegSolution>;
    public outgoingPriceSelection$: Observable<AcePointToPointPrice>;
    public returnPriceSelection$: Observable<AcePointToPointPrice>;
    public seasonsRoute: boolean;
    public deliverySelection$: ReplaySubject<AceCreateTicketingOptionPayload> = new ReplaySubject(null);
    public deliveryPostData$: Subject<DeliveryPostData> = new Subject();
    public travelcardSelection$: BehaviorSubject<any> = new BehaviorSubject(null);
    public seatPreferenceLegType: SeatPreferenceLegType;
    public seatSelection: string[];
    public isPhotocardValid$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    public selection: AceSelection = null;
    public routerNavigationCompleted: ReplaySubject<boolean> = new ReplaySubject(1);

    constructor(
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private search: SearchService,
        private toastService: NotifyToastService,
        private location: Location,
        private cache: LocalCacheService,
        private store: Store
    ) {
        this.selection$
            .pipe(
                tap(selection => (this.selection = selection)),
                map(s => s.isComplete()),
                filter(val => val != null)
            )
            .subscribe(value => this.selectionComplete$.next(value));

        this.prices$ = this.selection$.pipe(map(s => s.prices));
        this.seasonsPrice$ = this.selection$.pipe(map(s => s.getSeasonPrice()));
        this.legSolutions$ = this.selection$.pipe(map(s => s.legSolutions));
        this.outgoingPriceSelection$ = this.selection$.pipe(map(s => s.outgoingPrice));
        this.outgoingLegSolutionSelection$ = this.selection$.pipe(map(s => s.outgoingLegSolution));
        this.returnPriceSelection$ = this.selection$.pipe(map(s => s.returnPrice));
        this.returnLegSolutionSelection$ = this.selection$.pipe(map(s => s.returnLegSolution));

        this.router.events
            .pipe(
                filter(event => event instanceof NavigationEnd),
                tap(event => {
                    this.routerNavigationCompleted.next(true);
                    this.seasonsRoute = (event as NavigationEnd).url.indexOf("season") > -1;
                })
            )
            .subscribe(() => this.onParamChange());

        this.deliverySelection$
            .pipe(withLatestFrom(this.selection$, this.isPhotocardValid$))
            .subscribe(([ticketingOptionPayload, selection, isPhotocardFormValid]) => this.updateTicketingOptions(ticketingOptionPayload, selection, isPhotocardFormValid));
    }

    public updateSelection(selection: AceSelection = this.selection): void {
        this.selection$.next(selection);
    }

    public restoreSearch(
        params,
        ticketingOption: AceCreateTicketingOptionPayload | null = null,
        defaultFulfilment?: { standard: DataModel.TicketingOptionCode; season: DataModel.TicketingOptionCode }
    ) {
        let defaultFulfilmentCode = null;

        if (defaultFulfilment) {
            defaultFulfilmentCode = this.seasonsRoute ? defaultFulfilment.season : defaultFulfilment.standard;
        }

        return this.search.searchResults$?.subscribe(results => {
            this.selection$.next(new AceSelection(results, params[URI_PRICE_PARAM_KEY], params[URI_LEG_PARAM_KEY], ticketingOption, null, null, defaultFulfilmentCode));
        });
    }

    public restoreTicketingOption(): Observable<AceCreateTicketingOptionPayload> {
        return this.cache.getValue(TICKETING_OPTION_CACHE_KEY).pipe(
            map(data => {
                if (data) {
                    return new AceCreateTicketingOptionPayload(
                        data.fulfillmentInformation.ticketOption,
                        {
                            shippingAddress: data.fulfillmentInformation.shippingAddress,
                            shippingName: data.fulfillmentInformation.shippingName
                        },
                        true
                    );
                }
                return null;
            })
        );
    }

    public onSelectionError(error): void {
        this.router.navigate(["/"]);
        this.toastService.create({
            msg: error.message || "Error encountered",
            timeout: 5000,
            theme: "error",
            icon: "error"
        });
    }

    public clearSelection(): void {
        let route: ActivatedRouteSnapshot = this.router.routerState.snapshot.root;
        while (route.firstChild) {
            route = route.firstChild;
        }

        const uri = route.pathFromRoot
            .map(a => a.url[0])
            .reduce((acc, tRoute) => {
                return tRoute ? acc + "/" + tRoute.path : acc;
            }, "");

        const params = { ...route.params };
        delete params[URI_LEG_PARAM_KEY];
        delete params[URI_PRICE_PARAM_KEY];
        delete params[URI_CACHE_PARAM_KEY];
        delete params[URI_TICKETING_OPTION_PARAM_KEY];
        delete params[URI_ORDER_ID_PARAM_KEY];
        this.router.navigate([uri], { queryParams: params, replaceUrl: true });
        // There is a current issue where multiple navigations are done in a short amount of time
        // may not replace the URL correctly so we have to handle it manually
        if (uri !== "") {
            this.location.replaceState(this.router.serializeUrl(this.router.createUrlTree([uri, params])));
        }
    }

    public isAmend(): boolean {
        return !!this.activatedRoute.snapshot.queryParams.id;
    }

    public getParamsForCurrentSelection(): Observable<object> {
        return this.selection$.pipe(
            take(1),
            map(selection => ({
                ...(!selection.travelPass && { [URI_LEG_PARAM_KEY]: selection.getAllLegSolutionIds() }),
                [URI_PRICE_PARAM_KEY]: selection.travelPass ? selection.getSeasonPriceId() : selection.getAllPriceIds(),
                [URI_CACHE_PARAM_KEY]: get(selection, "searchResults.cacheKey"),
                [URI_TICKETING_OPTION_PARAM_KEY]: selection.getTicketingOptionId()
            }))
        );
    }

    public setRouteParams(obj: object) {
        const newParams = { ...this.activatedRoute.snapshot.queryParams };

        Object.keys(obj).forEach(key => {
            const value = obj[key];
            if (value) {
                newParams[key] = value;
            } else {
                delete newParams[key];
            }
        });

        const uri = this.getCurrentUri();

        return this.router.navigate([uri], { queryParams: newParams, replaceUrl: true });
    }

    private onParamChange(): void {
        const params = this.activatedRoute.snapshot.queryParams;
        const metadata = this.store.selectSnapshot(state => MetadataState.metadata(state.metadata));
        let aceConfig = this.store.selectSnapshot(state => ConfigState.aceConfig(state.config));
        const features = aceConfig.features;

        if (features && features.tvmMobileDefaultFulfilment && [ViewportType.MOBILE, ViewportType.MOBILE_SMALL].includes(metadata.device)) {
            const defaultFulfilment = { ...aceConfig.defaultFulfilment, standard: TicketDeliveryCode.TVM };
            aceConfig = { ...aceConfig, defaultFulfilment };
        }

        // If no ticket search is available, but there is a cacheKey param, then we are in a page reload scenario and need to attempt to load from the cache
        if (params[URI_CACHE_PARAM_KEY]) {
            this.search.restoreFromCache(params[URI_CACHE_PARAM_KEY]).subscribe({
                next: null,
                error: this.onSelectionError.bind(this)
            });
        }

        // restore delivery option if locally cached
        if (params[URI_TICKETING_OPTION_PARAM_KEY] && !params[URI_ORDER_ID_PARAM_KEY]) {
            this.restoreTicketingOption().subscribe(restoreTicketingOption => {
                if (restoreTicketingOption) {
                    this.deliverySelection$.next(restoreTicketingOption);
                }
                this.restoreSearch(params, restoreTicketingOption, aceConfig.defaultFulfilment);
            });
        }

        // always restore the last cached search
        if (!params[URI_TICKETING_OPTION_PARAM_KEY] && !params[URI_ORDER_ID_PARAM_KEY]) {
            this.restoreSearch(params, null, aceConfig.defaultFulfilment);
        }
    }

    private updateTicketingOptions(ticketingOptionPayload: AceCreateTicketingOptionPayload, selection: AceSelection, isPhotocardFormValid: boolean): void {
        if (ticketingOptionPayload) {
            const isDeliveryMethodValid: boolean = ticketingOptionPayload.isValid(selection.getPassengerSelection());
            const flowStep = this.store.selectSnapshot(state => NavigationState.flowStep(state.navigation));

            if (isDeliveryMethodValid || selection.state === AceSelectionState.AMEND) {
                selection.ticketingOption = ticketingOptionPayload;
            }

            if (flowStep === FlowStep[FlowStep.SEATS_AND_EXTRAS]) {
                this.selectionComplete$.next(isDeliveryMethodValid);
            }

            if (flowStep === FlowStep[FlowStep.SEASON_DELIVERY]) {
                this.selectionComplete$.next(isDeliveryMethodValid && isPhotocardFormValid);
            }

            combineLatest([
                this.cache.expire(TICKETING_OPTION_CACHE_KEY).pipe(mergeMap(() => this.cache.observable(TICKETING_OPTION_CACHE_KEY, of(ticketingOptionPayload)))),
                this.selection$
            ])
                .pipe(take(1))
                .subscribe(([updateTicketOption]) =>
                    this.setRouteParams({
                        [URI_TICKETING_OPTION_PARAM_KEY]: updateTicketOption.fulfillmentInformation.ticketOption.code
                    })
                );
        }
    }

    private getCurrentUri(): string {
        let route: ActivatedRouteSnapshot = this.router.routerState.snapshot.root;

        while (route.firstChild) {
            route = route.firstChild;
        }

        return route.pathFromRoot
            .map(a => a.url)
            .reduce((acc, tRoute: UrlSegment[]) => {
                if (!tRoute) {
                    return acc;
                }

                return acc + "/" + tRoute.map(r => r.path).join("/");
            }, "");
    }
}
