import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { environment } from "../../environments/environment";
import { IRemoteConfig } from "../shared/models/interfaces/IAceConfig";

@Injectable({
    providedIn: "root"
})
export class ConfigAPIService {
    constructor(private http: HttpClient) {}

    public fetchConfig(): Observable<IRemoteConfig> {
        return this.http.get<DataModel.StandardResponse>(environment.configUrl).pipe(map(res => res.data));
    }
}
