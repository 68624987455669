export class AceBookingPayment {
    public amount: any;
    public balanceAmount: any;
    public formOfPayment: any;
    public postingDate: string;
    public priceIDRef: string;
    public receiptNumber: string;
    public referenceReceiptNumber: string;
    public type: string;

    constructor(data: DataModel.BookingRecordPayment) {
        this.amount = data.amount;
        this.balanceAmount = data.balanceAmount;
        this.formOfPayment = data.formOfPayment;
        this.postingDate = data.postingDate.replace("Z", "");
        this.priceIDRef = data.priceIDRef;
        this.receiptNumber = data.receiptNumber;
        this.referenceReceiptNumber = data.referenceReceiptNumber;
        this.type = data.type;
    }
}
