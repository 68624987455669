<div role="alert" class="container notification-box" [ngClass]="type ? 'notification-box-' + type : 'notification-box-warning'">
    <ng-container *ngIf="icon">
        <svg class="icon" [class.icon-color]="tocId === 'GC'" [class.icon-offset]="icon === 'warning-circle'" aria-hidden="true" viewBox="0 0 24 24">
            <use [attr.xlink:href]="'#' + icon" />
        </svg>
    </ng-container>

    <div class="notification-box-content" data-elid="notif-box-content">
        <ng-content *ngIf="!errorCode"></ng-content>

        <div *ngIf="errorCode === 'FPR0001'">
            <p>
                Your order has been paid. However we encountered a problem. Please contact our team on {{ configService.getPrimaryTel() | async }} for further assistance. Lines
                open from 7am to 10pm every day.
            </p>
            <p class="booking-reference">
                <span>Booking ref:</span>
                {{ error?.bookingId }}
            </p>
        </div>

        <div *ngIf="errorCode === 'FPR0002' || errorCode === 'FPR0003'">
            <p *ngIf="!(user$ | async).userIsGuest()">
                Unfortunately there has been an issue sending your Print and Home ticket to you. The good news is you can download the ticket for order {{ error?.bookingId }} in
                the
                <a href="/account/details/bookings/{{ this.error.bookingId }}">My Account</a>
                section.
            </p>
            <p *ngIf="(user$ | async).userIsGuest()">
                Unfortunately there has been an issue sending your Print at Home ticket to you. Please contact our team on {{ configService.getPrimaryTel() | async }} for further
                assistance. Lines are open from 7am to 10pm every day.
            </p>
        </div>

        <!-- TODO: get promotion details -->
        <div *ngIf="errorCode === 'FPR0004'">
            <p>
                Unfortunately the promotion [PROMOTION DETAILS] has not been correctly applied to your order. Please contact our team on
                {{ configService.getPrimaryTel() | async }} for further assistance. Lines are open from 7am to 10pm every day.
            </p>
        </div>

        <div *ngIf="errorCode === 'FPR0005'">
            <p *ngIf="!(user$ | async).userIsGuest()">
                Unfortunately there has been an issue sending confirmation of your order. The good news is that you can find your booking information for order
                {{ error?.bookingId }} in the
                <a href="/account/details/bookings/{{ this.error.bookingId }}">My Account</a>
                section.
            </p>
            <p *ngIf="(user$ | async).userIsGuest()">
                Unfortunately there has been an issue sending confirmation of your order. Please contact our team on {{ configService.getPrimaryTel() | async }} for further
                assistance and provide your order number {{ error?.bookingId }}. Lines are open from 7am to 10pm every day.
            </p>
        </div>

        <div *ngIf="errorCode === 'NUSINVALID'"><p>Oops! The code you entered is not valid.</p></div>

        <div *ngIf="errorCode === 'NUSEXPIRED'"><p>Oops! This card has expired.</p></div>

        <div *ngIf="errorCode === 'NUSEXCEPTION'"><p>We can't apply your discount at this moment. Please try again later or continue with your booking.</p></div>

        <div *ngIf="errorCode === 'NUSGUESTSAVE'"><p>Please log in now to save the card to your account.</p></div>

        <div *ngIf="errorCode === 'NUSGROUP'">
            <p>
                You're travelling in a group, so it's important you know that your TOTUM discount will not apply. If you wish to purchase a ticket with a TOTUM discount, you will
                need to buy this separately.
            </p>
        </div>

        <div *ngIf="errorCode === 'NUSAUTOEXPIRED'">
            <p>
                Your TOTUM card has expired.
                <a [routerLink]="['/account/details/personal-details']">Please update your details.</a>
            </p>
        </div>

        <div *ngIf="errorCode === 'NUSOVERRIDE'"><p>Your TOTUM discount has been applied for this journey.</p></div>

        <div *ngIf="errorCode === 'NOTFOUNDOPENRETURN'">
            <p>Sorry, Open Returns aren't available for this journey. We've provided alternative ticket types for you, or try searching for another route.</p>
        </div>
        <div *ngIf="errorCode === 'ACCOUNT_NOT_VALIDATED'">
            <p>Sorry, you cannot log in because you have not validated your account. Please check your email for the validation link we sent you when you created your account.</p>
        </div>
    </div>
</div>
