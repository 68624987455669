import { Component, EventEmitter, Input, Output } from "@angular/core";
import { IToast } from "../../../models/interfaces/IToast";

@Component({
    selector: "ace-notify-toast-content",
    templateUrl: "./notify-toast-content.component.html",
    styleUrls: ["./notify-toast-content.component.scss"]
})
export class NotifyToastContentComponent {
    @Input() public toast: IToast;
    @Output() public closeToast = new EventEmitter();

    public close(): void {
        this.closeToast.next(this.toast);
    }
}
