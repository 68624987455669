import { StationPickerMetadata } from "../models/entities/station-picker";

export const ORIGIN_STATION_PICKER: StationPickerMetadata = {
    id: "origin",
    label: "Origin station",
    placeholder: "From city or station",
    ariaLabel: "Pick origin station",
    dataElid: "qtt-from-station"
};

export const DESTINATION_STATION_PICKER: StationPickerMetadata = {
    id: "destination",
    label: "Destination station",
    placeholder: "Destination city or station",
    ariaLabel: "Pick destination station",
    dataElid: "qtt-destination-station"
};

export const VIAAVOID_STATION_PICKER: StationPickerMetadata = {
    id: "viaavoid",
    label: "Via / avoid station",
    placeholder: "Via / avoid city or station",
    ariaLabel: "Pick via avoid station",
    dataElid: "qtt-viaavoid-station"
};
