import { AceModel } from "./abstract/ace-model";

export class AceFee extends AceModel implements DataModel.MoneyType {
    public value: number;
    public currency: string;
    public isRefundable: boolean;

    constructor(data: DataModel.MoneyType) {
        super(data);
    }

    protected _init() {
        this.value = this.data.value;
        this.currency = this.data.currency;
        this.isRefundable = this.data.isRefundable;
    }

    protected _initEmpty() {
        this.value = 0;
        this.currency = "N/A";
        this.isRefundable = false;
    }
}
