<button
    #nativeButton
    class="btn {{ customClass }}"
    [ngClass]="{
        'btn-flag': appearance === 'flag',
        'btn-link': appearance === 'link',
        'btn-link-regular': appearance === 'link-regular',
        'btn-link-standard-light': appearance === 'link-standard-light',
        'btn-editorial': appearance === 'editorial',
        'btn-commercial': appearance === 'commercial',
        'btn-commercial-regular': appearance === 'commercial-regular',
        'btn-primary': size === 'primary',
        'btn-secondary': size === 'secondary',
        'btn-icon': appearance === 'icon',
        inverted: inverted,
        dark: dark
    }"
    [disabled]="disabled"
    [tabindex]="tabindex"
    [attr.form]="form"
    [attr.aria-label]="ariaLabel"
    [type]="buttonType"
    (click)="onButtonClick($event)"
>
    <ng-container *ngIf="showLoader; then spinner; else content"></ng-container>
    <ng-template #spinner><ace-spinner icon="dotted" [confirmLoadingLabel]="confirmLoadingLabel"></ace-spinner></ng-template>
    <ng-template #content>
        <svg *ngIf="icon === 'arrowLeft'" class="icon-arrow icon-size-regular" aria-hidden="true" viewBox="0 0 24 24">
            <title>arrow left</title>
            <use xlink:href="#arrow-left" />
        </svg>

        <ng-content></ng-content>

        <svg *ngIf="icon === 'externalLink'" class="icon-size-regular icon-left-spacing" aria-hidden="true" viewBox="0 0 24 24">
            <title>external link</title>
            <use xlink:href="#external-link" />
        </svg>

        <svg *ngIf="icon === 'arrowRight'" class="icon-arrow icon-size-regular" aria-hidden="true" viewBox="0 0 24 24">
            <title>arrow right</title>
            <use xlink:href="#arrow-right" />
        </svg>
    </ng-template>
</button>
