import { Injectable, Injector } from "@angular/core";
import { ActionType, getActionTypeFromInstance, NgxsNextPluginFn, NgxsPlugin, Store } from "@ngxs/store";
import { UserLoggedOut } from "../state/account/account.actions";
import { ClearNavigation } from "../state/navigation/navigation.actions";
import { ClearOrders } from "../state/order/order.actions";
import { ClearSearchCriteria } from "../state/qtt/qtt.actions";
import { ClearDelivery } from "../state/delivery/delivery.actions";

@Injectable()
export class LogoutMiddleware implements NgxsPlugin {
    constructor(private injector: Injector) {}

    public handle(state: any, action: ActionType, next: NgxsNextPluginFn): NgxsNextPluginFn {
        if (getActionTypeFromInstance(action) === UserLoggedOut.type) {
            const store = this.injector.get<Store>(Store);
            store.dispatch(new ClearOrders());
            store.dispatch(new ClearDelivery());
            store.dispatch(new ClearNavigation());
            store.dispatch(new ClearSearchCriteria());
        }

        return next(state, action);
    }
}
