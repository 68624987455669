import { Component, Injector, OnInit, ViewChild, ViewContainerRef } from "@angular/core";
import { ModalService } from "../../services/modal.service";

/**
 * View component that establishes physical presence of the ModalService within the application
 * It uses it's markup to serve as a container for whatever ModalService wants to display.
 */

@Component({
    selector: "ace-ui-modal",
    templateUrl: "ui-modal.component.html",
    styleUrls: ["ui-modal.component.scss"]
})
export class UIModalComponent implements OnInit {
    @ViewChild("modalplaceholder", { read: ViewContainerRef, static: true })
    public viewContainerRef;
    @ViewChild("modalconstructionyard", { read: ViewContainerRef, static: true })
    public factoryContainerRef;

    constructor(public modalService: ModalService, private injector: Injector) {}

    public ngOnInit() {
        this.modalService.registerViewContainerRef(this.viewContainerRef);
        this.modalService.registerFactoryContainerRef(this.factoryContainerRef);
        this.modalService.registerInjector(this.injector);
    }
}
