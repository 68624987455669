import Crypto from "crypto-browserify";

class CryptoTool {
    private static algorithm: string = "aes-256-ctr";
    private static password: string = "d6F3Efeq";

    public encrypt(text: string): string {
        const cipher: Crypto.Cipher = Crypto.createCipher(CryptoTool.algorithm, CryptoTool.password);
        let crypted = cipher.update(text, "utf8", "hex");
        crypted += cipher.final("hex");
        return crypted;
    }

    public decrypt(text: string): string {
        const decipher = Crypto.createDecipher(CryptoTool.algorithm, CryptoTool.password);
        let dec = decipher.update(text, "hex", "utf8");
        dec += decipher.final("utf8");
        return dec;
    }
}

export { CryptoTool };
