import { CurrencyPipe } from "@angular/common";
import { Pipe, PipeTransform } from "@angular/core";
import { AcePassPrice } from "../models/ace/ace-pass-price.model";

@Pipe({
    name: "priceSeasonLabel"
})
export class PriceSeasonLabelPipe implements PipeTransform {
    constructor(private currencyPipe: CurrencyPipe) {}

    transform(price: AcePassPrice): string {
        if (price == null) {
            return null;
        }

        return `${this.currencyPipe.transform(price.totalPrice.value, "GBP")} ${price.fareDisplayName}`;
    }
}
