export class Promotion {
    private _adultsMax: number;
    private _adultsMin: number;
    private _childrenMax: number;
    private _childrenMin: number;
    private _code: string;
    private _departureDates: DataModel.PromotionRange;
    private _description: string;
    private _destinations: string[];
    private _details: string[];
    private _discount: DataModel.DiscountValue;
    private _id: number;
    private _isActive: boolean;
    private _isGlobal: boolean;
    private _isOpenReturnEnabled: boolean;
    private _isReturnEnabled: boolean;
    private _name: string;
    private _origins: string[];
    private _returnDates: DataModel.PromotionRange;
    private _railcards: string[];
    private _type: DataModel.PromotionType;
    private _validityFrom: Date;
    private _validityTill: Date;
    private _viaStations: string[];

    constructor(data: DataModel.Promotion) {
        this._parse(data);
    }

    public get adultsMax(): number {
        return this._adultsMax;
    }

    public get adultsMin(): number {
        return this._adultsMin;
    }

    public get childrenMax(): number {
        return this._childrenMax;
    }

    public get childrenMin(): number {
        return this._childrenMin;
    }

    public get code(): string {
        return this._code;
    }

    public departureDates(): DataModel.PromotionRange {
        return this._departureDates;
    }

    public get description(): string {
        return this._description;
    }

    public get destinations(): string[] {
        return this._destinations;
    }

    public get details(): string[] {
        return this._details;
    }

    public get discount(): DataModel.DiscountValue {
        return this._discount;
    }

    public get id(): number {
        return this._id;
    }

    public get isActive(): boolean {
        return this._isActive;
    }

    public get isGlobal(): boolean {
        return this._isGlobal;
    }

    public get isOpenReturnEnabled(): boolean {
        return this._isOpenReturnEnabled;
    }

    public get isReturnEnabled(): boolean {
        return this._isReturnEnabled;
    }

    public get name(): string {
        return this._name;
    }

    public get origins(): string[] {
        return this._origins;
    }

    public get railcards(): string[] {
        return this._railcards;
    }

    public returnDates(): DataModel.PromotionRange {
        return this._returnDates;
    }

    public get type(): DataModel.PromotionType {
        return this._type;
    }

    public get validityFrom(): Date {
        return this._validityFrom;
    }

    public get validityTill(): Date {
        return this._validityTill;
    }

    public get viaStations(): string[] {
        return this._viaStations;
    }

    private _parse(data: DataModel.Promotion) {
        this._adultsMax = data.adultsMax;
        this._adultsMin = data.adultsMin;
        this._childrenMax = data.childrenMax;
        this._childrenMin = data.childrenMin;
        this._code = data.code;
        this._departureDates = data.departureDates;
        this._description = data.description;
        this._destinations = data.destinationStations;
        this._details = data.details;
        this._discount = data.discount;
        this._id = data.promotionId;
        this._isActive = data.isActive;
        this._isGlobal = data.isGlobal;
        this._isOpenReturnEnabled = data.isOpenReturn;
        this._isReturnEnabled = data.isReturn;
        this._name = data.name;
        this._origins = data.originStations;
        this._railcards = data.railcards;
        this._returnDates = data.returnDates;
        this._type = data.type;
        this._validityFrom = data.validityFrom;
        this._validityTill = data.validityTill;
        this._viaStations = data.viaStations;
    }
}
