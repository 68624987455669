import { uniqBy } from "../../../shared/utilities/Utils";
import { SeatOptions } from "./ace-seat-options.model";

export class AceSeatPreference {
    public FIRST: SeatOptions;
    public THIRD: SeatOptions;

    constructor(seats: DataModel.AvailableSeatPreferenceType[]) {
        this.FIRST = new SeatOptions(
            uniqBy(
                seats.filter(seat => seat.serviceClass === "FIRST"),
                "value"
            )
        );
        this.THIRD = new SeatOptions(
            uniqBy(
                seats.filter(seat => seat.serviceClass === "THIRD"),
                "value"
            )
        );
    }
}
