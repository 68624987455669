import { EventEmitter, Input, Output, ViewChild, Directive } from "@angular/core";
import { Select, Store } from "@ngxs/store";
import { getDate, getMonth, getYear, isBefore, isToday, parse, set } from "date-fns";
import { Observable } from "rxjs";
import { NotifyToastService } from "../../services/notify-toast.service";
import { RailcardService } from "../../services/railcard.service";
import { CalendarCloseOrigin } from "../components/calendar/calendar.component";
import { PassengerPickerComponent } from "../components/passenger-picker/passenger-picker.component";
import { DATE_TIME_STRING_FORMAT } from "../constants/date";
import { IQttValidationError } from "../models/interfaces/IQttValidationError";
import { IQueryParams } from "../models/interfaces/IQueryParams";
import { Railcard } from "../models/railcard";
import { RxjsComponent } from "../RxjsComponent";
import { ToggleQtt } from "../state/qtt/qtt.actions";
import { QttState } from "../state/qtt/qtt.state";
import { RailcardsState } from "../state/railcards/railcards.state";
import { QttValidator } from "./../validators/QttValidator";

@Directive()
export class QttBase extends RxjsComponent {
    @Input() public isPassengerPickerInit: boolean = false;
    @Input() public isVertical: boolean = false;
    @Input() public isReturn: boolean = false;
    @Input() public isJourneyAmendable: boolean = false;
    @Output() public searchSubmit: EventEmitter<IQueryParams> = new EventEmitter<IQueryParams>();
    @Output() public isOpenChange: EventEmitter<boolean> = new EventEmitter(false);
    @ViewChild(PassengerPickerComponent) public passengerPicker: PassengerPickerComponent;

    @Select(QttState.isOpen) public qttOpen$: Observable<boolean>;
    @Select(QttState.params) public qttParams$: Observable<IQueryParams>;

    public originTravelPoint: string = "";
    public destinationTravelPoint: string = "";
    public viaAvoidSelectedOption: "via" | "avoid" = "via";
    public viaAvoidTravelPoint: string = "";
    public totalCards: number;
    public isStartDateCalendarOn: boolean = false;
    public isEndDateCalendarOn: boolean = false;
    public hasViaAvoid: boolean = false;
    public isMobile: boolean = false;
    public adults: number = 1;
    public children: number = 0;
    public outboundDateTime: Date;
    public returnDateTime: Date;
    protected params: IQueryParams;

    constructor(protected _store: Store, protected _toastService: NotifyToastService, protected _railcardService: RailcardService) {
        super();
    }

    public search(): void {
        return null;
    }

    public onPassengersChangeHandler(passengers: string): void {
        const split: string[] = passengers.split("-");
        this.setPassengers(Number(split[0]), Number(split[1]));
    }

    public passengerPickerInitHandler(state: boolean): void {
        this.isPassengerPickerInit = state;
    }

    public closeStartDateCalendar(closeOrigin: CalendarCloseOrigin): void {
        if (!this.isMobile) {
            this.isStartDateCalendarOn = false;
        }
    }

    public closeEndDateCalendar(closeOrigin: CalendarCloseOrigin): void {
        if (!this.isMobile) {
            this.isEndDateCalendarOn = false;
        }
    }

    public setOutboundDate(date: Date): void {
        this.outboundDateTime = set(this.outboundDateTime, { year: getYear(date), month: getMonth(date), date: getDate(date) });

        if (isBefore(this.returnDateTime, this.outboundDateTime)) {
            this.setReturnDate(date);
        }
    }

    public setReturnDate(date: Date): void {
        this.returnDateTime = set(this.returnDateTime, { year: getYear(date), month: getMonth(date), date: getDate(date) });
    }

    public showStartDateCalendar(): void {
        if (!this.isStartDateCalendarOn) {
            this.isEndDateCalendarOn = false;
            this.isStartDateCalendarOn = true;
        }
    }

    public onStationPickerFocus(): void {
        this._store.dispatch(new ToggleQtt(true));

        if (this.isPassengerPickerInit) {
            this.passengerPicker.closePicker();
        }
    }

    public showEndDateCalendar(): void {
        if (!this.isReturn) {
            this.isReturn = true;
        }

        if (!this.isEndDateCalendarOn) {
            this.isStartDateCalendarOn = false;
            this.isEndDateCalendarOn = true;
        }
    }

    public removeReturnJourney(): void {
        if (this.isReturn) {
            this.isReturn = false;
        }

        if (this.isEndDateCalendarOn) {
            this.isStartDateCalendarOn = false;
            this.isEndDateCalendarOn = false;
        }
    }

    public getRailcards(railcards: Railcard[]): string {
        if (railcards) {
            return JSON.stringify(
                Object.keys(railcards)
                    .map(k => railcards[k])
                    .map(railcard => {
                        return {
                            Code: railcard.value,
                            Number: 1,
                            Type: railcard.type
                        };
                    })
            );
        }

        return this.params && this.params.railcards ? this.params.railcards : "[]";
    }

    public isDateToday(date: Date): boolean {
        return isToday(date);
    }

    public onValidationAlertClose(): void {
        // empty
    }

    protected setPassengers(adult: number, child: number): void {
        this.adults = adult;
        this.children = child;
    }

    protected clearFormValues(): void {
        this.originTravelPoint = "";
        this.destinationTravelPoint = "";
    }

    protected setFormValues(params: IQueryParams): void {
        this.params = params;

        if (params.origin && params.destination && params.origin.length < 2 && params.destination.length < 2) {
            return;
        }

        if (params.origin) {
            this.originTravelPoint = params.origin;
        }

        if (params.destination) {
            this.destinationTravelPoint = params.destination;
        }

        if (params.via || params.avoid) {
            this.hasViaAvoid = true;
            this.viaAvoidTravelPoint = params.via || params.avoid;
            this.viaAvoidSelectedOption = params.avoid ? "avoid" : "via";
        }

        if (params.adults > 1) {
            this.adults = Number(params.adults);
        }

        if (params.children > 0) {
            this.children = Number(params.children);
        }
    }

    protected isQttFormInvalid(params: IQueryParams, isSeason: boolean = false): IQttValidationError {
        if (params) {
            let validators: IQttValidationError[] = [];
            const railcardsSet = this._store.selectSnapshot(state => RailcardsState.railcards(state.railcards));
            const { origin, destination, via, avoid, adults, children, inbound, inboundTime, openReturn, outboundTimeType, outboundTime, railcards } = params;
            const isReturn = Boolean(inbound);
            const isOpenReturn = Boolean(openReturn);
            const outboundDepartOption = outboundTimeType;
            let outboundDateTime = outboundTime ? outboundTime : null;
            let returnDateTime = isReturn && !isOpenReturn && inboundTime ? inboundTime : null;

            if (isSeason) {
                const { startDate, endDate } = params;
                outboundDateTime = startDate;
                returnDateTime = endDate;
            }

            const parsedOutboundDateTime = parse(outboundDateTime, DATE_TIME_STRING_FORMAT, new Date());
            const parsedReturnDateTime = parse(returnDateTime, DATE_TIME_STRING_FORMAT, new Date());

            validators = [
                QttValidator.haveStations(origin, destination),
                QttValidator.areStationsEqual(origin, destination),
                QttValidator.isViaAvoidSameAsStation(origin, destination, via, avoid),
                QttValidator.isOutboundDateTimeValid(parsedOutboundDateTime, outboundDepartOption),
                QttValidator.isDepartureTimeValid(isReturn, isOpenReturn, parsedOutboundDateTime, parsedReturnDateTime),
                QttValidator.arePassengerValid(adults, children),
                QttValidator.railcards(railcardsSet, JSON.parse(railcards), adults)
            ];

            const errors = validators.filter(Boolean);

            return errors[0];
        }
    }

    protected validateSearchAndDisplayErrors(formValues: IQueryParams): boolean {
        const error = this.isQttFormInvalid(formValues);
        error != null ? this.onHandleRequest(error) : this._toastService.clearAll();
        return error == null;
    }

    protected onHandleRequest(error: IQttValidationError): void {
        if (error && error.message && error.theme && error.icon) {
            this._toastService.create({
                msg: error.message,
                timeout: 60000,
                theme: error.theme,
                icon: error.icon,
                onRemove: () => this.onValidationAlertClose()
            });
        }
    }

    protected shaveTimeOfDate(date: Date): Date {
        return set(date, { hours: 0, minutes: 0, seconds: 0, milliseconds: 0 });
    }
}
