import { AbstractControl, ValidatorFn } from "@angular/forms";

export class NumberValidators {
    public static min(min: number): ValidatorFn {
        return (control: AbstractControl): { [key: string]: any } => {
            const value: string = control.value;
            return Number(value) > min ? null : { min: true };
        };
    }

    public static max(max: number): ValidatorFn {
        return (control: AbstractControl): { [key: string]: any } => {
            const value: string = control.value;
            return Number(value) < max ? null : { max: true };
        };
    }
}
