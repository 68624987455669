import { Component, EventEmitter, Input, OnChanges, Output, SimpleChange, SimpleChanges } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup } from "@angular/forms";
import { debounceTime, distinctUntilChanged, map } from "rxjs/operators";
import { RailcardService } from "../../../services/railcard.service";
import { NusValidator } from "../../../shared/validator";
import { AceUser } from "../../models/ace/ace-user.model";
import { IAceError } from "../../models/interfaces/IAceError";
import { INusCardPayload, NuscardComponent, NusStatusEnum } from "../../models/nuscard";
import { RxjsComponent } from "./../../RxjsComponent";

@Component({
    selector: "ace-nuscard-picker",
    templateUrl: "./nuscard-picker.component.html",
    styleUrls: ["./nuscard-picker.component.scss"]
})
export class NuscardPickerComponent extends RxjsComponent implements OnChanges {
    @Input() public nuscardComponent: NuscardComponent;
    @Input() public user: AceUser;
    @Input() public initialCardId: string;
    @Input() public cardErrorCode: string;
    @Input() public cardStatus: NusStatusEnum;
    @Input() public validating: boolean;
    @Output() public cardInputChanged: EventEmitter<INusCardPayload> = new EventEmitter<INusCardPayload>();
    @Output() public cardValidChanged: EventEmitter<boolean> = new EventEmitter<boolean>();
    public form: UntypedFormGroup;
    public nusStatusEnum = NusStatusEnum;

    constructor(private formBuilder: UntypedFormBuilder, public railcardService: RailcardService) {
        super();
        this.initForm();
    }

    public ngOnChanges(changes: SimpleChanges) {
        const initialCardId: SimpleChange = changes.initialCardId;

        if (initialCardId && initialCardId.currentValue !== initialCardId.previousValue && this.form) {
            const nusIdFormControl = this.form.get("nusIdFormControl");

            if (nusIdFormControl.value !== initialCardId.currentValue) {
                this.resetForm();
                nusIdFormControl.setValue(initialCardId.currentValue);
            }
        }
    }

    public removeNus($event): void {
        this.resetForm();
        this.railcardService.currentValidNusCard$.next(null);
        $event.stopPropagation();
    }

    public get cardError(): IAceError {
        return {
            code: this.cardErrorCode
        };
    }

    public set saveNusCard(state) {
        this.railcardService.shouldSaveNusCard = state;
    }

    public get saveNusCard(): boolean {
        return this.railcardService.shouldSaveNusCard;
    }

    private formatNus(id: string) {
        return id.replace(/-|\s/g, "");
    }

    private initForm(): void {
        this.form = this.formBuilder.group({
            nusIdFormControl: [this.initialCardId, NusValidator.wellFormedNus]
        });

        const nusIdFormControl = this.form.get("nusIdFormControl");

        this.addSubscription(
            nusIdFormControl.valueChanges.pipe(debounceTime(100)).subscribe(value => this.cardInputChanged.emit({ card: this.formatNus(value), valid: this.form.valid }))
        );

        this.addSubscription(
            nusIdFormControl.valueChanges
                .pipe(
                    debounceTime(100),
                    map(() => this.form.valid),
                    distinctUntilChanged()
                )
                .subscribe(valid => this.cardValidChanged.emit(valid))
        );
    }

    private resetForm(): void {
        this.form.get("nusIdFormControl").setValue("");
    }
}
