export enum FlowStep {
    QTT,
    LOGIN,
    GUEST,
    REGISTER,
    RESET_PASSWORD,
    SEARCH,
    SEASON,
    MOBILE_SEARCH_OUTBOUND,
    MOBILE_SEARCH_RETURN,
    MOBILE_SEARCH_FARES,
    SEATS_AND_EXTRAS,
    SEASON_DELIVERY,
    SUMMARY,
    CONFIRMATION,
    ACCOUNT,
    BOOKINGS,
    BOOKING_DETAILS,
    PERSONAL_DETAILS,
    TRAVEL_PREFERENCES,
    COMMUNICATION_PREFERENCES
}

export enum BookingFlowStepName {
    SEARCH = "Select tickets",
    DELIVERY_STANDARD = "Delivery and extras",
    DELIVERY_SEASON = "Details and Delivery",
    DELIVERY_PHOTOCARD = "Photocard and Delivery",
    REVIEW = "Review and pay",
    SUMMARY = "Confirmation"
}
