import { AceBookingOrder } from "../ace-booking-order";

export class AceRetrieveExchangeSummaryPayload {
    constructor(
        private _exchangeSearchRequestId: string,
        private _recordLocator: string,
        private _orderLocator: string,
        private _passengerIds: string[],
        private _exchangeSets: DataModel.RetreiveExchangeSet[],
        private _bookingOrder: AceBookingOrder
    ) {}

    public toJSON(): DataModel.RetrieveExchangeSummaryRequest {
        return {
            exchangeSearchRequestId: this._exchangeSearchRequestId,
            recordLocator: this._recordLocator,
            orderLocator: this._orderLocator,
            exchangeSets: this._exchangeSets,
            passengerIds: this._passengerIds,
            fulfillmentInformation: this.getFullfilmentInformation()
        };
    }

    private getFullfilmentInformation() {
        const obj = {
            ticketOption: this._bookingOrder.selectedTicketingOption
        };
        return obj;
    }
}
