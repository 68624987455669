import moment from "moment";
import { CapacityIndicator } from "../api/capacity-indicator";
import { isEmpty } from "../../../shared/utilities/Utils";
import { AcePointToPointPrice } from "./ace-point-to-point-price.model";
import { AceSeatPreference } from "./ace-seat-preference.model";
import { AceTravelSegment } from "./ace-travel-segment.model";
import { Utils } from "./ace-utils";

export class AceLegSolution implements DataModel.LegSolution {
    private static legSolutionMap: { [key: string]: AceLegSolution } = {};
    public isOutgoing: boolean;
    public isReturn: boolean;
    public delayedDepartureDateTime: moment.Moment;
    public departureDateTime: string;
    public departureDateMoment: moment.Moment;
    public departureTimestamp: number;
    public delayedArrivalDateTime: moment.Moment;
    public arrivalDateMoment: moment.Moment;
    public arrivalDateTime: string;
    public arrivalTimestamp: number;
    public legSolutionID: string;
    public hasServiceAlerts: boolean;
    public serviceAlerts: DataModel.ServiceAlert[] = [];
    public hasOvertakenJourney: boolean;
    public hasRestrictions: boolean;
    public hasAvailableSeatPreferences: boolean;
    public hasTocTravelSegment: boolean;
    public hasTflTravelSegment: boolean;
    public hasFirstClass: boolean;
    public hasStandardClass: boolean;
    public changes: number;
    public isDirect: boolean;
    public hasBusTravelSegment: boolean;
    public complexDuration: { label: string; value: number; labelShort: string };
    public travelSegments: AceTravelSegment[];
    public originTravelPoint: DataModel.TravelPoint;
    public destinationTravelPoint: DataModel.TravelPoint;
    public revisions: any[];
    public hasRevisions: boolean = false;
    public hasCheapest: boolean = false;
    public isCancelled: boolean = false;
    public cancellationReason: string;
    public isDelayed: boolean = false;
    public showRouteDetails: boolean = false;
    public cheapestServiceFare: AcePointToPointPrice;
    public availableSeatPreferences: DataModel.AvailableSeatPreferenceType[];
    public isSelected: boolean = false;
    public overtakenJourney: boolean;
    public duration: string;
    public routingRestrictions: DataModel.RoutingRestriction[];
    public passengerInformationRequired: DataModel.PassengerInformation[];
    public availableSeatPreference: DataModel.AvailableSeatPreferenceType[];
    public loyaltyCards: DataModel.LoyaltyCard[];
    public seatOptions: AceSeatPreference;
    public capacityIndicator: CapacityIndicator;

    constructor(data: DataModel.LegSolution = null) {
        if (isEmpty(data)) {
            throw new Error("Cannot instantiate with an empty leg solution");
        }

        this.init(data);
    }

    public static getById(id: string): AceLegSolution {
        return AceLegSolution.legSolutionMap[id];
    }

    private init(data: DataModel.LegSolution) {
        this.legSolutionID = data.legSolutionID;
        this.isOutgoing = this.legSolutionID.indexOf("LS_1") === 0;
        this.isReturn = this.legSolutionID.indexOf("LS_2") === 0;
        this.hasServiceAlerts = data.serviceAlerts.length > 0;

        if (this.hasServiceAlerts) {
            for (const alerts of data.serviceAlerts) {
                this.serviceAlerts.push(alerts);
            }
        }

        this.hasOvertakenJourney = data.overtakenJourney;
        this.complexDuration = Utils.parseSilverRailDuration(data.duration);
        this.hasRestrictions = data.routingRestrictions.length > 0;
        this.changes = data.travelSegments.length - 1;
        this.isDirect = this.changes === 0;

        if (data.travelSegments && data.travelSegments.length !== 0) {
            this.travelSegments = this.initTravelSegement(data.travelSegments, data.revisions);
            this.delayedDepartureDateTime = this.travelSegments[0].delayedDepartureDateTime;
            this.delayedArrivalDateTime = this.travelSegments[this.travelSegments.length - 1].delayedArrivalDateTime;
        } else {
            this.travelSegments = [];
        }

        this.seatOptions = new AceSeatPreference(data.availableSeatPreference);

        this.hasAvailableSeatPreferences = data.availableSeatPreference ? data.availableSeatPreference.length > 0 : false;

        const firstTravelSegment = this.travelSegments[0];
        this.departureDateTime = firstTravelSegment.departureDateTime;
        this.departureDateMoment = firstTravelSegment.departureDateMoment;
        this.departureTimestamp = firstTravelSegment.departureTimestamp;
        this.originTravelPoint = firstTravelSegment.originTravelPoint;

        const lastTravelSegment = this.travelSegments[this.travelSegments.length - 1];
        this.arrivalDateTime = lastTravelSegment.arrivalDateTime;
        this.arrivalDateMoment = lastTravelSegment.arrivalDateMoment;
        this.arrivalTimestamp = lastTravelSegment.arrivalTimestamp;
        this.destinationTravelPoint = lastTravelSegment.destinationTravelPoint;

        this.hasTflTravelSegment = this.travelSegments.some(segment => segment.isLondonUnderground);
        this.hasTocTravelSegment = this.travelSegments.some(segment => segment.isTocTravelSegment);
        this.hasBusTravelSegment = this.travelSegments.some(segment => segment.isBus);

        this.revisions = data.revisions;
        this.hasRevisions = data.revisions.length > 0;

        AceLegSolution.legSolutionMap[this.legSolutionID] = this;

        this.isCancelled = data.facets != null && data.facets.some(facet => facet.keyname === "cancelled");
        if (this.isCancelled) {
            data.revisions.forEach(revision => {
                this.cancellationReason = revision.reason !== null ? revision.reason.content : "";
            });
        }
        this.isDelayed = this.revisions.filter(rev => rev.hasOwnProperty("revisedDepartureDateTime") || rev.hasOwnProperty("revisedArrivalDateTime")).length >= 1;

        this.overtakenJourney = data.overtakenJourney;
        this.duration = data.duration;
        this.routingRestrictions = data.routingRestrictions;
        this.passengerInformationRequired = data.passengerInformationRequired;
        this.availableSeatPreference = data.availableSeatPreference;
        this.loyaltyCards = data.loyaltyCards;

        const trainLoadingTravelSegment = this.travelSegments.find(segment => segment.capacityIndicator);
        if (trainLoadingTravelSegment) {
            this.capacityIndicator = trainLoadingTravelSegment.capacityIndicator;
        }
    }

    private initTravelSegement(travelSegments: DataModel.TravelSegment[], revisions: DataModel.Revision[]): AceTravelSegment[] {
        return travelSegments
            .map(travelSegment => {
                const travelSegmentRevisions = revisions != null ? revisions.filter(revision => revision.travelSegmentIDRef === travelSegment.travelSegmentID) : [];
                return new AceTravelSegment(travelSegment, travelSegmentRevisions);
            })
            .sort((a, b) => a.travelSegmentID.localeCompare(b.travelSegmentID));
    }
}
