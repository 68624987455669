import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from "@angular/core";
import { Router } from "@angular/router";

type ButtonTypes = "button" | "submit" | "reset";
type ButtonAppearanceTypes = "link" | "link-regular" | "link-standard-light" | "editorial" | "commercial" | "commercial-regular" | "icon" | "flag";
type ButtonSizeTypes = "primary" | "secondary" | "flag";
type ButtonIconTypes = "externalLink" | "leftArrow" | "rightArrow";

@Component({
    selector: "ace-button",
    templateUrl: "button.component.html",
    styleUrls: ["button.component.scss"]
})
export class ButtonComponent {
    @Input() public buttonType: ButtonTypes = "button";
    @Input() public appearance: ButtonAppearanceTypes = "link";
    @Input() public size: ButtonSizeTypes;
    @Input() public tabindex = 0;
    @Input() public disabled = false;
    @Input() public showLoader = false;
    @Input() public icon: ButtonIconTypes;
    @Input() public form: string;
    @Input() public customClass: string;
    @Input() public ariaLabel: string;
    @Input() public confirmLoadingLabel: string;
    @Input() public inverted = false;
    @Input() public internalLink: string;
    @Input() public dark = false;
    @Output() public action: EventEmitter<MouseEvent> = new EventEmitter<MouseEvent>();
    @ViewChild("nativeButton") public nativeButtonRef!: ElementRef<HTMLButtonElement>;

    constructor(private router: Router) {}

    public onButtonClick(event: MouseEvent): void {
        if (this.disabled) {
            event.stopPropagation();
            event.preventDefault();
        } else {
            this.action.emit(event);

            if (this.internalLink) {
                this.router.navigate([`${this.internalLink}`]);
            }
        }
    }

    public focus(): void {
        setTimeout(() => {
            this.nativeButtonRef?.nativeElement?.focus();
        }, 0);
    }

    public scrollToElement(): void {
        this.nativeButtonRef?.nativeElement?.scrollIntoView({ behavior: "auto", block: "center", inline: "center" });
    }
}
