import { Component, EventEmitter, Output } from "@angular/core";

@Component({
    selector: "ace-debug-ui",
    templateUrl: "./debug-ui.component.html",
    styleUrls: ["./debug-ui.component.scss"]
})
export class DebugUiComponent {
    @Output() public showElids: EventEmitter<boolean> = new EventEmitter();
    private debugParams: IDebugParams = {
        showElids: false
    };

    public toggleShowElids(val) {
        this.debugParams.showElids = val;
        this.showElids.emit(val);
    }
}

interface IDebugParams {
    showElids: boolean;
}
