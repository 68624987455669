import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from "@angular/core";

@Component({
    selector: "ace-numerical-stepper",
    templateUrl: "numerical-stepper.component.html",
    styleUrls: ["numerical-stepper.component.scss"]
})
export class NumericalStepperComponent {
    @Input() public disabled: boolean;
    @Input() public min: number;
    @Input() public max: number;
    @Input() public value: number = 0;
    @Input() public ariaLabel: string;
    @Input() public currentValueLabel: string;
    @Output() public valueChanged: EventEmitter<number> = new EventEmitter(null);
    @ViewChild("sliderElement") public sliderElement: ElementRef<HTMLDivElement>;

    public stepUp() {
        if (this.disabled || (this.max !== undefined && this.value >= this.max)) {
            return;
        }
        this._setValue(this.value + 1);
    }

    public stepDown() {
        if (this.disabled || (this.min !== undefined && this.value <= this.min)) {
            return;
        }
        this._setValue(this.value - 1);
    }

    private _setValue(value: number) {
        this.value = value;
        this.valueChanged.emit(this.value);
    }
}
