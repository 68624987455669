import { AceTicketingOption } from "../models/ace/ace-ticketing-option.model";
import { TicketDeliveryCode, TicketType } from "../models/entities/ticket";

/**
 * It encapsulates properties that define delivery codes so that we can eliminate string literals across the application
 * It encapsulates utility methods that help to determine the type of delivery option without the need of duplication of
 * logic across templates and components. It also provides a single point where business logic, for suggesting the best
 * delivery option for a user based on their preferences, sits.
 */
export class DeliveryPreferencesHelper {
    public static getDeliveryOptionByUserPreference(userPreference: any, ticketingOptions: AceTicketingOption[]): AceTicketingOption {
        let selectedOption: AceTicketingOption;
        if (this.isOfSupportedType(userPreference.code)) {
            selectedOption = this.getDeliveryOptionByType(ticketingOptions, userPreference.code);
        } else if (this.isOfSupportedCode(userPreference.code)) {
            selectedOption = this.getTicketingOption(ticketingOptions, this.getTypeForCode(userPreference.code), userPreference.code);
        }

        return selectedOption;
    }

    public static isOnDepartureType(option: DataModel.TicketingOption): boolean {
        return option.type === TicketType.ON_DEPARTURE;
    }

    public static isSmartTicket(option: DataModel.TicketingOption): boolean {
        return option.type === TicketType.S_TICKET;
    }

    public static isMailType(option: DataModel.TicketingOption): boolean {
        return option.type === TicketType.MAIL;
    }

    public static isEticket(option: DataModel.TicketingOption): boolean {
        return option.code === TicketDeliveryCode.XVD;
    }
    public static isPrintAtHome(option: DataModel.TicketingOption): boolean {
        return option.code === TicketDeliveryCode.PAH;
    }

    public static isOnDeparture(option: DataModel.TicketingOption): boolean {
        return option.code === TicketDeliveryCode.TVM || option.code === TicketDeliveryCode.TOF;
    }

    public static hasShipping(option: DataModel.TicketingOption): boolean {
        return [this.isMailType(option), this.isPrintAtHome(option)].some(type => type === true);
    }

    public static hasDigitalDeliveryMethod(option: DataModel.TicketingOption): boolean {
        return [DeliveryPreferencesHelper.isSmartTicket(option), DeliveryPreferencesHelper.isEticket(option)].some(Boolean);
    }

    public static getTicketingOptionByCode(ticketingOptions: AceTicketingOption[], code: DataModel.TicketingOptionCode): AceTicketingOption {
        return ticketingOptions.find(option => option.code === code);
    }

    private static isOfSupportedType(entity: TicketType): boolean {
        return Boolean(~[TicketType.E_TICKET, TicketType.PAH_TICKET, TicketType.MAIL, TicketType.ON_DEPARTURE, TicketType.S_TICKET].indexOf(entity));
    }

    private static isOfSupportedCode(entity: DataModel.TicketingOptionCode | any): boolean {
        return Boolean(
            ~[
                TicketDeliveryCode.TVM,
                TicketDeliveryCode.TOF,
                TicketDeliveryCode.TBM,
                TicketDeliveryCode.TBX,
                TicketDeliveryCode.TBO,
                TicketDeliveryCode.XVD,
                TicketDeliveryCode.PAH
            ].indexOf(entity)
        );
    }

    private static getEticketOption(ticketingOptions: AceTicketingOption[], code: DataModel.TicketingOptionCode = TicketDeliveryCode.XVD): AceTicketingOption {
        return (
            this.getTicketingOption(ticketingOptions, code, TicketDeliveryCode.XVD) || this.getTicketingOption(ticketingOptions, this.getTypeForCode(code), TicketDeliveryCode.XVD)
        );
    }

    private static getTVMachineOption(ticketingOptions: AceTicketingOption[], type: DataModel.TicketingOptionCode): AceTicketingOption {
        return this.getTicketingOption(ticketingOptions, type, TicketDeliveryCode.TVM) || this.getTicketingOption(ticketingOptions, type, TicketDeliveryCode.TOF);
    }
    private static getTicketingOption(ticketingOptions: AceTicketingOption[], type: string, code: DataModel.TicketingOptionCode): AceTicketingOption {
        return ticketingOptions.find(options => options.type === type && options.code === code);
    }

    private static getDeliveryOptionByType(ticketingOptions: AceTicketingOption[], code: DataModel.TicketingOptionCode): AceTicketingOption {
        switch (code as TicketType) {
            case TicketType.ON_DEPARTURE:
                return this.getTVMachineOption(ticketingOptions, code);
            case TicketType.E_TICKET:
                return this.getEticketOption(ticketingOptions, code);
            case TicketType.MAIL:
                return this.getTicketingOption(ticketingOptions, code, TicketDeliveryCode.TBM);
            case TicketType.PAH_TICKET:
                return this.getTicketingOption(ticketingOptions, code, TicketDeliveryCode.PAH);
            case TicketType.S_TICKET:
                return this.getTicketingOption(ticketingOptions, code, TicketDeliveryCode.SCT);
            default:
                break;
        }
    }

    private static getTypeForCode(code: DataModel.TicketingOptionCode): string {
        switch (code) {
            case TicketDeliveryCode.TVM:
            case TicketDeliveryCode.TOF:
                return TicketType.ON_DEPARTURE;
            case TicketDeliveryCode.TBM:
            case TicketDeliveryCode.TBX:
            case TicketDeliveryCode.TBO:
                return TicketType.MAIL;
            case TicketDeliveryCode.XVD:
            case TicketDeliveryCode.PAH:
                return TicketType.PAH_TICKET;
            case TicketDeliveryCode.SCT:
                return TicketType.S_TICKET;
            default:
                return "N/A";
        }
    }
}
