import { isEmpty, isObject } from "../../../shared/utilities/Utils";
import { AcePassTicketableFare } from "./ace-pass-ticketable-fare";

export class AcePassPrice {
    public originTravelPoint?: DataModel.TravelPoint;
    public destinationTravelPoint?: DataModel.TravelPoint;
    public priceID?: string;
    public ticketableFares?: AcePassTicketableFare[];
    public holdExpiration?: string;
    public fareDisplayName?: string;
    public fareOrigin?: string;
    public fareDestination?: string;
    public fareStartDate?: string;
    public fareExpirationDate?: string;
    public fareRules?: any;
    public fareRulesDescription?: string;
    public hasStnr?: boolean;
    public cabinClass?: string;
    public fareClass?: string;
    public serviceClass?: string;
    public totalPrice?: any;
    public fareMerchandisingRank?: number;
    public compatiblePriceIDRefs?: string[];
    public availabilityCheckBypassed?: boolean;
    public compoundRowKeys?: string[] = [];

    constructor(data: DataModel.PassPrice) {
        if (!isObject(data) || isEmpty(data)) {
            throw new Error("Constructor argument must be an object");
        }
        this._init(data);
    }

    private _init(data: DataModel.PassPrice) {
        this.fareMerchandisingRank = data.fareMerchandisingRank;
        this.holdExpiration = data.holdExpiration;
        this.compatiblePriceIDRefs = data.compatiblePriceIDRefs;
        this.priceID = data.priceID;
        this.availabilityCheckBypassed = data.availabilityCheckBypassed;

        this.ticketableFares = data.ticketableFares.map(fare => new AcePassTicketableFare(fare));
        this.fareDisplayName = this.ticketableFares[0].fareDisplayName;

        this.originTravelPoint = this.ticketableFares[0].fareOrigin;
        this.destinationTravelPoint = this.ticketableFares[0].fareDestination;

        this.fareOrigin = this.originTravelPoint.code;
        this.fareDestination = this.destinationTravelPoint.code;

        this.fareStartDate = this.ticketableFares[0].fareStartDate;
        this.fareExpirationDate = this.ticketableFares[0].fareExpirationDate;

        this.compoundRowKeys = this.ticketableFares[0].passengerFareInfo.code;
        this.cabinClass = this.ticketableFares[0].passengerFareInfo.cabinClass;
        this.fareRules = this.ticketableFares[0].fareTextRules;

        if (this.fareRules) {
            this.fareRulesDescription = "";
            this.fareRules.forEach(rule => (this.fareRulesDescription += rule.description + "\n"));
        }

        this.hasStnr = this.ticketableFares[0].ticketingOptions.sct;

        this.fareClass = this.ticketableFares[0].passengerFareInfo.fareClass;
        this.serviceClass = this.ticketableFares[0].passengerFareInfo.serviceClass;
        this.totalPrice = this.ticketableFares[0].totalPrice;
    }
}
