import { INavigationStateModel } from "./navigation-state.model";

export class UpdateNavigation {
    static readonly type = "[Navigation] Update Navigation";

    constructor(public payload: INavigationStateModel) {}
}

export class ClearNavigation {
    static readonly type = "[Navigation] Clear navigation";
}
