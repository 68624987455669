import { Inject, Injectable } from "@angular/core";
import { BehaviorSubject, fromEvent } from "rxjs";
import { WINDOW } from "./window.service";

@Injectable({
    providedIn: "root"
})
export class ConnectionService {
    public hasInternetAccess$: BehaviorSubject<boolean>;

    constructor(@Inject(WINDOW) private window: Window) {
        this.hasInternetAccess$ = new BehaviorSubject(this.window.navigator.onLine);
        fromEvent(this.window, "online").subscribe(() => this.hasInternetAccess$.next(true));
        fromEvent(this.window, "offline").subscribe(() => this.hasInternetAccess$.next(false));
    }
}
