import { Component, EventEmitter, Input, Output } from "@angular/core";
import { CalendarMonthCard } from "../../classes/CalendarMonthCard";
import { CalendarDay } from "../../models/entities/calendar";

@Component({
    selector: "ace-calendar-card",
    templateUrl: "./calendar-card.component.html",
    styleUrls: ["./calendar-card.component.scss"]
})
export class CalendarCardComponent {
    @Input() public card: CalendarMonthCard;
    @Input() public disabled: boolean;
    @Input() public selectedDateString: string;
    @Output() public selectDate: EventEmitter<string> = new EventEmitter();
    @Output() public closeCalendar: EventEmitter<undefined> = new EventEmitter();

    public onUpdateDate(dateString: string): void {
        this.selectDate.emit(dateString);
    }

    public onCloseCalendar(): void {
        this.closeCalendar.emit();
    }

    public dayClickHandler(day: CalendarDay): void {
        if (day.isEnabled) {
            this.selectDate.emit(day.dateAsString);
            this.closeCalendar.emit();
        }
    }
}
