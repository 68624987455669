import { take } from "rxjs/operators";
import { ConfigService } from "../../../services/config.service";
import { DeliveryPreferencesHelper } from "../../../shared/utilities/DeliveryPreferencesHelper";
import { difference } from "../../../shared/utilities/Utils";
import { featureFlag } from "../../constants/feature-flags";
import { TicketDeliveryCode } from "../entities/ticket";
import { AceBookingOrderInformation } from "./ace-booking-order-information.model";
import { AceTicketingOption } from "./ace-ticketing-option.model";

export class AceBookingRecordInformation {
    public orderInformationSet: AceBookingOrderInformation[];
    public deliveryMethodsOrder: Array<string>;
    private _isSeason: boolean = false;
    private _isEticketOn: boolean = true;
    private _isSeasonEticketOn: boolean = true;
    private _isSmartCardTicketOn: boolean = false;
    private _minOptionCountToPrioritise: number;

    constructor(
        data: DataModel.BookingRecordInformation | DataModel.NreBookingRecordInformation | DataModel.BookingRecord,
        deliveryMethodsOrder: Array<string>,
        isSeason: boolean,
        private configService: ConfigService,
        minOptionCountToPrioritise: number,
        optionDetails: Array<any>,
        userDeliveryPreference: DataModel.TicketingOptionCode = null
    ) {
        this.configService
            .hasFeature(featureFlag.ETICKET_ENABLED)
            .pipe(take(1))
            .subscribe(value => (this._isEticketOn = value));
        this.configService
            .hasFeature(featureFlag.SEASON_ETICKET_ENABLED)
            .pipe(take(1))
            .subscribe(value => (this._isSeasonEticketOn = value));
        this.configService
            .hasFeature(featureFlag.STNR)
            .pipe(take(1))
            .subscribe(value => (this._isSmartCardTicketOn = value));

        this._minOptionCountToPrioritise = minOptionCountToPrioritise;
        if ((<DataModel.BookingRecord>data).orders) {
            this.orderInformationSet = this._parseOrderInformationSet((<DataModel.BookingRecord>data).orders, deliveryMethodsOrder, optionDetails, userDeliveryPreference);
        } else {
            this.orderInformationSet = this._parseOrderInformationSet(
                (<DataModel.BookingRecordInformation>data).orderInformationSet,
                deliveryMethodsOrder,
                optionDetails,
                userDeliveryPreference
            );
        }
        this._isSeason = isSeason;
    }

    public filterDeliveryOptions(multipleBookingsPresent: boolean): AceTicketingOption[] {
        return this.getAllDeliveryOptions(multipleBookingsPresent).filter(option => !DeliveryPreferencesHelper.isMailType(option));
    }

    public mainDeliveryOptions(multipleBookingsPresent: boolean): AceTicketingOption[] {
        return this.getAllDeliveryOptions(multipleBookingsPresent).slice(0, this._minOptionCountToPrioritise);
    }

    public otherDeliveryOptions(multipleBookingsPresent: boolean): AceTicketingOption[] {
        const ticketingOptions = this.getAllDeliveryOptions(multipleBookingsPresent);
        return difference(ticketingOptions, this.mainDeliveryOptions(multipleBookingsPresent));
    }

    public getAllDeliveryOptions(multipleBookingsPresent: boolean = false): AceTicketingOption[] {
        const fulfillmentPrinciples = {
            [TicketDeliveryCode.TBM]: !this._isSeason,
            [TicketDeliveryCode.XVD]: this._isSeason ? this._isSeasonEticketOn : this._isEticketOn,
            [TicketDeliveryCode.SCT]: this._isSmartCardTicketOn && !multipleBookingsPresent
        };

        return this.orderInformationSet[0].ticketingOptions.filter(option => {
            const principle = option && fulfillmentPrinciples[option.code];
            return typeof principle === "boolean" ? principle : true;
        });
    }

    private _parseOrderInformationSet(
        orderInformationSet: DataModel.OrderInformation[] | DataModel.BookingRecordOrder[],
        deliveryMethodsOrder: Array<string>,
        optionDetails: Array<any>,
        userDeliveryPreference: DataModel.TicketingOptionCode
    ): AceBookingOrderInformation[] {
        return orderInformationSet.map(order => new AceBookingOrderInformation(order, deliveryMethodsOrder, optionDetails, userDeliveryPreference));
    }
}
