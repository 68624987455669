import { Injectable } from "@angular/core";
import { Store } from "@ngxs/store";
import { EMPTY, Observable, of } from "rxjs";
import { mergeMap } from "rxjs/operators";
import { v4 as uuid } from "uuid";
import { environment } from "../../environments/environment";
import { CryptoTool } from "../shared/extensions/crypto/CryptoTool";
import { ViewportType } from "../shared/models/entities/viewport";
import { IExtendedMetadata } from "../shared/state/metadata/metadata-state.model";
import { MetadataState } from "../shared/state/metadata/metadata.state";
import { AwsSdkService } from "./aws-sdk.service";
import { CredentialsSigner } from "./credentials-signer.service";

@Injectable({
    providedIn: "root"
})
class CloudWatchLoggingService {
    private static LOG_STREAM_NAME_KEY: string = "logStreamName";
    private static SEQUENCE_TOKEN_KEY: string = "sequenceToken";

    private logGroupName: string;
    private cloudWatchService: any;
    private AWS: any;
    private events: Event[] = [];

    constructor(private awsSdkService: AwsSdkService, private credentialsSigner: CredentialsSigner, private _store: Store) {
        this.AWS = this.awsSdkService.getAWS();
        this.cloudWatchService = new this.AWS.CloudWatchLogs(JSON.parse(new CryptoTool().decrypt(environment.cloudWatchParams)));
        this.logGroupName = environment.logGroupName;
    }

    public logEvent(event: Event): void {
        if (!event.timestamp) {
            event.timestamp = new Date().getTime();
        }
        this.events.push(event);
    }

    public logEvents(events: Event[]): void {
        events.forEach(event => {
            if (!event.timestamp) {
                event.timestamp = new Date().getTime();
            }
            this.events.push(event);
        });
    }

    public pushToCloudWatch(id?: string): Observable<any> {
        if (!id) {
            id = uuid();
        }

        if (this.events.length) {
            const sequenceToken: string = localStorage.getItem(CloudWatchLoggingService.SEQUENCE_TOKEN_KEY);
            const metadata = this._getMetadata();

            return this.credentialsSigner.getCredsObservable.pipe(
                mergeMap(() => this.getLogStreamName(sequenceToken)),
                mergeMap(logStreamName => {
                    const params = {
                        logEvents: this.events.map(event => {
                            if (metadata) {
                                const payload = JSON.parse(event.message);
                                event.message = JSON.stringify({ ...payload, metadata });
                            }

                            const message = `[${id}] [ace-web] [${event.level}] ${event.message}`;
                            const timestamp = event.timestamp ? event.timestamp : new Date().getTime();

                            return { timestamp, message };
                        }),
                        logGroupName: this.logGroupName,
                        logStreamName,
                        sequenceToken
                    };

                    return new Observable<boolean>(observer =>
                        this.cloudWatchService.putLogEvents(params, (err, data) => {
                            if (err) {
                                return observer.error(err);
                            }

                            this.events = [];
                            localStorage.setItem(CloudWatchLoggingService.SEQUENCE_TOKEN_KEY, data.nextSequenceToken);

                            observer.next(true);
                            observer.complete();
                        })
                    );
                })
            );
        } else {
            return EMPTY;
        }
    }

    private _getMetadata(): IExtendedMetadata {
        let metadata = this._store.selectSnapshot(state => MetadataState.metadata(state.metadata));

        if (metadata != null) {
            const { client, device, lastRequestId, sessionId } = metadata;
            metadata = {};

            if (client) {
                metadata = { ...metadata, client };
            }

            const context = ViewportType[device];
            if (context) {
                metadata = { ...metadata, context };
            }

            if (lastRequestId) {
                metadata = { ...metadata, lastRequestId };
            }

            if (sessionId) {
                metadata = { ...metadata, sessionId };
            }

            return metadata;
        }
    }

    private getLogStreamName(sequenceToken: string): Observable<string> {
        let logStreamName: string = localStorage.getItem(CloudWatchLoggingService.LOG_STREAM_NAME_KEY);

        if (logStreamName && sequenceToken) {
            return of(logStreamName);
        }

        logStreamName = this.generateLogStreamName();

        const params = {
            logGroupName: this.logGroupName,
            logStreamName
        };

        return new Observable<string>(observer =>
            this.cloudWatchService.createLogStream(params, (err, _) => {
                if (err) {
                    return observer.error(err);
                }

                localStorage.setItem(CloudWatchLoggingService.LOG_STREAM_NAME_KEY, logStreamName);
                observer.next(logStreamName);
                observer.complete();
            })
        );
    }

    private generateLogStreamName(): string {
        const now: Date = new Date();
        return `${now.getFullYear()}-${now.getMonth()}-${now.getDay()}-${uuid()}`;
    }
}

class Event {
    public level: EventLevel;
    public message: string;
    public timestamp?: number;
}

type EventLevel = "ERROR" | "WARN" | "INFO";

export { CloudWatchLoggingService, Event, EventLevel };
