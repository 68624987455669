import { AceLegSolution } from "../ace/ace-leg-solution.model";
import { AcePointToPointPrice } from "../ace/ace-point-to-point-price.model";
import { AceSearchResult } from "../ace/ace-search-result.model";

export enum ISearchResultType {
    EMPTY,
    INVALID,
    SINGLE,
    RETURN,
    OPENRETURN
}

export interface ISearchResultDirection {
    originTravelPoint: {
        facet: null;
        code: string;
        type: string;
    };
    destinationTravelPoint: {
        facet: null;
        code: string;
        type: string;
    };
    legSolutions: DataModel.LegSolution[];
}

export enum ITravelPointType {
    UNKNOWN,
    STATION
}

export interface ITotalPrice {
    isEstimated: boolean;
    requiresDiscountCode: boolean;
    value: number;
    currency: string;
}

export enum SearchResultDirectionContext {
    OUT,
    RETURN
}

export interface ISelection {
    outgoingLegSolution: AceLegSolution;
    outgoingPrice: AcePointToPointPrice;
    returnLegSolution: AceLegSolution;
    returnPrice: AcePointToPointPrice;
    searchResult: AceSearchResult;
}

export interface IAmenity {
    travelSegmentIDRef: string;
    type: string;
    value: string;
    cabinClass: string;
    serviceClass: string;
}
