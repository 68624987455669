<div class="container">
    <div class="station-picker">
        <div class="station-picker-content">
            <div class="station-picker-group">
                <ace-station-picker
                    #stationPickerController
                    [inputLabel]="stationPickerMetadata?.label"
                    [defaultLabel]="stationPickerMetadata?.placeholder"
                    [defaultAccessibleLabel]="stationPickerMetadata?.ariaLabel"
                    [stationPickerName]="stationPickerMetadata?.id"
                    [baseId]="stationPickerMetadata?.id"
                    [attr.data-elid]="stationPickerMetadata?.dataElid"
                    isRequired
                    [value]="stationTravelPoint"
                    (valueChange)="onStationChange($event)"
                ></ace-station-picker>

                <button class="close-button" title="Close" aria-label="Close" data-elid="cancel-button" (click)="onCancel()">
                    <svg viewBox="0 0 24 24" aria-hidden="true">
                        <title>close</title>
                        <use xlink:href="#close" />
                    </svg>
                </button>
            </div>
            <div class="buttons">
                <ace-button appearance="editorial" size="primary" (action)="onContinue()" data-elid="continue-button">{{ cta }}</ace-button>
            </div>
        </div>
    </div>
</div>
