<form class="qtt" id="qtt-seasons">
    <div class="qtt-wrapper">
        <ace-qtt-station-pickers
            [baseId]="'season-qtt'"
            [(originTravelPoint)]="originTravelPoint"
            [(destinationTravelPoint)]="destinationTravelPoint"
            [(viaAvoidTravelPoint)]="viaAvoidTravelPoint"
            [(viaAvoidSelectedOption)]="viaAvoidSelectedOption"
            [(hasViaAvoid)]="hasViaAvoid"
            [qttOpen]="qttOpen$ | async"
            (stationPickerFocus)="onStationPickerFocus()"
        ></ace-qtt-station-pickers>

        <div *ngIf="qttOpen$ | async">
            <div class="row">
                <div class="qtt-calendar">
                    <div class="col col-left">
                        <div
                            #startDateCalendar
                            tabindex="0"
                            role="button"
                            class="input calendar-text"
                            (click)="showStartDateCalendar()"
                            data-elid="outbound-calendar"
                            (keyup.enter)="showStartDateCalendar()"
                            (keyup.space)="showStartDateCalendar()"
                            [attr.aria-label]="'Start date ' + (startDate | date: 'EEEE, MMMM d, y')"
                        >
                            Start date
                            <span *ngIf="!isDateToday(startDate)" class="qtt-calendar-date" data-elid="outward-date">{{ startDate | date: "EEEE dd MMMM" }}</span>
                            <span *ngIf="isDateToday(startDate)" class="qtt-calendar-date">Today</span>
                        </div>
                    </div>

                    <div class="col col-right">
                        <div
                            #endDateCalendar
                            class="input calendar-text calendar-text-space"
                            tabindex="0"
                            role="button"
                            [class.input-disabled]="!endDate"
                            data-elid="return-calendar"
                            (click)="showEndDateCalendar()"
                            (keyup.enter)="showEndDateCalendar()"
                            (keyup.space)="showEndDateCalendar()"
                            [attr.aria-label]="endDate != null ? 'End date ' + (endDate | date: 'EEEE, MMMM d, y') : 'Add end date'"
                        >
                            End date
                            <span *ngIf="!isDateToday(endDate)" class="qtt-calendar-date" data-elid="return-date">{{ endDate | date: "EEEE dd MMMM" }}</span>
                            <span *ngIf="isDateToday(endDate)" class="qtt-calendar-date">Today</span>
                        </div>

                        <div class="add-return" *ngIf="!isReturn" (click)="showEndDateCalendar()">
                            <svg viewBox="0 0 24 24" aria-hidden="true">
                                <title>plus</title>
                                <use xlink:href="#plus" />
                            </svg>
                        </div>
                        <div
                            class="cancel-return"
                            *ngIf="isReturn"
                            tabindex="0"
                            role="button"
                            aria-label="Remove end date"
                            data-elid="cancel-return-btn"
                            (click)="removeReturnJourney()"
                            (keyup.enter)="removeReturnJourney()"
                            (keyup.space)="removeReturnJourney()"
                        >
                            <svg viewBox="0 0 24 24" role="img">
                                <title>close</title>
                                <use xlink:href="#close" />
                            </svg>
                        </div>
                    </div>
                </div>
            </div>

            <div class="qtt-calendar-view" *ngIf="isStartDateCalendarOn" data-elid="calendar-modal">
                <div class="dropdown-header dropdown-header-left qtt-dropdown-header"></div>
                <ace-calendar
                    class="qtt-calendar"
                    [date]="startDate"
                    [minDate]="minStartDate"
                    [maxDate]="maxStartDate"
                    [visibleCardsNum]="totalCards"
                    [toggleElement]="startDateCalendar"
                    (closeRequest)="closeStartDateCalendar()"
                    (dateChange)="setOutboundDate($event)"
                ></ace-calendar>
            </div>
            <div class="qtt-calendar-view" *ngIf="isEndDateCalendarOn">
                <div class="dropdown-header dropdown-header-right qtt-dropdown-header"></div>
                <ace-calendar
                    class="qtt-calendar"
                    [date]="endDate"
                    [minDate]="minEndDate"
                    [maxDate]="maxEndDate"
                    [visibleCardsNum]="totalCards"
                    [toggleElement]="endDateCalendar"
                    (closeRequest)="closeEndDateCalendar()"
                    (dateChange)="setReturnDate($event)"
                ></ace-calendar>
            </div>

            <div class="row">
                <div class="col col-12">
                    <div class="qtt-passenger">
                        <ace-passenger-picker
                            [max]="MAX_PASSENGERS"
                            [adults]="adults"
                            [children]="children"
                            [showPassengerPicker]="true"
                            (init)="passengerPickerInitHandler($event)"
                            (passengersChange)="onPassengersChangeHandler($event)"
                        ></ace-passenger-picker>
                    </div>

                    <div class="qtt-submit">
                        <ace-button #submitButton appearance="commercial" size="primary" (action)="search()" id="search-seasons" data-elid="search-qtt"
                            >Buy Season Tickets</ace-button
                        >
                    </div>
                </div>
            </div>

            <div *ngIf="showCopy">
                <ace-message-box
                    [close]="false"
                    [container]="true"
                    [wrap]="false"
                    icon="warning-circle"
                    body="Because you have chosen a departure date between today and {{
                        ASSIST_WEEKLY_TICKETS_DAYS
                    }} days' time your ticket can only be collected with a registered Smartcard or at a ticket machine."
                    theme="dark"
                    data-elid="season-info-msg-box"
                ></ace-message-box>
            </div>
        </div>
    </div>
</form>
