import { addHours, addMinutes, getMinutes, set, setMinutes } from "date-fns";

export class TimeHelper {
    // fit time to dataPicet via rounding to 30 or full hours
    public static normaliseTime(time: Date): Date {
        let result: Date = new Date(time.getTime());
        const currentTimeMinutes: number = getMinutes(result);
        let timeMinutes = 0;

        if (currentTimeMinutes !== 0 && currentTimeMinutes !== 30) {
            if (currentTimeMinutes > 30) {
                if (currentTimeMinutes >= 45) {
                    timeMinutes = 0;
                    result = addHours(result, 1);
                } else {
                    timeMinutes = 30;
                }
            } else {
                if (currentTimeMinutes >= 15) {
                    timeMinutes = 30;
                } else {
                    timeMinutes = 0;
                }
            }
        } else if (currentTimeMinutes === 30) {
            timeMinutes = 30;
        }

        result = setMinutes(result, timeMinutes);

        return result;
    }

    public static generateTimeOptions(): Date[] {
        const options: Date[] = [];
        let timeLoop: Date = set(new Date(), { hours: 0, minutes: 0 });

        for (let i = 0; i < 24 * 2; i++) {
            options.push(new Date(timeLoop.getTime()));
            timeLoop = addMinutes(timeLoop, 30);
        }

        return options;
    }
}
