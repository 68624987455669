import { environment } from "./environment";

export const logs = {
    Debugging: true,
    QTTComponent: true,
    RouteValidatorService: true,
    CredentialsSigner: true,
    HttpService: true,
    RailcardService: true,
    AceSelection: true,
    OrderSummaryComponent: true,
    DesktopMixingDeckComponent: true,
    DesktopSeasonTicketsComponent: true,
    ItsoAuthModalComponent: true,
    SeasonSearchComponent: true,
    BookingService: true,
    LocalStorageService: true,
    SearchService: true,
    TicketPreviewComponent: true,
    SeasonTicketsEnabledGuard: true,
    isLoggerEnabled: environment.loggerEnabled,
    ReduxStore: environment.loggerRedux
};

export const levels = environment.levels;
export const routerTracingEnabled = false;
