<div role="alert" aria-live="polite" class="container" [class.wide]="wide" [class.spacing]="spacing">
    <div *ngIf="isOpen" class="message-box message-box-{{ theme }}" [class.message-box-close-space]="close" [class.wrap]="wrap">
        <div class="message-box-header">
            <svg viewBox="0 0 24 24" class="message-box-icon" *ngIf="icon"><use [attr.xlink:href]="'#' + icon" /></svg>
            <span class="message-box-title" *ngIf="title">
                <svg viewBox="0 0 24 24" class="message-box-icon-title" *ngIf="iconTitle">
                    <use [attr.xlink:href]="'#' + iconTitle" />
                </svg>
                {{ adjustedTitle }}
            </span>
            <button class="message-box-close" *ngIf="close" (click)="openCloseMessage()" (keyup.enter)="openCloseMessage()" (keyup.Space)="openCloseMessage()" tabindex="0">
                <span class="sr-only">Close</span>
                <svg viewBox="0 0 24 24"><use xlink:href="#close" /></svg>
            </button>
        </div>

        <div class="message-box-container">
            <div class="message-box-body" *ngIf="body">
                <div class="row" *ngIf="!twoColumn">
                    <span [innerHTML]="body"></span>
                    <span *ngIf="detailsCopy && !details" class="body-link" (click)="onClick()">{{ detailsCopy }}</span>
                    <a *ngIf="detailsCopy && details" class="body-link" href="{{ details }}" target="_blank">{{ detailsCopy }}</a>
                </div>

                <div class="row" *ngIf="twoColumn">
                    <div class="col col-md-9 col-12">
                        <span [innerHTML]="body"></span>
                    </div>
                    <div class="col col-md-3 col-12 message-box-body-action">
                        <span *ngIf="detailsCopy && !details" class="body-link body-link-font-emphasis" (click)="onClick()">{{ detailsCopy }}</span>
                        <a *ngIf="detailsCopy && details" class="body-link body-link-font-emphasis" href="{{ details }}" target="_blank">{{ detailsCopy }}</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
