import { Component, OnInit } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { AccountService } from "../../../services/account.service";
import { NotifyToastService } from "../../../services/notify-toast.service";
import { ToastThemeTypes, ToastIconTypes } from "../../../shared/models/interfaces/IToast";
import { RxjsComponent } from "../../../shared/RxjsComponent";
import { PasswordValidators } from "../../../shared/validator";
import { BasicValidator } from "../../../shared/validators/BasicValidator";

@Component({
    selector: "ace-change-password",
    templateUrl: "change-password.component.html",
    styleUrls: ["change-password.component.scss"]
})
export class ChangePasswordComponent extends RxjsComponent implements OnInit {
    public form: UntypedFormGroup;
    public token: string = "";
    public inProgress: boolean = false;

    constructor(private fb: UntypedFormBuilder, private accountService: AccountService, private toastService: NotifyToastService, private router: Router, private route: ActivatedRoute) {
        super();
    }

    public ngOnInit(): void {
        this.form = this.fb.group(
            {
                password: ["", [Validators.required, BasicValidator.checkInjection()]],
                confirmPassword: ["", [Validators.required, BasicValidator.checkInjection()]]
            },
            {
                validator: Validators.compose([
                    PasswordValidators.checkPasswordsMatch,
                    PasswordValidators.checkPasswordNumerics,
                    PasswordValidators.checkPasswordCases,
                    PasswordValidators.checkPasswordSymbols,
                    PasswordValidators.checkPasswordLength
                ])
            }
        );
        this.addSubscription(
            this.route.queryParams.subscribe(param => {
                if (!param.verificationToken) {
                    return this.router.navigate(["/"]);
                }
                this.token = param.verificationToken;
            })
        );
    }

    public onChangePassword() {
        this.inProgress = true;
        for (const key of Object.keys(this.form.controls)) {
            this.form.controls[key].markAsTouched();
        }

        if (!this.form.valid) {
            return;
        }

        this.updatePassword();
    }

    public updatePassword() {
        const payload: DataModel.ResetPasswordRequest = {
            passwordResetToken: this.token,
            newPassword: this.form.value.password
        };

        this.addSubscription(
            this.accountService.resetPassword(payload).subscribe(res => {
                this.inProgress = false;
                if (res.data !== "Success") {
                    this.onHandleRequest("There was a problem resetting your password", "error", "error");
                } else {
                    this.onHandleRequest("Your password was reset successfully", "success", "checkmark");
                    this.router.navigate(["/account/login"]);
                }
            })
        );
    }

    public onHandleRequest(message: string, theme: ToastThemeTypes, icon: ToastIconTypes) {
        this.toastService.create({
            msg: message,
            timeout: 5000,
            theme,
            icon
        });
    }

    public get errorMessage(): string | null {
        if (this.form.get("password").hasError("required")) {
            return "Password is required";
        }

        if (this.form.hasError("numerics")) {
            return "Password must have at least one number";
        }

        if (this.form.hasError("cases")) {
            return "Password must have at least one uppercase and one lowercase letter";
        }

        if (this.form.hasError("symbols")) {
            return "Password requires at least one special character e.g. ! @ # $ % ^ & * ( ) &#x7b; &#x7d; [ ]";
        }

        if (this.form.hasError("length")) {
            return "Password must have at least eight characters";
        }

        return null;
    }
}
