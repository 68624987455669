import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { environment } from "../../environments/environment";
import { LocalCacheService } from "./local-cache.service";

@Injectable({
    providedIn: "root"
})
export class VersionCheck {
    private static pending: boolean = false;
    private readonly CACHE_KEY: string = "AppVersion";
    private readonly CACHE_EXPIRE: number = 30;
    private readonly EXCLUDE_PAGE: string[] = ["/booking/summary", "/account/login", "/account/details/personal-details"];
    private _appVersion: string;
    private _isVersionValid: boolean = false;

    private readonly enabledDomains: string[] = [
        // DEV
        "dev-ch.nagarro-acetrains.co.uk",
        "dev-gc.nagarro-acetrains.co.uk",
        // TEST
        "test.orm-pro.co.uk",
        "test-gc.orm-pro.co.uk",
        // UAT
        "web.chiltern-uat.ormlondon.com",
        "web.gc-uat.ormtemp.co.uk",
        // PROD
        "buy.chilternrailways.co.uk",
        "buy.grandcentralrail.com",
        // LOCAL
        "localhost"
    ];

    constructor(private _cache: LocalCacheService, private _http: HttpClient, private _router: Router) {}

    public check() {
        // only check version on the Angular SPA, skip the marketing site standalone QTT
        if (this.enabledDomains.includes(location.hostname)) {
            if (!VersionCheck.pending) {
                VersionCheck.pending = true;

                this._cache.getValue(this.CACHE_KEY).subscribe(localVersion => this.runCheckFlow(localVersion));
            }
        }
    }

    private runCheckFlow(localVersion: string) {
        this.getAppVersion().subscribe(appVersion => {
            if (localVersion === null) {
                VersionCheck.pending = false;
                return this.setNewAppVersion(appVersion).then(() => this.setNewVersionInCache().subscribe());
            }

            return this.setNewAppVersion(appVersion)
                .then(() => this.validVersion(appVersion, localVersion))
                .then(isVersionValid => this.reloadApp(isVersionValid));
        });
    }

    private getAppVersion(): Observable<any> {
        return this._http.get<DataModel.StandardResponse>(environment.appVersionCheckUrl).pipe(map(res => res.data.appVersion));
    }

    private setNewAppVersion(appVersion): Promise<string> {
        this._appVersion = appVersion;
        return Promise.resolve(appVersion);
    }

    private validVersion = (appVersion: any, localVersion: string): Promise<boolean> => {
        this._isVersionValid = !appVersion || appVersion === localVersion;
        return Promise.resolve(this._isVersionValid);
    };

    private setNewVersionInCache(): Observable<string> {
        const expire = new Date();
        expire.setDate(expire.getDate() + this.CACHE_EXPIRE);
        return this._cache.value(this.CACHE_KEY, this._appVersion, expire);
    }

    private reloadApp(isVersionValid) {
        VersionCheck.pending = false;

        if (this.isExcludedPage(this._router.url)) {
            return;
        }

        if (isVersionValid === false) {
            this.setNewVersionInCache().subscribe(() => {
                if (isVersionValid === false) {
                    window.location.replace(window.location.href);
                }
            });
        }
    }

    private isExcludedPage(path: string): boolean {
        let isExcluded = false;

        this.EXCLUDE_PAGE.forEach(rule => {
            if (path.indexOf(rule) === 0) {
                isExcluded = true;
            }
        });

        return isExcluded;
    }
}
