import { Component, Input, ViewChild, AfterViewInit, OnInit } from "@angular/core";
import { filter } from "rxjs/operators";
import { Select } from "@ngxs/store";
import { Observable } from "rxjs/internal/Observable";
import { CredentialsSigner } from "../../../../services/credentials-signer.service";
import { ButtonComponent } from "../../../../shared/components/button/button.component";
import { ModalContainer } from "../modal-container.component";
import { MetadataState } from "../../../../shared/state/metadata/metadata.state";
import { ViewportType } from "../../../../shared/models/entities/viewport";

const CONFIRM = "Confirm";
const CANCEL = "Cancel";

@Component({
    selector: "ace-modal-prompt",
    templateUrl: "prompt.component.html",
    styleUrls: ["prompt.component.scss"]
})
export class PromptComponent extends ModalContainer implements OnInit, AfterViewInit {
    @Select(MetadataState.deviceType) public deviceType$: Observable<ViewportType>;
    @Input() public confirm;
    @Input() public confirmLoadingLabel;
    @Input() public cancel;
    @Input() public isDefaultCardConfirmation: boolean = false;
    @Input() public confirmCTAText: string = CONFIRM;
    @Input() public cancelCTAText: string = CANCEL;
    @ViewChild("cancelButton") private cancelButton: ButtonComponent;
    @ViewChild("confirmButton") private confirmButton: ButtonComponent;
    public title: string = "";
    public description: string = "";
    public extra: string = "";
    public submitted: boolean = false;
    public isDeviceDesktop = false;
    public isDeviceMobile = false;
    public isDeviceTablet = false;

    constructor(private credentialsSigner: CredentialsSigner) {
        super();
        this.addSubscription(this.credentialsSigner.authenticatedSessionExpired.pipe(filter(Boolean)).subscribe(() => this.cancel(this)));
    }
    ngOnInit(): void {
        this.addSubscription(
            this.deviceType$?.subscribe(viewport => {
                if (viewport === ViewportType.DESKTOP) {
                    this.isDeviceDesktop = true;
                    this.isDeviceMobile = false;
                    this.isDeviceTablet = false;
                } else if ([ViewportType.MOBILE_SMALL, ViewportType.MOBILE, ViewportType.TABLET].some(type => type === viewport)) {
                    this.isDeviceMobile = true;
                    this.isDeviceDesktop = false;
                    this.isDeviceTablet = false;
                } else if (viewport === ViewportType.TABLET) {
                    this.isDeviceTablet = true;
                    this.isDeviceMobile = false;
                    this.isDeviceDesktop = false;
                }
            })
        );
    }

    public ngAfterViewInit(): void {
        setTimeout(() => {
            this.cancelButton.focus();
            if (this.isDeviceMobile) {
                this.cancelButton.nativeButtonRef.nativeElement.style.width = "100%";
                this.confirmButton.nativeButtonRef.nativeElement.style.width = "100%";
            }
        }, 0);
    }

    public get hasDefaultValues(): boolean {
        return this.confirmCTAText === CONFIRM && this.cancelCTAText === CANCEL;
    }

    public confirmPrompt(): void {
        this.submitted = true;
        this.confirm(this);
    }

    public cancelPrompt(): void {
        this.cancel(this);
    }
}
