export class Collection<T> {
    private _items: T[];
    private NON_EXISTENT_INDEX: number = -1;
    private _isStrict: boolean = false;

    constructor(items: T[] = []) {
        this._items = items;
    }

    public getIndexOfItem(item: T): number {
        for (let index = 0; index < this._items.length; index++) {
            if (this._items[index] === item) {
                return index;
            }
        }
        return this.NON_EXISTENT_INDEX;
    }

    public getItemByIndex(index: number): T {
        if (index >= 0 && index < this._items.length) {
            return this._items[index];
        }
        return null;
    }

    public isItInCollection(item: T): boolean {
        for (item of this._items) {
            if (item === item) {
                return true;
            }
        }
        return false;
    }

    public add(item: T): boolean {
        if (this._isStrict) {
            if (!this.isItInCollection(item)) {
                this._items.push(item);
                return true;
            }
        } else {
            this._items.push(item);
            return true;
        }
        return false;
    }

    public remove(item: T): boolean {
        const index: number = this.getIndexOfItem(item);
        if (index !== this.NON_EXISTENT_INDEX) {
            this._items.splice(index, 1);
            return true;
        }
        return false;
    }

    public count(): number {
        return this._items.length;
    }

    public toArray(): T[] {
        return this._items.slice();
    }

    public clone(): Collection<T> {
        return new Collection(this.toArray());
    }

    public set isStrict(value: boolean) {
        this._isStrict = value;
    }
}
