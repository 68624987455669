<div class="passenger-picker">
    <div
        #pickerElement
        role="button"
        class="passenger-picker-toggle"
        [class.active]="isOpen$ | async"
        [attr.tabindex]="amend ? -1 : 0"
        aria-haspopup="true"
        [attr.aria-expanded]="isOpen$ | async"
        [attr.aria-label]="
            (adults | pluralise: 'adult') +
            (children != 0 ? ', ' + (children | pluralise: 'child') : '') +
            ((railcardAmount$ | async) != 0 ? ', ' + (railcardAmount$ | async | pluralise: 'Railcard') : ', railcards')
        "
        (click)="openPicker('passenger')"
        (keydown.enter)="openPicker('passenger')"
        (keydown.space)="openPicker('passenger')"
    >
        <div class="input facade input-passenger" data-elid="qtt-passengers">
            <span class="passenger-label">
                <ng-container *ngIf="adults > 0 || children === 0">{{ adults | pluralise: "adult" }}</ng-container>
                <ng-container *ngIf="(adults > 0 && children > 0) || (adults === 0 && children === 0)">, </ng-container>
                <ng-container *ngIf="children > 0 || adults === 0">{{ children | pluralise: "child" }}</ng-container>
            </span>

            <div class="input-icon">
                <svg viewBox="0 0 24 24" aria-hidden="true">
                    <title>plus</title>
                    <use xlink:href="#plus" />
                </svg>
            </div>
        </div>
        <div class="input facade input-railcard" data-elid="qtt-railcards">
            <div class="railcard-label" [class.railcard-label-small]="!showPassengerPicker">
                <ng-container *ngIf="(nus$ | async) && !isMobile; then nusFeatureTemplate; else noNusFeatureTemplate"></ng-container>

                <ng-template #nusFeatureTemplate>
                    <ng-container *ngIf="showPassengerPicker; then nusAndRailcardsEnabled; else nusAndRailcardsDisabled"></ng-container>

                    <ng-template #nusAndRailcardsEnabled>
                        <span *ngIf="!(railcardAmount$ | async)">Railcard(s)/TOTUM</span>
                        <span *ngIf="railcardAmount$ | async">{{ railcardAmount$ | async | pluralise: "Railcard" }}/TOTUM</span>
                    </ng-template>

                    <ng-template #nusAndRailcardsDisabled><span>Railcards and TOTUM not applicable</span></ng-template>
                </ng-template>

                <ng-template #noNusFeatureTemplate>
                    <ng-container *ngIf="showPassengerPicker; then railcardsEnabled; else railcardsDisabled"></ng-container>

                    <ng-template #railcardsEnabled>
                        <span *ngIf="!(railcardAmount$ | async)">Railcard(s)</span>
                        <span *ngIf="railcardAmount$ | async">{{ railcardAmount$ | async | pluralise: "Railcard" }}</span>
                    </ng-template>

                    <ng-template #railcardsDisabled><span>Railcards N/A</span></ng-template>
                </ng-template>

                <div class="input-icon">
                    <svg viewBox="0 0 24 24" aria-hidden="true">
                        <title>plus</title>
                        <use xlink:href="#plus" />
                    </svg>
                </div>
            </div>
        </div>

        <div class="input facade input-promotion" data-elid="qtt-promotion" (click)="openPicker('promotion')" *ngIf="(nus$ | async) && isMobile">
            <div class="promotion-label">TOTUM</div>

            <div class="input-icon">
                <svg viewBox="0 0 24 24" aria-hidden="true">
                    <title>plus</title>
                    <use xlink:href="#plus" />
                </svg>
            </div>
        </div>
    </div>

    <div [class.hide]="!(isOpen$ | async)">
        <div class="dropdown-header dropdown-header-reverse"></div>
        <div class="input-body" [class.short]="!showPassengerPicker" cdkTrapFocus>
            <div [class.hide]="!(displayPassengerPicker$ | async)">
                <ace-numerical-stepper
                    *ngIf="!disabled"
                    #adultsStepper
                    [value]="adultsCount"
                    [min]="0"
                    [max]="maxAdults"
                    [disabled]="disabled"
                    ariaLabel="Passengers over 16"
                    [currentValueLabel]="adultsCount === 1 ? '1 adult' : adultsCount + ' adults'"
                    (valueChanged)="onAdultValueChanged($event)"
                    data-elid="qtt-adult-count"
                >
                    <numerical-stepper-label>
                        <div class="adult">
                            <svg viewBox="0 0 24 24" aria-hidden="true">
                                <title>user</title>
                                <use xlink:href="#user" />
                            </svg>
                            <span>{{ adultsCount | pluralise: "Adult" }}</span>
                            <span class="ages">(16+)</span>
                        </div>
                    </numerical-stepper-label>
                </ace-numerical-stepper>

                <ace-numerical-stepper
                    *ngIf="!disabled"
                    [value]="childrenCount"
                    [min]="0"
                    [disabled]="disabled"
                    [max]="maxChildren"
                    ariaLabel="Passengers over 5 and under 15 (under 5s go free)"
                    [currentValueLabel]="childrenCount === 1 ? '1 child' : childrenCount + ' children'"
                    (valueChanged)="onChildrenValueChanged($event)"
                    data-elid="qtt-children-count"
                >
                    <numerical-stepper-label>
                        <div class="child">
                            <svg viewBox="0 0 24 24" aria-hidden="true">
                                <title>user</title>
                                <use xlink:href="#user" />
                            </svg>
                            <span>{{ childrenCount | pluralise: "Child" }} (5 - 15)</span>
                            <span class="ages">Under 5s go free!</span>
                        </div>
                    </numerical-stepper-label>
                </ace-numerical-stepper>

                <div class="disabled" *ngIf="disabled">
                    <span>
                        You've got a journey in your basket for
                        <ng-container>{{ adultsCount + childrenCount | pluralise: "person" }}</ng-container>
                        .
                    </span>
                    <br />
                    <span>To book another journey for a different number of passengers, you'll need to complete the existing booking first.</span>
                </div>
            </div>

            <ace-railcard-picker
                *ngIf="showPassengerPicker"
                [children]="childrenCount"
                [adult]="adultsCount"
                [class.hide]="!(displayPassengerPicker$ | async)"
            ></ace-railcard-picker>

            <ace-nuscard-picker
                *ngIf="showPassengerPicker && (isOpen$ | async) && (nus$ | async)"
                [nuscardComponent]="localNuscardComponent"
                [user]="user$ | async"
                [initialCardId]="nusCardNumber$ | async"
                [cardErrorCode]="nusCardErrorCode$ | async"
                [cardStatus]="nusCardStatus$ | async"
                [validating]="railcardService.nusValidating$ | async"
                [class.hide]="!(displayPromotionPicker$ | async)"
                (cardInputChanged)="onNusCardInput($event)"
                (cardValidChanged)="onNusCardValidChange($event)"
            ></ace-nuscard-picker>

            <div class="input-body-controls">
                <ace-button appearance="editorial" size="secondary" (action)="onCancel()">Cancel</ace-button>
                <ace-button
                    appearance="commercial"
                    size="primary"
                    *ngIf="showPassengerPicker"
                    [disabled]="!(pickerValid$ | async)"
                    (action)="onDone()"
                    id="submit-passengers-with-railcard"
                >
                    Done
                </ace-button>
                <ace-button appearance="commercial" size="primary" *ngIf="!showPassengerPicker" (action)="onDone()" id="submit-passengers-no-railcard">Done</ace-button>
            </div>
        </div>
    </div>
</div>
