import { A11yModule } from "@angular/cdk/a11y";
import { CommonModule } from "@angular/common";
import { CUSTOM_ELEMENTS_SCHEMA, ModuleWithProviders, NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { SharedModule } from "../shared/shared.module";
import { GenericModalComponent } from "./components/modals/generic/generic-modal.component";
import { HelpModalComponent } from "./components/modals/help-modal/help-modal.component";
import { ItsoAuthModalComponent } from "./components/modals/itso-auth-modal/itso-auth-modal.component";
import { MixingDeckRowDescriptionModalComponent } from "./components/modals/mixing-deck-row-details/mixing-deck-row-details-modal";
import { MixingDeckModalComponent } from "./components/modals/mixing-deck/mixing-deck-model.component";
import { PromoModalComponent } from "./components/modals/promo-modal/promo-modal.component";
import { PromptComponent } from "./components/modals/prompt/prompt.component";
import { QTTModalComponent } from "./components/modals/qtt-modal/qtt-modal.component";
import { StationPickerMobileModalComponent } from "./components/modals/station-picker-mobile-modal.component/station-picker-mobile-modal.component";
import { QTTSTModalComponent } from "./components/modals/qtt-st-modal/qtt-st-modal.component";
import { StandardErrorModalComponent } from "./components/modals/standard-error/standard-error-modal.component";
import { PopupWrapperComponent } from "./components/popup-wrapper/ace-popup-wrapper";
import { UIModalComponent } from "./components/ui-modal/ui-modal.component";
import { ModalService } from "./services/modal.service";

@NgModule({
    declarations: [
        GenericModalComponent,
        PopupWrapperComponent,
        HelpModalComponent,
        MixingDeckModalComponent,
        MixingDeckRowDescriptionModalComponent,
        PromoModalComponent,
        PromptComponent,
        QTTModalComponent,
        QTTSTModalComponent,
        StandardErrorModalComponent,
        UIModalComponent,
        ItsoAuthModalComponent,
        StationPickerMobileModalComponent
    ],
    exports: [
        GenericModalComponent,
        PopupWrapperComponent,
        HelpModalComponent,
        MixingDeckModalComponent,
        MixingDeckRowDescriptionModalComponent,
        PromoModalComponent,
        PromptComponent,
        QTTModalComponent,
        QTTSTModalComponent,
        StandardErrorModalComponent,
        UIModalComponent,
        ItsoAuthModalComponent,
        StationPickerMobileModalComponent
    ],
    imports: [CommonModule, SharedModule, FormsModule, ReactiveFormsModule, A11yModule],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    providers: []
})
export class ModalModule {
    // This ensures that only a single instance of the provided services exists at the root level
    public static forRoot(): ModuleWithProviders<ModalModule> {
        return {
            ngModule: ModalModule,
            providers: [ModalService]
        };
    }
}
