import { Component, ElementRef, EventEmitter, Input, OnInit, Output, Renderer2 } from "@angular/core";

@Component({
    selector: "ace-message-box",
    templateUrl: "./message-box.component.html",
    styleUrls: ["./message-box.component.scss"]
})
export class MessageBoxComponent implements OnInit {
    @Input() public body: string = "";
    @Input() public details: string = "";
    @Input() public detailsCopy?: string = "";
    @Input() public close?: boolean;
    @Input() public icon?: string = "";
    @Input() public iconTitle?: string = "";
    @Input() public spacing?: boolean = false;
    @Input() public wide?: boolean = false;
    @Input() public theme: string = "";
    @Input() public title?: string = "";
    @Input() public twoColumn?: boolean;
    @Input() public container?: boolean;
    @Input() public wrap?: boolean = true;
    @Output() public isClicked = new EventEmitter<boolean>();

    public isOpen: boolean = true;

    private nativeElement: Node;

    constructor(private _renderer: Renderer2, private el: ElementRef) {}

    public ngOnInit() {
        if (!this.container) {
            this.nativeElement = this.el.nativeElement.children[0];
            this._renderer.removeClass(this.nativeElement, "container");
        }
    }

    public get adjustedTitle(): string {
        return this.title.includes("ONLINE25") ? "Totum 25% off" : this.title;
    }

    public onClick() {
        this.isClicked.emit(true);
    }

    public openCloseMessage(): boolean {
        return (this.isOpen = !this.isOpen);
    }

    public get isPromo(): boolean {
        return this.theme === "promo";
    }
}
