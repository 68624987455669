import moment from "moment";
import { SilverRailRailcard } from "./api/railcard";
import { IQueryParams } from "./interfaces/IQueryParams";

export class SearchAnalyticsModel {
    public sor: string = null;
    public from: string = null;
    public to: string = null;
    public route: string = null;
    public odd: string = null;
    public odt: string = null;
    public outType: string = null;
    public rdd: string = null;
    public rdt: string = null;
    public returnType: string = null;
    public passengers: string = null;
    public adults: string = null;
    public children: string = null;
    public travelAssistance: string = null;

    constructor(query: IQueryParams) {
        if (query != null) {
            this.sor = query.inbound === "true" ? "Return" : "Single";

            if (query.openReturn === "true") {
                this.sor = "Open Return";
            }

            this.from = query.origin;
            this.to = query.destination;
            this.outType = query.outboundTimeType === "DEPARTURE" ? "OBDA" : "OBAB";
            this.odd = moment(query.outboundTime).format("DD-MM-YYYY");
            this.odt = moment(query.outboundTime).format("H:mm");

            if (query.inbound && query.inboundTime) {
                this.rdd = moment(query.inboundTime).format("DD-MM-YYYY");
                this.rdt = moment(query.inboundTime).format("H:mm");
                this.returnType = query.inboundTimeType === "DEPARTURE" ? "RTDA" : "RTAB";
            }

            if (query.via || query.avoid) {
                this.route = query.via ? "VIA:" + query.via : "AVOID:" + query.avoid;
            }

            this.passengers = String(Number(query.adults) + Number(query.children));

            this.adults = String(query.adults);
            this.children = String(query.children);

            if (query.railcards) {
                const railcards = JSON.parse(query.railcards);

                for (const key of Object.keys(railcards)) {
                    this[railcards[key].Code] += 1;
                }
            }
        }
    }

    public getParamData(railcards: SilverRailRailcard[]): string {
        const keyOrder = this._getOrder(railcards.map(railcard => railcard.code));
        let stringParams = "";

        for (const key of Object.keys(keyOrder)) {
            const objKey = keyOrder[key];
            let value = this[objKey] !== undefined ? this[objKey] : null;

            if (typeof value === "number") {
                if (value === 0) {
                    value = null;
                } else {
                    value = objKey + ":" + this[objKey];
                }
            }

            stringParams += (Number(key) === 0 ? "" : "|") + value;
        }

        return stringParams;
    }

    private _getOrder(railcardCodes: string[]): string[] {
        return ["sor", "from", "to", "route", "odd", "odt", "outType", "rdd", "rdt", "returnType", "passengers", "adults", "children", "travelAssistance"].concat(railcardCodes);
    }
}
