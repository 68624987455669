import { Component } from "@angular/core";
import { environment } from "../../../../environments/environment";
const packageJson = require("../../../../../package.json");

@Component({
    selector: "ace-footer",
    templateUrl: "footer.component.html",
    styleUrls: ["footer.component.scss"]
})
export class FooterComponent {
    public ver = !environment.env.includes("PROD") ? packageJson.version : null;
}
