import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from "@angular/router";
import { Observable } from "rxjs";
import { map, take } from "rxjs/operators";
import { ConfigService } from "../../services/config.service";
import { LoggerService } from "../../services/logger.service";

@Injectable()
export class FeatureEnabledGuard implements CanActivate {
    constructor(private router: Router, private logger: LoggerService, private configService: ConfigService) {}

    public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        const key = route.data.feature as string;
        return this.configService.hasFeature(key).pipe(
            map(feature => {
                if (!feature) {
                    this.logger.info(`${key} are disabled`);
                    this.router.navigate([""]);
                    return false;
                }
                return true;
            }),
            take(1)
        );
    }
}
