import { Injectable } from "@angular/core";
import { Observable, Subject } from "rxjs";
import { map } from "rxjs/operators";
import { AceSearchResult } from "../shared/models/ace/ace-search-result.model";
import { AceSelection } from "../shared/models/ace/ace-selection.model";
import { INREAnalytics } from "../shared/models/interfaces/INREAnalytics";
import { NationRailParams } from "../shared/models/national-rail.model";
import { RailcardQuery, NreRailcardCodes, SilverRailRailcard } from "../shared/models/api/railcard";
import { AceCoreApiService } from "./ace-core-api.service";
import { GoogleAnalyticsService } from "./google-analytics.service";
import { nreActionType } from "../shared/models/entities/nre";

@Injectable({
    providedIn: "root"
})
export class NationalRailService {
    public selectionInvalid$: Observable<boolean>;
    public nationalRailSearch: NationRailParams;
    private _selectionSubject: Subject<boolean> = new Subject<boolean>();

    constructor(private _aceCoreApi: AceCoreApiService, private _analyticsService: GoogleAnalyticsService) {
        this.selectionInvalid$ = this._selectionSubject.asObservable();
        this.nationalRailSearch = new NationRailParams();
    }

    public validateBooking(cacheId: string): Observable<[DataModel.NreValidateBookingRecordInformationResponse, string]> {
        return this._aceCoreApi
            .call("NreValidateBookingRecordInformationRequest", { cacheId })
            .pipe(map((response: DataModel.StandardResponse): [DataModel.NreValidateBookingRecordInformationResponse, string] => [response.data, response.id]));
    }
    public updateParams(params) {
        this.nationalRailSearch.setParams(params);
        return this.nationalRailSearch.params;
    }

    public findSelections(result: AceSearchResult): void {
        // check if there are search results
        if (!result.isEmpty) {
            // pass in the result to apply the selection
            this.nationalRailSearch.getSelection(result);

            // if search and ticketing is valid apply the selected legs/prices
            if (!this.nationalRailSearch.isValid()) {
                // return error if not valid
                this._selectionSubject.next(false);
            }
        } else {
            // error if no results
            this._selectionSubject.next(false);
        }
    }

    public setNreDefaultDeliveryOption(code: DataModel.TicketingOptionCode): DataModel.TicketingOptionCode {
        this.nationalRailSearch.defaultDeliveryOption = code;
        return this.nationalRailSearch.defaultDeliveryOption;
    }

    public mapNreRailcards(params: any, srRailcardsList: SilverRailRailcard[]) {
        const query = { ...params };
        const mappedRailcards: { Code: string; Number: string; Type: string }[] = [];

        if (query && !query.railcards) {
            return query;
        }

        if (query && query.railcards && srRailcardsList && srRailcardsList.length > 0) {
            const railcardsQuery: RailcardQuery<NreRailcardCodes>[] = JSON.parse(query.railcards);
            const findSrRailcard = (nreRailcard: RailcardQuery<NreRailcardCodes>) => srRailcardsList.find(srRailcard => srRailcard.nreCode === nreRailcard.Code);

            railcardsQuery
                .filter(nreRailcard => findSrRailcard(nreRailcard))
                .map(nreRailcard => ({ ...findSrRailcard(nreRailcard), amount: nreRailcard.Number }))
                .map(srRailcard => {
                    const { code, type, amount } = srRailcard;
                    if (code && type && amount) {
                        for (let index = 0; index < Number(amount); index++) {
                            mappedRailcards.push({
                                Code: code,
                                Number: "1",
                                Type: type
                            });
                        }
                    }
                });

            return { ...query, railcards: JSON.stringify(mappedRailcards) };
        }
    }

    public successfulHandoffAnalytics(selection: AceSelection): void {
        const logData: INREAnalytics = {
            handoffId: selection.handoffId,
            origin: selection.outgoingLegSolution.originTravelPoint.code,
            destination: selection.outgoingLegSolution.destinationTravelPoint.code,
            errormsg: "N/A",
            pagenotif: "N/A",
            value: selection.prices.reduce((prev, curr) => prev + curr.totalPrice.value, 0).toFixed(2)
        };

        this._analyticsService.nreAnalytics(nreActionType.REDIRECTION, logData);
    }
}
