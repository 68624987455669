import { AfterViewInit, Component, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import { ButtonComponent } from "../../../shared/components/button/button.component";

@Component({
    selector: "ace-not-found",
    templateUrl: "./not-found.component.html",
    styleUrls: ["./not-found.component.scss"]
})
export class NotFoundComponent implements AfterViewInit {
    @ViewChild("homepageButton") public homepageButton: ButtonComponent;

    constructor(private router: Router) {}

    public ngAfterViewInit() {
        setTimeout(() => {
            this.homepageButton.focus();
        }, 0);
    }

    public redirect() {
        this.router.navigate(["/"]);
    }
}
