import { IQueryParams } from "../models/interfaces/IQueryParams";
import { Railcard } from "../models/railcard";

/* eslint-disable prefer-arrow/prefer-arrow-functions */
export const PHONE_NUMBER_ALLOWED_CHARS = ["+", "-", " "];

export const isValidNumberOnInput = ($event: KeyboardEvent, allowedChars: Array<string> = []): boolean => {
    if ($event.key && allowedChars.includes($event.key)) {
        return true;
    }

    if ($event.key != null && $event.key !== "Unidentified") {
        // Allow: backspace, delete, tab, escape, enter and .
        if (
            ["Delete", "Backspace", "Tab", "Escape", "Enter", "Decimal", "."].includes($event.key) ||
            // Allow: Ctrl+A,Ctrl+C,Ctrl+V, Command+A
            (["a", "c", "v"].includes($event.key) && ($event.ctrlKey || $event.metaKey)) ||
            // Allow: home, end, left, right, down, up
            ["Home", "End", "ArrowLeft", "Left", "ArrowRight", "Right", "ArrowDown", "Down", "ArrowUp", "Up"].includes($event.key)
        ) {
            // let it happen, don't do anything
            return true;
        }

        // validate digits
        if ($event.shiftKey || !Array.from({ length: 10 }, (_, i) => i.toString()).includes($event.key)) {
            return false;
        }

        return true;
    }
};

export const isValidNumberOnPaste = ($event: ClipboardEvent, allowedChars: Array<string> = []): boolean => {
    if ($event.clipboardData && $event.clipboardData.getData) {
        let text = $event.clipboardData.getData("text") ? $event.clipboardData.getData("text").trim() : "";
        allowedChars.forEach(char => (text = text.split(char).join("")));
        if (text !== "") {
            const digitsRegex = /^\d+$/;
            return digitsRegex.test(text);
        }
    } else {
        return true;
    }
};

export const sortArrayOfObjectsByPriorities = <T>(arr: Array<any>, fieldName: string, priorities: Array<number | string>): Array<T> => {
    const prioritizedArray = [...arr];
    [...priorities.reverse()].forEach(priority => {
        prioritizedArray.sort((itemA, itemB) => {
            let output = 0;
            if (itemA[fieldName] === priority) {
                output = -1;
            } else if (itemB[fieldName] === priority) {
                output = 1;
            }
            return output;
        });
    });

    return prioritizedArray;
};

export const mergeArraysOfObjectsIdentifiedByField = <T>(arrA: Array<any>, arrB: Array<any>, fieldName: string): Array<T> => {
    return arrA.map(itemA => {
        const sibling = arrB.find(itemB => itemB[fieldName] === itemA[fieldName]);
        return sibling ? { ...itemA, ...sibling } : itemA;
    });
};

// Deep comparison - https://github.com/mjackson/value-equal/blob/master/modules/index.js
function valueOf(obj: any): any {
    return obj.valueOf ? obj.valueOf() : Object.prototype.valueOf.call(obj);
}

export function isDeeplyEqual(a: any, b: any): boolean {
    if (a === b) {
        return true;
    }

    if (a == null || b == null) {
        return false;
    }

    if (Array.isArray(a)) {
        return Array.isArray(b) && a.length === b.length && a.every((item, index) => isDeeplyEqual(item, b[index]));
    }

    if (typeof a === "object" || typeof b === "object") {
        const aValue = valueOf(a);
        const bValue = valueOf(b);

        if (aValue !== a || bValue !== b) {
            return isDeeplyEqual(aValue, bValue);
        }

        return Object.keys(Object.assign({}, a, b)).every(key => isDeeplyEqual(a[key], b[key]));
    }

    return false;
}

// https://github.com/you-dont-need/You-Dont-Need-Lodash-Underscore#_get
export function get<SourceType, ValueType, DefaultType = ValueType | undefined>(obj: SourceType, path: string, defaultValue?: DefaultType): ValueType | DefaultType {
    const result: ValueType = path
        .split(/[,[\].]+?/)
        .filter(Boolean)
        .reduce((res: any, key) => (res !== null && res !== undefined ? res[key] : res), obj);
    return result === undefined ? defaultValue : result;
}

export function inRange(value: number, a: number = 0, b?: number): boolean {
    if (!b) {
        b = a;
        a = 0;
    }
    return value >= Math.min(a, b) && value < Math.max(a, b);
}

export function sortByKey<T>(array: T[], key: string): T[] {
    return array.sort((a, b) => (a[key] > b[key] ? 1 : b[key] > a[key] ? -1 : 0));
}

// TODO: flatten can be replaced with native flat (it is more type-safe) from es9 and polyfill by core-js, typescript version is a blocker
export const flatten = <T>(data: T[]) => [].concat(...data);
export const isEmpty = (it: any): boolean => [Object, Array].includes((it || {}).constructor) && !Object.entries(it || {}).length;
export const isObject = (it: any): boolean => Boolean(it && typeof it === "object" && it.constructor === Object);
export const asArray = <T>(it: T | T[]): T[] => (!Array.isArray(it) ? [it] : it);
export const difference = <T>(arrA: T[], arrB: T[]): T[] => [arrA, arrB].reduce((a, b) => a.filter(c => !b.includes(c)));
export const uniqDeepEqual = <T>(data: T[]): T[] => data.reduce((prev, current) => (prev.some(it => isDeeplyEqual(it, current)) ? prev : [...prev, current]), []);
export const uniq = <T>(data: T[]): T[] => data.reduce((prev, current) => (prev.includes(current) ? prev : [...prev, current]), []);
export const groupBy = <T>(arr: T[], k: string) => arr.reduce((r, c) => ((r[c[k]] = [...(r[c[k]] || []), c]), r), {});

// https://reactgo.com/removeduplicateobjects/
export const uniqBy = <T>(data: T[], key: string) => {
    return data
        .map(e => e[key])
        .map((e, i, final) => final.indexOf(e) === i && i)
        .filter(e => data[e])
        .map(e => data[e]);
};

// https://github.com/you-dont-need/You-Dont-Need-Lodash-Underscore#_pickby
export function pickBy(data: object) {
    const obj = {};
    for (const key in data) {
        if (data[key] !== null && data[key] !== false && data[key] !== undefined && data[key] !== "") {
            obj[key] = data[key];
        }
    }
    return obj;
}

let activeModal: boolean;

export function getActiveModal(): boolean {
    return activeModal;
}

export function setActiveModal(flag: boolean): void {
    activeModal = flag;
}

export function generateRailcardManifest(params: IQueryParams): DataModel.FareQualifier[] {
    const fareQualifiers = [];
    const railcards = JSON.parse(params.railcards || "[]");

    if (params.railcards) {
        railcards.map((railcard, index) => {
            fareQualifiers.push({
                program: railcard.Code
            });
        });
    }

    return fareQualifiers;
}

export function getRailcards(railcards: Railcard[], params: IQueryParams): string {
    if (railcards) {
        return JSON.stringify(
            Object.keys(railcards)
                .map(k => railcards[k])
                .map(railcard => {
                    return {
                        Code: railcard.value,
                        Number: 1,
                        Type: railcard.type
                    };
                })
        );
    }

    return params ? params.railcards : "[]";
}
