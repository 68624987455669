<div class="station-picker" [class.stnr]="isStnrPicker === true">
    <div
        #toggleButton
        role="button"
        class="station-picker-toggle non-focus"
        [ngClass]="{ 'toggle-hidden': isFocused === true, disabled: disabled === true }"
        (click)="onPickerLabelClick()"
        (keydown.enter)="onComponentFocus()"
        [attr.tabindex]="amend ? -1 : 0"
    >
        <div class="input facade no-overflow" [class.input-disabled]="isStationPickerDisabled$ | async">
            <span *ngIf="stationService.isStationsLoading$ | async" class="loading-msg">
                <ace-loading-indicator [default]="true" data-elid="qtt-station-picker-loading-indicator"></ace-loading-indicator>
                Loading stations...
            </span>
            <span *ngIf="(stationService.isStationsLoading$ | async) === false" class="location-label">
                <span *ngIf="stationLabel$ | async; else defaultLabelTemplate"
                    ><i class="sr-only">{{ inputLabel }}: </i>{{ stationLabel$ | async }}<i class="sr-only">, click to change</i></span
                >
                <ng-template #defaultLabelTemplate>
                    <span *ngIf="defaultAccessibleLabel" class="sr-only">{{ defaultAccessibleLabel }} <i *ngIf="isRequired">required</i></span>
                    <span aria-hidden="true">{{ defaultLabel }}</span>
                </ng-template>
            </span>
        </div>
    </div>

    <div class="station-picker-list-container" [ngClass]="{ 'list-hidden': isFocused === false }">
        <div
            class="station-picker-input-container"
            role="combobox"
            [attr.aria-expanded]="isFocused"
            [attr.aria-owns]="baseId + '-station-picker-' + stationPickerName + '-list'"
            aria-haspopup="listbox"
        >
            <label class="sr-only" [id]="baseId + '-station-picker-' + stationPickerName + '-label'" [for]="baseId + '-station-picker-' + stationPickerName + '-input'">{{
                inputLabel
            }}</label>
            <input
                #inputElement
                type="search"
                name="{{ stationPickerName }}"
                [id]="baseId + '-station-picker-' + stationPickerName + '-input'"
                class="input station-picker-input"
                placeholder="Search"
                [required]="isRequired"
                autocomplete="off"
                aria-autocomplete="list"
                [attr.aria-controls]="baseId + '-station-picker-' + stationPickerName + '-list'"
                [attr.aria-activedescendant]="baseId + '-station-picker-' + stationPickerName + '-list-option-' + (highlight$ | async)"
                [attr.aria-labelledby]="baseId + '-station-picker-' + stationPickerName + '-label'"
                [ngModel]="userInput"
                (ngModelChange)="onInputContentChange($event)"
                (blur)="shiftFocusToDropDown()"
                (keydown)="onKeyDown($event)"
            />
        </div>
        <div #searchResultPanelHeader class="dropdown-header"></div>
        <div
            #searchResultPanel
            role="listbox"
            class="station-picker-body"
            tabindex="-1"
            [attr.id]="baseId + '-station-picker-' + stationPickerName + '-list'"
            [class.station-picker-short-list]="!(isMobile$ | async)"
        >
            <div
                [ngClass]="!locationServicesActive && !fetchedNearByStation ? 'location-heading-error' : 'location-heading'"
                role="option"
                [attr.id]="baseId + '-station-picker-' + stationPickerName + '-list-label'"
                data-elid="dropdown-picker-header"
                *ngIf="!hideNearByStation"
            >
                <span class="display-flex center-align" *ngIf="!loadNearByStations">
                    <span *ngIf="locationServicesActive !== false && !fetchedNearByStation && locationServicesAvailable"
                        ><img class="image-height-width" src="/assets/images/navigation.png" alt="navigation"
                    /></span>
                    <span *ngIf="!locationServicesActive && !locationServicesAvailable && !fetchedNearByStation" class="center-align display-flex">
                        <svg viewBox="0 0 24 24" class="warning-icon">
                            <use xlink:href="#warning-triangle" />
                        </svg>
                    </span>
                    <span
                        *ngIf="locationServicesActive === false && !fetchedNearByStation && locationServicesAvailable"
                        class="left-right-padding image-height-width center-align display-flex"
                    >
                        <img src="../../../../assets/images/warning-info.png" alt="" height="18px" width="18px" />
                    </span>
                    <span
                        class="location-heading-text display-flex"
                        *ngIf="!loadNearByStations"
                        [ngClass]="{
                            'location-heading-text': locationServicesActive === true,
                            'location-heading-text-error': locationServicesAvailable === false
                        }"
                    >
                        {{ locationLabel }}
                    </span>
                </span>
                <span *ngIf="!locationServicesAvailable && !fetchedNearByStation && !loadNearByStations" class="center-align display-flex pointer-cursor">
                    <svg viewBox="0 0 24 24" aria-hidden="true" class="image-height-width" (click)="hideNearByStations()" (mousedown)="hideNearByStations()">
                        <use xlink:href="#close" />
                    </svg>
                </span>
                <span *ngIf="loadNearByStations" class="loading-msg">
                    <ace-loading-indicator [default]="true" data-elid="qtt-station-picker-loading-indicator"></ace-loading-indicator>
                    Loading stations...
                </span>
            </div>
            <ng-container *ngIf="!hideNearByStation">
                <div
                    *ngFor="let nearByLocation of nearBySearchResults$ | async as nearBySearchResults; let j = index"
                    class="body-item"
                    [class.highlighted]="j === (nearByHighlight$ | async)"
                    role="option"
                    [attr.id]="baseId + '-station-picker-' + stationPickerName + '-list-option-' + i"
                    [attr.aria-selected]="j === (nearByHighlight$ | async)"
                    (click)="onOptionSelect(nearByLocation)"
                    (mouseover)="onNearByMouseOver(j)"
                    (mousedown)="onMouseDown()"
                >
                    <div class="display-flex space-between" *ngIf="nearBySearchResults.length > 0">
                        <span>
                            <span>{{ nearByLocation.name }}</span>
                            <span *ngIf="nearByLocation.crsCode">&nbsp;({{ nearByLocation.crsCode }})</span>
                        </span>
                        <span>{{ nearByLocation.miles }}</span>
                    </div>
                </div>
                <div
                    *ngIf="fetchedNearByStation && !nearByStationsAvailable && !loadNearByStations && (!nearBySearchResults || nearBySearchResults?.length === 0)"
                    class="no-nearby-station"
                >
                    <span> No nearby stations available. </span>
                </div>
            </ng-container>
            <div
                class="location-heading"
                role="option"
                [attr.id]="baseId + '-station-picker-' + stationPickerName + '-list-label'"
                data-elid="dropdown-picker-header"
                *ngIf="!hideRecentStation && isUserLoggedIn"
            >
                <span class="display-flex location-heading-text">
                    {{ recentLocationLabel }}
                </span>
            </div>
            <ng-container *ngIf="!hideRecentStation && isUserLoggedIn">
                <div
                    *ngFor="let recentLocation of recentSearchResults$ | async as recentSearchResults; let k = index"
                    class="body-item"
                    [class.highlighted]="k === (recentHighlight$ | async)"
                    role="option"
                    [attr.id]="baseId + '-station-picker-' + stationPickerName + '-list-option-' + i"
                    [attr.aria-selected]="k === (recentHighlight$ | async)"
                    (click)="onOptionSelect(recentLocation)"
                    (mouseover)="onRecentMouseOver(k)"
                    (mousedown)="onMouseDown()"
                >
                    <div class="display-flex space-between center-align" *ngIf="recentSearchResults.length > 0">
                        <span class="display-flex center-align">
                            <img class="image-height-width" src="/assets/images/clock.png" alt="clock" />
                            <span>{{ recentLocation.name }}</span>
                            <span *ngIf="recentLocation.crsCode">&nbsp;({{ recentLocation.crsCode }})</span>
                        </span>
                    </div>
                </div>
            </ng-container>
            <div class="body-heading" [attr.id]="baseId + '-station-picker-' + stationPickerName + '-list-label'" data-elid="dropdown-picker-header">{{ searchLabel }}</div>

            <ng-container *ngIf="!(isMobile$ | async)">
                <div
                    *ngFor="let location of locationSearchResults$ | async; let i = index"
                    class="body-item"
                    [class.highlighted]="i === (highlight$ | async)"
                    role="option"
                    [attr.id]="baseId + '-station-picker-' + stationPickerName + '-list-option-' + i"
                    [attr.aria-selected]="i === (highlight$ | async)"
                    (click)="onOptionSelect(location)"
                    (mouseover)="onMouseOver(i)"
                    (mousedown)="onMouseDown()"
                >
                    {{ location.name }}
                    <span *ngIf="location.crsCode">({{ location.crsCode }})</span>
                </div>
            </ng-container>

            <cdk-virtual-scroll-viewport *ngIf="(isMobile$ | async) && isFocused" itemSize="50" class="station-picker-long-list">
                <div
                    *cdkVirtualFor="let location of locationSearchResults$ | async; let i = index"
                    class="body-item"
                    [class.highlighted]="i === (highlight$ | async)"
                    role="option"
                    [attr.id]="baseId + '-station-picker-' + stationPickerName + '-list-option-' + i"
                    [attr.aria-selected]="i === (highlight$ | async)"
                    (click)="onOptionSelect(location)"
                    (mouseover)="onMouseOver(i)"
                    (mousedown)="onMouseDown()"
                >
                    {{ location.name }}
                    <span *ngIf="location.crsCode">({{ location.crsCode }})</span>
                </div>
            </cdk-virtual-scroll-viewport>

            <ng-container *ngIf="locationSearchResults$ | async as locationSearchResults">
                <div *ngIf="locationSearchResults.length === 0" class="no-results">We can't find a station matching your search - please enter a valid station name</div>
            </ng-container>
        </div>
    </div>
</div>
