import { Component, Input, OnInit, ViewChild } from "@angular/core";
import { StationPickerComponent } from "../../../../shared/components/station-picker/station-picker.component";
import { StationPickerMetadata, StationPickerType } from "../../../../shared/models/entities/station-picker";
import { ModalContainer } from "../modal-container.component";

@Component({
    selector: "ace-station-picker-mobile-modal.component",
    templateUrl: "station-picker-mobile-modal.component.html",
    styleUrls: ["station-picker-mobile-modal.component.scss"]
})
export class StationPickerMobileModalComponent extends ModalContainer implements OnInit {
    @ViewChild("stationPickerController") private stationPickerController!: StationPickerComponent;
    @Input() public handleCTAClick: Function;
    @Input() public handleCloseClick: Function;
    @Input() public cta: string = "cta";
    @Input() public stationTravelPoint: string;
    @Input() public stationPickerType: StationPickerType;
    @Input() public stationPickerMetadata: StationPickerMetadata;

    constructor() {
        super();
    }

    public ngOnInit(): void {
        setTimeout(() => {
            this.stationPickerController?.onPickerLabelClick();
        }, 50);
    }

    public onStationChange(code: string): void {
        this.stationTravelPoint = code;
    }

    public onContinue(): void {
        this.handleCTAClick(this);
    }

    public onCancel(): void {
        this.handleCloseClick(this);
    }
}
