import { DOCUMENT } from "@angular/common";
import { OnInit, OnDestroy, Renderer2, Inject, Directive, Input } from "@angular/core";

@Directive({
    selector: "[aceAnnounce]"
})
export class AnnounceDirective implements OnInit, OnDestroy {
    @Input() public aceAnnounce: string;
    private component: HTMLSpanElement;
    constructor(private renderer: Renderer2, @Inject(DOCUMENT) private document: Document) {}

    public ngOnInit() {
        if (this.document.body && this.aceAnnounce) {
            this.component = this.document.createElement("span");
            this.renderer.addClass(this.component, "sr-only");
            this.renderer.setAttribute(this.component, "role", "alert");
            const alert = this.renderer.createText(this.aceAnnounce);
            this.renderer.appendChild(this.component, alert);
            this.renderer.appendChild(this.document.body, this.component);
        }
    }

    public ngOnDestroy() {
        if (this.document.body && this.component) {
            this.renderer.removeChild(this.document.body, this.component);
            this.component = null;
        }
    }
}
