export interface IViewportSize {
    width: number;
    height: number;
}

export enum ViewportOrientation {
    LANDSCAPE,
    PORTRAIT
}

export enum ViewportType {
    DESKTOP,
    TABLET,
    MOBILE,
    MOBILE_SMALL
}
