import { Inject, Injectable } from "@angular/core";
import { Observable, of, ReplaySubject } from "rxjs";
import { catchError, map, mergeMap } from "rxjs/operators";
import { LocalStorageService } from "./local-storage.service";
import { WINDOW } from "./window.service";

@Injectable({
    providedIn: "root"
})
export class LocalCacheService {
    public static readonly APP_REVISION_KEY = "aceAppRevision";
    private cacheReady$: ReplaySubject<boolean> = new ReplaySubject(1);
    private defaultExpires: number = 86400;

    constructor(private localstorage: LocalStorageService, @Inject(WINDOW) private window: Window) {
        // On page load, detect whether this is a new app version compared to what is stored in the cache
        this.localstorage
            .getItem(LocalCacheService.APP_REVISION_KEY)
            .pipe(
                mergeMap(value => {
                    if (!value || value !== window["_aceDeployRevision"]) {
                        // Values dont match === New build. Bust all the caches and save the new
                        return this.localstorage.clear().pipe(
                            mergeMap(() => this.localstorage.setItem(LocalCacheService.APP_REVISION_KEY, this.window["_aceDeployRevision"])),
                            map(() => true)
                        );
                    } else {
                        // Values match === Previous caches were from the same build. Nothing to see here...
                        return of(true);
                    }
                })
            )
            .subscribe(() => this.cacheReady$.next(true));
    }

    public observable(key: string, observable: Observable<any>, expires?: number, hash?: string): Observable<any> {
        if (!expires) {
            expires = this.defaultExpires;
        }

        // First fetch the item from localstorage (even though it may not exist)
        return this.localstorage.getItem<ICacheStorageRecord>(key).pipe(
            // if the cached value has a different hash nullify it, otherwise pass it through
            map(val => {
                if (val) {
                    if (val.hash) {
                        if (val.hash === hash) {
                            return val;
                        } else {
                            return null;
                        }
                    }
                    return val;
                }
            }),
            // If the cached value has expired, nullify it, otherwise pass it through
            map(val => {
                if (val) {
                    if (val.expires) {
                        return new Date(val.expires).getTime() > Date.now() ? val : null;
                    } else {
                        return val;
                    }
                }
            }),
            // At this point, if we encounter a null or empty value, either it doesnt exist in the cache or it has expired.
            // If it doesnt exist, simply return the observable that has been passed in, caching its value as it passes through
            mergeMap(val => {
                if (val) {
                    return of(val.value);
                } else {
                    return observable.pipe(
                        // The result may have 'expires' explicitly set
                        mergeMap(tVal => this.value(key, tVal, tVal["expires"] || expires, hash))
                    );
                }
            })
        );
    }

    public value<T>(key: string, value: T, expires: number | string | Date = this.defaultExpires, hash?: string) {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        const _expires: Date = this.sanitizeAndGenerateDateExpiry(expires);

        // Add a dependency on the _cacheReady property so that we ensure caches from a previous app build are purged before
        // the first values are cached
        return this.cacheReady$.pipe(
            mergeMap(() =>
                this.localstorage.setItem(key, {
                    expires: _expires,
                    value,
                    hash
                })
            ),
            map(val => (val as any).value),
            catchError(() =>
                // caching has failed so we just return the value we tried caching
                of(value)
            )
        );
    }

    public getValue(key: string, hash?: string) {
        return this.localstorage.getItem<ICacheStorageRecord>(key).pipe(
            map(val => {
                if (val) {
                    if (val.hash) {
                        if (val.hash === hash) {
                            return val;
                        } else {
                            return null;
                        }
                    }
                    return val;
                }
            }),
            map(val => {
                if (val) {
                    if (val.expires) {
                        return new Date(val.expires).getTime() > Date.now() ? val.value : null;
                    } else {
                        return val;
                    }
                } else {
                    return null;
                }
            })
        );
    }

    public expire(key: string) {
        return this.localstorage.removeItem(key);
    }

    public expireAll() {
        return this.localstorage.clear();
    }

    private sanitizeAndGenerateDateExpiry(expires: string | number | Date): Date {
        const expiryDate: Date = this.expiryToDate(expires);

        // Dont allow expiry dates in the past
        if (expiryDate.getTime() <= Date.now()) {
            return new Date(Date.now() + this.defaultExpires);
        }

        return expiryDate;
    }

    private expiryToDate(expires: number | string | Date): Date {
        if (typeof expires === "number") {
            return new Date(Date.now() + Math.abs(expires) * 1000);
        }
        if (typeof expires === "string") {
            return new Date(expires);
        }
        if (expires instanceof Date) {
            return expires;
        }

        return new Date();
    }
}

interface ICacheStorageRecord {
    expires?: Date;
    hash?: string;
    value?: any;
}
