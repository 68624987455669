import { AfterContentInit, Component, ElementRef, EventEmitter, Input, NgZone, OnChanges, OnInit, Output, ViewChild } from "@angular/core";
import { Select, Store } from "@ngxs/store";
import { BehaviorSubject, forkJoin, Observable, of, ReplaySubject, Subject } from "rxjs";
import { catchError, debounceTime, distinctUntilChanged, filter, map, mergeMap, switchMap, take, takeLast, withLatestFrom } from "rxjs/operators";
import { StationService } from "../../../services/station.service";
import { ArrowDirection } from "../../models/entities/keyboard";
import { StationPickerType } from "../../models/entities/station-picker";
import { StationApiResponse } from "../../models/entities/station";
import { RxjsComponent } from "../../RxjsComponent";
import { MetadataState } from "../../state/metadata/metadata.state";
import { QttState } from "../../state/qtt/qtt.state";
import { AceNearByStation } from "../../models/ace/ace-nearby-station-model";
import { AccountState } from "../../state/account/account.state";
import { AceUser } from "../../models/ace/ace-user.model";

@Component({
    selector: "ace-station-picker",
    templateUrl: "station-picker.component.html",
    styleUrls: ["station-picker.component.scss"]
})
export class StationPickerComponent extends RxjsComponent implements OnInit, OnChanges, AfterContentInit {
    @Select(QttState.stationPicker) public isStationPickerDisabled$: Observable<boolean>;
    @Select(MetadataState.isMobile) public isMobile$: Observable<boolean>;
    @Select(AccountState.account) public user$: Observable<AceUser>;
    @ViewChild("toggleButton") public toggleButton: ElementRef<HTMLDivElement>;
    @ViewChild("inputElement") public inputElement: ElementRef;
    @ViewChild("searchResultPanel", { static: true }) public searchResultPanel: ElementRef;
    @ViewChild("searchResultPanelHeader") public searchResultPanelHeader: ElementRef;
    @Input() public baseId: string;
    @Input() public inputLabel: string;
    @Input() public defaultLabel: string;
    @Input() public defaultAccessibleLabel: string;
    @Input() public value: string | number;
    @Input() public disabled: boolean;
    @Input() public amend: boolean;
    @Input() public stationPickerName: StationPickerType;
    @Input() public includeTravelPassStations: boolean;
    @Input() public isRemoved: boolean;
    @Input() public isRequired = false;
    @Input() public isStnrPicker = false;
    @Output() public focused: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output() public valueChange: EventEmitter<string> = new EventEmitter<string>();
    public highlight$: BehaviorSubject<number | null> = new BehaviorSubject(0);
    public nearByHighlight$: BehaviorSubject<number | null> = new BehaviorSubject(0);
    public recentHighlight$: BehaviorSubject<number | null> = new BehaviorSubject(0);
    public errors$: ReplaySubject<number | null> = new ReplaySubject(1);
    public searchQueryStream$: Subject<string> = new Subject<string>();
    public locationSearchResults$: Observable<StationApiResponse[]> = new ReplaySubject(1);
    public nearBySearchResults$: ReplaySubject<any[]>;
    public recentSearchResults$: ReplaySubject<any[]>;
    public isFocused: boolean = false;
    public userInput: string = "";
    public searchLabel: string;
    public locationLabel: string = "Enable location settings to see nearby stations";
    public recentLocationLabel: string = "Recent Stations";
    public locationServicesActive: boolean;
    public locationServicesAvailable: boolean = true;
    public fetchedNearByStation: boolean = false;
    public nearByStationsAvailable: boolean = false;
    public recentStationsAvailable: boolean = false;
    public loadNearByStations: boolean = false;
    public hideNearByStation: boolean = false;
    public hideRecentStation: boolean = false;
    private longitude: number;
    private latitude: number;
    public stationLabel$: ReplaySubject<string> = new ReplaySubject(1);
    public verticalArrowKeys: string[] = ["ArrowUp", "ArrowDown", "Up", "Down"];
    public mousedownStarted: boolean = false;
    public isUserLoggedIn: boolean = false;
    public currentUser: AceUser;
    protected searchValues$: BehaviorSubject<string | undefined> = new BehaviorSubject("");
    private values$: BehaviorSubject<string | undefined> = new BehaviorSubject(undefined);
    private distinctValues$ = this.values$.pipe(distinctUntilChanged());
    private keyStream$: Subject<KeyboardEvent> = new Subject();
    private nearByStations = 0;
    private recentStations = 0;
    private keyDownCount = 0;

    constructor(public stationService: StationService, private store: Store, private ngZone: NgZone) {
        super();
    }
    ngAfterContentInit(): void {
        if (this.nearBySearchResults$) {
            this.fetchNearByStation();
        }
        if (this.recentSearchResults$ && this.isUserLoggedIn) {
            this.fetchRecentStation();
        }
    }

    public ngOnInit(): void {
        this.highlight$.next(-1);
        this.recentHighlight$.next(-1);
        // Search results are basically the result of a popular stations observable or a search observable
        this.locationSearchResults$ = this.searchValues$.pipe(
            debounceTime(250),
            distinctUntilChanged(),
            mergeMap(query => this.runQuery(query))
        );

        // Convert UP/Down into a highlight index stream
        this.addSubscription(
            this.keyStream$
                .pipe(
                    filter(event => this.verticalArrowKeys.includes(event.key)),
                    map(event => {
                        event.preventDefault();

                        if (event.key === "ArrowDown" || event.key === "Down") {
                            return ArrowDirection.Down;
                        } else {
                            return ArrowDirection.Up;
                        }
                    }),
                    withLatestFrom(
                        this.highlight$,
                        this.nearByHighlight$,
                        this.recentHighlight$,
                        this.locationSearchResults$.pipe(map(data => data.length)),
                        (direction, highlightIndex, nearByhighlightIndex, recenthighlightIndex, resultCount) => {
                            if (direction === ArrowDirection.Down) {
                                resultCount = resultCount + this.nearByStations + this.recentStations;
                                if (typeof highlightIndex === "number" || typeof nearByhighlightIndex === "number" || typeof recenthighlightIndex === "number") {
                                    // Highlighted
                                    this.keyDownCount < 0 ? (this.keyDownCount = 0) : (this.keyDownCount = this.keyDownCount);
                                    this.keyDownCount = this.keyDownCount + 1;
                                    highlightIndex = this.keyDownCount;
                                    return highlightIndex < resultCount ? highlightIndex : highlightIndex - 1;
                                } else {
                                    // Nothing highlighted
                                    return 0;
                                }
                            } else {
                                this.keyDownCount > 0 ? (highlightIndex = this.keyDownCount - 1) : (highlightIndex = null);
                                if (typeof highlightIndex === "number") {
                                    // Highlighted
                                    this.keyDownCount -= 1;
                                    return highlightIndex < 0 ? 0 : highlightIndex;
                                } else {
                                    // Nothing highlighted, pressing up does nothing
                                    return null;
                                }
                            }
                        }
                    )
                )
                .subscribe(highlightIndex => {
                    if (typeof highlightIndex === "number") {
                        if (this.nearByStationsAvailable && highlightIndex < this.nearByStations) {
                            this.nearByHighlight$.next(highlightIndex);
                            this.recentHighlight$.next(null);
                            this.highlight$.next(null);
                        } else if (this.recentStationsAvailable && highlightIndex < this.nearByStations + this.recentStations) {
                            if (this.nearByStationsAvailable && highlightIndex >= this.nearByStations && highlightIndex < this.nearByStations + this.recentStations) {
                                this.recentHighlight$.next(this.nearByStations ? highlightIndex - this.nearByStations : highlightIndex);
                                this.highlight$.next(null);
                                this.nearByHighlight$.next(null);
                            } else if (!this.nearByStationsAvailable && highlightIndex < this.recentStations) {
                                this.recentHighlight$.next(highlightIndex);
                            }
                        } else {
                            highlightIndex = highlightIndex - (this.nearByStations + this.recentStations);
                            this.highlight$.next(highlightIndex);
                            this.recentHighlight$.next(null);
                            this.nearByHighlight$.next(null);
                        }
                    } else if (highlightIndex === null) {
                        if (this.nearByStationsAvailable) {
                            this.nearByHighlight$.next(0);
                        } else if (!this.nearByStationsAvailable && this.recentStationsAvailable) {
                            this.recentHighlight$.next(0);
                        } else {
                            this.highlight$.next(0);
                        }
                    }
                })
        );

        // On Highlight change, we need to update some scroll positions
        this.addSubscription(this.highlight$.pipe(distinctUntilChanged()).subscribe(this.onHighlightChange.bind(this)));

        // On value change, initReset the highlight
        this.addSubscription(this.distinctValues$?.subscribe(() => this.highlight$.next(null)));

        // Enter key
        this.addSubscription(
            this.keyStream$
                .pipe(
                    filter(event => event.key === "Enter"),
                    withLatestFrom(this.locationSearchResults$, this.highlight$, (e, stations, highlightIndex) => {
                        e.preventDefault();

                        // No stations and no highlight? Do nothing
                        if (highlightIndex === null && stations.length === 0) {
                            return null;
                        }

                        // No highlight, but with stations? Pick the first item
                        if (highlightIndex === null && stations.length > 0) {
                            return stations[0];
                        }

                        return stations[highlightIndex];
                    })
                )
                .subscribe(station => {
                    if (station !== null) {
                        this.values$.next(station.code);
                        this.isFocused = false;
                    }
                })
        );

        // Station label (only when there is a value)
        this.addSubscription(
            this.distinctValues$
                .pipe(
                    filter(val => !!val),
                    mergeMap(val => this.stationService.fetchStation(val)),
                    // assemble content of label - station name plus optional (crsCode)
                    map(val => `${val.name} ${val.crsCode ? " (" + val.crsCode + ")" : ""}`),
                    catchError(err => {
                        this.errors$.next(err.message);
                        return of("");
                    })
                )
                .subscribe(label => this.stationLabel$.next(label))
        );

        this.addSubscription(
            this.locationSearchResults$
                .pipe(
                    filter(results => results.length > 0),
                    distinctUntilChanged()
                )
                .subscribe(() => {
                    this.highlight$.next(0);
                })
        );

        // Expose to outside component
        this.distinctValues$?.subscribe(val => this.valueChange.emit(val));
        this.nearBySearchResults$ = new ReplaySubject(1);
        this.recentSearchResults$ = new ReplaySubject(1);
        this.handlePermission();

        this.addSubscription(
            this.user$?.subscribe((user: AceUser) => {
                this.isUserLoggedIn = user?.isLoggedIn;
                this.currentUser = user;
            })
        );

        const callOnBlur = function () {
            // calling the onBlur function when onBlur is called on dropdown list
            this.onBlur();
        }.bind(this);

        this.searchResultPanel.nativeElement.addEventListener("blur", callOnBlur);
    }

    public shiftFocusToDropDown() {
        this.searchResultPanel.nativeElement?.focus();
    }

    public ngOnChanges(changes: any): void {
        if (changes["value"]) {
            this.values$.next(changes["value"].currentValue);
        }

        if (this.isRemoved) {
            this.values$.next(null);
        }
    }

    public onHighlightChange(index: number | null): void {
        if (index === null) {
            this.searchResultPanel.nativeElement.scrollTop = 0;
            return;
        }

        // Set search result box scroll position
        const searchItem = this.searchResultPanel.nativeElement.querySelectorAll(".station-picker-body-item")[index];
        if (searchItem) {
            const itemOffset = searchItem.offsetTop;
            const itemHeight = searchItem.offsetHeight;
            const scrollOffset = this.searchResultPanel.nativeElement.scrollTop;
            const searchPanelHeight = this.searchResultPanel.nativeElement.offsetHeight;

            if (itemOffset < scrollOffset) {
                this.searchResultPanel.nativeElement.scrollTop = itemOffset;
            }

            if (itemOffset + itemHeight > scrollOffset + searchPanelHeight) {
                this.searchResultPanel.nativeElement.scrollTop = itemOffset + itemHeight - searchPanelHeight;
            }
        }
    }

    public onHighlightChangeAdjustScroll(): void {
        const selectedItem = this.searchResultPanel.nativeElement.querySelectorAll(".body-item")[this.keyDownCount];
        if (selectedItem) {
            const itemOffset = selectedItem.offsetTop;
            const itemHeight = selectedItem.offsetHeight;
            const scrollOffset = this.searchResultPanel.nativeElement.scrollTop;
            const searchPanelHeight = this.searchResultPanel.nativeElement.offsetHeight;

            let newPosition = 0;
            newPosition += this.nearByStationsAvailable ? this.nearByStations : 1;
            newPosition += this.recentStationsAvailable ? this.recentStations : 1;

            if (itemOffset < scrollOffset) {
                this.searchResultPanel.nativeElement.scrollTop = itemOffset;
            }

            const conditionToCheck = newPosition * 42 + itemOffset;
            if (conditionToCheck > scrollOffset + searchPanelHeight) {
                this.searchResultPanel.nativeElement.scrollTop = conditionToCheck - searchPanelHeight;
            }
        }
    }

    public onPickerLabelClick(): void {
        // hide search result panel to avoid UI glitches
        this.toggleSearchResultPanelOpacity(false);

        if ([this.amend, this.store.selectSnapshot(state => QttState.stationPicker(state.qtt))].some(it => it)) {
            return;
        }

        this.isFocused = true;
        this.userInput = "";
        this.searchQueryStream$.next("");
        this.highlight$.next(-1);
        this.recentHighlight$.next(-1);
        this.enableLocationServices();
        if (this.isUserLoggedIn) {
            this.fetchRecentStation();
        }

        // Give ngIf a chance to render the <input>.
        // Then set the focus, but do this outside the Angular zone to be efficient.
        // There is no need to run change detection after setTimeout() runs,
        // since we're only focusing an element.
        this.ngZone.runOutsideAngular(() => setTimeout(() => this.inputElement.nativeElement.focus(), 10));

        this.focused.emit(true);
        setTimeout(() => {
            this.toggleSearchResultPanelOpacity(true);
        }, 0);
    }

    public onOptionSelect(location: StationApiResponse): void {
        this.setValue(location.code);

        setTimeout(() => {
            this.toggleButton?.nativeElement?.focus();
            this.keyDownCount = 0;
            this.onBlur();
        }, 50);
    }

    public onComponentFocus(): void {
        if (this.amend) {
            return;
        }
        this.onPickerLabelClick();
    }

    public onKeyDown(event: KeyboardEvent): void {
        if (event.key === "Enter") {
            event.preventDefault();
            this.enableLocationServices();
            this.onConfirmKeyDown(event);
            if (this.isUserLoggedIn) {
                this.fetchRecentStation();
            }
        } else {
            this.keyStream$.next(event);
        }
        this.onHighlightChangeAdjustScroll();
    }

    public onMouseDown(): void {
        this.mousedownStarted = true;
    }

    public onConfirmKeyDown(event: KeyboardEvent): void {
        event.preventDefault();
        forkJoin([
            this.locationSearchResults$.pipe(
                take(1),
                filter(results => results.length > 0)
            ),
            this.highlight$.pipe(take(1))
        ])
            .pipe(take(1))
            .subscribe(results => {
                const searchResults: StationApiResponse[] = results[0];
                const highlightIndex: number = results[1];
                if (this.nearByStationsAvailable && this.keyDownCount < this.nearByStations) {
                    let nearBySearchResults;
                    this.nearBySearchResults$?.subscribe(nearBy => {
                        nearBySearchResults = nearBy;
                        const nearByHighlightIndex: number = this.keyDownCount;
                        this.onOptionSelect(nearBySearchResults[nearByHighlightIndex >= 0 ? nearByHighlightIndex : 0]);
                        this.onBlur();
                    });
                } else if (this.recentStationsAvailable && this.keyDownCount < this.nearByStations + this.recentStations) {
                    let recentSearchResults;
                    this.recentSearchResults$?.subscribe(recent => {
                        recentSearchResults = recent;
                        const recentHighlightIndex: number = this.keyDownCount - this.nearByStations;
                        this.onOptionSelect(recentSearchResults[recentHighlightIndex >= 0 ? recentHighlightIndex : 0]);
                        this.onBlur();
                    });
                } else {
                    this.onOptionSelect(searchResults[highlightIndex ? highlightIndex : 0]);
                    this.onBlur();
                }
            });
    }

    public onMouseOver(index: number): void {
        this.highlight$.next(index);
        this.nearByHighlight$.next(null);
        this.recentHighlight$.next(null);
    }

    public onNearByMouseOver(index: number): void {
        this.nearByHighlight$.next(index);
        this.recentHighlight$.next(null);
        this.highlight$.next(null);
    }

    public onRecentMouseOver(index: number): void {
        this.recentHighlight$.next(index);
        this.nearByHighlight$.next(null);
        this.highlight$.next(null);
    }

    public onBlur(): void {
        this.inputElement.nativeElement.blur();
        // Need to wait before setting focus to false since a user may have clicked on an option
        // and a click event could take up to 300ms to fire.
        setTimeout(
            () => {
                this.isFocused = false;
                this.mousedownStarted = false;
            },
            this.mousedownStarted ? 301 : 0
        );
        this.keyDownCount = 0;
    }

    public onInputContentChange(inputValue: string): void {
        this.userInput = inputValue;
        this.searchValues$.next(this.userInput);
        if (this.userInput === "") {
            this.fetchNearByStation();
            this.fetchRecentStation();
        }
    }

    protected runQuery(query: string): Observable<StationApiResponse[]> {
        if (query) {
            this.searchLabel = "Search results:";
            this.hideNearByStation = true;
            this.hideRecentStation = true;
            return this.stationService.search(query).pipe(
                map(stations =>
                    stations.filter(station => {
                        let stationAllowed = this.isStnrPicker === false || (this.isStnrPicker === true && station.smartCardSupport === true);

                        if (stationAllowed === false) {
                            return false;
                        }

                        // include extra London Zone stations that only work as itinerary stations for seasons, despite having itineraryStation as "NO"
                        if (this.includeTravelPassStations) {
                            stationAllowed = station.itineraryStation === "YES" || station.name.indexOf("London Travelcard") === 0;
                        } else {
                            stationAllowed = station.itineraryStation === "YES";
                        }

                        return stationAllowed;
                    })
                )
            );
        } else {
            this.searchLabel = "Popular stations:";
            this.hideNearByStation = false;
            this.hideRecentStation = false;
            this.nearBySearchResults$ = new ReplaySubject(1);
            this.recentSearchResults$ = new ReplaySubject(1);
            this.stationService.recommendedStations$?.subscribe((stations: StationApiResponse[]) => {
                stations.filter(station => this.isStnrPicker === false || (this.isStnrPicker === true && station.smartCardSupport === true));
            });
            return this.stationService.recommendedStations$;
        }
    }

    protected setValue(value: string): void {
        this.values$.next(value);
    }

    protected toggleSearchResultPanelOpacity(show: boolean): void {
        if (this.searchResultPanel && this.searchResultPanel.nativeElement) {
            this.searchResultPanel.nativeElement.style.opacity = Number(show);
        }

        if (this.searchResultPanelHeader && this.searchResultPanelHeader.nativeElement) {
            this.searchResultPanelHeader.nativeElement.style.opacity = Number(show);
        }
    }

    public handlePermission() {
        navigator.permissions.query({ name: "geolocation" }).then(result => {
            if (result.state === "granted") {
                this.enableLocationServices();
            } else if (result.state === "prompt") {
                this.enableLocationServices();
            } else if (result.state === "denied") {
                this.enableLocationServices();
            }
            result.addEventListener("change", () => {
                this.enableLocationServices();
            });
        });
    }

    public enableLocationServices() {
        const current = navigator.geolocation;
        const options = {
            enableHighAccuracy: true,
            timeout: 10000,
            maximumAge: 0
        };
        if (current) {
            current.getCurrentPosition(
                position => {
                    this.longitude = position.coords.longitude;
                    this.latitude = position.coords.latitude;
                    this.locationServicesActive = true;
                    if (this.searchLabel !== "Search results:") {
                        this.fetchNearByStation();
                    }
                },
                err => {
                    if (err.code === 1) {
                        this.locationServicesActive = false;
                        this.locationLabel = "Turn on location services in your settings to view nearby stations";
                    }
                    if (err.code === 3) {
                        this.locationServicesActive = null;
                        this.locationLabel = "Enable location settings to see nearby stations";
                    }
                    this.fetchedNearByStation = false;
                    this.nearBySearchResults$.next(null);
                    console.log("error in fetching location of the user", err);
                },
                options
            );
        } else {
            this.locationServicesAvailable = false;
            this.locationLabel = "Your device does not allow location services";
        }
    }

    public fetchNearByStation() {
        if (this.locationServicesActive) {
            this.locationLabel = "Nearby stations:";
            this.fetchedNearByStation = true;
            this.loadNearByStations = true;
            this.stationService
                .getNearByStation(this.latitude + "", this.longitude + "")
                .pipe(
                    debounceTime(400),
                    distinctUntilChanged(),
                    switchMap(x => of(x))
                )
                .subscribe(
                    (nearByStations: AceNearByStation) => {
                        if (nearByStations._nearByStation?.length > 0) {
                            this.nearByStations = nearByStations._nearByStation?.length;
                            this.nearBySearchResults$.next(nearByStations._nearByStation);
                            this.nearByStationsAvailable = true;
                            this.highlight$.next(null);
                            this.loadNearByStations = false;
                        } else {
                            this.loadNearByStations = false;
                            this.nearByStationsAvailable = false;
                        }
                    },
                    err => {
                        this.loadNearByStations = false;
                        this.nearByStationsAvailable = false;
                        console.log("Error in fetching near by stations : ", err.errorMessage);
                    }
                );
        }
    }

    public fetchRecentStation() {
        this.recentLocationLabel = "Recent Stations:";
        this.stationService
            .getRecentStation(this.currentUser.userId)
            .pipe(
                debounceTime(400),
                distinctUntilChanged(),
                switchMap(x => of(x))
            )
            .subscribe(
                recentStations => {
                    if (recentStations.length > 0) {
                        this.recentStations = recentStations.length;
                        this.recentStationsAvailable = true;
                        this.recentSearchResults$.next(recentStations);
                    } else if (this.recentSearchResults$) {
                        this.recentStations = recentStations.length;
                        this.recentStationsAvailable = true;
                        this.recentSearchResults$.next(recentStations);
                    } else {
                        this.hideRecentStation = true;
                        this.recentStationsAvailable = false;
                    }
                },
                err => {
                    this.recentStationsAvailable = false;
                    console.log("Error in fetching recent stations : ", err.errorMessage);
                }
            );
    }

    private hideNearByStations() {
        this.hideNearByStation = true;
    }
}
