import { AbstractControl } from "@angular/forms";

export class NusValidator {
    public static wellFormedNus(control: AbstractControl) {
        if (control.value) {
            const value = control.value.replace(/-|\s/g, "");
            const pattern = new RegExp(/^\d{18}$/);
            return pattern.test(String(value)) ? null : { wellFormedNus: true };
        }
    }
}
